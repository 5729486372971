import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { EMPTY_PAGE_SLUG } from '@/pages/app/Portfolio/types';
import { useProvidePortfolio } from '@/pages/app/Portfolio/useProvidePortfolio';
import { evaluatePortfolioSubRoute } from '@/utils';

import PortfolioManagementRouter from './PortfolioManagement';
import { SubRouter } from './index';
import { PORTFOLIO_SUB_ROUTES } from './routes';

const PortfolioRouter: React.FC<PropsWithChildren> = () => {
  const { state } = useProvidePortfolio();

  const defaultPortfolioId = state.portfolios[0]?._id;
  const redirectSlug = `${
    defaultPortfolioId ?? EMPTY_PAGE_SLUG
  }/${evaluatePortfolioSubRoute()}`;

  return (
    <>
      <PortfolioManagementRouter />
      <SubRouter routes={PORTFOLIO_SUB_ROUTES} rootPath={APP_ROUTE_PORTFOLIOS}>
        <Route
          exact
          path={`/${APP_ROUTE_PORTFOLIOS}`}
          render={() => {
            return <Redirect to={`/${APP_ROUTE_PORTFOLIOS}/${redirectSlug}`} />;
          }}
        />
      </SubRouter>
    </>
  );
};

export default PortfolioRouter;
