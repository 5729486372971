import { openApiConfig } from '@/utils';
import { InsightsApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

export const INSIGHTS_QUERY_KEYS = {
  SECTORS_ASSETS_HELD: 'sectors-assets-held',
  SECTORS_PERFORMERS: 'sectors-performers',
};

export const useGetSectorsAssetsHeld = () => {
  return useQuery([INSIGHTS_QUERY_KEYS.SECTORS_ASSETS_HELD], async () => {
    const insightsApi = new InsightsApi(openApiConfig());

    const result = await insightsApi.getSectorsAssetsHeld();

    return result.data;
  });
};

export const useGetSectorsPerformers = () => {
  return useQuery([INSIGHTS_QUERY_KEYS.SECTORS_PERFORMERS], async () => {
    const insightsApi = new InsightsApi(openApiConfig());

    const result = await insightsApi.getSectorsPerformers();

    return result.data;
  });
};
