export enum TAB_KEYS {
  DETAILS = 'DETAILS',
  FEES = 'FEES',
  INCOME = 'INCOME',
  EQUITY_STATS = 'EQUITY_STATS',
  PLATFORMS = 'PLATFORMS',
}

export enum TAB_KEYS_MPS {
  DFM = 'DFM',
  MPS_OPTIONS = 'MPS_OPTIONS',
  SUSTAINABILITY = 'SUSTAINABILITY',
  RISK_RATINGS = 'RISK_RATINGS',
  PLATFORMS = 'PLATFORMS',
}
