import { ReactComponent as GraphicFile } from '@/assets/svg/graphic-file.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { Button, Dropdown } from 'antd';

export const FolderEmptyState = () => {
  return (
    <div
      className={`min-h-[70vh] relative w-full rounded-lg 
        bg-white overflow-hidden text-center px-5`}
    >
      <div className="pt-48 flex flex-col items-center">
        <GraphicFile />
        <h1 className="pt-4 text-[#313341] text-xl font-semibold block">
          No buckets added
        </h1>
        <p className="pt-2 text-sm text-neutral-700">
          Start adding buckets to this folder
        </p>
        <div className="pt-4">
          <Dropdown
            placement="bottom"
            trigger={['click']}
            menu={{
              items: [
                {
                  label: 'Existing Bucket',
                  key: 'existing-bucket',
                },
                {
                  label: 'New Bucket',
                  key: 'new-bucket',
                },
              ],
            }}
          >
            <Button
              type="primary"
              className="h-10 text-sm"
              icon={<AddIcon className="icon !h-3" />}
            >
              Add bucket
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
