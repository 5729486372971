import { Skeleton } from 'antd';

const SubCardSkeletonLoader = () => {
  return (
    <>
      <div className="flex flex-row gap-2 justify-between">
        <Skeleton.Button
          active
          size="default"
          style={{ width: 64, height: 16 }}
        />
        <Skeleton.Button
          active
          size="default"
          style={{ width: 120, height: 16 }}
        />
      </div>
      <div className="w-full flex flex-col h-full pt-7 gap-2">
        <Skeleton.Input
          active
          size="default"
          style={{ width: 240, height: 16 }}
        />
        <Skeleton.Input
          active
          size="default"
          style={{ width: 240, height: 16 }}
        />
        <Skeleton.Input
          active
          size="default"
          style={{ width: 240, height: 16 }}
        />
      </div>
    </>
  );
};

export default SubCardSkeletonLoader;
