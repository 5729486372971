import { Skeleton } from 'antd';
import React, { PropsWithChildren } from 'react';

interface ResearchCardProps {
  isLoading: boolean;
  title: string;
  subtitle: string;
  icon: JSX.Element;
  count: number;
}

const ResearchCard: React.FC<PropsWithChildren<ResearchCardProps>> = ({
  title,
  subtitle,
  icon,
  count,
  isLoading,
}) => {
  return (
    <div className="snap-start">
      <div className="p-4 bg-white rounded-lg shadow-card h-full w-[296px]">
        <div className="flex justify-between items-center mb-1.5 gap-x-2">
          <Skeleton
            title={false}
            paragraph={{ rows: 1 }}
            loading={isLoading}
            active
          >
            <span className="text-neutral-200 font-bold text-sm">{title}</span>
          </Skeleton>
        </div>
        <div className="flex flex-col justify-between">
          <div className="w-full text-xs text-normal text-neutral">
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              active
              loading={isLoading}
            >
              <span className="text-xs font-normal text-neutral">
                {subtitle}
              </span>
            </Skeleton>
          </div>
          <div className="flex items-center mt-2">
            {icon}
            {/* <TrendIcon
              className={cx(
                'icon text-xl &_path:stroke-width-2.4 !fill-none',
                cardItem.value >= 0
                  ? '[transform:rotate(180deg)_scaleX(-1)]'
                  : '',
              )}
            /> */}
            <h1 className="text-2xl text-darkest font-bold">
              <Skeleton
                title={false}
                paragraph={{ rows: 1 }}
                active
                loading={isLoading}
              >
                {count}
              </Skeleton>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
