import { Fund, Performance } from '@aminsights/contract';
import {
  MonthCode,
  RipsReturnType,
  findReturnByMonthCode,
} from '@aminsights/shared';

import { findPercentileByMonthCode } from '@aminsights/shared';
import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

export const computedAnnualisedRowMapper = (
  key: string,
  fund: Fund,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: fund?.fundName,
    ytdReturn: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.TotalReturn,
    ),
    ytdPercentile: findPercentileByMonthCode(fund, MonthCode.YTD),

    threeMonthsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M3,
      RipsReturnType.TotalReturn,
    ),
    threeMonthsPercentile: findPercentileByMonthCode(fund, MonthCode.M3),

    sixMonthsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M6,
      RipsReturnType.TotalReturn,
    ),
    sixMonthsPercentile: findPercentileByMonthCode(fund, MonthCode.M6),

    oneYearReturn: findReturnByMonthCode(
      fund,
      MonthCode.M12,
      RipsReturnType.TotalReturn,
    ),
    oneYearPercentile: findPercentileByMonthCode(fund, MonthCode.M12),

    threeYearsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M36,
      RipsReturnType.TotalReturn,
    ),
    threeYearsPercentile: findPercentileByMonthCode(fund, MonthCode.M36),

    fiveYearsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M60,
      RipsReturnType.TotalReturn,
    ),
    fiveYearsPercentile: findPercentileByMonthCode(fund, MonthCode.M60),

    ytdSP: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.SharePrice,
    ),
    threeMonthsSP: findReturnByMonthCode(
      fund,
      MonthCode.M3,
      RipsReturnType.SharePrice,
    ),
    sixMonthsSP: findReturnByMonthCode(
      fund,
      MonthCode.M6,
      RipsReturnType.SharePrice,
    ),
    fiveYearsSP: findReturnByMonthCode(
      fund,
      MonthCode.M60,
      RipsReturnType.SharePrice,
    ),
  };
};

/**
 * Maps a Fund into ICalendarYearPerformanceData
 *
 * As of writing this comment it is used in 2 components:
 *
 * In compare tool:
 *   CompareCalendarYearPerformance.tsx,
 *
 * In fund detail page:
 *   CalendarPerformanceTable.tsx
 */
export const calendarYearPerformanceMapper = (
  key: string,
  fund: Fund,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: fund.fundName,
    totalReturnYtdPct: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.TotalReturn,
    ),
    totalReturnYtdMorningstarCategoryPcl:
      fund?.totalReturnYtdMorningstarCategoryPcl,
    totalReturn1calendarYearsAgoPct: fund.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: fund.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: fund.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: fund.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: fund.totalReturn5calendarYearsAgoPct,

    totalReturn1calendarYearsAgoCategoryPcl:
      fund.totalReturn1calendarYearsAgoCategoryPcl ??
      (fund as any)?.totalReturn1CalendarYearsAgoCategoryPcl,
    totalReturn2calendarYearsAgoCategoryPcl:
      fund.totalReturn2calendarYearsAgoCategoryPcl ??
      (fund as any)?.totalReturn2CalendarYearsAgoCategoryPcl,
    totalReturn3calendarYearsAgoCategoryPcl:
      fund.totalReturn3calendarYearsAgoCategoryPcl ??
      (fund as any)?.totalReturn3CalendarYearsAgoCategoryPcl,
    totalReturn4calendarYearsAgoCategoryPcl:
      fund.totalReturn4calendarYearsAgoCategoryPcl ??
      (fund as any)?.totalReturn4CalendarYearsAgoCategoryPcl,
    totalReturn5calendarYearsAgoCategoryPcl:
      fund.totalReturn5calendarYearsAgoCategoryPcl ??
      (fund as any)?.totalReturn5CalendarYearsAgoCategoryPcl,

    sharePriceReturnYtdPct: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.SharePrice,
    ),
    sharePriceReturn1calendarYearsAgoPct:
      fund.sharePriceReturn1calendarYearsAgoPct,
    sharePriceReturn2calendarYearsAgoPct:
      fund.sharePriceReturn2calendarYearsAgoPct,
    sharePriceReturn3calendarYearsAgoPct:
      fund.sharePriceReturn3calendarYearsAgoPct,
    sharePriceReturn4calendarYearsAgoPct:
      fund.sharePriceReturn4calendarYearsAgoPct,
    sharePriceReturn5calendarYearsAgoPct:
      fund.sharePriceReturn5calendarYearsAgoPct,
  };
};

export const benchmarkAnnualisedMapper = (
  key: string,
  benchmark: Performance,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: benchmark?.name,
    ytdReturn: benchmark?.totalReturnYtdPct,
    threeMonthsReturn: benchmark?.totalReturn3MonthPct,
    sixMonthsReturn: benchmark?.totalReturn6MonthPct,
    oneYearReturn: benchmark?.totalReturn1YearPct,
    threeYearsReturn: benchmark?.totalReturn3YearPct,
    fiveYearsReturn: benchmark?.totalReturn5YearPct,
  };
};

export const benchmarkCalendarYearMapper = (
  key: string,
  benchmark: Performance,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: benchmark.name,
    totalReturnYtdPct: benchmark.totalReturnYtdPct,
    totalReturn1calendarYearsAgoPct: benchmark.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: benchmark.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: benchmark.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: benchmark.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: benchmark.totalReturn5calendarYearsAgoPct,
  };
};
