import { RipsReturnType } from '@aminsights/shared';
import { AreaConfig } from '@ant-design/charts';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetAbsoluteChartDataWithPortfolio from '@/hooks/query-hooks/chart-hooks/useGetAbsoluteChartDataWithPortfolio';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import { getLegendIdToRemove, getLegendSuperTag } from '@/utils';

import {
  SECTORS_COLORS_MAP,
  getSectorsSortOrder,
} from '@/pages/app/Insights/utils';
import BaseChart from '../components/BaseChart';
import { PerformanceCardDetailsWithPortfolio } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';

interface AbsoluteSectorsChartProps {
  sectors: string[];
}

const AbsoluteSectorsChart: React.FC<
  PropsWithChildren<AbsoluteSectorsChartProps>
> = ({ sectors }) => {
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<
    PerformanceCardDetailsWithPortfolio[]
  >([]);

  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading } = useGetAbsoluteChartDataWithPortfolio({
    sectors: sectors ? sectors : [],
    isins: [],
    period,
    investmentTrustReturn: RipsReturnType.TotalReturn,
  });

  const absoluteData = data?.data || [];

  useEffect(() => {
    if (data?.legendData?.length) {
      const mappedLegendData = data.legendData.map(legendItem => ({
        ...legendItem,
        tooltip: legendItem.tooltip || '',
        value: legendItem.value || '',
        id: legendItem.id || '',
        label: legendItem.label || '',
        // Since the sectors are hardcoded, we also use predefined colors.
        color: SECTORS_COLORS_MAP[legendItem.id || ''] || '',
        date: legendItem.date || '',
        dataTag: legendItem.dataTag,
      }));
      const sortedLegendData = getSectorsSortOrder(mappedLegendData);

      setLegendData(sortedLegendData);
    }
  }, [data]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: absoluteData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return SECTORS_COLORS_MAP[id] || '';
      },
    }),
    [absoluteData],
  );

  return (
    <div className="w-full h-full">
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          dataTestId="absoluteSectorsChart"
          onUpdateLegend={setLegendData}
          tooltipOrder={tooltipOrder}
        />
      )}
      {isLoading && (
        <img className="md:h-[calc(100%-120px)] my-5" src={loaderImage} />
      )}
      <div
        data-test-id="absoluteSectorsChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                value={legendItem.value}
                id={getLegendIdToRemove(legendItem)}
                tooltip={legendItem.tooltip || legendItem.label}
                color={legendItem.color}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                dataTag={legendItem.dataTag}
                date={legendItem.date}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default AbsoluteSectorsChart;
