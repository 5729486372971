import React, { PropsWithChildren } from 'react';

import Tooltip from '@/components/Tooltip';

import Loader from '../Loader';

interface SectionItemProps {
  title: string;
  content: string | React.ReactNode;
  loading?: boolean;
  tooltipTitle?: string;
  dataTestId: string;
}

const SectionItem: React.FC<PropsWithChildren<SectionItemProps>> = ({
  title,
  content,
  loading,
  tooltipTitle,
  dataTestId,
}) => {
  return (
    <div
      className="
        flex w-30 flex-col items-start
        md:items-start md:flex-col md:min-w-38 md:w-auto md:pr-4 md:pb-4
        sm:grid sm:min-w-[85px] 
        xs:min-w-[80px] 
        lg:min-w-[135px]
      "
      data-test-id={`fundDetailsHeader${dataTestId}`}
    >
      <Loader
        width="50px"
        loading={loading}
        component={
          <h5 className="text-xs w-24 md:w-auto md:mb-2 text-[#516285]">
            {title}
          </h5>
        }
      />
      <Loader
        className="mt-2"
        loading={loading}
        component={
          <h1
            className="
              text-base font-semibold sm:text-lg sm:font-semibold md:text-2xl md:font-bold 
              text-darkest relative
            "
          >
            <Tooltip
              title={tooltipTitle}
              placement="bottomLeft"
              color="#3E414B"
            >
              {content}
            </Tooltip>
          </h1>
        }
      />
    </div>
  );
};

export default SectionItem;
