import { Alert, AlertProps } from 'antd';
import React, { PropsWithChildren } from 'react';

import style from './style.module.less';

export const SimpleAlert: React.FC<PropsWithChildren<AlertProps>> = ({
  message,
  type = 'success',
  closable,
  afterClose,
}) => {
  return (
    <Alert
      className={style[type]}
      message={message}
      type={type}
      closable={closable}
      afterClose={afterClose}
    />
  );
};
