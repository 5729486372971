import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { LEGAL_STRUCTURE } from '@aminsights/shared';
import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Analyst from './Analyst';
import Meetings from './Meetings';
import Ratings from './Ratings';
import { ResearchSubRouter } from './ResearchSubRouter';

const ResearchLayout = () => {
  const { data: fundData } = useFundByIsInParam();
  const { isRestrictedAccess } = useUpgradeAccess();

  const isLegalStructureMPS = useMemo(() => {
    return fundData?.fund.legalStructure === LEGAL_STRUCTURE.MPS;
  }, [fundData]);
  const { id: fundMetadataId } = useParams<{ id: string }>();

  return (
    <div className="grid lg:grid-cols-[1fr_360px] gap-4 md:px-4 pt-2">
      <div className="w-full bg-white flex flex-col p-4 lg:rounded-lg min-h-80 lg:min-h-128">
        <ResearchSubRouter />
      </div>
      <div className="flex flex-col gap-y-4">
        {!(isLegalStructureMPS || isRestrictedAccess) && (
          <Analyst fundMetadataId={fundMetadataId} />
        )}
        <Meetings />
        {!(isLegalStructureMPS || isRestrictedAccess) && <Ratings />}
      </div>
    </div>
  );
};

export default memo(ResearchLayout);
