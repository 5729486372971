import { RESTRICTED_FEATURE } from '@/constants/features';
import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { ReactNode } from 'react';

import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import CorrelationMatrix from '@/partials/CorrelationMatrix/CorrelationMatrix';

import WatchlistEquityGroup from './Group/Equity';
import EquityChildren from './Group/EquityChildren';
import WatchlistFixedIncomeGroup from './Group/FixedIncome';
import FixedIncomeChildren from './Group/FixedIncomeChildren';
import WatchlistPerfRiskGroup from './Group/PerfRisk';
import PerfRiskChildren from './Group/PerfRiskChildren';
import { ChartsTab } from './Watchlist/components';
import WatchlistAssetAllocationTable from './WatchlistTables/WatchlistAssetAllocation/WatchlistAssetAllocationTable';
import WatchlistCurrencyTable from './WatchlistTables/WatchlistCurrency/WatchlistCurrencyTable';
import WatchlistESGTable from './WatchlistTables/WatchlistESG/WatchlistESGTable';
import WatchlistGenderPayGapTable from './WatchlistTables/WatchlistGenderPayGap/WatchlistGenderPayGapTable';
import WatchlistIncomeTable from './WatchlistTables/WatchlistIncome/WatchlistIncomeTable';
import WatchlistSummaryTable from './WatchlistTables/WatchlistSummary/WatchlistSummaryTable';

export enum WATCHLIST_TAB_GROUP {
  EQUITY = 'Equity',
  EQUITY_STYLE = 'Equity Style',
  FIXED_INCOME = 'Fixed Income',
  FIXED_INCOME_STYLE = 'Fixed Income Style',
  PERF_AND_RISK = 'Perf and Risk',
}

interface WatchlistTabItem {
  key: WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP;
  label: ReactNode;
  children?: ReactNode;
  lockedForRestrictedAccess?: RESTRICTED_FEATURE;
}

export const watchlistTabGroup = [
  WATCHLIST_TAB_GROUP.EQUITY,
  WATCHLIST_TAB_GROUP.FIXED_INCOME,
  WATCHLIST_TAB_GROUP.PERF_AND_RISK,
];

export const fixedIncomeTabs = [
  WATCHLIST_TAB_KEY.STYLE_FI,
  WATCHLIST_TAB_KEY.SECTORS_FI,
  WATCHLIST_TAB_KEY.GEOGRAPHY_FI,
  WATCHLIST_TAB_KEY.MATURITY,
  WATCHLIST_TAB_KEY.CREDIT_QUALITY,
];

export const perfRiskTabs = [
  WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
  WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
  WATCHLIST_TAB_KEY.RISK,
];

export const equityTabs = [
  WATCHLIST_TAB_KEY.STYLE_EQ,
  WATCHLIST_TAB_KEY.SECTORS_EQ,
  WATCHLIST_TAB_KEY.GEOGRAPHY_EQ,
  WATCHLIST_TAB_KEY.MARKET_CAP,
  WATCHLIST_TAB_KEY.EQUITY_STATS,
];

export const watchlistTabGroupMappings: Partial<
  Record<
    (typeof watchlistTabGroup)[number],
    (WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP)[]
  >
> = {
  'Fixed Income': fixedIncomeTabs,
  'Perf and Risk': perfRiskTabs,
  Equity: equityTabs,
};

export const getWatchlistTabItems: (
  activeTab: WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP,
) => Array<WatchlistTabItem> = activeTab => [
  {
    label: (
      <ElementWithDataTestId
        className="with-label is-watchlist-single-tab-item"
        id={'tabCharts'}
        label={'Charts'}
      />
    ),
    key: WATCHLIST_TAB_KEY.CHARTS,
    children: <ChartsTab />,
  },
  {
    key: WATCHLIST_TAB_KEY.SUMMARY,
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabSummary"
      >
        Summary
      </span>
    ),
    children: (
      <WatchlistSummaryTable
        isActive={activeTab === WATCHLIST_TAB_KEY.SUMMARY}
      />
    ),
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabAssetAllocation"
      >
        Asset Allocation
      </span>
    ),
    key: WATCHLIST_TAB_KEY.ASSET_ALLOCATION,
    children: (
      <WatchlistAssetAllocationTable
        isActive={activeTab === WATCHLIST_TAB_KEY.ASSET_ALLOCATION}
      />
    ),
  },
  {
    label: (
      <div data-test-id="tabGroupEquity">
        <WatchlistEquityGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.EQUITY,
    children: <EquityChildren />,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_EQUITY,
  },
  {
    label: (
      <div data-test-id="tabGroupFixedIncome">
        <WatchlistFixedIncomeGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.FIXED_INCOME,
    children: <FixedIncomeChildren />,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_FIXED_INCOME,
  },
  {
    key: WATCHLIST_TAB_KEY.CURRENCY,
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabCurrency"
      >
        Currency
      </span>
    ),
    children: (
      <WatchlistCurrencyTable
        isActive={activeTab === WATCHLIST_TAB_KEY.CURRENCY}
      />
    ),
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_CURRENY,
  },
  {
    label: (
      <div data-test-id="tabGroupPerfRisk">
        <WatchlistPerfRiskGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.PERF_AND_RISK,
    children: <PerfRiskChildren />,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_PERF_AND_RISK,
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="correlationMatrix"
      >
        Correlation
      </span>
    ),
    key: WATCHLIST_TAB_KEY.CORRELATION_MATRIX,
    children: <CorrelationMatrix />,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_CORRELATION,
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabIncome"
      >
        Income
      </span>
    ),
    key: WATCHLIST_TAB_KEY.INCOME,
    children: (
      <WatchlistIncomeTable isActive={activeTab === WATCHLIST_TAB_KEY.INCOME} />
    ),
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_INCOME,
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabESG"
      >
        ESG
      </span>
    ),
    key: WATCHLIST_TAB_KEY.ESG,
    children: (
      <WatchlistESGTable isActive={activeTab === WATCHLIST_TAB_KEY.ESG} />
    ),
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_ESG,
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabGenderPayGap"
      >
        Gender Pay Gap
      </span>
    ),
    key: WATCHLIST_TAB_KEY.GENDER_PAY_GAP,
    children: (
      <WatchlistGenderPayGapTable
        isActive={activeTab === WATCHLIST_TAB_KEY.GENDER_PAY_GAP}
      />
    ),
    lockedForRestrictedAccess: RESTRICTED_FEATURE.WATCHLIST_GENDER_PAY_GAP,
  },
];
