import { ChartsApi } from '@aminsights/contract';
import { MS_DATE_FORMAT } from '@aminsights/shared';
import { useQuery } from '@tanstack/react-query';

import {
  CORRELATION_MATRIX,
  WATCHLIST,
} from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { openApiConfig } from '@/utils';
import { Dayjs } from 'dayjs';

const chartsApi = new ChartsApi(openApiConfig());

const useCorrelationMatrixQuery = ({
  isins,
  from,
  to,
}: {
  isins?: string[];
  from: Dayjs;
  to: Dayjs;
}) => {
  const fromFormatted = from.format(MS_DATE_FORMAT);
  const toFormatted = to.format(MS_DATE_FORMAT);

  const query = useQuery(
    [WATCHLIST, CORRELATION_MATRIX, isins, fromFormatted, toFormatted],
    async () => {
      const response = await chartsApi.getCorrelationMatrix(isins, [
        fromFormatted,
        toFormatted,
      ]);
      return response.data;
    },
    {
      enabled: !!isins,
    },
  );

  return query;
};

export default useCorrelationMatrixQuery;
