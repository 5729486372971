import { SignificantChangesItem } from '@aminsights/contract';
import React, { PropsWithChildren } from 'react';

import { Avatar } from '@/components';
import { generateAvatarColor } from '@/utils/generate-color';

interface TableFixedProps {
  data: SignificantChangesItem;
}

const SignificantChangesFirstColumn: React.FC<
  PropsWithChildren<TableFixedProps>
> = ({ data }) => {
  return (
    <div className="w-full flex items-center">
      <div
        className={`hidden md:block ${
          !data.assetClassCode ? '!hidden' : 'mr-2'
        }`}
      >
        {data.assetClassCode && (
          <Avatar
            className="text-xxs font-bold capitalize"
            color={generateAvatarColor(data.assetClassCode)}
            size="small"
          >
            {data.assetClassCode}
          </Avatar>
        )}
      </div>
      <p className="text-xs font-bold cursor-pointer text-darkest hover:text-primary whitespace-normal">
        {data.fundName}
      </p>
    </div>
  );
};

export default SignificantChangesFirstColumn;
