import { Meeting, MeetingBody } from '@aminsights/contract';
import { DISPLAY_TIME_FORMAT } from '@aminsights/shared';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
  SelectProps,
} from 'antd';
import generatePicker from 'antd/es/date-picker/generatePicker';
import TextArea from 'antd/es/input/TextArea';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '@/assets/svg/icons/icon-calendar.svg';
import { ReactComponent as ClockIcon } from '@/assets/svg/icons/icon-clock.svg';

import {
  useCreateMeeting,
  useUpdateMeeting,
} from '@/hooks/query-hooks/research-hooks/meetings-hooks';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { TIME_OPTIONS } from './utils';

const AntDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

type UserOptions = SelectProps['options'] & Meeting['attendees'];

const MAX_NOTE_CHARACTERS = 2000;

const DEFAULT_FORM_VALUES: MeetingForm = {
  date: dayjs(),
  time: '',
  attendees: [],
  note: '',
};

interface MeetingForm {
  date: Dayjs;
  time: string;
  attendees: Meeting['attendees'];
  note: string;
}

interface MeetingFormModalProps {
  isVisible: boolean;
  userOptions: UserOptions;
  initialValue: Meeting | null;
  toggleForm: () => void;
  onSubmitForm: (data: MeetingBody) => void;
}

const MeetingFormModal: FC<MeetingFormModalProps> = ({
  isVisible,
  initialValue,
  userOptions,
  toggleForm,
}) => {
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const [isTimeDrawerVisible, setIsTimeDrawerVisible] = useState(false);
  const { isLoading: isCreatingMeeting, mutateAsync: createMeeting } =
    useCreateMeeting();
  const { isLoading: isUpdatingMeeting, mutateAsync: updateMeeting } =
    useUpdateMeeting();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const formMethods = useForm<MeetingForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'all',
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = formMethods;

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (!initialValue) {
      reset(DEFAULT_FORM_VALUES);
    } else {
      const { note, attendees, date } = initialValue;
      const combinedDateTime = dayjs(date);
      const datePart = combinedDateTime.startOf('day');
      const timePart = combinedDateTime.format(DISPLAY_TIME_FORMAT);

      reset({ note, attendees, date: datePart, time: timePart });
    }
  }, [initialValue, isVisible]);

  const note = watch('note');
  const attendees = watch('attendees');
  const date = watch('date');
  const time = watch('time');

  const isFormChanged = useMemo(() => {
    let isChanged = true;

    if (initialValue) {
      const initialValues = initialValue || DEFAULT_FORM_VALUES;

      let currentDateTime = dayjs(date);
      if (time) {
        const parsedTime = dayjs(time, [DISPLAY_TIME_FORMAT, 'h:mm']);
        currentDateTime = currentDateTime
          .hour(parsedTime.hour())
          .minute(parsedTime.minute())
          .second(0)
          .millisecond(0);
      }
      const initialDateTime = dayjs(initialValues.date);

      const isAttendeesChanged =
        attendees.length !== initialValues.attendees.length ||
        attendees.some(
          (attendee, index) =>
            attendee.id !== initialValues.attendees[index]?.id ||
            attendee.name !== initialValues.attendees[index]?.name,
        );

      isChanged =
        note !== initialValues.note ||
        !currentDateTime.isSame(initialDateTime) ||
        isAttendeesChanged;
    }

    return isChanged;
  }, [initialValue, note, attendees, date, time]);

  const handleFormSubmit = async (data: MeetingForm) => {
    let parsedDate = data.date;
    if (data.time) {
      const parsedTime = dayjs(data.time, [DISPLAY_TIME_FORMAT, 'h:mm']);
      parsedDate = data.date
        .hour(parsedTime.hour())
        .minute(parsedTime.minute())
        .second(0)
        .millisecond(0);
    } else {
      parsedDate = data.date.hour(0).minute(0).second(0).millisecond(0);
    }

    const formattedData: MeetingBody = {
      date: parsedDate.toISOString(),
      attendees: data.attendees,
      note: data.note,
      fundMetadataId,
    };

    if (initialValue?._id) {
      await updateMeeting({
        fundMetadataId,
        meetingId: initialValue._id,
        body: formattedData,
      });
    } else {
      await createMeeting({ fundMetadataId, body: formattedData });
    }
    toggleForm();
  };

  const handleCancel = () => {
    if (isCreatingMeeting || isUpdatingMeeting) return;
    reset();
    toggleForm();
  };

  const isFormValid =
    note.length > 0 && attendees.length > 0 && Object.keys(errors).length === 0;

  return (
    <Modal
      open={isVisible}
      className={cx(
        'action-modal max-sm:full-page-modal',
        'md:min-w-[600px] lg:min-w-[800px]',
        '[&_.ant-modal-body]:p-10 [&_.ant-modal-body]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-6',
      )}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          size="large"
          type="link"
          onClick={handleCancel}
          disabled={isCreatingMeeting || isUpdatingMeeting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          size="large"
          type="primary"
          className="px-4"
          onClick={handleSubmit(handleFormSubmit)}
          disabled={
            !isFormValid ||
            isCreatingMeeting ||
            isUpdatingMeeting ||
            !isFormChanged
          }
        >
          {initialValue?._id ? 'Save' : 'Add'}
        </Button>,
      ]}
      destroyOnClose
    >
      <FormProvider {...formMethods}>
        <form className="flex flex-col gap-4">
          <h3 className="text-xl font-bold text-neutral-200 mb-2">
            {initialValue?._id ? 'Edit' : 'Add'} meeting
          </h3>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 flex flex-col gap-2">
              <label
                htmlFor="meeting-date"
                className="text-xs font-medium text-neutral-200"
              >
                Date
              </label>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <div
                    className={cx(
                      '[&_.ant-picker-dropdown]:!absolute',
                      '[&_.ant-picker-dropdown]:!h-[unset]',
                      '[&_.ant-picker-panel]:!h-auto',
                      '[&_.ant-picker-now-btn]:text-primary',
                      '[&_.ant-picker-panel-container]:!shadow-modal',
                    )}
                  >
                    <AntDatePicker
                      id="meeting-date"
                      className={cx(
                        'w-full h-10 rounded text-sm text-neutral-700 font-normal',
                        'border border-neutral-300 hover:border-primary focus-within:border-primary',
                        'relative pl-8',
                      )}
                      placement="bottomLeft"
                      getPopupContainer={trigger => {
                        if (trigger.parentElement) return trigger.parentElement;
                        return trigger;
                      }}
                      suffixIcon={
                        <CalendarIcon className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-5 fill-neutral-100" />
                      }
                      allowClear={false}
                      format="D MMM YYYY"
                      {...field}
                    />
                  </div>
                )}
              />
            </div>
            <div className="flex-1 flex flex-col gap-2">
              <label
                htmlFor="meeting-time"
                className="text-xs font-medium text-neutral-200"
              >
                Time
              </label>
              <Controller
                name="time"
                control={control}
                rules={{
                  pattern: {
                    value: /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm|AM|PM)?$/,
                    message:
                      'Use the format HH:MM AM/PM (e.g., 08:30 AM or 8:30 PM).',
                  },
                }}
                render={({ field }) => (
                  <>
                    <Dropdown
                      placement="bottom"
                      trigger={['click']}
                      overlayClassName="w-[16rem] lg:w-[22rem]"
                      align={{ offset: [-10, 5] }}
                      dropdownRender={() => (
                        <div
                          className={'bg-white mt-1 pt-1 pb-2 rounded w-full'}
                        >
                          <Menu
                            className="overflow-y-auto !px-2 rounded ~shadow-none"
                            style={{ height: 268 }}
                            items={
                              isMobile
                                ? []
                                : TIME_OPTIONS.map(opt => ({
                                    ...opt,
                                    onClick: () => {
                                      field.onChange(opt.value);
                                    },
                                  }))
                            }
                          />
                        </div>
                      )}
                    >
                      <Input
                        className={cx(
                          'w-full h-10 rounded text-sm text-neutral-700 font-normal [&_.ant-input]:placeholder-neutral-100',
                          'border border-neutral-300 hover:border-primary focus-within:border-primary',
                          {
                            'border-destructive-500 hover:border-destructive-500 focus-within:border-destructive-500':
                              errors.time,
                          },
                        )}
                        placeholder="00:00"
                        onClick={() => isMobile && setIsTimeDrawerVisible(true)}
                        prefix={<ClockIcon />}
                        {...field}
                      />
                    </Dropdown>
                    {errors.time && (
                      <p className="text-xs text-destructive-500">
                        {errors.time.message}
                      </p>
                    )}
                    {isMobile && (
                      <NestedDrawer
                        menuItems={TIME_OPTIONS.map(opt => ({
                          ...opt,
                          onClick: () => {
                            field.onChange(opt.value);
                          },
                        }))}
                        visible={isTimeDrawerVisible}
                        onClose={() => setIsTimeDrawerVisible(false)}
                        title="Time"
                      />
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="meeting-attendees"
              className="text-xs font-medium text-neutral-200 mb-2"
            >
              Attendees
            </label>
            <Controller
              name="attendees"
              control={control}
              rules={{
                validate: value =>
                  value.length > 0 || 'Please add at least one attendee.',
              }}
              render={({ field }) => (
                <Select
                  id="meeting-attendees"
                  mode="tags"
                  options={userOptions}
                  placeholder="Ex. John, Jennifer,..."
                  suffixIcon={null}
                  className={cx(
                    'w-full min-h-10 custom-antd-selection',
                    '[&_.ant-select-selection-placeholder]:text-neutral-100 [&_.ant-select-selection-placeholder]:px-1',
                    '[&_.ant-select-selector]:py-[5px] [&_.ant-select-selector]:px-2 [&_.ant-select-selector]:rounded [&_.ant-select-selector]:ring-0',
                    '[&_.ant-select-selector]:border-neutral-300 [&_.ant-select-selector]:hover:border-primary',
                    '[&_.ant-select-selector]:focus-within:border-primary',
                  )}
                  fieldNames={{ label: 'name', value: 'id' }}
                  optionFilterProp="name"
                  {...field}
                  value={field.value.map(val => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  onChange={(selectedValues: SelectProps['options']) => {
                    const mappedValues = (selectedValues ?? []).map(val => ({
                      id: val.value,
                      name: val.label ?? val.value,
                    }));
                    field.onChange(mappedValues);
                  }}
                  labelInValue
                />
              )}
            />
            <p className="text-xs text-neutral h-0 mb-2">
              Add one or more attendees, separated by commas.
            </p>
          </div>

          <div className="flex flex-col gap-2 h-full">
            <label
              htmlFor="meeting-note"
              className="text-xs font-medium text-neutral-200"
            >
              Meeting note
            </label>
            <Controller
              name="note"
              control={control}
              rules={{
                required: 'Meeting note is required.',
                maxLength: {
                  value: MAX_NOTE_CHARACTERS,
                  message:
                    'You have hit the 2,000 character limit. Reduce the characters and also let us know if this is a regular problem for you.',
                },
              }}
              render={({ field }) => (
                <>
                  <TextArea
                    id="meeting-note"
                    className={cx(
                      'w-full rounded p-4 text-sm text-neutral-700 placeholder-neutral-100',
                      'border border-neutral-300 hover:border-primary focus-within:border-primary',
                    )}
                    placeholder="Write something.."
                    rows={8}
                    style={{ height: '180px' }}
                    {...field}
                  />
                  {errors.note && (
                    <p className="text-xs text-destructive-500">
                      {errors.note.message}
                    </p>
                  )}
                </>
              )}
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default MeetingFormModal;
