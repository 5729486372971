import { ReactComponent as Chevron } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';
import { Avatar } from '@/components';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import { useGetNotesHistory } from '@/hooks/query-hooks/research-hooks/notes-hooks';
import { getUserInitials } from '@/utils/getUserInitials';
import { NoteStatus } from '@aminsights/contract';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

const NoteInfo = ({
  note,
  user,
  showCategory = true,
}: {
  note: any;
  user: any;
  showCategory?: boolean;
}) => {
  const humanizeStatus = (status: NoteStatus | undefined) => {
    switch (status) {
      case NoteStatus.PublishedNew:
        return 'created';
      case NoteStatus.PublishedUpdate:
        return 'edited';
      case NoteStatus.Deleted:
        return 'deleted';
      default:
        return status;
    }
  };

  return (
    <p className="text-neutral-700 text-sm">
      <strong className="font-semibold">
        {user?.given_name} {user?.family_name}
      </strong>{' '}
      <span>{humanizeStatus(note?.status)}</span>{' '}
      {showCategory && (
        <strong className="font-semibold">{note?.category}</strong>
      )}{' '}
      <span>{dayjs(note?._updatedAt).format('DD MMM YYYY - HH:mm:ss a')}</span>
    </p>
  );
};

const ResearchHistory = () => {
  const history = useHistory();
  const { id: fundId, historyId } = useParams<{
    id: string;
    historyId: string;
  }>();
  const { data: orgUsers } = useOrganizationUsers();
  const { data: notesHistory } = useGetNotesHistory(fundId);
  const activeHistory = notesHistory?.find(note => note._id === historyId);

  const user = orgUsers?.find(
    user => user.user_id === activeHistory?.createdBy, // TODO - needs to be updatedBy
  );

  const humanizeAction = (status: NoteStatus | undefined) => {
    switch (status) {
      case NoteStatus.Deleted:
        return 'Deleted by';

      case NoteStatus.PublishedUpdate:
        return 'Updated by';

      case NoteStatus.PublishedNew:
        return 'Created by';

      default:
        return 'Last Updated';
    }
  };

  return (
    <div className="">
      <Link
        className="px-2 inline-grid grid-cols-[min-content_auto] gap-2 items-center"
        to={`/fund/${fundId}/details/research`}
      >
        <Chevron className="w-2 h-2 rotate-90 fill-neutral-400" />
        <span className="text-neutral-700 text-sm font-medium">Back</span>
      </Link>

      <h3 className="mt-4 mb-6 text-sm font-bold text-neutral-900">
        Notes History
      </h3>
      <div className="grid gap-2 px-4">
        {notesHistory?.map(note => {
          const { createdBy, status, category } = note;
          const user = orgUsers?.find(user => user.user_id === createdBy);

          return (
            <div
              key={note._id}
              className="inline-grid grid-cols-[min-content_auto_min-content] gap-2 items-center border-b-[1px] border-grey-lightest pb-2"
            >
              <Avatar type="user-avatar" color="sky-blue">
                {getUserInitials(user)}
              </Avatar>
              <NoteInfo note={note} user={user} />
              <Link
                to={`/fund/${fundId}/details/research/history/${note._id}`}
                className="text-primary transition-all font-medium text-sm hover:text-primary-400"
              >
                View
              </Link>
            </div>
          );
        })}
      </div>

      <Modal
        open={!!historyId}
        onCancel={() => {
          history.push(`/fund/${fundId}/details/research/history`);
        }}
        footer={null}
        className="min-w-[800px]"
      >
        <div className="py-8">
          <h3 className="text-xl font-bold text-neutral-900 mb-2 mt-2">
            Notes History
          </h3>
          <NoteInfo note={activeHistory} user={user} />
          <div className="border-[1px] border-neutral-300 rounded p-4 mt-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-neutral-900 font-bold text-sm">
                {activeHistory?.category}
              </p>
              <p className="text-xxs text-neutral-500">
                <span>{humanizeAction(activeHistory?.status)}: </span>{' '}
                <span className="font-semibold">{user?.name}</span>{' '}
                <span>
                  {dayjs(activeHistory?._updatedAt).format(
                    'DD MMM YYYY - HH:mm:ss A',
                  )}
                </span>
              </p>
            </div>
            <p className="text-neutral-700 text-sm">
              {activeHistory?.description}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(ResearchHistory);
