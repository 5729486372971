import { ChartsApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_GENERATE_CHART_COLORS,
} from '../watchlist-hooks/query-keys';

export interface GenerateChartColorsParams {
  isins: string[];
  benchmarkId?: string;
  sectors?: string[];
  featuredIsins?: string[];
  retainSortOrder?: boolean;
  enableTransparentColors?: boolean;
}

const useGenerateChartColors = (
  params: GenerateChartColorsParams,
  options?: Omit<
    UseQueryOptions<
      Record<string, string> | null,
      unknown,
      Record<string, string> | null
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    isins,
    benchmarkId,
    sectors,
    featuredIsins,
    retainSortOrder,
    enableTransparentColors,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());
  const enabled = isins.length !== 0;

  return useQuery<
    Record<string, string> | null,
    unknown,
    Record<string, string> | null
  >(
    [CHARTS, CHARTS_GENERATE_CHART_COLORS, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.generateChartColors(
        isins,
        benchmarkId,
        sectors,
        featuredIsins,
        retainSortOrder,
        enableTransparentColors,
      );
      return response.data as Record<string, string>;
    },
    {
      ...options,
    },
  );
};

export default useGenerateChartColors;
