import { Button, Tabs } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import NestedDrawer from '@/partials/NestedDrawer';
import queryClient from '@/queryClient';
import { convertNameAndIDToSlug } from '@/utils';
import { stopPropogationOnRightClick } from '@/utils/StopPropogationOnRightClick';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

const TABS_ALL = 'All';

// TODO: Must be inside router component, that uses param:slug for watchlist
// move BucketsScrollTab to partials since it is used in Watchlist and SC Pages
export const BucketsScrollTab: React.FC<PropsWithChildren> = () => {
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const location = useLocation();
  const currentWatchlist = useCurrentWatchlist();
  const [selectedBucket, setSelectedBucket] = useState<string | undefined>(
    'All',
  );
  const buckets = currentWatchlist.data?.buckets;
  const isLoading = currentWatchlist.isLoading;

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  useEffect(() => {
    if (buckets?.length) {
      const currentTab = [
        TABS_ALL,
        ...(buckets
          ? buckets.map(bucket =>
              convertNameAndIDToSlug({ id: bucket?.id, name: bucket?.name }),
            )
          : []),
      ].find(word => location.pathname.includes(word));
      setActiveTab(currentTab);
    }
  }, [location, buckets]);

  const onNavigateTab = () => {
    queryClient.invalidateQueries([WATCHLIST]);
  };

  const tabs = [
    {
      key: TABS_ALL,
      label: (
        <Link
          to={TABS_ALL}
          onClick={event => {
            stopPropogationOnRightClick(event);
            setIsNestedDrawerVisible(false);
            setSelectedBucket('All');
          }}
        >
          <span data-test-id={`bucketTab-${TABS_ALL}`}>{TABS_ALL}</span>
        </Link>
      ),
      selected: selectedBucket === 'All',
    },
  ];

  // If buckets are defined, map them and push to items
  if (buckets?.length) {
    const mappedBuckets = buckets.map(bucket => ({
      key: convertNameAndIDToSlug(bucket),
      label: (
        <Link
          to={convertNameAndIDToSlug(bucket)}
          onClick={event => {
            stopPropogationOnRightClick(event);
            setIsNestedDrawerVisible(false);
            setSelectedBucket(bucket.name);
          }}
        >
          <span data-test-id={`bucketTab-${bucket.id}`}>{bucket.name}</span>
        </Link>
      ),
      selected: selectedBucket === bucket.name,
    }));
    tabs.push(...mappedBuckets);
  } else if (isLoading) {
    tabs.push({
      label: <span>Loading...</span>,
      key: 'loading',
      selected: false,
    });
  }

  return (
    <>
      {isMobile ? (
        <div className="hover:fill-[#40a9ff] text-neutral fill-[#545576]">
          <Button
            onClick={() => isMobile && setIsNestedDrawerVisible(true)}
            className="text-neutral w-full h-10 text-left flex items-center justify-between border border-light rounded hover:border-primary"
          >
            <span className="text-sm">{selectedBucket}</span>
            <NextArrow className="w-3 rotate-90" />
          </Button>
          <NestedDrawer
            menuItems={tabs}
            title="Select Bucket"
            visible={isNestedDrawerVisible}
            onClose={() => {
              setIsNestedDrawerVisible(false);
            }}
            key={buckets?.length}
          />
        </div>
      ) : (
        <Tabs
          activeKey={activeTab}
          onChange={onNavigateTab}
          defaultActiveKey="All"
          items={tabs}
        />
      )}
    </>
  );
};
