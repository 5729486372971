export const formatDate = (dateString?: string): string => {
  if (!dateString) return 'Invalid date';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid date';

  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  const getOrdinalSuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th'; // Covers 4th-20th
    const lastDigit = day % 10;
    return lastDigit === 1
      ? 'st'
      : lastDigit === 2
        ? 'nd'
        : lastDigit === 3
          ? 'rd'
          : 'th';
  };

  return `As at ${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};
