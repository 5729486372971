import { ChartsApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_GENERATE_CHART_COLORS,
} from '../watchlist-hooks/query-keys';

export interface GenerateChartColorsForPortfolioParams {
  portfolioId: string;
  benchmarkId?: string;
}

const useGenerateChartColorsForPortfolio = (
  params: GenerateChartColorsForPortfolioParams,
  options?: Omit<
    UseQueryOptions<
      Record<string, string> | null,
      unknown,
      Record<string, string> | null
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { portfolioId, benchmarkId } = params;
  const chartApi = new ChartsApi(openApiConfig());
  const enabled = !!portfolioId;

  return useQuery<
    Record<string, string> | null,
    unknown,
    Record<string, string> | null
  >(
    [CHARTS, CHARTS_GENERATE_CHART_COLORS, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.generateChartColorsForPortfolio(
        portfolioId,
        benchmarkId,
      );
      return response.data as Record<string, string>;
    },
    {
      ...options,
    },
  );
};

export default useGenerateChartColorsForPortfolio;
