import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import { FC, ReactNode } from 'react';

import { ReactComponent as ArrowIcon } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';

const { Panel } = Collapse;

interface AccordionProps extends CollapseProps {
  onChange?: (key: string | string[]) => void;
}

interface TestablePanelProps extends CollapsePanelProps {
  dataTestId?: string;
  children: ReactNode;
  disabled?: boolean;
}

const PanelWithAdditionalProp: FC<TestablePanelProps> = ({
  dataTestId,
  children,
  disabled,
  ...props
}) => (
  <Panel
    {...props}
    header={<div data-test-id={dataTestId}>{props.header}</div>}
    collapsible={disabled ? 'disabled' : undefined}
  >
    {children}
  </Panel>
);

const Accordion: FC<AccordionProps> = ({
  defaultActiveKey,
  onChange,
  children,
  ...props
}) => (
  <div>
    <Collapse
      bordered={false}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <ArrowIcon
          className="icon"
          style={{
            transform: isActive ? 'rotate(180deg)' : '',
          }}
        />
      )}
      onChange={onChange}
      accordion
      {...props}
    >
      {children}
    </Collapse>
  </div>
);

export { PanelWithAdditionalProp as Panel };
export default Accordion;
