import { APP_ROUTE_CHARTS } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { CHARTS_ROUTES } from './routes';

const ChartingToolRouter: React.FC<PropsWithChildren> = () => (
  <SubRouter routes={CHARTS_ROUTES} rootPath={APP_ROUTE_CHARTS}>
    <Route
      exact
      path={`/${APP_ROUTE_CHARTS}`}
      render={() => <Redirect to={`/${APP_ROUTE_CHARTS}/absolute`} />}
    />
  </SubRouter>
);

export default ChartingToolRouter;
