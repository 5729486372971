import { Skeleton } from 'antd';

import { useGetSectorsPerformers } from '@/hooks/query-hooks/insights-hooks/useInsights';

import BasePageWithMetadata from '../BasePageWithMetadata';
import AssetsHeld from './AssetsHeld';
import PerformanceTable from './PerformanceTable';
import SectorsPerformanceChart from './SectorsPerformanceChart';

const InsightsPage = () => {
  const { data: sectorsPerformersData, isLoading } = useGetSectorsPerformers();

  return (
    <BasePageWithMetadata title="Insights">
      <div className="md:px-4 pt-4">
        {isLoading ? (
          <div className="py-2">
            <Skeleton.Button style={{ width: '96px', height: '20px' }} active />
          </div>
        ) : (
          <h2 className="max-lg:px-4 pb-2 text-xl font-bold text-neutral-200">
            Insights
          </h2>
        )}
        <div className="flex flex-col py-2 gap-6">
          <div className="flex flex-col md:flex-row gap-6">
            <SectorsPerformanceChart />
            <AssetsHeld />
          </div>

          <div className="flex flex-col lg:flex-row gap-6">
            <PerformanceTable
              data={sectorsPerformersData?.topPerformers ?? []}
              title="Top Performers"
              isLoading={isLoading}
            />
            <PerformanceTable
              data={sectorsPerformersData?.bottomPerformers ?? []}
              title="Bottom Performers"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </BasePageWithMetadata>
  );
};

export default InsightsPage;
