import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import {
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Modal } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';

import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { getLatestDateForNewPortfolioColumn } from '@/utils/date';

const AntDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

export interface PortfolioFundHeaderDateSelectProps {
  isValid: boolean;
  date: Dayjs;
  disabledDates: string[];
  onUpdateDate?: (oldDate: Dayjs, newDate: Dayjs) => boolean;
  disableEdit?: boolean;
  cleanEmptyDates?: () => void;
  removeDate?: (date: Dayjs) => void;
}

const PortfolioFundHeaderDateSelect: React.FC<
  PropsWithChildren<PortfolioFundHeaderDateSelectProps>
> = ({
  isValid,
  date,
  disabledDates,
  onUpdateDate,
  cleanEmptyDates,
  children,
  disableEdit,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [isOpen, setIsOpen] = useState(!date.isValid());
  const dateValue = getLatestDateForNewPortfolioColumn(disabledDates, date);
  const [tempDateValue, setTempDateValue] = useState<Dayjs>();
  const { currentWidth } = useScreenWidth();
  const isMobile = currentWidth < screenBreakpoints.sm;

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: newState => {
      if (!isMobile) {
        // If the modal is closed with an invalid value, important because esc will close the popover
        if (!newState && !date.isValid() && onUpdateDate) {
          const calculateLatestDate = (dates: string[]) => {
            const lastDateConvertedToDayJS = dayjs(
              dates[0],
              DATE_FORMAT_DASHED,
            );
            return lastDateConvertedToDayJS.isValid()
              ? lastDateConvertedToDayJS
              : dayjs();
          };
          const latestDate = calculateLatestDate(
            disabledDates.filter(d => d !== ''),
          );

          let dateToSet =
            disabledDates.filter(d => d !== '').length !== 0
              ? latestDate.add(1, 'day')
              : latestDate;
          if (dayjs().diff(latestDate, 'day') > 0) {
            dateToSet = dayjs();
          }
          if (!onUpdateDate(date, dateToSet)) {
            dispatchApp({
              type: APP_ACTIONS.SET_ERROR_MESSAGE,
              payload: 'You are trying to select already selected date.',
            });
          }
        }
        !disableEdit && setIsOpen(newState);
      }
    },
    placement: 'bottom-start',
    middleware: [offset({ mainAxis: 24, crossAxis: -25 }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const rootPortal = document.getElementById('root-portal');
  const referenceProps = !isMobile ? getReferenceProps() : {};
  const isNewDate = !date.isValid();

  const handleModalClose = () => {
    if (isNewDate && cleanEmptyDates) {
      cleanEmptyDates();
    }

    if (isNewDate && !tempDateValue) {
      onUpdateDate?.(date, dayjs());
    }
    setTempDateValue(undefined);
    setIsOpen(false);
  };

  // pfh = PorfolioFundHeader
  return (
    <div
      className={cx(
        'pfh-date-select relative justify-end text-right w-full flex',
        'h-full relative',
      )}
    >
      {/* INPUT */}
      <div
        ref={refs.setReference}
        style={{ color: !isValid ? '#E64236' : 'inherit' }}
        className={cx('flex flex-row text-xs font-medium cursor-pointer')}
        // TODO: Consider changing this element to a button
        onClick={e => {
          e.stopPropagation();
          !disableEdit && setIsOpen(true);
        }}
        {...referenceProps}
      >
        <span className={isOpen ? 'text-primary' : undefined}>{children}</span>
      </div>
      {isMobile && (
        <Modal
          open={isOpen}
          className="full-page-modal information-modal [&_.ant-picker]:hidden full-width-date-picker sm:hidden"
          title={isNewDate ? 'Add Column' : 'Edit Column'}
          onCancel={handleModalClose}
          onClose={handleModalClose}
          footer={[
            <Button
              key="cancel-button"
              className="!text-neutral-700"
              type="link"
              onClick={handleModalClose}
            >
              Cancel
            </Button>,

            <Button
              key="add-button"
              type="primary"
              className="disabled:!bg-neutral-300 bg-primary"
              onClick={() => {
                if (tempDateValue && onUpdateDate) {
                  const successUpdatingDate = onUpdateDate(date, tempDateValue);
                  if (!successUpdatingDate) {
                    dispatchApp({
                      type: APP_ACTIONS.SET_ERROR_MESSAGE,
                      payload:
                        'You are trying to select already selected date.',
                    });
                    return;
                  }
                  handleModalClose();
                }
              }}
              disabled={!(tempDateValue && onUpdateDate)}
            >
              {isNewDate ? 'Add' : 'Save changes'}
            </Button>,
          ]}
        >
          <AntDatePicker
            open={true}
            value={tempDateValue}
            onChange={newDate => {
              if (newDate) {
                setTempDateValue(newDate);
              }
            }}
            disabledDate={d =>
              disabledDates.some(dD => d.format(DATE_FORMAT_DASHED) === dD)
            }
            placement="bottomLeft"
            getPopupContainer={trigger => {
              if (trigger.parentElement) return trigger.parentElement;
              return trigger;
            }}
          />
        </Modal>
      )}
      {/* CALENDAR */}
      {rootPortal &&
        !isMobile &&
        createPortal(
          isOpen ? (
            <div
              ref={refs.setFloating}
              className={cx(
                'fixed w-fit h-fit z-[1000] top-0 left-0 border border-[#adadb3] rounded-md',
                'overflow-hidden [&_.ant-picker]:hidden',
                '[&_.ant-picker_div]:!relative [&_.ant-picker_div]:!top-[unset] [&_.ant-picker_div]:!left-[unset]',
                '[&_.ant-picker_div]:!h-[unset] [&_.ant-picker_div]:!w-[unset]',
              )}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {/* AntdDatePicker comes with its own popover, but it has not been reliable in positioning within an element, hence this approach exists (and has existed in other parts of the code base) */}
              <AntDatePicker
                open
                value={dateValue}
                onChange={newDate => {
                  if (newDate && onUpdateDate) {
                    const successUpdatingDate = onUpdateDate(date, newDate);
                    if (!successUpdatingDate) {
                      dispatchApp({
                        type: APP_ACTIONS.SET_ERROR_MESSAGE,
                        payload:
                          'You are trying to select already selected date.',
                      });
                    }
                  }
                }}
                disabledDate={d =>
                  disabledDates.some(dD => d.format(DATE_FORMAT_DASHED) === dD)
                }
                placement="bottomLeft"
                getPopupContainer={trigger => {
                  if (trigger.parentElement) return trigger.parentElement;
                  return trigger;
                }}
              />
            </div>
          ) : (
            <></>
          ),
          rootPortal,
          'calendar-portal',
        )}
    </div>
  );
};

export default PortfolioFundHeaderDateSelect;
