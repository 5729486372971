import {
  ChartDataResponseLegendData,
  ScatterPlotResponseLegendData,
} from '@aminsights/contract';

export type LegendData =
  | ChartDataResponseLegendData
  | ScatterPlotResponseLegendData;

export const CHART_COLOR_FOR_INDEX_LINE = '#0072E6';
export const CHART_COLOR_FOR_SECTOR_LINE = '#18A515';
export const CHART_COLOR_FOR_EXCLUDED_LEGEND = '#BAC0D0';

export const ChartColorsWithBrightEffect = [
  { transparent: '#FAD3EC', bright: '#EA50B4' }, // Brilliant Rose
  { transparent: '#CEF7EE', bright: '#3BDEBC' }, // Blue green
  { transparent: '#E6D3FD', bright: '#9A4EF6' }, // Lavender Indigo
  { transparent: '#D5D3F3', bright: '#36335E' }, // Blue Magenta
  { transparent: '#FDDABF', bright: '#F56A00' }, // Orange
  { transparent: '#E9D5C2', bright: '#A5550B' }, // Light brown
  { transparent: '#D6C5EF', bright: '#5A17BF' }, // Purple
  { transparent: '#FDEBC1', bright: '#F7AF08' }, // Yellow
  { transparent: '#E9E1D1', bright: '#A88648' }, // Bronze (Metallic)
  { transparent: '#F9CCCC', bright: '#E63232' }, // Red
  { transparent: '#EAD1EE', bright: '#AB47BC' }, // Purple plum
];

export const ChartingToolColors = [
  ...ChartColorsWithBrightEffect,
  { transparent: '#D3F1FA', bright: '#50CEEA' },
  { transparent: '#BFFFAF', bright: '#32B212' },
  { transparent: '#BAC1FF', bright: '#5564F1' },
  { transparent: '#E6A1AD', bright: '#B14155' },
  { transparent: '#C0CFC5', bright: '#548565' },
  { transparent: '#C3D4DE', bright: '#065A89' },
  { transparent: '#FFDDEB', bright: '#FE97C2' },
  { transparent: '#FDFECA', bright: '#FAFF31' },
  { transparent: '#F7D5AE', bright: '#F38607' },
];
