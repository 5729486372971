import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import NestedDrawer from '@/partials/NestedDrawer';
import {
  DATE_PERIOD_FILTER,
  DISPLAY_DATE_FORMAT,
  EDateFilterValues,
  EMPTY_DATA_POINT,
} from '@aminsights/shared';
import { Button, Dropdown, Skeleton, Tooltip } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import cx from 'classnames';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { Fragment, useState } from 'react';

import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { parseDateValue } from '../DatePicker/Kinds/RangeDatePicker';
import {
  CorrelationMatrixColorLegend,
  getCorrelationValueColor,
} from './CorrelationMatrixColorGradient';
import CorrelationMatrixError from './components/CorrelationMatrixError';
import { MatrixFundName, MatrixNumber } from './components/MatrixFundName';
import useCorrelationMatrixQuery from './useCorrelationMatrixQuery';

export const allowedPeriodValuesForCorrelationMatrix = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const CorrelationMatrix = () => {
  const currentBucket = useCurrentBucket();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<EDateFilterValues>(
    EDateFilterValues['3YR'],
  );

  const isinsInCurrentBucket = currentBucket?.funds.map(f => f.isin);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValuesForCorrelationMatrix.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setCurrentPeriod(period.value);
      setIsNestedDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  let dateRange = parseDateValue(currentPeriod);
  // just type safety, don't expect this in the runtime
  if (!dateRange) {
    dateRange = [dayjs().subtract(3, 'year'), dayjs()];
  }

  const query = useCorrelationMatrixQuery({
    isins: isinsInCurrentBucket,
    from: dayjs(dateRange[0]),
    to: dayjs(dateRange[1]),
  });

  const isLoading = query.isLoading;

  if (query.isError && !query.isFetching) {
    return (
      <CorrelationMatrixError
        onRetry={() => {
          query.refetch();
        }}
      />
    );
  }

  const { rawMatrix } = query.data ?? {};

  const isins: string[] = [];

  if (query.isLoading && isinsInCurrentBucket) {
    isins.push(...isinsInCurrentBucket);
  } else {
    isins.push(...(query.data?.rawIsinByIndex ?? []));
  }

  const loadingArray = Array.from({
    length: currentBucket?.funds.length ?? 0,
  }).map(() =>
    Array.from({ length: currentBucket?.funds.length ?? 0 }).map(() => 0),
  );
  const outputMatrix = query.isLoading ? loadingArray : (rawMatrix ?? []);
  const to = query.data?.queriedDateRange?.to;

  return (
    <div className="p-4">
      <div className="mb-4 grid gap-1 md:grid-cols-2">
        <p className="font-bold text-sm">Correlation Matrix</p>
        <div className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto flex items-center gap-2">
          {isLoading ? (
            <SkeletonButton className="w-40" active />
          ) : (
            <div
              data-test-id="correlationMatrixChartDropdown"
              className="pt-2 md:pt-0"
            >
              <Dropdown
                trigger={['click']}
                placement="bottom"
                menu={{ items: isMobile ? [] : allowedPeriodsForDropdown }}
              >
                <div className="hover:fill-[#40a9ff] text-neutral fill-[#545576]">
                  <Button
                    className="w-full h-[40px] text-neutral-700 text-left flex items-center justify-between border border-light rounded hover:border-primary hover:[&>p]:text-primary hover:[&>svg]:text-primary"
                    onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                  >
                    <p className="truncate text-sm mb-0 flex-grow">
                      {
                        allowedPeriodsForDropdown.find(
                          p => p.key === currentPeriod,
                        )?.label
                      }
                    </p>
                    <NextArrow className="w-3 rotate-90 fill-current" />
                  </Button>
                </div>
              </Dropdown>
            </div>
          )}
          {isMobile && (
            <NestedDrawer
              menuItems={allowedPeriodsForDropdown}
              visible={isNestedDrawerVisible}
              onClose={() => setIsNestedDrawerVisible(false)}
              title="Select"
            />
          )}
          {to && (
            <p className="text-xs md:text-right ml-2">
              as at <i>{dayjs(to).format(DISPLAY_DATE_FORMAT)}</i>
            </p>
          )}
        </div>
      </div>
      <div className="grid gap-4 md:px-4 xl:grid-cols-[1fr_max-content] xl:gap-12 xl:items-center">
        <div className="grid pt-4 gap-2 md:gap-8 grid-cols-[min-content_auto]">
          <div className="w-6 md:w-64 md:mt-2">
            {isins?.map((isin, index) => {
              const fund = currentBucket?.funds.find(f => f.isin === isin);
              return (
                <Fragment key={isin}>
                  <MatrixNumber
                    index={index}
                    fundName={fund?.fundName ?? ''}
                    className="md:hidden py-2"
                    spanClassName="leading-10 w-auto"
                    isFeatured={fund?.isFeatured}
                    isLoading={query.isLoading}
                  />
                  <MatrixFundName
                    fundName={fund?.shareClassDetailsCode ?? ''}
                    isin={fund?.isin ?? ''}
                    index={index}
                    isFeatured={fund?.isFeatured}
                    className="hidden md:flex mb-4"
                    isLoading={query.isLoading}
                  />
                </Fragment>
              );
            })}
          </div>
          <div
            className={cx(
              'relative',
              query.isLoading ? 'overflow-hidden' : 'overflow-auto',
            )}
          >
            {query.isLoading && (
              <Skeleton.Node
                fullSize
                active
                className="absolute top-0 left-0 w-full h-full block [&_*_.anticon]:hidden"
              />
            )}
            {outputMatrix?.map((y, yIndex) => {
              const matrixRowKey =
                currentBucket?.funds[yIndex].shareClassDetailsCode || yIndex;

              return (
                <div
                  className="grid h-14 last:pt-3 last:h-7"
                  style={{ gridTemplateColumns: `repeat(${y.length}, 1fr)` }}
                  key={`${matrixRowKey}`}
                >
                  {y.map((x, xIndex) => {
                    const showSkeleton = query.isLoading;
                    const shouldShow = yIndex >= xIndex;
                    const value = new Decimal(x).toDecimalPlaces(2).toNumber();
                    const isEmptyValue = isNaN(value);
                    const roundedValue = isEmptyValue ? '-' : value.toFixed(2);
                    const color = getCorrelationValueColor(
                      isEmptyValue ? '-' : value,
                    );
                    const output = value === 1 ? '1.00' : roundedValue;
                    const matrixColumnKey =
                      currentBucket?.funds[xIndex].isin || xIndex;

                    return (
                      <Fragment key={`${matrixRowKey}-${matrixColumnKey}`}>
                        {showSkeleton && (
                          <div
                            className="w-full h-full bg-black relative z-10 border-gray-100 border-solid border"
                            style={{
                              minWidth: '56px',
                              borderColor: 'rgba(204, 204, 208, 0.4)',
                              backgroundColor: !shouldShow
                                ? 'white'
                                : 'transparent',
                            }}
                          />
                        )}
                        {!showSkeleton && (
                          <Tooltip
                            open={shouldShow ? undefined : false}
                            placement="bottom"
                            overlayClassName="pointer-events-none max-w-none"
                            align={{
                              offset: [0, 6],
                            }}
                            color="#313341"
                            title={
                              isEmptyValue ? (
                                'No data'
                              ) : (
                                <div className="text-xs whitespace-nowrap">
                                  <p>
                                    {currentBucket?.funds[yIndex]
                                      .shareClassDetailsCode ??
                                      EMPTY_DATA_POINT}
                                  </p>
                                  <p>
                                    {currentBucket?.funds[xIndex]
                                      .shareClassDetailsCode ??
                                      EMPTY_DATA_POINT}
                                  </p>
                                </div>
                              )
                            }
                          >
                            <div
                              className={cx(
                                'text-xs h-full grid items-center justify-center border-gray-100 border-solid border text-white',
                                isEmptyValue && 'text-neutral-450',
                                shouldShow &&
                                  'hover:!border-[#00AFFF] hover:border-2',
                                output === '-' && '!text-[#3B3C53]',
                              )}
                              style={{
                                minWidth: '56px',
                                borderColor: 'rgba(204, 204, 208, 0.4)',
                                backgroundColor: shouldShow
                                  ? color
                                  : 'transparent',
                              }}
                            >
                              {shouldShow ? output : ''}
                            </div>
                          </Tooltip>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              );
            })}
            <div
              className="grid h-8 bg-white relative min-w-14"
              style={{
                gridTemplateColumns: `repeat(${outputMatrix.length}, 1fr)`,
              }}
            >
              {Array.from({ length: outputMatrix.length }).map((_, index) => {
                const matchingFund = currentBucket?.funds.find(
                  f => f.isin === isins[index],
                );
                return (
                  <div className="min-w-14" key={matchingFund?.isin}>
                    <MatrixNumber
                      fundName={matchingFund?.fundName ?? ''}
                      isLoading={query.isLoading}
                      index={index}
                      className="w-14"
                      spanClassName="w-full"
                      isFeatured={matchingFund?.isFeatured}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <CorrelationMatrixColorLegend isLoading={query.isLoading} />
      </div>
      <div className="md:hidden flex flex-col gap-y-2 mt-6">
        {isins.map((isin, index) => {
          const fund = currentBucket?.funds.find(f => f.isin === isin);
          return (
            <MatrixFundName
              fundName={fund?.shareClassDetailsCode ?? ''}
              isin={fund?.isin ?? ''}
              index={index}
              isFeatured={fund?.isFeatured}
              isLoading={query.isLoading}
              key={isin}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CorrelationMatrix;
