import { Button, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import cx from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as CheckedCircleIcon } from '@/assets/svg/icons/icon-checked-circle.svg';
import { ReactComponent as UnlockIcon } from '@/assets/svg/icons/icon-unlock.svg';
import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccessRequest from '@/hooks/query-hooks/support-hooks/useUpgradeAccessRequest';

interface UpgradeAccessModalProps {
  isModalOpen: boolean;
  restrictedFeature?: RESTRICTED_FEATURE;
  onModalClose: () => void;
}

const UpgradeAccessModal: FC<UpgradeAccessModalProps> = ({
  isModalOpen,
  restrictedFeature,
  onModalClose,
}) => {
  const [upgradeMessage, setUpgradeMessage] = useState('');
  const [isUpgradeRequestSent, setIsUpgradeRequestSent] = useState(false);
  const {
    mutate: sendUpgradeAccessRequest,
    isLoading: isSendingUpgradeRequest,
  } = useUpgradeAccessRequest();

  const handleCloseModal = () => {
    if (isSendingUpgradeRequest) return;

    setUpgradeMessage('');
    setIsUpgradeRequestSent(false);
    onModalClose();
  };

  const handleSubmitUpgradeRequest = async () => {
    await sendUpgradeAccessRequest({
      message: upgradeMessage,
      feature: restrictedFeature,
    });
    setIsUpgradeRequestSent(true);
    setUpgradeMessage('');
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      zIndex={1001}
      className={cx(
        'max-sm:full-page-modal action-modal',
        '[&_.ant-modal-body]:p-10 [&_.ant-modal-body]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-6',
        isUpgradeRequestSent ? 'md:min-w-[480px]' : 'md:min-w-[560px]',
      )}
      footer={
        isUpgradeRequestSent
          ? null
          : [
              <Button
                key="cancel"
                size="large"
                type="link"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                size="large"
                type="primary"
                className="px-4"
                disabled={!upgradeMessage.length}
                onClick={handleSubmitUpgradeRequest}
                loading={isSendingUpgradeRequest}
              >
                {isSendingUpgradeRequest ? 'Submitting...' : 'Submit'}
              </Button>,
            ]
      }
    >
      {isUpgradeRequestSent ? (
        <div className="flex flex-col items-center justify-center gap-6 pb-10">
          <div className="flex flex-col items-center justify-center gap-2">
            <CheckedCircleIcon />
            <h2 className="text-xl font-bold text-neutral-200">
              Request Sent Successfully
            </h2>
            <p className="text-sm font-normal text-neutral-700 text-center">
              Your request to upgrade has been received. A member of our team
              will reach out to you shortly.
            </p>
          </div>
          <Button
            size="large"
            type="primary"
            className="px-4"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-6 max-sm:pt-6">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <UnlockIcon />
              <h2 className="text-xl font-bold text-neutral-200">
                Upgrade to unlock this feature
              </h2>
            </div>
            <p className="text-sm font-normal text-neutral-700">
              Interested in upgrading? Tell us a bit more about what you need,
              and a member of our team will be in touch.
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="upgrade-message"
              className="text-xs font-medium text-neutral-200"
            >
              How do you think we can help you? *
            </label>
            <TextArea
              id="upgrade-message"
              className={cx(
                'w-full rounded p-4 text-sm text-neutral-700 placeholder-neutral-100',
                'border border-neutral-300 hover:border-primary focus-within:border-primary',
              )}
              placeholder="Provide as much details as possible"
              value={upgradeMessage}
              onChange={e => setUpgradeMessage(e.target.value)}
              rows={5}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UpgradeAccessModal;
