import { sectorLegendList } from '@aminsights/shared';
import cx from 'classnames';
import React from 'react';

interface SectorLegendProp {
  className?: string;
  sectorType: number;
}

const SectorLegendColor: React.FC<SectorLegendProp> = ({
  className,
  sectorType,
}) => {
  const sectorTypeColor = sectorLegendList.find(i =>
    i.type.includes(sectorType),
  )?.color;
  return sectorTypeColor ? (
    <div
      className={cx('w-2 h-2 rounded-full', className)}
      style={{ backgroundColor: sectorTypeColor }}
    />
  ) : null;
};

export default SectorLegendColor;
