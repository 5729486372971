import { Menu } from 'antd';
import cx from 'classnames';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import style from './style.module.less';

export type DropdownItem = {
  label: React.ReactNode;
  value: string | number | boolean;
  emitIsItemSelected?: boolean;
};

export type DropdownProps = {
  autoFocus?: boolean;
  dropdownKey?: string;
  isItemRefreshed?: boolean;
  items: Array<DropdownItem>;
  valuesToHide?: Array<string>;
  label?: string;
  defaultValue?: string | number | boolean;
  value?: string | number | boolean;
  type?: 'ghost' | 'link' | 'text' | 'primary' | 'default' | 'dashed';
  className?: string;
  shouldHighlightSelected?: boolean;
  onClick?: Dispatch<SetStateAction<DropdownItem | undefined>>;
  onSelect?: Dispatch<SetStateAction<any>>;
  onClear?: () => void;
  rounded?: boolean;
  iconCarretClassName?: string;
  disabled?: boolean;
  showSearch?: boolean;
  height?: number;
  dataTestId?: string;
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'bottom';
};

type DropdownMenuProps = {
  items: Array<DropdownItem>;
  valuesToHide?: Array<string>;
  state?: DropdownItem;
  setState?: Dispatch<SetStateAction<DropdownItem | undefined>>;
  onSelect?: Dispatch<SetStateAction<any>>; // TODO should be a type generic
  rounded?: boolean;
  emitIsItemSelected?: (value: boolean) => void;
  height?: number;
  dataTestIdPrefix?: string;
};

export const DropdownMenu: React.FC<PropsWithChildren<DropdownMenuProps>> = ({
  items,
  valuesToHide,
  state,
  setState,
  onSelect,
  rounded,
  emitIsItemSelected,
  height,
  dataTestIdPrefix,
}) => {
  const handleClick = (item: DropdownItem) => {
    if (setState) setState(item);
    if (onSelect) onSelect(item.value);
    if (emitIsItemSelected) emitIsItemSelected(true);
  };

  const [heightDropdown, setHeightDropDown] = useState<number | string>();

  useEffect(() => {
    if (!height && items.length >= 10) {
      setHeightDropDown(380); // to show 10 items
    } else if (height) {
      setHeightDropDown(height);
    } else {
      setHeightDropDown('auto');
    }
  }, [items, height]);

  return (
    <Menu
      className={cx(style['custom-dropdown-menu'], { 'rounded-menu': rounded })}
      style={{ height: heightDropdown }}
      items={items
        .filter(i => !valuesToHide?.some(iH => iH === i.value))
        .map(item => ({
          label: (
            <span
              className={state?.value === item.value ? 'active' : 'inactive'}
              data-test-id={`${dataTestIdPrefix ?? ''}Value${item.value}`}
            >
              {item.label}
            </span>
          ),
          key: item.value.toString(),
          onClick: () => handleClick(item),
        }))}
    />
  );
};
