import DataTable, { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { Skeleton } from 'antd';
import style from '../Summary/style.module.less';

interface MPSItem {
  type: string;
  value: string | undefined;
}

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    renderType: 'text',
    render: (item: MPSItem) => (
      <span className="text-neutral-200 text-xs font-bold">{item.type}</span>
    ),
    minWidth: 240,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Type',
    isColumnFixed: true,
  },
  {
    title: 'Available',
    renderType: 'text',
    render: (item: MPSItem) => item.value || '-',
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Available',
    className: 'w-full',
    isColumnFixed: true,
  },
];

const MPSOptions = () => {
  const { data: state, isLoading } = useFundByIsInParam();

  const mpsOptionsData = [
    {
      type: 'Co-Branded',
      value: state?.fund.dfmDetails?.coBranded ? 'Yes' : 'No',
    },
    {
      type: 'Tailored',
      value: state?.fund.dfmDetails?.tailored ? 'Yes' : 'No',
    },
    {
      type: 'Sub-Advised',
      value: state?.fund.dfmDetails?.subAdvised ? 'Yes' : 'No',
    },
  ];

  return (
    <div className={style['summary-table']}>
      <DataTable
        uniqueKey="type"
        columns={columns}
        data={mpsOptionsData}
        loading={isLoading}
      />
    </div>
  );
};

export default MPSOptions;
