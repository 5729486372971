import { Skeleton, Tabs } from 'antd';
import React, { PropsWithChildren } from 'react';

import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { formatDate } from '@/utils/formatDate';
import { AnnualisedPerformanceTable } from './AnnualisedPerformanceTable';
import { CalendarPerformanceTable } from './CalendarPerformanceTable';
import { FundCharts } from './FundCharts';
import { InvestmentTrustCharts } from './InvestmentTrustCharts';

const Performance: React.FC<PropsWithChildren> = () => {
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const { data: state, isLoading } = useFundByIsInParam();
  const annualisedPerfAsOfDate =
    state?.fund?.computedNavAnnualisedReturns?.[0]?.asOfDate;
  return (
    <div className="lg:px-4">
      <div>
        {isInvestmentTrust && <InvestmentTrustCharts />}
        {!isInvestmentTrust && <FundCharts />}
        <div
          className="bg-white mt-4 lg:rounded-lg"
          data-test-id="fundDetailsPerfRiskTabPerformance"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-sm text-darkest font-bold p-4">Performance</h2>
            {isLoading ? (
              <div>
                <Skeleton.Button
                  className="flex"
                  active
                  size="small"
                  style={{ width: '96px', height: '13px' }}
                />
              </div>
            ) : (
              <span className="text-xs italic font-bold leading-4 text-darkest p-4">
                {annualisedPerfAsOfDate
                  ? formatDate(annualisedPerfAsOfDate)
                  : 'No date available'}
              </span>
            )}
          </div>
          <Tabs
            items={[
              {
                label: (
                  <div data-test-id="fundDetailsPerfRiskTabPerformanceAnnualisedTab">
                    Ann. Perf
                  </div>
                ),
                key: 'summary',
                children: <AnnualisedPerformanceTable />,
              },
              {
                label: (
                  <div data-test-id="fundDetailsPerfRiskTabPerformanceCalendarTab">
                    Cal. Perf
                  </div>
                ),
                key: 'calendar',
                children: <CalendarPerformanceTable />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Performance;
