import { IDataTableColumns } from '@/components/Table/DataTable';
import {
  EMPTY_DATA_POINT,
  getPerformanceTableReturnColumnName,
} from '@aminsights/shared';
import { buildFundDetailsPath } from '@aminsights/shared';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { formatDate } from '@/utils/formatDate';
import { Tooltip } from 'antd';
import cx from 'classnames';
import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

export const AnnualisedPerformanceColumns = (
  isInvestmentTrust: boolean,
  isTitleColumnClickable?: boolean,
): IDataTableColumns[] => {
  const navOrReturn = getPerformanceTableReturnColumnName(isInvestmentTrust);
  const { data: state } = useFundByIsInParam();
  const fundId = state?.fund?._id;
  const annualisedPerfAsOfDate =
    state?.fund?.computedNavAnnualisedReturns?.[0]?.asOfDate;
  const benchmarkAsOfDate = state?.benchmarkPerformance?.asOfDate ?? undefined;
  const categoryAsOfDate = state?.categoryPerformance?.asOfDate ?? undefined;
  return [
    {
      title: isInvestmentTrust ? 'Investment Trust' : 'Fund',
      render: (item: IAnnualisedPerformanceData) => {
        const [tooltipVisible, setTooltipVisible] = useState(false);
        // biome-ignore lint/correctness/useExhaustiveDependencies: This is a memoized function that is only called once
        const getAsOfDate = useMemo(() => {
          if (fundId === item.key && !annualisedPerfAsOfDate) {
            return 'No date available';
          }
          if (
            'benchmarkPerformanceRow' === item.key &&
            benchmarkAsOfDate &&
            benchmarkAsOfDate !== annualisedPerfAsOfDate
          ) {
            return formatDate(benchmarkAsOfDate as string | undefined);
          }
          if (
            'categoryPerformanceRow' === item.key &&
            categoryAsOfDate &&
            categoryAsOfDate !== annualisedPerfAsOfDate
          ) {
            return formatDate(categoryAsOfDate as string | undefined);
          }
        }, []);

        const tooltipContent = getAsOfDate && (
          <Tooltip
            title={getAsOfDate}
            placement="bottom"
            color="#313341"
            open={tooltipVisible}
            onOpenChange={setTooltipVisible}
            align={{ offset: [0, 10] }}
            zIndex={10}
          >
            <InfoIcon
              className={cx('icon text-neutral', {
                'text-neutral-100': tooltipVisible,
              })}
            />
          </Tooltip>
        );
        return isTitleColumnClickable && item.key !== 'benchmark' ? (
          <div className="flex items-center gap-x-2">
            <Link
              to={buildFundDetailsPath(item.key)}
              className="font-bold text-darkest flex justify-center gap-x-2"
            >
              {item.name}
            </Link>
            <div>{tooltipContent}</div>
          </div>
        ) : (
          <div className="flex items-center gap-x-2">
            <div className="font-bold text-darkest flex justify-center gap-x-2">
              {item.name}
            </div>
            <div>{tooltipContent}</div>
          </div>
        );
      },
      renderType: 'text',
      sortKey: 'name',
      width: 200,
      isColumnFixed: true,
      idSubText: 'name',
    },
    {
      title: 'YTD',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          render: (item: IAnnualisedPerformanceData) => {
            return (
              <div className="table-cell-value whitespace-nowrap">
                {item.ytdSP ? `${item.ytdSP.toFixed(1)}%` : EMPTY_DATA_POINT}
              </div>
            );
          },
          renderType: 'number',
          sortKey: 'ytdSP',
          idSubText: 'ytdSP',
        },
        {
          title: navOrReturn,
          sortKey: 'ytdReturn',
          idSubText: 'ytdReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.ytdReturn
                ? `${item.ytdReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.ytdPercentile
                ? `${item.ytdPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'ytdPercentile',
          idSubText: 'ytdPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '3 mos',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'threeMonthsSP',
          idSubText: 'threeMonthsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeMonthsSP
                ? `${item.threeMonthsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'threeMonthsReturn',
          idSubText: 'threeMonthsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeMonthsReturn
                ? `${item.threeMonthsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeMonthsPercentile
                ? `${item.threeMonthsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'threeMonthsPercentile',
          idSubText: 'threeMonthsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '6 mos',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'sixMonthsSP',
          idSubText: 'sixMonthsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.sixMonthsSP
                ? `${item.sixMonthsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'sixMonthsReturn',
          idSubText: 'sixMonthsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.sixMonthsReturn
                ? `${item.sixMonthsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.sixMonthsPercentile
                ? `${item.sixMonthsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'sixMonthsPercentile',
          idSubText: 'sixMonthsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '1 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'oneYearSP',
          idSubText: 'oneYearSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.oneYearSP
                ? `${item.oneYearSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'oneYearReturn',
          idSubText: 'oneYearReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.oneYearReturn
                ? `${item.oneYearReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.oneYearPercentile
                ? `${item.oneYearPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'oneYearPercentile',
          idSubText: 'oneYearPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '3 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'threeYearsSP',
          idSubText: 'threeYearsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeYearsSP
                ? `${item.threeYearsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'threeYearsReturn',
          idSubText: 'threeYearsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeYearsReturn
                ? `${item.threeYearsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.threeYearsPercentile
                ? `${item.threeYearsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'threeYearsPercentile',
          idSubText: 'threeYearsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '5 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'fiveYearsSP',
          idSubText: 'fiveYearsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.fiveYearsSP
                ? `${item.fiveYearsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'fiveYearsReturn',
          idSubText: 'fiveYearsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.fiveYearsReturn
                ? `${item.fiveYearsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value whitespace-nowrap">
              {item.fiveYearsPercentile
                ? `${item.fiveYearsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'fiveYearsPercentile',
          idSubText: 'fiveYearsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
  ];
};

export const CalendarYearPerformanceColumns = (
  isInvestmentTrust: boolean,
  isTitleColumnClickable?: boolean,
): IDataTableColumns[] => {
  const navOrReturn = getPerformanceTableReturnColumnName(isInvestmentTrust);
  return [
    {
      title: isInvestmentTrust ? 'Investment Trust' : 'Fund',
      sortKey: 'name',
      idSubText: 'name',
      render: (item: ICalendarYearPerformanceData): React.ReactNode => {
        return isTitleColumnClickable && item.key !== 'benchmark' ? (
          <Link
            to={buildFundDetailsPath(item.key)}
            className="font-bold text-darkest flex justify-center"
          >
            {item.name}
          </Link>
        ) : (
          <div className="font-bold text-darkest flex justify-center">
            {item.name}
          </div>
        );
      },
      renderType: 'text',
      width: 200,
      isColumnFixed: true,
    },
    {
      title: 'YTD',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'sharePriceReturnYtdPct',
          idSubText: 'sharePriceReturnYtdPct',
          render: (item: ICalendarYearPerformanceData) => {
            return (
              <div className="table-cell-value whitespace-nowrap">
                {item.sharePriceReturnYtdPct !== undefined
                  ? `${Number(item.sharePriceReturnYtdPct).toFixed(1)}%`
                  : EMPTY_DATA_POINT}
              </div>
            );
          },
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'totalReturnYtdPct',
          idSubText: 'totalReturnYtdPct',
          render: (item: ICalendarYearPerformanceData) => {
            return (
              <div className="table-cell-value whitespace-nowrap">
                {item.totalReturnYtdPct !== undefined
                  ? `${Number(item.totalReturnYtdPct).toFixed(1)}%`
                  : EMPTY_DATA_POINT}
              </div>
            );
          },
          renderType: 'number',
        },
        {
          title: 'Pcl',
          render: (item: ICalendarYearPerformanceData) => {
            return (
              <div className="table-cell-value whitespace-nowrap">
                {item?.totalReturnYtdMorningstarCategoryPcl !== undefined
                  ? `${Number(
                      item?.totalReturnYtdMorningstarCategoryPcl,
                    ).toFixed(0)}`
                  : EMPTY_DATA_POINT}
              </div>
            );
          },
          renderType: 'number',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          idSubText: 'totalReturnYtdMorningstarCategoryPcl',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    ...([1, 2, 3, 4, 5].map(yearsAgo => {
      const year = new Date().getFullYear() - yearsAgo;
      const pctKey = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      const pclKey = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;
      const spKey = `sharePriceReturn${yearsAgo}calendarYearsAgoPct`;
      return {
        title: year.toString(),
        align: 'center',
        renderType: 'custom',
        children: [
          isInvestmentTrust && {
            title: 'SP',
            render: (item: ICalendarYearPerformanceData) => {
              const fundValue =
                item?.[spKey as keyof ICalendarYearPerformanceData];
              return (
                <div className="table-cell-value whitespace-nowrap">
                  {fundValue !== undefined
                    ? `${Number(fundValue).toFixed(1)}%`
                    : EMPTY_DATA_POINT}
                </div>
              );
            },
            renderType: 'number',
            sortKey: spKey,
            idSubText: spKey,
          },
          {
            title: navOrReturn,
            sortKey: pctKey,
            idSubText: pctKey,
            render: (item: ICalendarYearPerformanceData) => {
              const fundValue =
                item?.[pctKey as keyof ICalendarYearPerformanceData];
              return (
                <div className="table-cell-value whitespace-nowrap">
                  {fundValue !== undefined
                    ? `${Number(fundValue).toFixed(1)}%`
                    : EMPTY_DATA_POINT}
                </div>
              );
            },
            renderType: 'number',
          },
          {
            title: 'Pcl',
            render: (item: ICalendarYearPerformanceData) => {
              const fundValue =
                item?.[pclKey as keyof ICalendarYearPerformanceData];
              return (
                <div className="table-cell-value whitespace-nowrap">
                  {fundValue !== undefined
                    ? `${Number(fundValue).toFixed(0)}`
                    : EMPTY_DATA_POINT}
                </div>
              );
            },
            renderType: 'number',
            sortKey: pclKey,
            idSubText: pclKey,
          },
        ].filter(Boolean) as IDataTableColumns[],
      };
    }) as IDataTableColumns[]),
  ];
};
