import { Tabs } from 'antd';

import ManagerTable from '../ManagerTable';
import OverviewMPS from './OverviewMPS';

const SummaryMPS = () => {
  return (
    <div className="lg:px-4 grid gap-x-5 gap-y-5 lg:grid-cols-[0.65fr_0.35fr]">
      <Tabs
        items={[
          {
            label: 'Overview',
            key: 'overview',
            children: <OverviewMPS />,
          },
        ]}
        className={'[&_.ant-tabs-nav]:lg:rounded-t-lg'}
        data-test-id="fundDetailsOverviewESGTabs"
      />
      <div className="flex flex-col gap-y-5">
        <ManagerTable />
      </div>
    </div>
  );
};

export default SummaryMPS;
