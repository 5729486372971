import { Fund } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  formatNumberDecimals,
  getExploreCategoryLink,
  getShortHumanReadableStyleboxName,
  isFixedIncomeBroadAssetClass,
  isFund,
  isInvestmentTrust,
  shortenLongNumber,
} from '@aminsights/shared';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { DataTable, Tooltip } from '@/components';
import { useExploreDefaultFiltersQuery } from '@/pages/app/Explore/context';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import Stylebox from '../../Stylebox';
import { getFundTitle } from '../utils/shared';
import { extractFundTitle } from './extractFundTitle';

interface CompareFundBasicsProps {
  loading?: boolean;
  funds?: Fund[];
}

type Keys =
  | 'Fund Size'
  | 'OCF'
  | 'Modified Duration'
  | 'Average Credit Quality'
  | 'Yield to Maturity'
  | '1Y Max. Drawdown'
  | 'Holdings'
  | 'Launch Date'
  | 'Style Box'
  | 'Category'
  | 'Manager';

export const CompareFundBasics: FC<CompareFundBasicsProps> = ({
  funds,
  loading = true,
}) => {
  // Manage mobile state here
  const screenWidthMode = getScreenWidthMode();

  const isFixedIncome = isFixedIncomeBroadAssetClass(
    funds?.[0]?.broadAssetClass ?? '',
  );

  const fundsContainTrusts = funds?.some(fund =>
    isInvestmentTrust(fund.legalStructure),
  );

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isFundType = funds?.[0] ? isFund(funds?.[0]) : false;
  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      loaderSize={7}
      uniqueKey="rowKey"
      className="md:table-fixed"
      columns={[
        {
          title: '',
          render: ({ rowKey }) =>
            rowKey === '1Y Max. Drawdown' && fundsContainTrusts ? (
              <div className="font-bold w-full flex justify-around items-center">
                {rowKey}
                <Tooltip
                  title="Based off NAV for investment trusts"
                  placement="bottom"
                  color="#3E414B"
                  onOpenChange={setTooltipVisible}
                  arrow={{ pointAtCenter: true }}
                >
                  <InfoIcon
                    className={cx('icon text-neutral', {
                      'text-neutral-100': tooltipVisible,
                    })}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="font-bold">{rowKey}</div>
            ),
          width: screenWidthMode[ScreenWidthEnum.MinLg] ? 190 : 120,
          idSubText: 'name',
          renderType: 'text',
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className="min-w-17">
              {getFundTitle({
                isin: f._id,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),

          render: ({ rowKey }: { rowKey: Keys }) => fundBasicCells(f, rowKey),
          idSubText: `fund-${idx}`,
          renderType: 'text' as const,
        })),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={[
        { rowKey: 'Fund Size' },
        { rowKey: 'OCF' },
        ...(isFixedIncome
          ? isFundType
            ? [
                { rowKey: 'Modified Duration' },
                { rowKey: 'Average Credit Quality' },
                { rowKey: 'Yield to Maturity' },
              ]
            : []
          : [{ rowKey: 'Holdings' }]),
        { rowKey: 'Launch Date' },
        { rowKey: '1Y Max. Drawdown' },
        { rowKey: 'Style Box' },
        { rowKey: 'Category' },
        { rowKey: 'Manager' },
      ]}
    />
  );
};

const fundBasicCells = (data?: Fund, key?: Keys) => {
  if (data === undefined) return '';
  switch (key) {
    case 'Fund Size': {
      const fundSize = data.fundSize;
      return (
        <div>
          {fundSize
            ? `${fundSize.currencyId} ${shortenLongNumber(fundSize.value)}`
            : EMPTY_DATA_POINT}
        </div>
      );
    }
    case 'OCF': {
      return (
        <div>
          {data.ocfPct ? `${data.ocfPct.toFixed(2)}%` : EMPTY_DATA_POINT}
        </div>
      );
    }
    case 'Modified Duration': {
      const md = data.broadAssetClassValues?.bonds?.modifiedDuration;
      return <div>{md ? `${md.toFixed(1)}` : EMPTY_DATA_POINT}</div>;
    }
    case 'Yield to Maturity': {
      const ytm = data.broadAssetClassValues?.bonds?.yieldToMaturity;
      return <div>{ytm ? `${ytm.toFixed(1)}%` : EMPTY_DATA_POINT}</div>;
    }
    case 'Average Credit Quality': {
      const acq =
        data.broadAssetClassValues?.bonds?.averageCreditQualityDefinition;
      return <div>{acq ? `${acq}` : EMPTY_DATA_POINT}</div>;
    }
    case 'Holdings': {
      return (
        <div>
          {data.numberOfHoldings
            ? `${data.numberOfHoldings}`
            : EMPTY_DATA_POINT}
        </div>
      );
    }
    case 'Launch Date': {
      return (
        <div>
          {data.shareClassDetails.inceptionDate
            ? dayjs(data.shareClassDetails.inceptionDate).format(
                DISPLAY_DATE_FORMAT,
              )
            : EMPTY_DATA_POINT}
        </div>
      );
    }
    case 'Style Box': {
      const styleboxValue = data.broadAssetClassValues?.styleBox;
      const isFixedIncome = isFixedIncomeBroadAssetClass(
        data?.broadAssetClass || '',
      );
      const styleboxLabels = isFixedIncome
        ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
        : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ;
      return styleboxValue ? (
        <div className="flex gap-x-2 items-center">
          <div className="h-5 w-5">
            <Stylebox
              broadAssetClass={data.broadAssetClass}
              positionPair={[[styleboxValue]]}
            />
          </div>
          <p className="text-darkest text-sm">
            {getShortHumanReadableStyleboxName(styleboxValue, styleboxLabels)}
          </p>
        </div>
      ) : (
        EMPTY_DATA_POINT
      );
    }
    case 'Category': {
      const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
      const categoryLink = getExploreCategoryLink(
        data.msCategoryDefinition || '',
        defaultfiltersOptions.categories,
      );
      return (
        <div>
          <Link to={categoryLink}>
            {data.msCategoryDefinition || EMPTY_DATA_POINT}
          </Link>
        </div>
      );
    }
    case 'Manager': {
      let managers: string = data.managerList
        .map(x => x.name)
        .join(', ')
        .trim();
      if (managers) {
        const isNotDisclosed = managers === 'Not Disclosed';
        if (isNotDisclosed) {
          managers = isInvestmentTrust(data.legalStructure)
            ? (data.providerName ?? EMPTY_DATA_POINT)
            : EMPTY_DATA_POINT;
        }
      }
      return <div>{managers}</div>;
    }
    case '1Y Max. Drawdown': {
      const fund1YMaxDrawdown = data?.riskMeasuresDetail?.find(
        el => el.timePeriod === 'M12',
      );
      return (
        <div>
          {fund1YMaxDrawdown?.maximumDrawdown
            ? `${formatNumberDecimals(fund1YMaxDrawdown.maximumDrawdown, 2)}%`
            : EMPTY_DATA_POINT}
        </div>
      );
    }

    default: {
      return <div>Unhandled key</div>;
    }
  }
};
