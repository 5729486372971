import { Button } from 'antd';
import cx from 'classnames';

import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-restricted-lock.svg';
import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';

interface RestrictedAccessPageProps {
  restrictedFeature: RESTRICTED_FEATURE;
  className?: string;
  title?: string;
  description?: string;
  cta?: {
    text?: string;
    onClick?: () => void;
  };
}

const RestrictedAccessPage = ({
  restrictedFeature,
  title,
  description,
  className = '',
  cta,
}: RestrictedAccessPageProps) => {
  const { toggleUpgradeModal } = useUpgradeAccess();

  return (
    <div className="p-6 pt-0">
      <div
        className={cx('bg-white rounded-lg h-[calc(100vh-100px)]', className)}
      >
        <div className="w-full h-full p-5 flex flex-col justify-center items-center gap-y-4">
          <LockIcon />
          <div className="flex flex-col gap-2 items-center justify-center">
            <b className="text-xl font-semibold text-center text-darkest">
              {title ?? 'Access restricted'}
            </b>
            <span className="text-sm font-normal text-center text-neutral-700">
              {description ?? 'This feature requires an upgrade.'}
            </span>
          </div>
          <Button
            className={'flex items-center justify-center mt-2 rounded h-auto'}
            type="primary"
            onClick={() => {
              toggleUpgradeModal(restrictedFeature);
              cta?.onClick?.();
            }}
            data-test-id="portfolioAddButton"
          >
            <p className="px-4 py-2 text-sm font-medium">
              {cta?.text ?? 'Submit upgrade request'}
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestrictedAccessPage;
