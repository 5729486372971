import {
  FundPlatformsEnum,
  PageQueryParametersSortDirectionEnum,
  PortfolioFund,
} from '@aminsights/contract';
import { useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DataTable } from '@/components';
import handleOnRow from '@/hooks/handleOnRow';
import usePortfolioPlatformsQuery, {
  GET_PORTFOLIO_PLATFORMS,
} from '@/hooks/query-hooks/portfolio-hooks/usePortfolioPlatformsQuery';
import { useProvidePortfolio } from '@/pages/app/Portfolio/useProvidePortfolio';

import EmptyPortfolioTable from '../../EmptyPortfolioTable';
import PlatformColumns from './columns';

const isKeyOfFund = (
  item: PortfolioFund,
  key: keyof PortfolioFund | FundPlatformsEnum,
): key is keyof PortfolioFund => {
  if (!!item[key as keyof PortfolioFund]) {
    return true;
  }
  return false;
};

const PortfolioPerformanceTab: React.FC<PropsWithChildren> = () => {
  const queryClient = useQueryClient();
  const clickRow = handleOnRow();
  const { state, addFundSearchBoxToCurrentPortfolio } = useProvidePortfolio();
  const { portfolioId } = useParams<{
    portfolioId: string;
  }>();

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );

  const portfolioPlatformsQuery = usePortfolioPlatformsQuery([portfolioId], {
    enabled: !!portfolioId,
  });
  const holdingsData =
    state?.portfolios?.find(p => p._id === portfolioId)?.funds || [];
  const portfolioData = portfolioPlatformsQuery?.data?.portfolios;

  /**
   * No need to sort on the backend for now. We have all the data on the frontend.
   */
  const handleOnSort = (
    key: keyof PortfolioFund | FundPlatformsEnum,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const dir = direction === PageQueryParametersSortDirectionEnum.Asc ? 1 : -1;
    queryClient.setQueryData(
      [GET_PORTFOLIO_PLATFORMS, [portfolioId]],
      (prevData?: { portfolios: PortfolioFund[] }) => {
        if (prevData?.portfolios?.length) {
          const clonedData = [...(prevData ? prevData.portfolios : [])];
          return {
            portfolios: clonedData.sort((a, b) => {
              if (isKeyOfFund(a, key)) {
                if (typeof a[key] === 'string') {
                  const res = (a[key] as string).localeCompare(
                    b[key] as string,
                  );
                  return res * dir;
                }
                return dir;
              }
              // Sorting for platform keys
              const platformA = (a?.platforms || []).indexOf(key);
              const platformB = (b?.platforms || []).indexOf(key);
              const res = platformA - platformB;
              return res * dir;
            }),
          };
        }
        return { portfolios: [] };
      },
    );
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const timeout = setTimeout(() => {
      const validHoldingsData = holdingsData.filter(hd => !!hd.isin);
      if (
        validHoldingsData?.length !== portfolioData?.length ||
        !validHoldingsData.every(p =>
          portfolioData?.find?.(pd => pd.isin === p.isin),
        )
      ) {
        portfolioPlatformsQuery.refetch();
      }
    }, 800);
    return () => clearTimeout(timeout);
  }, [JSON.stringify(holdingsData)]);

  const isEmpty = !currentPortfolio || currentPortfolio.funds.length === 0;

  return (
    <>
      {!isEmpty ? (
        <DataTable
          uniqueKey={'isin'}
          loading={portfolioPlatformsQuery.isLoading}
          columns={PlatformColumns()}
          data={portfolioData}
          onRow={(id: string, event: React.MouseEvent<HTMLTableRowElement>) =>
            clickRow(id, event)
          }
          enableBorders={true}
          initialSorting={
            !!portfolioData?.length
              ? {
                  sortDirection: PageQueryParametersSortDirectionEnum.Desc,
                  sortKey: 'fundName',
                }
              : undefined
          }
          onSort={(
            key: string,
            direction: PageQueryParametersSortDirectionEnum,
          ) =>
            handleOnSort(
              key as keyof PortfolioFund | FundPlatformsEnum,
              direction,
            )
          }
        />
      ) : (
        <EmptyPortfolioTable
          currentPortfolioId={state.currentPortfolioId}
          onAddFundClick={addFundSearchBoxToCurrentPortfolio}
        />
      )}
    </>
  );
};

export default PortfolioPerformanceTab;
