export const AUTH_ROUTE_FORGOT_PASSWORD = 'forgot-password';
export const AUTH_ROUTE_RESET_PASSWORD = 'reset-password';
export const AUTH_ROUTE_REQUEST_ACCOUNT = 'request-account';
export const AUTH_ROUTE_WELCOME_SETUP = 'setup-account';
export const AUTH_ROUTE_EMAIL_VERIFICATION = 'email-verification';
export const AUTH_ROUTE_ACCESS_DENIED = 'access-denied';

export const APP_ROUTE_WELCOME = 'welcome';
export const APP_ROUTE_DASHBOARD = 'dashboard';
export const APP_ROUTE_FUND = 'fund';
export const APP_ROUTE_WATCHLIST = 'watchlist';
export const APP_ROUTE_PORTFOLIOS = 'portfolios';
export const APP_ROUTE_SIGNIFICANT_CHANGES = 'significant-changes';
export const APP_ROUTE_EXPLORE = 'explore';
export const APP_ROUTE_SETTINGS = 'settings';
export const APP_ROUTE_CHARTS = 'charts';
export const APP_ROUTE_RESEARCH = 'research';
export const APP_ROUTE_INSIGHTS = 'insights';
export const APP_ROUTE_HELP = 'help';

export const APP_ROUTES = [
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_FUND,
  APP_ROUTE_WATCHLIST,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_CHARTS,
  APP_ROUTE_RESEARCH,
  APP_ROUTE_INSIGHTS,
];

export enum FUND_DETAILS_TAB {
  SUMMARY = 'summary',
  PERFORMANCE_AND_RISK = 'performance-and-risk',
  PORTFOLIO = 'portfolio',
  PLATFORMS = 'platforms',
  SIGNIFICANT_CHANGES = 'significant-changes',
  SHARE_CLASSES = 'share-classes',
  INVESTORS = 'investors',
  INTERNAL = 'internal',
  RESEARCH = 'research',
  RESEARCH_HISTORY = 'research/history',
}

export const buildFundDetailsPath = (
  isin: string,
  subTab?: FUND_DETAILS_TAB,
): string => {
  return `/fund/${isin.toUpperCase()}/details/${subTab ?? FUND_DETAILS_TAB.SUMMARY}`;
};
