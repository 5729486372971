import {
  APP_ROUTE_SETTINGS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
} from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as EmptyIcon } from '@/assets/svg/icons/icon-empty-sc.svg';

import EmptyStateBase from '../../EmptyStateBase';

const linkTo = `/${APP_ROUTE_SETTINGS}/${APP_ROUTE_SIGNIFICANT_CHANGES}`;

interface SignificantChangesEmptyStateProps
  extends React.HTMLAttributes<HTMLDivElement> {
  noThresholds?: boolean;
  customMessage?: string;
}

const SignificantChangesEmptyState: React.FC<
  PropsWithChildren<SignificantChangesEmptyStateProps>
> = ({ noThresholds, customMessage, ...props }) => (
  <EmptyStateBase
    {...props}
    icon={<EmptyIcon />}
    title="No Significant Changes"
    subtitle={
      <span className="text-neutral">
        {noThresholds ? (
          <>
            Set parameters in{' '}
            <Link style={{ color: '#1890ff' }} to={linkTo}>
              {' '}
              Settings{' '}
            </Link>{' '}
            to be alerted about changes in your watchlist.
          </>
        ) : (
          customMessage || 'There are no significant changes yet on this fund'
        )}
      </span>
    }
  />
);

export default SignificantChangesEmptyState;
