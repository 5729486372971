import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, Layout } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as BurgerMenu } from '@/assets/svg/graphic-burger-menu.svg';
import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import Avatar from '@/components/Avatar';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { screenBreakpoints } from '@/hooks/screenWidth';
import { useProvideProfile } from '@/pages/app/Settings/Profile/useProvideProfile';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { getUserInitials } from '@/utils/getUserInitials';
import NavSearchBox from './NavSearchbox';
import NotificationsWrapper from './Notifications';
import style from './style.module.less';

const { Header } = Layout;
interface NavbarProps {
  collapsed: boolean;
  onToggleMenu: () => void;
}

const Navbar: React.FC<PropsWithChildren<NavbarProps>> = ({
  collapsed,
  onToggleMenu,
}) => {
  const [isMenuToggle, setIsMenuToggle] = useState<boolean>(false);
  const [isToggleSearch, setIsToggleSearch] = useState<boolean>(false);
  const { user } = useProvideProfile();
  const { logout } = useAuth0();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;

  const handleToggle = () => {
    onToggleMenu();
    setIsMenuToggle(!isMenuToggle);
  };

  const handleToggleHome = () => {
    if (!isNotMobileResolution()) {
      onToggleMenu();
    }
  };

  useEffect(() => {
    setIsMenuToggle(collapsed);
  }, [collapsed]);

  const handleUserInitials = useMemo(() => {
    return getUserInitials(user);
  }, [user]);

  const isNotMobileResolution = () => {
    return (
      screenWidthMode[ScreenWidthEnum.MinMd] &&
      window.innerHeight > screenBreakpoints.sm
    );
  };

  const handleClickLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const menuItems = [
    {
      label: 'Logout',
      key: 'logout',
      onClick: () => handleClickLogout(),
      'data-test-id': 'navLogoutButton',
    },
  ];

  return (
    <Header
      className={cx(
        style.navbar,
        'sticky',
        `${
          (!isMenuToggle || isNotMobileResolution()) && style['navbar-active']
        }`,
      )}
    >
      <div className="flex items-center justify-start">
        <button
          className={style['navbar-menu']}
          type="button"
          tabIndex={0}
          onClick={handleToggle}
          data-test-id="navBurgerMenu"
        >
          <BurgerMenu />
        </button>
        <Link
          to="/dashboard"
          className={style['navbar-logo']}
          onClick={() => handleToggleHome()}
          data-test-id="navAMILogo"
        >
          <Logo />
        </Link>
      </div>
      <div
        className={cx(style['icons-wrapper'], {
          [style['icons-wrapper-hidden']]: !collapsed,
        })}
      >
        {!isAppLimitedAccessEnabled ? (
          isToggleSearch ? (
            <div className={style['navbar-search']} data-test-id="navSearch">
              <NavSearchBox onClose={() => setIsToggleSearch(false)} />
            </div>
          ) : (
            <button
              className={style['icon-search']}
              type="button"
              tabIndex={0}
              onClick={e => {
                setIsToggleSearch(!isToggleSearch);
                e.stopPropagation();
              }}
              data-test-id="navSearchButton"
            >
              <IconSearch className={cx('icon', style['icon-search'])} />
            </button>
          )
        ) : null}

        <NotificationsWrapper />
        <Dropdown
          menu={{ items: isMobile ? [] : menuItems }}
          trigger={['click']}
          placement="bottomRight"
          className={style['navbar-profile']}
        >
          <div
            data-test-id="navUserIcon"
            onClick={() => {
              setIsNestedDrawerVisible(true);
            }}
          >
            <Avatar type="user-avatar" color="sky-blue">
              {handleUserInitials?.toUpperCase()}
            </Avatar>
          </div>
        </Dropdown>
        {isMobile && (
          <NestedDrawer
            menuItems={menuItems}
            visible={isNestedDrawerVisible}
            onClose={() => {
              setIsNestedDrawerVisible(false);
            }}
            title="Account"
          />
        )}
      </div>
    </Header>
  );
};

export default Navbar;
