import { GetResearchResponse, ResearchApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useUserAccess from '@/hooks/useUserAccess';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';
import { USER_PERMISSIONS } from '@aminsights/shared';

type UseDashboardResearchResponse = GetResearchResponse;
type UseDashboardResearchError = unknown; // If API had error types place here
type UseDashboardResearchOptions = Omit<
  UseQueryOptions<UseDashboardResearchResponse, UseDashboardResearchError>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const DASHBOARD_RESEARCH_QUERY_KEY = 'dashboard_research';
export const invalidateDashboardResearch = () => {
  queryClient.invalidateQueries({ queryKey: [DASHBOARD_RESEARCH_QUERY_KEY] });
};
const useDashboardResearch = (options?: UseDashboardResearchOptions) => {
  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);
  const { state: featureSwitch } = useFeatureSwitchContext();
  return useQuery<UseDashboardResearchResponse, UseDashboardResearchError>(
    [DASHBOARD_RESEARCH_QUERY_KEY],
    async () => {
      if (
        !hasResearchViewPermissions ||
        !featureSwitch.isResearchDashboardEnabled
      ) {
        return { overdue: [], upcoming: [] };
      }
      const api = new ResearchApi(openApiConfig());
      const result = await api.getResearchWidget();
      return result.data;
    },
    options,
  );
};

export default useDashboardResearch;
