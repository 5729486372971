import { ADVISOR_ALLOWED_SECTORS } from '@aminsights/shared/dist/constants/user';

const SECTORS_SORT_ORDER = {
  [ADVISOR_ALLOWED_SECTORS[1]]: 1,
  [ADVISOR_ALLOWED_SECTORS[2]]: 2,
  [ADVISOR_ALLOWED_SECTORS[3]]: 3,
  [ADVISOR_ALLOWED_SECTORS[0]]: 4,
};

export const SECTORS_COLORS_MAP = {
  [ADVISOR_ALLOWED_SECTORS[0]]: '#00AFFF',
  [ADVISOR_ALLOWED_SECTORS[1]]: '#3BDEBC',
  [ADVISOR_ALLOWED_SECTORS[2]]: '#0EA79E',
  [ADVISOR_ALLOWED_SECTORS[3]]: '#EA50B4',
};

export const getSectorsSortOrder = <T extends { id: string }>(
  data: T[],
): T[] => {
  return data.sort((a, b) => {
    return SECTORS_SORT_ORDER[a.id] - SECTORS_SORT_ORDER[b.id];
  });
};
