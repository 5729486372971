import { APP_ROUTE_SIGNIFICANT_CHANGES } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { SIGNIFICANT_CHANGES_ROUTES } from './routes';

const SignificantChangesRouter: React.FC<PropsWithChildren> = () => (
  <SubRouter
    routes={SIGNIFICANT_CHANGES_ROUTES}
    rootPath={APP_ROUTE_SIGNIFICANT_CHANGES}
  >
    <Route
      exact
      path={`/${APP_ROUTE_SIGNIFICANT_CHANGES}`}
      render={() => (
        <Redirect to={`/${APP_ROUTE_SIGNIFICANT_CHANGES}/bucket/All`} />
      )}
    />
  </SubRouter>
);

export default SignificantChangesRouter;
