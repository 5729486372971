import { PORTFOLIO_REFETCH_KEY } from '@aminsights/shared';
import { Button, Tabs } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import NestedDrawer from '@/partials/NestedDrawer';
import { evaluatePortfolioSubRoute } from '@/utils';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { EMPTY_PAGE_SLUG } from '../../types';
import { useProvidePortfolio } from '../../useProvidePortfolio';

export const PortfoliosScrollTab: React.FC<PropsWithChildren> = () => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const history = useHistory();
  const { state, setPortfolio, fetchPortfolios } = useProvidePortfolio();
  const { portfolioId, tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [selectedPortfolio, setSelectedPortfolio] = useState<
    string | undefined
  >(state.portfolios.find(p => p._id === portfolioId)?.name);

  //Effect to redirect if smth is wrong with the route
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    // If refetch key is present, we don't want to redirect unitl the portfolios are fetched
    if (searchParams.has(PORTFOLIO_REFETCH_KEY)) return;
    const portfolioIsPresent = state.portfolios.some(
      p => p._id === portfolioId,
    );
    history.replace(
      `../${
        portfolioIsPresent
          ? portfolioId
          : state.portfolios[0]?._id || EMPTY_PAGE_SLUG
      }/${evaluatePortfolioSubRoute(tab)}`,
    );
    portfolioIsPresent &&
      setSelectedPortfolio(
        state.portfolios.find(p => p._id === portfolioId)?.name,
      );
  }, [portfolioId, state.portfolios.length, state.arePortfoliosLoading]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (searchParams.has(PORTFOLIO_REFETCH_KEY)) {
      searchParams.delete(PORTFOLIO_REFETCH_KEY);
      fetchPortfolios().then(() => {
        history.replace({
          search: searchParams.toString(),
        });
      });
    }
  }, [searchParams]);

  const onNavigateTab = (newPortfolioId: string) => {
    setPortfolio(newPortfolioId);
    history.push(`../${newPortfolioId}/${evaluatePortfolioSubRoute(tab)}`);
  };

  const menuItems = [
    ...(state?.portfolios
      ? state.portfolios.map(portfolio => ({
          label: <ElementWithDataTestId label={portfolio.name} />,
          key: portfolio._id,
          selected: portfolio.name === selectedPortfolio,
          onClick: () => {
            setIsNestedDrawerVisible(false);
            history.push(
              `/portfolios/${portfolio._id}/${evaluatePortfolioSubRoute(tab)}`,
            );
          },
        }))
      : []),
  ];

  return (
    <>
      {isMobile ? (
        <div className="hover:fill-[#40a9ff] text-neutral fill-[#545576]">
          <Button
            onClick={() => setIsNestedDrawerVisible(true)}
            className="w-full h-10 text-neutral text-left flex items-center justify-between border border-light rounded hover:border-primary"
          >
            <span className="text-sm text-neutral">{selectedPortfolio}</span>
            <NextArrow className="w-3 rotate-90" />
          </Button>
          <NestedDrawer
            menuItems={menuItems}
            title="Select Portfolio"
            visible={isNestedDrawerVisible}
            onClose={() => {
              setIsNestedDrawerVisible(false);
            }}
            key={state.portfolios.length}
          />
        </div>
      ) : (
        <Tabs
          activeKey={portfolioId}
          onChange={onNavigateTab}
          defaultActiveKey=""
          items={
            state.arePortfoliosLoading
              ? [
                  {
                    label: 'Loading...',
                    key: 'loading',
                    disabled: true,
                  },
                ]
              : state?.portfolios?.map(portfolio => ({
                  label: (
                    <Link
                      to={`/portfolios/${
                        portfolio._id
                      }/${evaluatePortfolioSubRoute(tab)}`}
                      onClick={event => {
                        event.stopPropagation();
                      }}
                    >
                      <ElementWithDataTestId label={portfolio.name} />
                    </Link>
                  ),
                  key: portfolio._id,
                })) || []
          }
        />
      )}
    </>
  );
};

export default PortfoliosScrollTab;
