import { Bar, BarConfig } from '@ant-design/charts';
import React, { PropsWithChildren } from 'react';

export interface IBarChartData {
  title: string;
  yField: string;
  xField: number;
}

type BarChartProps = {
  data: Array<IBarChartData>;
  yFormatter?: () => string;
  xFormatter?: () => string;
  tickInterval?: number;
  color?: string;
  legend?: false; // TODO: discovery for Legend type
};

const BarChart: React.FC<PropsWithChildren<BarChartProps>> = ({
  data,
  yFormatter = (text: string) => text.split(' ').join('\n'),
  xFormatter = (text: string) => `${text}%`,
  tickInterval = 10,
  color = '#00afff',
  legend = false,
}) => {
  // Calculate the index of 0 in the x-axis
  const minXValue = Math.abs(Math.min(...data.map(item => item.xField)));
  const zeroIndex = Math.ceil(minXValue / tickInterval);

  const config: BarConfig = {
    legend,
    yField: 'yField',
    xField: 'xField',
    seriesField: 'title',
    maxBarWidth: 8,
    data,
    color,
    barStyle: {
      fill: color,
      fillOpacity: 1,
      radius: [100, 100, 0, 0],
    },
    yAxis: {
      label: {
        style: {
          fontFamily: 'Inter',
          fill: '#545576',
        },
        formatter: yFormatter,
      },
      grid: {
        line: {
          style: {
            stroke: '#eaeaea',
          },
        },
      },
    },
    tooltip: {
      formatter: datum => {
        return { value: `${datum.xField}%`, name: `${datum.title}:` };
      },
      showTitle: false,
      domStyles: {
        'g2-tooltip-list-item': {
          display: 'flex',
          alignItems: 'center',
          fontSize: 0, // this hides the colon between name and value as it is no longer needed
        },
        'g2-tooltip-name': {
          display: 'inline-block',
          alignItems: 'center',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          fontSize: '12px',
        },
        'g2-tooltip-value': {
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#313341',
        },
      },
    },
    xAxis: {
      tickInterval,
      label: {
        style: {
          fontFamily: 'Inter',
          fill: '#545576',
        },
        formatter: xFormatter,
      },
      grid: {
        line: {
          style: (_, index: number) => {
            let stroke;
            if (index === zeroIndex && minXValue < 0) stroke = '#bac0d0';
            else stroke = '#eaeaea';

            return {
              stroke,
              lineDash: [7, 7],
            };
          },
        },
      },
    },
  };

  return <Bar {...config} />;
};

export default BarChart;
