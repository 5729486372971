import { BucketsApi } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  NotificationType,
} from '@aminsights/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'classnames';
import dayjs from 'dayjs';
import React, { PropsWithChildren } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useMarkNotificationsAsRead from '@/hooks/query-hooks/notification-hooks/useMarkNotificationsAsRead';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { openApiConfig } from '@/utils';

import { NOTES } from '@/hooks/query-hooks/research-hooks/query-keys';
import { Button } from '..';
import style from './style.module.less';

type NotificationPaneProps = {
  id: string;
  title: string;
  subtitle: string;
  date: string;
  navigationLink?: string; //TODO: should it be mandatory?
  isRead: boolean;
  onClick?: () => void;
  onClickDetail?: (id: string) => void;
  type: string;
};

const NotificationPane: React.FC<PropsWithChildren<NotificationPaneProps>> = ({
  id,
  title,
  subtitle,
  date,
  navigationLink,
  isRead,
  onClick,
  onClickDetail,
  type,
}) => {
  const { id: fundId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const bucketsApi = new BucketsApi(openApiConfig());
  const appContext = useAppContext();
  const { mutate: acceptBucketInvite, isLoading: isBucketInviteLoading } =
    useMutation(
      (token?: string) => bucketsApi.acceptSharedBucketInvite(token),
      {
        onSuccess: async res => {
          queryClient.invalidateQueries([WATCHLIST]);
          const navLink = navigationLink?.split('?token=')[0];
          history.push(navLink || '#');
          onClick?.();
          if (res.data.status === 204) {
            appContext.dispatch({
              type: APP_ACTIONS.SET_REGULAR_MESSAGE,
              payload: {
                shouldUseTimer: true,
                showSnackbarIcon: true,
                text: `${res.data.message}`,
              },
            });
          }
        },
      },
    );

  const markNotificationsAsRead = useMarkNotificationsAsRead();
  const history = useHistory();
  const handleAcceptBucketClick = () => {
    if (!isRead) {
      markNotificationsAsRead.mutate([id]);
      const token = navigationLink?.split('?token=')[1];
      acceptBucketInvite(token);
    }
  };

  const isBucketSharedNotification = type === NotificationType.BucketShared;
  const isPortfolioSharedNotification =
    type === NotificationType.PortfolioShared;

  return (
    <div
      onClick={async () => {
        if (isBucketSharedNotification) {
          return;
        }
        if (!isRead) {
          markNotificationsAsRead.mutate([id]);
        }
        if (isPortfolioSharedNotification && onClickDetail) {
          onClickDetail(id);
          return;
        }
        if (type === NotificationType.NotePublished && fundId) {
          queryClient.invalidateQueries([NOTES, fundId]);
        }
        if (navigationLink) history.push(navigationLink);
        if (onClick) {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={clsx(
          style['notification-pane-wrapper'],
          'rounded',
          !isRead && '!bg-info-50',
        )}
      >
        <div className={style['notification-details']}>
          <div className={style['notification-title']}>
            <p>
              {!isRead ? <b>{title} </b> : <>{title} </>}
              {subtitle}
            </p>
          </div>
          <div
            className={clsx({
              [style['notification-date']]: !isRead,
              [style['notification-date-read']]: isRead,
            })}
          >
            {date ? dayjs(date).format(DISPLAY_DATE_FORMAT) : EMPTY_DATA_POINT}
          </div>
          {isBucketSharedNotification && !isRead && (
            <Button
              onClick={handleAcceptBucketClick}
              className={style['notification-button']}
              loading={isBucketInviteLoading}
            >
              Accept Bucket
            </Button>
          )}
        </div>
        <div className={style['notification-indicator']}>
          <div
            className={clsx({
              [style.dot]: !isRead,
              [style['dot-hide']]: isRead,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationPane;
