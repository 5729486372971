import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import { significantChangesReducer } from './reducers';
import {
  SignificantChangesContextProps,
  SignificantChangesParameters,
  SignificantChangesState,
} from './types';

export const significantChangesParametersEmpty: SignificantChangesParameters = {
  size: 20,
  page: 0,
  term: '',
  sortDirection: PageQueryParametersSortDirectionEnum.Desc,
  sortKey: 'updatedDate',
  changeTypes: [],
  period: '',
};

export const initialState: SignificantChangesState = {
  significantChangesParameters: significantChangesParametersEmpty,
  significantChangeForModal: { visible: false, data: null },
};

const Context = createContext<SignificantChangesContextProps>({
  state: { ...initialState },
  dispatch: () => ({}),
});

export const SignificantChangesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(significantChangesReducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const useSignificantChangesContext =
  (): SignificantChangesContextProps => {
    return useContext(Context);
  };

export default Context;
