import {
  ChartDataResponseLegendData,
  ChartDataTag,
  ScatterPlotResponseLegendData,
} from '@aminsights/contract';
import {
  CHART_COLOR_FOR_EXCLUDED_LEGEND,
  generateChartColors,
} from '@aminsights/shared';
import { useMemo } from 'react';

import useGenerateChartColors from '@/hooks/query-hooks/chart-hooks/useGenerateChartColors';
import useGenerateChartColorsForPortfolio from '@/hooks/query-hooks/chart-hooks/useGenerateChartColorsForPortfolio';
import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

export interface UseChartColorsBaseProps<T> {
  legend: T[];
  retainSortOrder?: boolean;
}
interface UseChartColorsByIsinsViaAPIProps<T>
  extends UseChartColorsBaseProps<T> {
  isins: string[];
  ids?: string[];
  featuredIsins?: string[];
  benchmarkId?: string;
  sectors?: string[];
  enableTransparentColors?: boolean;
}
interface UseChartColorsForPortfolioViaAPIProps<T>
  extends UseChartColorsBaseProps<T> {
  portfolioId: string;
  benchmarkId?: string;
}
export interface UseChartsColorsByIsinsAndPortfoliosProps<T>
  extends UseChartColorsBaseProps<T> {
  isins: string[];
  // Format should be {id}_{name}
  portfolioLegendIds?: string[];
}

type LegendData = ScatterPlotResponseLegendData | ChartDataResponseLegendData;

/**
 * Generates a set of colors for chart elements, ensuring consistency and synchronization across different charts.
 *
 * This logic was moved from the frontend to the backend to simplify state management and ensure that multiple charts
 * display consistent colors without needing complex providers. Since different charts receive
 * their own `legendData` from different APIs, managing color synchronization in the frontend was challenging.
 *
 * Benefits of moving this logic to the backend:
 * - Simplified frontend state management, as we only rely on react-query cache.
 * - Consistent colors across charts: Multiple charts can call the same API, with caching handled by React Query.
 * - Single source of truth for charting tool or bucket charts: All charts reference the same backend logic for color assignments.
 * - Future-proofing: Easier integration with user-defined colors from a database when customization features are added.
 * - Separation of concerns: The chart data API focuses on data, while this API handles representational logic.
 *
 * @param {string[]} isins - Cannot coexist with IDs.
 * @param {string[]} ids - Some of the charts pass ID for uniqueness (Share Nav, Premium Discount, etc). Cannot coexist with ISINs
 * @param {LegendData[]} legend
 * @param {string} [benchmarkId]
 * @param {string} [sector]
 * @param {string[]} [featuredIsins] - Optional array of ISINs that should be highlighted with brighter colors.
 * @param {boolean} [enableTransparentColors] - If true, non-featured ISINs will be assigned transparent colors.
 * @param {boolean} [retainSortOrder] - If true, the input order of `isins` will be preserved; otherwise, they will be sorted alphabetically.
 *
 * @returns {Record<string, string>}
 */
const useChartColors = <T extends LegendData>({
  isins,
  ids,
  legend,
  retainSortOrder,
  benchmarkId,
  featuredIsins,
  sectors,
  enableTransparentColors = !!featuredIsins?.length,
}: UseChartColorsByIsinsViaAPIProps<T>) => {
  const { data: fundColors } = useGenerateChartColors({
    isins: ids || isins,
    benchmarkId,
    featuredIsins,
    retainSortOrder,
    sectors,
    enableTransparentColors,
  });
  // biome-ignore lint/correctness/useExhaustiveDependencies: ids is redundant
  const colors = useMemo(() => {
    if (legend?.length && fundColors) {
      return legend.reduce(
        (prev, curr) => {
          if (curr.id) {
            const legendColor = (() => {
              if (ids?.length) {
                return fundColors[curr.id];
              }
              return curr.isin ? fundColors[curr.isin] : fundColors[curr.id];
            })() as string;
            if (curr.dataTag === ChartDataTag.Excluded) {
              return {
                ...prev,
                [curr.id]: CHART_COLOR_FOR_EXCLUDED_LEGEND,
              };
            }
            return {
              ...prev,
              [curr.id]: legendColor,
            };
          }
          return prev;
        },
        {} as Record<string, string>,
      );
    }
    return {};
  }, [legend, fundColors]);
  return colors;
};
export const useChartColorsForPortfolio = <T extends LegendData>({
  portfolioId,
  legend,
  benchmarkId,
}: UseChartColorsForPortfolioViaAPIProps<T>) => {
  const { data: portfolioColors } = useGenerateChartColorsForPortfolio({
    portfolioId,
    benchmarkId,
  });
  const colors = useMemo(() => {
    if (legend?.length && portfolioColors) {
      return legend.reduce(
        (prev, curr) => {
          if (curr.id) {
            const legendColor = portfolioColors[curr.id] as string;
            if (curr.dataTag === ChartDataTag.Excluded) {
              return {
                ...prev,
                [curr.id]: CHART_COLOR_FOR_EXCLUDED_LEGEND,
              };
            }
            return {
              ...prev,
              [curr.id]: legendColor,
            };
          }
          return prev;
        },
        {} as Record<string, string>,
      );
    }
    return {};
  }, [legend, portfolioColors]);
  return colors;
};

export const useChartColorsWithIsinsAndPortfolio = <T extends LegendData>({
  isins,
  portfolioLegendIds,
  legend,
}: UseChartsColorsByIsinsAndPortfoliosProps<T>) => {
  const { data: funds } = useMultipleFundsByIsins(isins);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const sortedIds = useMemo(() => {
    const portfolioLabels = legend
      .filter(l => portfolioLegendIds?.find(f => l.id?.includes(f)))
      .map(p => {
        const idSplit = p.id?.split('_') || [];
        return {
          label: idSplit[1] || '',
          id: p.id || '',
        };
      });
    const fundLabels = (funds || []).map(f => ({
      id: legend.find(l => l.isin === f._id)?.id || '',
      label: f.fundName,
    }));
    const sortedLabels = [...portfolioLabels, ...fundLabels].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    const sortedIds = sortedLabels
      .map(({ id }) => id)
      .filter(Boolean) as string[];
    return sortedIds;
  }, [funds, isins, portfolioLegendIds, legend]);
  const colors = useMemo(
    () => generateChartColors({ legendData: legend, ids: sortedIds }),
    [sortedIds, legend],
  );
  return colors;
};

export default useChartColors;
