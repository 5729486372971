import { ChartDataTag } from '@aminsights/contract';
import { EDateFilterValues } from '@aminsights/shared';
import { AreaConfig } from '@ant-design/charts';
import cx from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetRollingReturnsChartData from '@/hooks/query-hooks/chart-hooks/useGetRollingReturnsChartData';
import {
  getInvestmentTrustReturnType,
  useChartSettingsState,
} from '@/hooks/useChartSettingsState';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { useDatePickerContext } from '@/partials/DatePicker/Context';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartProps } from '../components/BaseChart';
import { PerformanceCardDetailsWithPortfolio } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';
import { useChartColorsWithIsinsAndPortfolio } from '../utils/useChartColors';

interface RollingReturnsChartProps extends BaseChartProps {
  scrollable?: boolean;
  fundDetailPageDsiplay?: boolean;
  portfolios?: string[];
}

export const allowedPeriodValuesForRollingReturns = [
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
  EDateFilterValues['10YR'],
];

const RollingReturnsChart: FC<RollingReturnsChartProps> = ({
  emptyState,
  showFundLinks = true,
  isins,
  benchmarkId,
  sectorId,
  featuredIsins,
  portfolios,
  chartColors: chartColorsProps,
  scrollable,
  fundDetailPageDsiplay,
  onRemoveFund,
}) => {
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<
    PerformanceCardDetailsWithPortfolio[]
  >([]);
  const { chartSettingsFilter } = useChartSettingsState();
  const { rollingPeriod, investmentTrustsReturns } = chartSettingsFilter;

  const period = useMemo(() => {
    if (allowedPeriodValuesForRollingReturns.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['3YR'];
  }, [datePickerValue]);

  const { data, isLoading } = useGetRollingReturnsChartData(
    {
      benchmarkId,
      sectors: sectorId ? [sectorId] : [],
      isins,
      period,
      featuredIsins,
      portfolios,
      rollingPeriod,
      investmentTrustReturn: getInvestmentTrustReturnType(
        investmentTrustsReturns,
      ),
    },
    { enabled: !!isins.length || !!benchmarkId || !!sectorId },
  );

  const rollingReturnsData = data?.data || [];

  const chartColorsHook = useChartColorsWithIsinsAndPortfolio({
    isins,
    legend: data?.legendData || [],
    portfolioLegendIds: portfolios,
  });

  const chartColors =
    !chartColorsProps || Object.keys(chartColorsProps).length === 0
      ? chartColorsHook
      : chartColorsProps;

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          value: '',
          id: f.id || '',
          label: f.label || '',
          dataTag: f.dataTag,
          color: chartColors[f.id || ''] || '',
          date: f.date || '',
        })),
      );
    }
  }, [data, chartColors]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: rollingReturnsData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [rollingReturnsData, chartColors],
  );

  if (!rollingReturnsData.length && !isLoading) {
    return (
      <ChartEmptyState
        title={emptyState.title}
        subtitle={emptyState.subTitle}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length &&
            legendData.some(d => d.dataTag === ChartDataTag.Featured)
          }
          dataTestId="rollingReturnsChart"
          onUpdateLegend={setLegendData}
          scrollable={scrollable}
          tooltipOrder={tooltipOrder}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="rollingReturnsChartLegend"
        className={cx(
          'mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2',
          fundDetailPageDsiplay ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
        )}
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                value={legendItem.value}
                tooltip={legendItem.tooltip || legendItem.label}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                id={getLegendIdToRemove(legendItem)}
                date={legendItem.date}
                targetLink={
                  showFundLinks ? getLegendTargetLink(legendItem) : undefined
                }
                color={legendItem.color}
                dataTag={legendItem.dataTag}
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default RollingReturnsChart;
