export const MS_DATE_FORMAT = 'YYYY-MM-DD';
export const UK_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';
export const DISPLAY_TIME_FORMAT = 'h:mm A';

export enum EDateFilterValues {
  '7D' = '7D',
  '30D' = '30D',
  YTD = 'YTD',
  '1YR' = '1YR',
  '3YR' = '3YR',
  '5YR' = '5YR',
  '7YR' = '7YR',
  '10YR' = '10YR',
  CUSTOM = 'CUSTOM',
}

export const DATE_PERIOD_FILTER = [
  {
    value: EDateFilterValues['7D'],
    label: 'Last 7 days',
  },
  {
    value: EDateFilterValues['30D'],
    label: 'Last 30 days',
  },
  {
    value: EDateFilterValues.YTD,
    label: 'YTD',
  },
  {
    value: EDateFilterValues['1YR'],
    label: '1 year',
  },
  {
    value: EDateFilterValues['3YR'],
    label: '3 years',
  },
  {
    value: EDateFilterValues['5YR'],
    label: '5 years',
  },
  {
    value: EDateFilterValues['7YR'],
    label: '7 years',
  },
  {
    value: EDateFilterValues['10YR'],
    label: '10 years',
  },
  {
    value: EDateFilterValues.CUSTOM,
    label: 'Custom',
  },
];
