import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}

const withScrollToTop = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> =>
  function WithScrollToTopWrapper(props: P) {
    return (
      <ScrollToTop>
        <Component {...props} />
      </ScrollToTop>
    );
  };

export default withScrollToTop;
