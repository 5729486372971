import React, { PropsWithChildren } from 'react';

import loaderImage from '@/assets/images/market-data-mask.png';
import { BarChart } from '@/components';
import { IBarChartData } from '@/components/Charts/BarChart';
import useMarketDataByRange from '@/hooks/query-hooks/chart-hooks/useMarketDataByRange';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import RangeDatePicker, { useDatePickerContext } from '@/partials/DatePicker';

export const MarketDataChart: React.FC<PropsWithChildren> = () => {
  const { data: currencyData } = useGetCurrencyRates();

  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();

  const { data, isLoading } = useMarketDataByRange(datePickerValue.range);

  let maximum = 0;
  data?.forEach(marketData => {
    if (maximum > marketData?.value) {
      maximum = Math.abs(marketData.value);
    }
  });
  const tick: number = maximum > 5 ? Math.round(maximum) : 1;

  return (
    <>
      <div
        className="flex flex-col justify-center pb-2 h-32 items-start sm:h-auto sm:flex-row sm:justify-between sm:items-center lg:items-start lg:flex-col lg:h-32 lg:justify-center"
        data-test-id="dashboardMarketData"
      >
        <h5 className="text-sm font-bold text-darkest">
          Market Data{' '}
          <span className="font-normal text-neutral text-sm">
            ({currencyData?.currencyCode})
          </span>
        </h5>
        <div className="my-4 w-full sm:w-auto lg:w-full">
          {!isLoading && (
            <RangeDatePicker
              disabled={isLoading}
              onChange={handleDatePickerChange}
              value={datePickerValue}
              btnClassName="[&]:w-full"
            />
          )}
        </div>
      </div>
      {!isLoading && (
        <div data-test-id="dashboardMarketDataChart">
          <BarChart
            tickInterval={tick}
            data={
              //hide bloomberg benchmark if feature switch is off
              data
                ?.filter(_data => _data.name !== 'Global Agg')
                .map(
                  _data =>
                    ({
                      title: _data.tooltipTitle,
                      yField: _data.name,
                      xField: _data.value,
                    }) as IBarChartData,
                ) || []
            }
          />
        </div>
      )}
      {isLoading && (
        <img className="mt-5 mb-5 w-full" src={loaderImage} alt="loader" />
      )}
    </>
  );
};
