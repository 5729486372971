import React, { PropsWithChildren } from 'react';

import LegendCard, { LegendCardProps } from '..';

interface ChartsLegendCardProps extends LegendCardProps {
  value: string;
}

const ChartsLegendCard: React.FC<PropsWithChildren<ChartsLegendCardProps>> = ({
  color,
  label,
  tooltip,
  value,
  date,
  id,
  superTag,
  targetLink,
  dataTag,
  cardClasses,
  onRemove,
}) => {
  return (
    <LegendCard
      color={color}
      label={label}
      tooltip={tooltip}
      date={date}
      id={id}
      superTag={superTag}
      targetLink={targetLink}
      dataTag={dataTag}
      cardClasses={cardClasses}
      onRemove={onRemove}
    >
      <p className="text-xs font-bold text-darkest min-w-14 text-end">
        {value}
      </p>
    </LegendCard>
  );
};

export default ChartsLegendCard;
