/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Pagination } from '@/components';

import style from './style.module.less';

// TODO: find out the proper types for columns and dataSource
type CustomTableProps = {
  columns?: any[];
  data?: any[];
  loading?: boolean;
  scroll?: {
    x?: string | number | true | undefined;
    y?: string | number | undefined;
  };
  paginateTotalCount: number;
  showSorterTooltip?: boolean;
  className?: string;
  uniqueKey: string;
};
//What is this? not used anywhere, is it safe to be remove?
const CustomTable: React.FC<PropsWithChildren<CustomTableProps>> = ({
  columns,
  data,
  scroll,
  paginateTotalCount,
  loading = false,
  showSorterTooltip = true,
  className,
  uniqueKey,
}) => {
  const handlePagination = (_pageNumber: number, _itemsPerPage: number) => {
    // API call here with page number
    // e.g. https://api.instantwebtools.net/v1/passenger?page={pageNumber}&size={itemsPerPage}
    // save data from api to state management and display data
  };
  return (
    <div className={style['table-wrapper']}>
      <Table
        loading={loading}
        className={cx(style['custom-table'], className)}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={scroll}
        showSorterTooltip={showSorterTooltip}
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey={uniqueKey}
      />
      {paginateTotalCount > 0 ? (
        <div className={cx('lg:hidden', style['table-pagination'])}>
          <Pagination
            totalCount={paginateTotalCount}
            onPageChange={handlePagination}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
