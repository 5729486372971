import UpgradeAccessModal from '@/components/UpgradeAccessModal';
import { RESTRICTED_FEATURE } from '@/constants';
import { PropsWithChildren, createContext, useState } from 'react';

interface IUpgradeAccessContext {
  isUpgradeModalOpen: boolean;
  toggleUpgradeModal: (feature?: RESTRICTED_FEATURE) => void;
  restrictedFeature: RESTRICTED_FEATURE | undefined;
}

export const UpgradeAccessContext = createContext<IUpgradeAccessContext>({
  isUpgradeModalOpen: false,
  toggleUpgradeModal: () => {},
  restrictedFeature: undefined,
});

const UpgradeAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [restrictedFeature, setRestrictedFeature] = useState<
    RESTRICTED_FEATURE | undefined
  >(undefined);

  const toggleUpgradeModal = (feature?: RESTRICTED_FEATURE) => {
    setIsUpgradeModalOpen(prev => !prev);
    setRestrictedFeature(feature);
  };

  return (
    <UpgradeAccessContext.Provider
      value={{
        isUpgradeModalOpen,
        toggleUpgradeModal,
        restrictedFeature,
      }}
    >
      {children}
      <UpgradeAccessModal
        isModalOpen={isUpgradeModalOpen}
        onModalClose={toggleUpgradeModal}
        restrictedFeature={restrictedFeature}
      />
    </UpgradeAccessContext.Provider>
  );
};

export { UpgradeAccessProvider };
