import { PortfolioFund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  MonthCode,
  WATCHLIST_TAB_KEY,
  buildFundDetailsPath,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';
import WatchlistColumnAsOfDate from '@/partials/AsOfDates/WatchlistColumnAsOfDate';
import { RenderAnnualisedReturns } from '@/partials/RenderFundValues/RenderAnnualisedReturns';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

const PerformanceColumns = (): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();

  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      sortKey: 'fundName',
      render: (item: PortfolioFund): React.ReactNode => {
        if (!item.isin || !item.fundName) {
          return EMPTY_DATA_POINT;
        }
        return (
          <Link
            onClick={e => e.preventDefault()}
            to={buildFundDetailsPath(item.isin)}
            className="flex justify-center items-center gap-2"
          >
            {item.fundName}
            {(() => {
              const annualisedReturns =
                'computedNavAnnualisedReturns' in item &&
                Array.isArray(item.computedNavAnnualisedReturns)
                  ? item.computedNavAnnualisedReturns[0]
                  : undefined;
              const annualisedAsOfDate = annualisedReturns?.asOfDate;
              return (
                <WatchlistColumnAsOfDate
                  type={WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE}
                  fundAsOfDate={annualisedAsOfDate}
                />
              );
            })()}
          </Link>
        );
      },
      renderType: 'text',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      sortKey: 'totalReturnYtdPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.YTD} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '3 mos',
      sortKey: 'totalReturn3MonthPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M3} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '6 mos',
      sortKey: 'totalReturn6MonthPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M6} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '1 yr',
      sortKey: 'totalReturn1YearPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M12} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '3 yrs',
      sortKey: 'totalReturn3YearPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M36} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '5 yrs',
      sortKey: 'totalReturn5YearPct',
      render: (item: PortfolioFund) => {
        return (
          <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M60} />
        );
      },
      renderType: 'number',
      width: 85,
    },
    {
      title: '',
      render: () => '',
      renderType: 'text',
    },
  ];
};

export default PerformanceColumns;
