import { getFundOrInvestmentTrustTitle } from '@aminsights/shared';

import { IDataTablePortfolioColumns } from '../types';
import { IMarketCapData } from './type';

const getColumns = (
  isInvestmentTrust: boolean,
): IDataTablePortfolioColumns[] => [
  {
    title: 'Market Cap',
    render: (item: IMarketCapData) => {
      return <span className="font-medium text-neutral">{item.label}</span>;
    },
    renderType: 'text',
  },
  {
    title: getFundOrInvestmentTrustTitle(isInvestmentTrust),
    renderType: 'number',
    render: (item: IMarketCapData) => {
      return (
        <span className="text-neutral">
          {/* EMPTY_DATA_POINT is used inside of value (check generateMarketCapData), no formatting required */}
          {item.value}
        </span>
      );
    },
  },
];

export default getColumns;
