import { Tabs } from 'antd';
import cx from 'classnames';

import { useState } from 'react';
import DFMTable from '../../DFMDetails';
import Summary from '../../InfoAndStats/Summary';
import MPSOptions from '../../MPSOptions';
import Objectives from '../../Objectives';
import Platforms from '../../Platforms';
import RiskRatings from '../../RiskRatings';
import style from '../style.module.less';
import { TAB_KEYS_MPS } from '../types';

const OverviewMPS = () => {
  const [activeTab, setActiveTab] = useState(TAB_KEYS_MPS.DFM);

  const handleTabChange = (key: string) => {
    setActiveTab(key as TAB_KEYS_MPS);
  };

  return (
    <div className={cx('flex flex-col gap-y-5', 'max-w-[100vw]')}>
      <Summary />
      <div
        className={cx(style['section'], 'lg:rounded-t-lg')}
        data-test-id="fundDetailsSummaryTabFundDetails"
      >
        <Tabs
          className="sm:max-w-[unset] [&_.ant-table-cell]:w-1/2"
          activeKey={activeTab}
          onChange={handleTabChange}
          items={[
            {
              key: TAB_KEYS_MPS.DFM,
              label: <span data-test-id="dfmTab">DFM</span>,
              children: <DFMTable />,
            },
            {
              key: TAB_KEYS_MPS.MPS_OPTIONS,
              label: <span data-test-id="mpsOptionsTab">MPS Options</span>,
              children: <MPSOptions />,
            },
            {
              key: TAB_KEYS_MPS.SUSTAINABILITY,
              label: (
                <span data-test-id="sustainabilityTab">Sustainability</span>
              ),
              children: <Objectives />,
            },
            {
              key: TAB_KEYS_MPS.PLATFORMS,
              label: <span data-test-id="platformsTab">Platforms</span>,
              children: <Platforms />,
            },
            {
              key: TAB_KEYS_MPS.RISK_RATINGS,
              label: <span data-test-id="riskRatingsTab">Risk Ratings</span>,
              children: <RiskRatings />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OverviewMPS;
