import { DataSourceType, Fund, ItemsSectorDetail } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';
import { getFundOrInvestmentTrustTitle } from '@aminsights/shared';

import SectorLegendColor from '@/components/SectorEquityLegend/SectorLegendColor';

import { IDataTablePortfolioColumns } from '../types';

export const generateSectorFundDetailsColumn = (
  isInvestmentTrust: boolean,
  shouldShowEquityColumns: boolean,
  isEquityBroadAssetClass?: boolean,
  isPadi?: boolean,
): IDataTablePortfolioColumns[] => [
  {
    title: 'Sector',
    render: (item: ItemsSectorDetail) => {
      return (
        <div className="flex items-center">
          {isEquityBroadAssetClass && !isPadi && (
            <div className="w-4">
              <SectorLegendColor sectorType={item.type} />
            </div>
          )}
          <span className="font-medium text-neutral">
            {item.name || EMPTY_DATA_POINT}
          </span>
        </div>
      );
    },
    renderType: 'text',
    isColumnFixed: true,
    sortKey: isEquityBroadAssetClass && !isPadi ? 'sortByLegend' : undefined,
  },
  {
    title: getFundOrInvestmentTrustTitle(isInvestmentTrust),
    render: (item: ItemsSectorDetail) => (
      <span className="text-neutral">
        {!item.value ? EMPTY_DATA_POINT : `${item.value.toFixed(1)}%`}
      </span>
    ),
    renderType: 'number',
    width: 150,
    align: 'right',
  },
  ...(shouldShowEquityColumns
    ? [
        {
          title: 'Index',
          render: (item: ItemsSectorDetail) => (
            <span className="text-neutral">
              {!item.index ? EMPTY_DATA_POINT : `${item.index.toFixed(1)}%`}
            </span>
          ),
          renderType: 'number' as const,
          width: 150,
          align: 'right',
          isVisible: (
            fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
          ) =>
            !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
            fund.portfolioDataSource !== DataSourceType.Padi,
        },
        {
          title: 'Relative',
          render: (item: ItemsSectorDetail) => (
            <span className="text-neutral">
              {!item.relative
                ? EMPTY_DATA_POINT
                : `${item.relative.toFixed(1)}%`}
            </span>
          ),
          renderType: 'number' as const,
          width: 150,
          align: 'right',
          isVisible: (
            fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
          ) =>
            !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
            fund.portfolioDataSource !== DataSourceType.Padi,
        },
      ]
    : []),
];
