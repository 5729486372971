import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import useWatchlistAnnualisedPerformanceInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistAnnualisedPerformanceInfiniteQuery';
import useWatchlistRiskInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistRiskInfiniteQuery';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { formatDate } from '@/utils';
import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { memo, useState } from 'react';
import { getLatesAsofDate } from './findLatestAsOfDate';

const WatchlistColumnAsOfDate: React.FC<{
  type: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE | WATCHLIST_TAB_KEY.RISK;
  fundAsOfDate: string | undefined;
  className?: string;
}> = ({ type, fundAsOfDate, className }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const currentBucketId = useCurrentBucketId();

  const { data: performanceData } =
    useWatchlistAnnualisedPerformanceInfiniteQuery({
      bucketId: currentBucketId,
    });
  const { data: riskData } = useWatchlistRiskInfiniteQuery({
    bucketId: currentBucketId,
  });

  let computedAsOfDate;
  let riskMeasuresDetailsAsOfDate;
  let displayTooltip = false;
  if (type === WATCHLIST_TAB_KEY.RISK) {
    const pagesData = riskData?.pages[0].data;
    computedAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'computedNavRiskDetails')
      : null;
    riskMeasuresDetailsAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'riskMeasuresDetail', 'endDate')
      : null;
    if (
      riskMeasuresDetailsAsOfDate &&
      riskMeasuresDetailsAsOfDate !== computedAsOfDate
    ) {
      computedAsOfDate = riskMeasuresDetailsAsOfDate;
    }
    displayTooltip = fundAsOfDate !== computedAsOfDate;
  } else if (type === WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE) {
    const pagesData = performanceData?.pages[0].data;
    const latestAnnualisedPerformanceAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'computedNavAnnualisedReturns')
      : null;
    computedAsOfDate = latestAnnualisedPerformanceAsOfDate;
    displayTooltip = fundAsOfDate !== computedAsOfDate;
  }

  return (
    <div className={className}>
      {displayTooltip && (
        <Tooltip
          title={fundAsOfDate ? formatDate(fundAsOfDate) : 'No date available'}
          placement="bottom"
          color="#313341"
          open={tooltipVisible}
          onOpenChange={setTooltipVisible}
          align={{ offset: [0, 10] }}
          zIndex={50}
        >
          <InfoIcon
            className={cx('icon text-neutral', {
              'text-neutral-100': tooltipVisible,
            })}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default memo(WatchlistColumnAsOfDate);
