import { getFundOrInvestmentTrustTitle } from '@aminsights/shared';
import { Tabs } from 'antd';
import React, { PropsWithChildren, useMemo, useState } from 'react';

import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import InvestorsFundTable from './FundsTable';
import InvestorsShareClassTable from './ShareClassesTable';

enum InvestorsTabTitle {
  FUND = 'Fund',
  SHARE_CLASS = 'Share Class',
  TRUST = 'Trust',
}

const Investors: React.FC<PropsWithChildren> = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const isFundOrTrust = getFundOrInvestmentTrustTitle(!!isInvestmentTrust);
  const [activeTab, setActiveTab] = useState(
    isInvestmentTrust ? InvestorsTabTitle.TRUST : InvestorsTabTitle.FUND,
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const INVESTORS_TAB = useMemo(() => {
    const shareClasses = state?.sharedClass.map(s => s._id) ?? [];

    const tabs = [
      {
        tab: isFundOrTrust,
        component: () =>
          state?.fund.fundId && <InvestorsFundTable isins={shareClasses} />,
        dataTestId: `fundDetailsInvestorsTab${isFundOrTrust}Tab`,
      },
      ...(isInvestmentTrust
        ? []
        : [
            {
              tab: InvestorsTabTitle.SHARE_CLASS,
              component: () =>
                state?.fund._id && (
                  <InvestorsShareClassTable isin={state?.fund._id} />
                ),
              dataTestId: 'fundDetailsInvestorsTabShareClassTab',
            },
          ]),
    ];
    return tabs;
  }, [state?.fund, state?.sharedClass]);

  return (
    <div className="grid grid-cols-12 gap-4 lg:px-4">
      <div className="col-span-12 overflow-hidden lg:rounded-lg">
        <Tabs
          defaultActiveKey="Holdings"
          activeKey={activeTab}
          onChange={activeTab => {
            setActiveTab(activeTab as InvestorsTabTitle);
          }}
          items={INVESTORS_TAB.map(({ tab, component, dataTestId }) => ({
            label: <div data-test-id={dataTestId}>{tab}</div>,
            key: tab || '',
            children: <>{component()}</>,
          }))}
        />
      </div>
    </div>
  );
};

export default Investors;
