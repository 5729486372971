import { InsightsSectorAssetsHeld } from '@aminsights/contract';
import { roundOrEmpty } from '@aminsights/shared';
import { Pie } from '@ant-design/charts';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import { useGetSectorsAssetsHeld } from '@/hooks/query-hooks/insights-hooks/useInsights';
import { SECTORS_COLORS_MAP, getSectorsSortOrder } from './utils';

interface AssetsHeld extends InsightsSectorAssetsHeld {
  color: string;
}

const AssetsHeld = () => {
  const [sectorsAssetsHeld, setSectorsAssetsHeld] = useState<AssetsHeld[]>([]);
  const { data, isLoading } = useGetSectorsAssetsHeld();

  useEffect(() => {
    if (data) {
      const sectorsAssets = data.map(sector => ({
        ...sector,
        assetSizePercentage: Number(
          (sector.assetSizePercentage || 0).toFixed(1),
        ),
        // Since the sectors are hardcoded, we also use predefined colors.
        color: SECTORS_COLORS_MAP[sector.sectorId],
        id: sector.sectorId,
      }));
      const sortedSectorsAssets = getSectorsSortOrder(sectorsAssets);

      setSectorsAssetsHeld(sortedSectorsAssets);
    }
  }, [data]);

  const config = {
    height: 216,
    width: 216,
    innerRadius: 0.75,
    tooltip: undefined,
    data: sectorsAssetsHeld,
    angleField: 'assetSizePercentage',
    colorField: 'sectorName',
    color: sectorsAssetsHeld.map(sector => sector.color),
    // A workaround to hide the default legends, and labels.
    label: false,
    legend: false,
    statistic: {
      title: undefined,
      content: {
        content: '% of total assets',
        style: {
          color: '#313341',
          fill: '#545576',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 'medium',
          overflow: 'hidden',
          padding: '0 20px',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  };

  return (
    <div className="md:min-w-[280px] lg:min-w-[360px] md:rounded-lg bg-white">
      {isLoading ? (
        <div className="p-4">
          <Skeleton.Button style={{ width: '96px', height: '20px' }} active />
        </div>
      ) : (
        <p className="text-sm font-bold text-neutral-200 p-4">Assets Held</p>
      )}
      <div className="flex flex-col sm:flex-row md:flex-col justify-around">
        <div className="flex-1 flex justify-center items-center p-4 sm:py-12 md:py-4">
          {isLoading ? (
            <Skeleton.Avatar
              style={{ width: '216px', height: '216px' }}
              active
            />
          ) : (
            // This is a workaround to hide the default legends, and labels.
            // We simply mark the legends and labels as false in the config.
            // @ts-ignore
            <Pie {...config} />
          )}
        </div>
        <div className="flex-1 flex flex-col py-4 gap-2 self-stretch sm:self-center md:self-stretch">
          {isLoading
            ? Array.from({ length: 4 })
                .map((_, index) => index + 1)
                .map(key => (
                  <div
                    className="flex flex-row px-4 my-2 justify-between"
                    key={key}
                  >
                    <Skeleton.Button
                      style={{ width: '120px', height: '16px' }}
                      active
                    />
                    <Skeleton.Button
                      style={{ width: '20px', height: '16px' }}
                      active
                    />
                  </div>
                ))
            : sectorsAssetsHeld.map(sector => (
                <div
                  className="flex gap-x-1 items-center h-12 px-4 border-b border-grey-50 justify-between"
                  key={sector.sectorId}
                >
                  <p className="flex items-center">
                    <span
                      className="w-2 h-2 rounded-full mr-2"
                      style={{ backgroundColor: sector.color }}
                    />
                    <span className="text-xs font-bold text-neutral-200">
                      {sector.sectorName}
                    </span>
                  </p>
                  <p className="text-xs font-normal text-neutral-700">
                    {roundOrEmpty(sector.assetSizePercentage, 1, '%')}
                  </p>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default AssetsHeld;
