import { Meeting } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, USER_PERMISSIONS } from '@aminsights/shared';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '@/assets/svg/research/calendar.svg';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useDeleteMeeting,
  useGetMeetings,
} from '@/hooks/query-hooks/research-hooks/meetings-hooks';
import useUserAccess from '@/hooks/useUserAccess';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

import EmptyState from '../EmptyState';
import SubCardSkeletonLoader from '../Loaders/SubCard';
import AllMeetingsList from './AllMeetingsList';
import MeetingDetailModal from './MeetingDetailModal';
import MeetingFormModal from './MeetingFormModal';
import MeetingsListItem from './MeetingsListItem';

const Meetings = () => {
  const [showMeetingsForm, setShowMeetingsForm] = useState(false);
  const [showMeetingDetail, setShowMeetingDetail] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAllMeetings, setShowAllMeetings] = useState(false);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const { hasPermissions } = useUserAccess();

  const { data: meetings, isLoading } = useGetMeetings(fundMetadataId);
  const { data: usersData } = useOrganizationUsers();
  const { mutateAsync: deleteMeeting } = useDeleteMeeting();

  const handleDeleteMeeting = async () => {
    if (!selectedMeeting) return;
    setIsDeleteing(true);
    await deleteMeeting({
      fundMetadataId,
      meetingId: selectedMeeting._id,
    });
    setShowDeleteModal(false);
    setSelectedMeeting(null);
    setIsDeleteing(false);
  };

  const hasResearchWritePermissions = hasPermissions([
    USER_PERMISSIONS.research.write,
  ]);

  const hasExistingMeetings = Boolean(meetings?.length);
  const hasMoreThanThreeMeetings = (meetings?.length || 0) > 3;

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col self-stretch min-h-28 p-4 pb-6 lg:rounded-lg bg-white">
          <SubCardSkeletonLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 self-stretch min-h-28 p-4 pb-6 lg:rounded-lg bg-white">
          <div className="flex justify-between items-center">
            <h4 className="text-sm font-bold text-neutral-200">Meetings</h4>
            {hasExistingMeetings && hasResearchWritePermissions && (
              <Button
                size="large"
                type="primary"
                onClick={() => setShowMeetingsForm(true)}
              >
                Add meeting
              </Button>
            )}
          </div>
          {hasExistingMeetings ? (
            <div className="flex flex-col">
              {meetings?.slice(0, 3).map(meeting => (
                <MeetingsListItem
                  key={meeting._id}
                  meeting={meeting}
                  onEditMeeting={meeting => {
                    setSelectedMeeting(meeting);
                    setShowMeetingsForm(true);
                  }}
                  onDeleteMeeting={meeting => {
                    setSelectedMeeting(meeting);
                    setShowDeleteModal(true);
                  }}
                  onShowMeetingDetail={meeting => {
                    setSelectedMeeting(meeting);
                    setShowMeetingDetail(true);
                  }}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              icon={<CalendarIcon />}
              onClick={() => setShowMeetingsForm(true)}
              title="You haven’t logged any meetings"
              buttonLabel="Add meeting"
              hideButton={!hasResearchWritePermissions}
            />
          )}
          {hasMoreThanThreeMeetings && (
            <div className="flex justify-center">
              <Button
                type="link"
                className="font-medium leading-5"
                onClick={() => setShowAllMeetings(true)}
              >
                View all meetings
              </Button>
            </div>
          )}
          <MeetingFormModal
            initialValue={selectedMeeting}
            isVisible={showMeetingsForm}
            toggleForm={() => {
              setShowMeetingsForm(!showMeetingsForm);
              setSelectedMeeting(null);
            }}
            onSubmitForm={() => {}}
            userOptions={(usersData ?? []).map(user => ({
              id: user.user_id,
              name: user.name,
              title: '',
            }))}
          />
          <MeetingDetailModal
            isVisible={showMeetingDetail}
            meeting={selectedMeeting}
            toggleModal={() => {
              setShowMeetingDetail(!showMeetingDetail);
              setSelectedMeeting(null);
            }}
            showBackButton={showAllMeetings}
          />
          <AllMeetingsList
            isVisible={
              showAllMeetings &&
              !(showDeleteModal || showMeetingsForm || showMeetingDetail)
            }
            meetings={meetings ?? []}
            toggleModal={() => setShowAllMeetings(false)}
            onEditMeeting={meeting => {
              setSelectedMeeting(meeting);
              setShowMeetingsForm(true);
            }}
            onDeleteMeeting={meeting => {
              setSelectedMeeting(meeting);
              setShowDeleteModal(true);
            }}
            onShowMeetingDetail={meeting => {
              setSelectedMeeting(meeting);
              setShowMeetingDetail(true);
            }}
          />
          <ConfirmationModalDanger
            isVisible={showDeleteModal}
            loading={isDeleteing}
            modalInfo={{
              title: `Delete ‘${dayjs(selectedMeeting?.date).format(
                DISPLAY_DATE_FORMAT,
              )}’?`,
              succesMessage: 'Meeting has been deleted',
              description: (
                <span className="text-sm font-normal text-neutral-700">
                  This action cannot be undone.
                </span>
              ),
              primaryActionLabel: 'Delete',
            }}
            toggleModal={() => {
              setShowDeleteModal(false);
              setSelectedMeeting(null);
            }}
            onConfirm={handleDeleteMeeting}
          />
        </div>
      )}
    </>
  );
};

export default Meetings;
