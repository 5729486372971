import {
  DATE_PERIOD_FILTER,
  DISPLAY_DATE_FORMAT,
  EDateFilterValues,
} from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import dayjs from 'dayjs';
import { PropsWithChildren, useMemo } from 'react';
import { useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import ChartSettings from '@/components/ChartSettings/ChartSettings';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { useDatePickerContext } from '@/partials/DatePicker';
import { parseDateValue } from '@/partials/DatePicker/Kinds/RangeDatePicker';
import NestedDrawer from '@/partials/NestedDrawer';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import chartStyles from '../../Charts/utils/chart.module.less';

interface RiskReturnPlotTitleProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  riskReturnLatestDate?: string;
}

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const RiskReturnPlotTitle: React.FC<
  PropsWithChildren<RiskReturnPlotTitleProps>
> = ({ showDropdown, hasInvestmentTrust, riskReturnLatestDate }) => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  const currentPeriod = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);
  const activePeriod = DATE_PERIOD_FILTER.find(p => p.value === currentPeriod);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedYearDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedYearDrawerVisible, setIsNestedYearDrawerVisible] =
    useState(false);

  const humanizedPeriod = useMemo(
    () => DATE_PERIOD_FILTER.find(fd => fd.value === currentPeriod)?.label,
    [currentPeriod],
  );

  return (
    <>
      <div className={chartStyles.header}>
        <h5
          data-test-id="riskReturnChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader width="150px" component={<>Risk/Return</>} />
        </h5>
        <div className="flex items-center gap-x-2">
          <div
            className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto"
            data-test-id="riskReturnChartDropdown"
          >
            <Dropdown
              trigger={['click']}
              placement="bottom"
              menu={{
                items: isMobile ? [] : allowedPeriodsForDropdown,
              }}
            >
              <Button
                type="default"
                className="hover:fill-primary-light h-[32px] rounded w-full flex items-center justify-between text-neutral-700"
                onClick={() => setIsNestedYearDrawerVisible(true)}
              >
                <span>{activePeriod?.label}</span>
                <NextArrow className="w-3 h-3 rotate-90 fill-current" />
              </Button>
            </Dropdown>
            {isMobile && (
              <NestedDrawer
                menuItems={allowedPeriodsForDropdown}
                visible={isNestedYearDrawerVisible}
                onClose={() => setIsNestedYearDrawerVisible(false)}
                title="Select"
              />
            )}
          </div>
          {showDropdown && (
            <ChartSettings
              hidePanelKeys={[
                'rolling-period',
                ...(!hasInvestmentTrust
                  ? (['investment-trusts-returns'] as const)
                  : []),
              ]}
            />
          )}
        </div>
      </div>
      <div className="w-full justify-self-end">
        {humanizedPeriod && riskReturnLatestDate && (
          <p className="text-xs md:text-right mt-2">
            <strong>{humanizedPeriod}</strong> as at{' '}
            <i>{dayjs(riskReturnLatestDate).format(DISPLAY_DATE_FORMAT)}</i>
          </p>
        )}
      </div>
    </>
  );
};

export default RiskReturnPlotTitle;
