import { HorizontalScroll, PageResults } from '@/components';
import RestrictedAccessPage from '@/components/RestrictedAccessPage';
import { APP_ACTIONS, RESTRICTED_FEATURE } from '@/constants';
import {
  useGetAdminSettingsQuery,
  usePatchAdminSettings,
} from '@/hooks/query-hooks/admin-settings-hooks/admin-settings-hooks';
import useDashboardResearch from '@/hooks/query-hooks/dashboard-hooks/useDashboardResearch';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import useGetResearchCardsQuery from '@/hooks/query-hooks/research-hooks/useGetResearchCardsQuery';
import useNotificationSettings from '@/hooks/query-hooks/settings-hooks/useNotificationSettings';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import useUserAccess from '@/hooks/useUserAccess';
import ResearchTable from '@/partials/ResearchTable';
import { RESEARCH_WIDGET_ROWS_LIMIT } from '@/partials/ResearchWidget/const';
import { ResearchTableType } from '@/types/research';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Skeleton, Tabs } from 'antd';
import cx from 'classnames';
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import BasePageWithMetadata from '../BasePageWithMetadata';
import AllAnalystsModal from '../FundAndInvestmentTrust/components/Research/Analyst/AllAnalystsModal';
import AnalystFormModal from '../FundAndInvestmentTrust/components/Research/Analyst/AnalystFormModal';
import AnalystListing from '../FundAndInvestmentTrust/components/Research/Analyst/AnalystListing';
import HeaderButtonGroup from './component/HeaderButtonGroup';
import MyResearchTable from './component/MyResearchTable';
import ResearchCard from './component/ResearchCard';
import SkeletonFundPerformance from './component/SkeletonFundPerformance';
import { testMyResearchData } from './data';
import { generateResearchCards } from './utils';

enum MY_RESEARCH_TABS {
  ALL = 'All',
  OVERDUE = 'Overdue',
  UPCOMING = 'Upcoming',
  DRAFT = 'Draft',
}
const Research: React.FC<PropsWithChildren> = () => {
  const { user } = useAuth0();
  const { data: usersData, isLoading: isUserDataLoading } =
    useOrganizationUsers();
  const { data: adminSettings, isLoading: isAdminSettingsLoading } =
    useGetAdminSettingsQuery();
  const [seletectAnalysts, setSelectedAnalysts] = useState<string[]>([]);
  const isAnalystsLoading = isUserDataLoading || isAdminSettingsLoading;
  const patchAdminSettings = usePatchAdminSettings();

  const analystSubs = [
    ...(adminSettings?.analysts ?? []),
    usersData?.find(u => u.user_id === user?.sub)?.user_id,
  ];

  useEffect(() => {
    if (adminSettings) setSelectedAnalysts(adminSettings.analysts);
  }, [adminSettings]);
  const analysts = (usersData ?? [])
    .filter(user => analystSubs.includes(user.user_id))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleAssignAnalysts = () => {
    patchAdminSettings
      .mutateAsync({
        body: { analysts: seletectAnalysts },
      })
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Analysts has been added' },
        });
      });
    setShowAnalystForm(false);
  };

  const handleRemoveAnalyst = (id: string) => {
    patchAdminSettings
      .mutateAsync({
        body: { analysts: seletectAnalysts.filter(analyst => analyst !== id) },
      })
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Analysts has been removed' },
        });
      });
    setShowAnalystForm(false);
  };

  const { isRestrictedAccess } = useUpgradeAccess();
  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);
  const hasAdminPermissions = hasPermissions([USER_PERMISSIONS.research.admin]);

  if (isRestrictedAccess || !hasResearchViewPermissions) {
    return (
      <div className="pt-6">
        <RestrictedAccessPage
          restrictedFeature={RESTRICTED_FEATURE.RESEARCH_DASHBOARD}
        />
      </div>
    );
  }
  const refScroll = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [scrollSteps, setScrollSteps] = useState(0);
  const [showAnalystForm, setShowAnalystForm] = useState<boolean>(false);
  const [showAllAnalystsModal, setShowAllAnalystsModal] =
    useState<boolean>(false);

  const { data: researchCardsData, isLoading: isResearchCardsLoading } =
    useGetResearchCardsQuery(!!adminSettings);
  const researchCards = useMemo(() => {
    if (!researchCardsData) {
      return [];
    }
    return generateResearchCards(researchCardsData, isResearchCardsLoading);
  }, [researchCardsData, isResearchCardsLoading]);
  const { data, isLoading } = useDashboardResearch();
  const { data: notificationSettings } = useNotificationSettings();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (refScroll.current && !isLoading && data) {
      const hasScroll =
        refScroll.current.scrollWidth > refScroll.current.clientWidth;
      setHasScrollbar(hasScroll);
      setScrollSteps(refScroll.current?.getBoundingClientRect().width);
    }
  }, [
    refScroll.current?.scrollWidth,
    refScroll.current?.getBoundingClientRect().width,
    isLoading,
  ]);

  const upcomingData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.upcoming.slice(0, RESEARCH_WIDGET_ROWS_LIMIT);
  }, [data]);

  const overdueData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.overdue.slice(0, RESEARCH_WIDGET_ROWS_LIMIT);
  }, [data]);

  const onClickScroll = (scroll: number) => {
    if (refScroll?.current) {
      refScroll.current.scrollTo({
        top: 0,
        left: scroll + refScroll?.current.scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  return (
    <BasePageWithMetadata title={'Research'}>
      <div className="lg:px-4 pt-4">
        {isLoading ? (
          <div className="mb-5">
            <Skeleton.Button
              active
              className="flex"
              size="small"
              style={{ width: '96px', height: '16px' }}
            />
          </div>
        ) : (
          <div className="pl-4 lg:pl-0 flex flex-row justify-between pt-2 pr-4">
            <h2 className="mb-4 text-xl font-bold text-darkest">Research</h2>
            <div className="flex justify-end mb-4 gap-x-1">
              {!isLoading &&
                (researchCards?.length ?? 0) !== 0 &&
                hasScrollbar && (
                  <>
                    <Button
                      className={cx(
                        '!rounded-full !text-xs !border-0 !h-8 !w-8',
                      )}
                      onClick={() => onClickScroll(-scrollSteps)}
                      icon={
                        <LeftOutlined className="text-xs rounded-full stroke-[50] stroke-[#545675] text-[#545675]" />
                      }
                    />
                    <Button
                      className={cx(
                        '!rounded-full !text-xs !border-0 !h-8 !w-8',
                      )}
                      onClick={() => onClickScroll(scrollSteps)}
                      icon={
                        <RightOutlined className="text-xs rounded-full stroke-[50] stroke-[#545675] text-[#545675]" />
                      }
                    />
                  </>
                )}
            </div>
          </div>
        )}
        <HorizontalScroll
          hideScrollbars
          horizontal
          ref={refScroll}
          className="snap-x snap-mandatory relative mb-4 scroll-pl-4"
        >
          <div
            className={cx(
              'shadow-custom-horizontal-scroll',
              !hasScrollbar && 'hidden',
            )}
          />
          <div className="flex gap-x-6 px-4 lg:px-0">
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div
                    key={
                      // biome-ignore lint/suspicious/noArrayIndexKey: used index as key
                      index
                    }
                  >
                    <div className="p-4 bg-white rounded-lg shadow-card h-full w-[296px] min-h-[116px] ">
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: '96px', height: '13px' }}
                      />
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: '212px', height: '13px' }}
                      />
                    </div>
                  </div>
                ))
              : Array.from(researchCards).map(card => {
                  return <ResearchCard {...card} key={card.key} />;
                })}
          </div>
        </HorizontalScroll>

        {hasResearchViewPermissions && (
          <section
            className={`grid grid-cols-1 ${hasAdminPermissions ? 'lg:grid-cols-3' : 'lg:grid-cols-2'} gap-4 min-h-[244px] mb-4`}
          >
            <div className="bg-white rounded-lg">
              <div className="p-4 flex flex-col justify-between">
                <div className="mb-4">
                  <HeaderButtonGroup isLoading={isLoading} label="Overdue" />
                </div>
                <div className="border border-grey-50 rounded-t-lg [&>div>div]:!rounded-t-lg">
                  <ResearchTable
                    title="Notes/Meetings"
                    tableType={ResearchTableType.Overdue}
                    isLoading={isLoading}
                    data={overdueData}
                    notificationSettings={
                      notificationSettings?.notesAndMeetings
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg">
              <div className="p-4 flex flex-col justify-between">
                <div className="mb-4">
                  <HeaderButtonGroup isLoading={isLoading} label="Upcoming" />
                </div>
                <div className="border border-grey-50 rounded-t-lg [&>div>div]:!rounded-t-lg">
                  <ResearchTable
                    title="Notes/Meetings"
                    tableType={ResearchTableType.Upcoming}
                    isLoading={isLoading}
                    data={upcomingData}
                    notificationSettings={
                      notificationSettings?.notesAndMeetings
                    }
                  />
                </div>
              </div>
            </div>
            {hasAdminPermissions && (
              <div className="bg-white rounded-lg overflow-auto">
                {isAnalystsLoading ? (
                  <SkeletonFundPerformance />
                ) : (
                  <>
                    <div className="flex flex-col justify-around gap-2 self-stretch min-h-48 h-full p-4 pb-6 lg:rounded-lg bg-white">
                      <div className=" flex justify-between items-center">
                        <h4 className="text-sm font-bold text-neutral-200">
                          Analysts
                        </h4>
                        <Button
                          className={cx(
                            'w-auto h-10 px-4 py-2 text-sm font-medium rounded',
                            'disabled:border-none disabled:bg-neutral-300 disabled:text-white',
                          )}
                          type="primary"
                          onClick={() => setShowAnalystForm(true)}
                        >
                          Add an analyst
                        </Button>
                      </div>
                      <AnalystListing
                        prohibitSelfRemove
                        classNameLi="pt-4"
                        analysts={analysts}
                        onRemoveAnalyst={handleRemoveAnalyst}
                        emptyStateText="No analysts"
                        hideAddButton
                      />

                      {analysts.length !== 0 && (
                        <Button
                          size="small"
                          type="link"
                          className="p-0 text-primary justify-self-center font-medium"
                          onClick={() => setShowAllAnalystsModal(true)}
                        >
                          <Link to={'#'}>View all analysts</Link>
                        </Button>
                      )}
                    </div>

                    <AnalystFormModal
                      showModalOnAllLayouts
                      isVisible={showAnalystForm}
                      selectedAnalysts={seletectAnalysts}
                      options={(usersData ?? []).map(user => ({
                        value: user.user_id,
                        label: user.name,
                      }))}
                      close={() => setShowAnalystForm(false)}
                      onChangeAnalysts={setSelectedAnalysts}
                      onSubmitForm={handleAssignAnalysts}
                    />
                    <AllAnalystsModal
                      analysts={analysts}
                      isVisible={showAllAnalystsModal}
                      close={() => setShowAllAnalystsModal(false)}
                      onRemoveAnalyst={handleRemoveAnalyst}
                      showModalOnAllLayouts
                    />
                  </>
                )}
              </div>
            )}
          </section>
        )}

        <section>
          <div className="min-h-[453px] pb-6 mb-6 bg-white rounded-t-lg">
            <div className="pt-4 p-y flex flex-col justify-between">
              <div className="px-4">
                {isLoading ? (
                  <Skeleton.Button
                    active
                    className="flex"
                    size="small"
                    style={{ width: '96px', height: '13px' }}
                  />
                ) : (
                  <h4 className="text-sm font-bold text-neutral-200">
                    My Research
                  </h4>
                )}
              </div>
              <div className="flex flex-row justify-between items-center">
                <Tabs
                  defaultActiveKey={MY_RESEARCH_TABS.ALL}
                  items={Object.values(MY_RESEARCH_TABS).map(value => ({
                    label: value,
                    key: value,
                  }))}
                  onChange={(activeKey: string) => {}}
                />
                {isLoading ? (
                  <div className="px-4">
                    <Skeleton.Button
                      active
                      className="flex"
                      size="small"
                      style={{ width: '96px', height: '13px' }}
                    />
                  </div>
                ) : (
                  <PageResults
                    totalCount={testMyResearchData.length}
                    className="w-full flex !justify-end pr-1 sm:pr-2 sm:w-32"
                  />
                )}
              </div>
              <div>
                <MyResearchTable
                  data={testMyResearchData}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </BasePageWithMetadata>
  );
};

export default Research;
function dispatchApp(arg0: { type: APP_ACTIONS; payload: { text: string } }) {
  throw new Error('Function not implemented.');
}
