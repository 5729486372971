/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import { RESTRICTED_FEATURE } from '@/constants/features';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { NotificationsSettingsForSignificantChanges } from '@aminsights/contract';
import {
  ChangeTypeEnum,
  ChangeTypeLabels,
  lockedChangeTypes,
} from '@aminsights/shared';
import { Checkbox } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';

export type NotificationType = keyof NotificationsSettingsForSignificantChanges;

type NotificationModalChecklistProps = {
  setChecked: (
    key: ChangeTypeEnum,
    type: NotificationType,
    value?: boolean,
  ) => void;
  setAllChecked: (type: NotificationType, value: boolean) => void;
  notificationSettings: NotificationsSettingsForSignificantChanges;
};

const NotificationModalChecklist: React.FC<
  PropsWithChildren<NotificationModalChecklistProps>
> = ({ notificationSettings, setChecked, setAllChecked }) => {
  const { inApp, email } = notificationSettings;
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const [allAppChecked, setAllAppChecked] = React.useState<boolean>(false);
  const [allEmailChecked, setAllEmailChecked] = React.useState<boolean>(false);
  useEffect(() => {
    setAllAppChecked(
      Object.entries(inApp)
        .filter(
          ([key]) =>
            !lockedChangeTypes.includes(key as ChangeTypeEnum) ||
            !isRestrictedAccess,
        )
        .every(([_, value]) => value === true),
    );
    setAllEmailChecked(
      Object.entries(email)
        .filter(
          ([key]) =>
            !lockedChangeTypes.includes(key as ChangeTypeEnum) ||
            !isRestrictedAccess,
        )
        .every(([_, value]) => value === true),
    );
  }, [inApp, email]);

  return (
    <div className="text-neutral-500 text-sm font-normal">
      <div className="md:my-4">
        <p className="font-normal text-sm text-darkest">
          Your notifications will be based on the parameters you have selected
          in your{' '}
          <Link
            to="/settings/significant-changes"
            data-test-id="significantChangesLink"
            className="text-link hover:text-info font-bold"
          >
            Significant Changes settings
          </Link>
        </p>
      </div>
      <div className="[&_.ant-checkbox-wrapper]:py-0 grid grid-cols-[5fr,_1fr,_1fr]">
        <div
          className={cx(
            'h-10 leading-10 text-left',
            'text-darkest font-medium',
          )}
        >
          <p>Notifications</p>
        </div>
        <div
          className={cx(
            'h-10 leading-10 text-center min-w-[60px]',
            'text-darkest font-medium',
          )}
        >
          <p>In-App</p>
        </div>
        <div
          className={cx(
            'h-10 leading-10 text-center',
            'text-darkest font-medium',
          )}
        >
          <p>Email</p>
        </div>
        <div className="h-10 leading-10 text-left">Select All</div>
        <div className="h-10 leading-10 text-center min-w-[60px]">
          <Checkbox
            onChange={() => {
              setAllChecked('inApp', !allAppChecked);
              setAllAppChecked(!allAppChecked);
            }}
            checked={allAppChecked}
            data-test-id="notificationsInAppAllCheckbox"
          />
        </div>

        <div className="h-10 leading-10 text-center">
          <Checkbox
            onChange={() => {
              setAllChecked('email', !allEmailChecked);
              setAllEmailChecked(!allEmailChecked);
            }}
            checked={allEmailChecked}
            data-test-id="notificationsEmailAllCheckbox"
          />
        </div>
        <div className="col-span-3 border-b border-[#bac0d0bf] my-2.5" />
        {Object.entries(ChangeTypeLabels).map(([key, value]) => (
          <React.Fragment key={key}>
            <div
              className="grid col-span-3 grid-cols-[5fr,_1fr,_1fr]"
              onClick={() => {
                if (
                  lockedChangeTypes.includes(key as ChangeTypeEnum) &&
                  isRestrictedAccess
                ) {
                  toggleUpgradeModal(RESTRICTED_FEATURE.SETTING_NOTIFICATIONS);
                }
              }}
            >
              <div className="h-10 leading-10 text-left flex items-center gap-x-2 flex-1">
                {value}
                {lockedChangeTypes.includes(key as ChangeTypeEnum) &&
                  isRestrictedAccess && (
                    <LockIcon className="fill-neutral-100" />
                  )}
              </div>
              <div className="h-10 leading-10 text-center min-w-[60px]">
                <Checkbox
                  onChange={() => setChecked(key as ChangeTypeEnum, 'inApp')}
                  checked={notificationSettings.inApp[key as ChangeTypeEnum]}
                  key={key}
                  disabled={
                    lockedChangeTypes.includes(key as ChangeTypeEnum) &&
                    isRestrictedAccess
                  }
                />
              </div>
              <div className="h-10 leading-10 text-center">
                <Checkbox
                  onChange={() => setChecked(key as ChangeTypeEnum, 'email')}
                  checked={notificationSettings.email[key as ChangeTypeEnum]}
                  key={key}
                  disabled={
                    lockedChangeTypes.includes(key as ChangeTypeEnum) &&
                    isRestrictedAccess
                  }
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default NotificationModalChecklist;
