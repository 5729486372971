import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { ReactComponent as Close } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Search } from '@/pages/app/Explore/components/Search';

import { DropdownItem, DropdownMenu, DropdownProps } from '../shared';
import style from '../style.module.less';

const CustomDropdown: React.FC<PropsWithChildren<DropdownProps>> = ({
  dropdownKey,
  items,
  valuesToHide,
  defaultValue,
  value,
  // type,
  label,
  onSelect,
  onClear,
  className,
  shouldHighlightSelected,
  rounded,
  iconCarretClassName,
  disabled = false,
  placement,
  showSearch,
  height,
  isItemRefreshed,
  dataTestId,
  autoFocus,
}) => {
  const [activeOption, setActiveOption] = useState<DropdownItem>();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [filteredItem, setFilteredItem] = useState<string>('');

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    /**
     * This effectively makes the component a controlled component
     */
    const newActiveOption = items.find(
      ({ value: itemValue }) => itemValue === value,
    );
    setActiveOption(newActiveOption);
  }, [value]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (defaultValue !== undefined && items?.length) {
      const itemByValue = items.find(({ value }) => value === defaultValue);
      setActiveOption(itemByValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isItemRefreshed) {
      setFilteredItem('');
    }
  }, [isItemRefreshed]);

  const onChange = (value: string) => {
    setFilteredItem(value?.toLowerCase() || '');
  };

  return (
    <>
      <div className={style.dropdown}>
        <Dropdown
          className={cx(className, 'p-1')}
          open={isMenuVisible}
          disabled={disabled}
          overlayStyle={{ width: 'min-content', maxWidth: '100%' }} // This value is actually useless, dropdown antd calculates the min-width, but the default was greater for some reason causing it to break responsiveness. 0px will actually fix it, i opted for min-content
          overlay={() => (
            <>
              {showSearch && (
                <div className="px-2 pt-2">
                  <Search
                    autoFocus={autoFocus}
                    onChange={onChange}
                    key={dropdownKey}
                    visible={isMenuVisible}
                  />
                </div>
              )}

              <DropdownMenu
                height={height}
                valuesToHide={valuesToHide}
                items={items.filter(name => {
                  let parsedLabel = name.label;
                  if (typeof parsedLabel !== 'string') {
                    const el = name.label as any;
                    // stupid solution, but hey it works. we can fix this later.
                    parsedLabel = el?.props?.children;
                  }
                  return parsedLabel
                    ?.toString()
                    .toLowerCase()
                    .includes(filteredItem);
                })}
                state={activeOption}
                setState={item => setActiveOption(item)}
                onSelect={onSelect}
                rounded={rounded}
                emitIsItemSelected={value => setIsMenuVisible(!value)}
                dataTestIdPrefix={dataTestId}
              />
            </>
          )}
          trigger={['click']}
          placement={placement}
        >
          <Button
            className={cx(style['dropdown-button'], {
              [style['dropdown-selected']]:
                shouldHighlightSelected && !!activeOption?.value,
            })}
            type="link"
            onClick={() => setIsMenuVisible(true)}
            data-test-id={dataTestId ?? ''}
          >
            <p
              className={cx(
                style['dropdown-label'],
                activeOption?.label === undefined && style['is-placeholder'],
              )}
              data-test-id={`${dataTestId}SelectedLabel`}
            >
              {activeOption?.label || label}
            </p>
            {onClear && value !== '' && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  onClear?.();
                }}
                className={cx(style['close-button'])}
              >
                <Close className={cx(style['close-icon'], 'cursor-pointer')} />
              </div>
            )}
            <NextArrow
              className={cx(
                'icon',
                style['dropdown-icon-caret'],
                iconCarretClassName,
              )}
            />
          </Button>
        </Dropdown>
      </div>
      {isMenuVisible && (
        <div
          className={style['dropdown-overlay']}
          onClick={() => setIsMenuVisible(false)}
          onKeyUp={() => setIsMenuVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default CustomDropdown;
