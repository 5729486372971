import { ResearchDeadlineWidgetTypeEnum } from '@aminsights/contract';

export enum BroadAssetClassColor {
  EQ_BG = '#E6F2FF',
  EQ_HIGHLIGHT = '#99CCFF',
  FI_BG = '#E5F2DA',
  FI_HIGHLIGHT = '#97D17D',
}

export const sectorLegendList = [
  { label: 'Cyclical', color: '#F56A00', type: [101, 102, 103, 104] },
  { label: 'Sensitive', color: '#0072E6', type: [308, 309, 310, 311] },
  { label: 'Defensive', color: '#18A515', type: [207, 206, 205] },
];

export const researchLegendList = [
  {
    label: 'Note',
    color: '#5A17BF',
    type: ResearchDeadlineWidgetTypeEnum.Note,
  },
  {
    label: 'Meeting',
    color: '#F56A00',
    type: ResearchDeadlineWidgetTypeEnum.Meeting,
  },
];
