import { Slider } from 'antd';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';
import { DynamicPlannerRiskFilterType } from '@aminsights/shared';
import { moreInitialState } from '../../../hooks/moreFiltersSubState';
import {
  riskRatingMarks,
  riskRatingOxfordMarks,
} from '../../../hooks/riskRatingSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../../../utils/adjustMarksToAvoidDuplicates';
import { getRangeSliderClassName } from '../../../utils/getRangeSliderClassName';
import style from '../../style.module.less';

const MoreFiltersRiskRating = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const defaqtoValue =
    tentativeFilters.moreFilters?.riskRating?.defaqto ??
    moreInitialState.riskRating?.defaqto;
  const dynamicPlannerValue =
    tentativeFilters.moreFilters?.riskRating?.dynamicPlanner ??
    moreInitialState.riskRating?.dynamicPlanner;
  const evValue =
    tentativeFilters.moreFilters?.riskRating?.ev ??
    moreInitialState.riskRating?.ev;
  const oxfordValue =
    tentativeFilters.moreFilters?.riskRating?.oxford ??
    moreInitialState.riskRating?.oxford;
  const synapticValue =
    tentativeFilters.moreFilters?.riskRating?.synaptic ??
    moreInitialState.riskRating?.synaptic;

  return (
    <div className="flex flex-col gap-2">
      <div
        className={style['range-slider']}
        data-test-id={'moreFiltersSliderRiskRatingDefaqto'}
      >
        <span className="text-sm font-normal text-neutral">Defaqto</span>
        <Slider
          className={
            style[
              getRangeSliderClassName(
                !!tentativeFilters.moreFilters?.riskRating?.defaqto,
              )
            ]
          }
          tooltip={{ open: false }}
          min={1}
          max={10}
          value={defaqtoValue}
          marks={riskRatingMarks}
          onChange={value => {
            const filters = getMutableFilters();
            filters.moreFilters = filters.moreFilters ?? {};
            let riskRatingFilters = filters.moreFilters?.riskRating ?? {};
            riskRatingFilters = {
              ...riskRatingFilters,
              defaqto: adjustMarksToAvoidDuplicates(value, riskRatingMarks),
            };
            filters.moreFilters.riskRating = riskRatingFilters;
            updateTentativeFilters(filters);
          }}
          range
        />
      </div>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-sm font-normal text-neutral">
            Dynamic Planner
          </span>
          <div>
            <MultiCheckBox
              data-test-id="dynamicPlannerRiskType"
              direction="row"
              data={[
                {
                  label: 'Risk Profiled',
                  value: DynamicPlannerRiskFilterType.RISK_PROFILED,
                },
                {
                  label: 'Risk Targeted',
                  value: DynamicPlannerRiskFilterType.RISK_TARGETED,
                },
              ]}
              value={
                tentativeFilters.moreFilters?.riskRating?.dynamicPlannerType
              }
              onChange={value => {
                const filters = getMutableFilters();
                filters.moreFilters = filters.moreFilters ?? {};
                filters.moreFilters.riskRating =
                  filters.moreFilters.riskRating ?? {};
                filters.moreFilters.riskRating.dynamicPlannerType =
                  value.length > 0 ? value : undefined;
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </div>
        <div
          className={style['range-slider']}
          data-test-id={'moreFiltersSliderRiskRatingDynamicPlanner'}
        >
          <Slider
            className={
              style[
                getRangeSliderClassName(
                  !!tentativeFilters.moreFilters?.riskRating?.dynamicPlanner,
                )
              ]
            }
            tooltip={{ open: false }}
            min={1}
            max={10}
            value={dynamicPlannerValue}
            marks={riskRatingMarks}
            onChange={value => {
              const filters = getMutableFilters();
              filters.moreFilters = filters.moreFilters ?? {};
              let riskRatingFilters = filters.moreFilters?.riskRating ?? {};
              riskRatingFilters = {
                ...riskRatingFilters,
                dynamicPlanner: adjustMarksToAvoidDuplicates(
                  value,
                  riskRatingMarks,
                ),
              };
              filters.moreFilters.riskRating = riskRatingFilters;
              updateTentativeFilters(filters);
            }}
            range
          />
        </div>
      </div>
      <div
        className={style['range-slider']}
        data-test-id={'moreFiltersSliderRiskRatingEv'}
      >
        <span className="text-sm font-normal text-neutral">ev (10 yrs)</span>
        <Slider
          className={
            style[
              getRangeSliderClassName(
                !!tentativeFilters.moreFilters?.riskRating?.ev,
              )
            ]
          }
          tooltip={{ open: false }}
          min={1}
          max={10}
          value={evValue}
          marks={riskRatingMarks}
          onChange={value => {
            const filters = getMutableFilters();
            filters.moreFilters = filters.moreFilters ?? {};
            let riskRatingFilters = filters.moreFilters?.riskRating ?? {};
            riskRatingFilters = {
              ...riskRatingFilters,
              ev: adjustMarksToAvoidDuplicates(value, riskRatingMarks),
            };
            filters.moreFilters.riskRating = riskRatingFilters;
            updateTentativeFilters(filters);
          }}
          range
        />
      </div>
      <div
        className={style['range-slider']}
        data-test-id={'moreFiltersSliderRiskRatingOxford'}
      >
        <span className="text-sm font-normal text-neutral">Oxford Risk</span>
        <Slider
          className={
            style[
              getRangeSliderClassName(
                !!tentativeFilters.moreFilters?.riskRating?.oxford,
              )
            ]
          }
          tooltip={{ open: false }}
          min={1}
          max={7}
          value={oxfordValue}
          marks={riskRatingOxfordMarks}
          onChange={value => {
            const filters = getMutableFilters();
            filters.moreFilters = filters.moreFilters ?? {};
            let riskRatingFilters = filters.moreFilters?.riskRating ?? {};
            riskRatingFilters = {
              ...riskRatingFilters,
              oxford: adjustMarksToAvoidDuplicates(
                value,
                riskRatingOxfordMarks,
              ),
            };
            filters.moreFilters.riskRating = riskRatingFilters;
            updateTentativeFilters(filters);
          }}
          range
        />
      </div>
      <div
        className={style['range-slider']}
        data-test-id={'moreFiltersSliderRiskRatingSynaptic'}
      >
        <span className="text-sm font-normal text-neutral">Synaptic</span>
        <Slider
          className={
            style[
              getRangeSliderClassName(
                !!tentativeFilters.moreFilters?.riskRating?.synaptic,
              )
            ]
          }
          tooltip={{ open: false }}
          min={1}
          max={10}
          value={synapticValue}
          marks={riskRatingMarks}
          onChange={value => {
            const filters = getMutableFilters();
            filters.moreFilters = filters.moreFilters ?? {};
            let riskRatingFilters = filters.moreFilters?.riskRating ?? {};
            riskRatingFilters = {
              ...riskRatingFilters,
              synaptic: adjustMarksToAvoidDuplicates(value, riskRatingMarks),
            };
            filters.moreFilters.riskRating = riskRatingFilters;
            updateTentativeFilters(filters);
          }}
          range
        />
      </div>
    </div>
  );
};

export default MoreFiltersRiskRating;
