import React, { PropsWithChildren } from 'react';

import { toCamelCase } from '@/utils/toCamelCase';

import WatchlistHeaderAsOfDate from '@/partials/AsOfDates/WatchlistHeaderAsOfDate';
import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import style from '../style.module.less';
interface IHeadingProps {
  isAllBucketsSelected: boolean;
  bucketName: string | undefined;
  bucketIndex: string | undefined;
  activeTabMenuItem?:
    | WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE
    | WATCHLIST_TAB_KEY.RISK
    | undefined;
}

export const Heading: React.FC<PropsWithChildren<IHeadingProps>> = ({
  isAllBucketsSelected,
  bucketName = '',
  bucketIndex = '',
  activeTabMenuItem,
}) => {
  const isPerformanceOrRisk = [
    WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
    WATCHLIST_TAB_KEY.RISK,
  ].includes(activeTabMenuItem as WATCHLIST_TAB_KEY);
  return (
    <div
      className={`${style['watchlist-body__header']} mb-2 h-14 md:h-8 !flex !flex-col !items-start`}
    >
      <div className="flex flex-row max-sm:flex-col-reverse justify-between !w-full sm:items-center gap-x-2 min-h-8">
        {isAllBucketsSelected ? (
          <h3 data-test-id="allFunds/Trusts" className="title">
            All Funds/Trusts
          </h3>
        ) : (
          <h3
            data-test-id={toCamelCase(`${bucketName}${bucketIndex || ''}`)}
            className="title"
          >
            {bucketName} {bucketIndex ? <span>({bucketIndex})</span> : ''}
          </h3>
        )}
        <div className="flex items-center max-sm:justify-end">
          <div id="scale-toggle-portal" />
          <div className="flex items-center gap-x-2 ml-2">
            {isPerformanceOrRisk && activeTabMenuItem && (
              <WatchlistHeaderAsOfDate
                type={activeTabMenuItem}
                className="hidden md:block"
              />
            )}
            <div
              id="dropdown-portal"
              data-test-id="WatchlistRiskTabDatePicker"
              className={style['year-dropdown']}
            />
          </div>
        </div>
      </div>
      <div className="block pl-4 md:hidden">
        {isPerformanceOrRisk && activeTabMenuItem && (
          <WatchlistHeaderAsOfDate type={activeTabMenuItem} className="block" />
        )}
      </div>
    </div>
  );
};
