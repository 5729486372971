import { DATE_PERIOD_FILTER, EDateFilterValues } from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import { useMemo, useState } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import ChartSettings from '@/components/ChartSettings/ChartSettings';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { allowedPeriodValuesForRollingReturns } from './RollingReturnsChart';

const RollingReturnsChartTitle = () => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedYearDrawerVisible, setIsNestedYearDrawerVisible] =
    useState(false);
  const [isChartSettingsVisible, setIsChartSettingsVisible] = useState(false);

  const currentPeriod = useMemo(() => {
    if (allowedPeriodValuesForRollingReturns.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['3YR'];
  }, [datePickerValue]);
  const activePeriod = DATE_PERIOD_FILTER.find(p => p.value === currentPeriod);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValuesForRollingReturns.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: (
      <span>
        <span
          className={cx(
            'flex-1',
            activePeriod?.value === period.value && 'active font-normal',
          )}
        >
          {period.label}
        </span>
        {activePeriod?.value === period.value && !isMobile && (
          <CheckActive className="icon text-sm" />
        )}
      </span>
    ),
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedYearDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  return (
    <div
      className={cx(
        'grid grid-cols-[1fr_auto_auto] gap-y-2 items-center my-2',
        'sm:grid-cols-[1fr_auto_auto] sm:justify-between',
        '[&_.dropdown]:pt-4 [&_.dropdown]:w-full md:[&_.dropdown]:w-auto md:[&_.dropdown]:pt-0',
      )}
    >
      <h5
        data-test-id="rollingReturnsChartLabel"
        className="text-sm w-full font-bold text-darkest col-span-3 sm:col-span-1"
      >
        <Loader width="150px" component={<>Rolling Returns</>} />
      </h5>
      <div className="flex items-center gap-x-2">
        <div
          className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto"
          data-test-id="rollingReturnsChartDropdown"
        >
          <Dropdown
            open={isChartSettingsVisible}
            trigger={['click']}
            placement="bottom"
            menu={{
              items: isMobile ? [] : allowedPeriodsForDropdown,
            }}
            onOpenChange={setIsChartSettingsVisible}
          >
            <Button
              type="default"
              className={`flex w-full h-[32px] items-center justify-between group rounded border
                hover:text-primary hover:border-primary ${
                  isChartSettingsVisible
                    ? 'text-primary border-primary'
                    : 'text-neutral-700 border-neutral-300'
                }`}
              onClick={() => setIsNestedYearDrawerVisible(true)}
            >
              <p className="truncate text-sm mb-0 grow">
                {activePeriod?.label}
              </p>
              <NextArrow
                className={`w-2.5 h-2.5 ml-1 mt-0.5 text-xs transform rotate-90 group-hover:fill-[#007fff]
                  ${isChartSettingsVisible ? 'text-primary fill-[#007fff]' : 'fill-[#516285]'}
                `}
              />
            </Button>
          </Dropdown>
          {isMobile && (
            <NestedDrawer
              menuItems={allowedPeriodsForDropdown}
              visible={isNestedYearDrawerVisible}
              onClose={() => setIsNestedYearDrawerVisible(false)}
              title="Select"
            />
          )}
        </div>
      </div>
      <div className="ml-2">
        <ChartSettings hidePanelKeys={['exclude-recent-launches']} />
      </div>
    </div>
  );
};

export default RollingReturnsChartTitle;
