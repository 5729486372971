import { INTERNAL } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import useUserAccess from '@/hooks/useUserAccess';
import queryClient from '@/queryClient';
import { Comment } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Radio, Skeleton, Space } from 'antd';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as MobileFiltersIcon } from '@/assets/svg/icons/icon-filter-modal.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import useScreenWidth from '@/hooks/screenWidth';
import { screenBreakpoints } from '@/hooks/screenWidth';
import NestedDrawer from '@/partials/NestedDrawer';
import CommentaryEmptyFilter from '../CommentaryEmptyFilter';
import CommentaryFormModal from '../CommentaryFormModal';
import CommentaryListItem from '../CommentaryListItem';
import CommentarySearchFilter from '../CommentarySearchFilter';
import EmptyCommentaryState from '../EmptyCommentaryState';

interface CommentaryProps {
  comments: Comment[];
  isLoading?: boolean;
}

const highlightCommentKey = 'highlightRecentCommentary';

const filterOptions = [
  { label: 'Pinned', value: 'pinned' },
  { label: 'All', value: 'all' },
  { label: 'Mine', value: 'mine' },
];

const Commentary: FC<CommentaryProps> = ({ comments, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [filteredComments, setFilteredComments] = useState<Comment[]>(comments);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [highlightRecent, setHighlightRecent] = useState(false);
  const [selectedComment, setSelectedComment] = useState<Comment | undefined>();
  const [searchInputValue, setSearchInputValue] = useState('');
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const { hasPermissions } = useUserAccess();
  const authContext = useContext(AxiosAuthContext);
  const currentUserId = authContext.state.decodedToken.sub;
  const { currentWidth } = useScreenWidth();
  const isMobile = currentWidth < screenBreakpoints.md;
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (searchParams.has(highlightCommentKey)) {
      searchParams.delete(highlightCommentKey);
      history.replace({
        search: searchParams.toString(),
      });
      setHighlightRecent(true);
    }
  }, [searchParams]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (highlightRecent) {
      queryClient.invalidateQueries([INTERNAL]);
      timeout = setTimeout(() => {
        setHighlightRecent(false);
      }, 1000 * 10);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [highlightRecent]);

  const hasCommentsWritePermissions = hasPermissions([
    USER_PERMISSIONS.comments.write,
  ]);

  const hasComments = comments && comments.length > 0;

  const filterMap = useMemo(
    () => ({
      pinned: (comment: Comment) => comment.pinnedAt,
      mine: (comment: Comment) => comment.createdBy === currentUserId,
      all: () => true,
    }),
    [currentUserId],
  );

  const searchDescription = (comment: Comment, term: string) => {
    const searchLower = term.toLowerCase();
    return comment.description?.toLowerCase().includes(searchLower);
  };
  //biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const applyFilters = useCallback(
    (comments: Comment[], filter: string, term: string) => {
      const filterFn = filterMap[filter as keyof typeof filterMap];
      return comments
        .filter(filterFn)
        .filter(comment => term === '' || searchDescription(comment, term));
    },
    [filterMap],
  );

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    setFilteredComments(applyFilters(comments, value, searchTerm));
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
    setFilteredComments(applyFilters(comments, selectedFilter, value));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const searchFilter = (
    <CommentarySearchFilter
      searchInputValue={searchInputValue}
      onSearchChange={onSearchChange}
      onSearch={onSearch}
      setSearchInputValue={setSearchInputValue}
    />
  );

  useEffect(() => {
    setFilteredComments(applyFilters(comments, selectedFilter, searchTerm));
  }, [comments, applyFilters, selectedFilter, searchTerm]);

  return (
    <div className="flex flex-col bg-white p-4 min-h-120 lg:rounded-lg">
      <div className="flex justify-between items-center gap-4">
        <p className="text-sm text-neutral-200 font-bold shrink-0">
          Commentary
        </p>
        <div className="flex flex-1 justify-end items-center gap-4">
          {!isMobile && searchFilter}
          {hasComments && hasCommentsWritePermissions && (
            <Button
              onClick={() => {
                setIsFormModalOpen(prev => !prev);
              }}
              className="h-10 text-sm font-medium shrink-0"
              type="primary"
            >
              + Add commentary
            </Button>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="flex gap-2 pt-4 pb-2">
          {searchFilter}
          <Button
            size="large"
            type="default"
            onClick={() => {
              setIsNestedDrawerVisible(prev => !prev);
            }}
            data-test-id={'mobile-filters-button'}
            className="w-10 h-10"
          >
            <div className="flex items-center">
              <MobileFiltersIcon
                height={20}
                width={20}
                className="text-neutral-100"
              />
            </div>
          </Button>
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col gap-8">
          {[1, 2].map(item => (
            <Skeleton key={item} paragraph={{ rows: 3 }} active />
          ))}
        </div>
      )}
      {!isLoading && hasComments && (
        <>
          {!isMobile && (
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              onChange={e => handleFilterChange(e.target.value)}
              value={selectedFilter}
              className={`pt-2 [&_.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)_span]:text-neutral-700
              [&_.ant-radio-button-wrapper]:border-light [&_.ant-radio-button-wrapper]:rounded
              [&_.ant-radio-button-wrapper.ant-radio-button-wrapper-checked]:border-primary
              [&_.ant-radio-button-wrapper.ant-radio-button-wrapper-checked]:bg-primary
            `}
            >
              <Space direction="horizontal">
                {filterOptions.map(option => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
          <div className="flex flex-col gap-4 pt-4">
            {filteredComments.length > 0 ? (
              filteredComments.map((comment, index) => (
                <CommentaryListItem
                  key={comment._id}
                  comment={comment}
                  highlight={highlightRecent && index === 0}
                  searchTerm={searchTerm}
                  onEditComment={() => {
                    setSelectedComment(comment);
                    setIsFormModalOpen(prev => !prev);
                  }}
                />
              ))
            ) : (
              <CommentaryEmptyFilter selectedFilter={selectedFilter} />
            )}
          </div>
        </>
      )}
      {!isLoading && !hasComments && (
        <EmptyCommentaryState
          onAddCommentary={() => {
            setIsFormModalOpen(prev => !prev);
          }}
          showWriteAction={hasCommentsWritePermissions}
        />
      )}
      <CommentaryFormModal
        isVisible={isFormModalOpen}
        initialValue={selectedComment}
        toggleModal={() => {
          setSelectedComment(undefined);
          setIsFormModalOpen(prev => !prev);
        }}
      />
      {isMobile && (
        <NestedDrawer
          menuItems={filterOptions.map(option => ({
            ...option,
            key: option.value,
            onClick: () => handleFilterChange(option.value),
            active: selectedFilter === option.value,
            selected: selectedFilter === option.value,
          }))}
          visible={isNestedDrawerVisible}
          onClose={() => {
            setIsNestedDrawerVisible(false);
          }}
          title="Filter"
        />
      )}
    </div>
  );
};

export default Commentary;
