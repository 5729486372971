import { NotificationItem } from '@aminsights/contract';
import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Button, Popover } from 'antd';
import cx from 'classnames';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as IconNotifBell } from '@/assets/svg/icons/icon-notif-bell.svg';
import NotificationDetailModal from '@/components/NotificationDetailModal';
import NotificationPane from '@/components/NotificationPane';
import useGetNotifications from '@/hooks/query-hooks/notification-hooks/useGetNotification';
import useMarkAllNotificationsAsRead from '@/hooks/query-hooks/notification-hooks/useMarkAllNotificationsAsRead';
import useMarkNotificationsAsSeen from '@/hooks/query-hooks/notification-hooks/useMarkNotificationsAsSeen';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import FullPageModal from '@/partials/Modal/FullPageModal';

import style from './style.module.less';

const NotificationsWrapper: React.FC<PropsWithChildren> = () => {
  const markAllNotificationsAsRead = useMarkAllNotificationsAsRead();
  const markNotificationsAsSeen = useMarkNotificationsAsSeen();

  const {
    data: notifications,
    fetchNextPage,
    isLoading,
  } = useGetNotifications();

  const [open, setOpen] = useState(false);
  const { currentWidth } = useScreenWidth();
  const isMobile = currentWidth < screenBreakpoints.md;

  const isMobileModalOpen = isMobile && open;
  const isDesktopPopoverOpen = !isMobile && open;

  const [currentNotificationId, setCurrentNotificationId] = useState<
    string | undefined
  >();
  const [totalUnseen, setTotalUnseen] = useState<number>(0);
  const [totalUnread, setTotalUnread] = useState<number>(0);

  useEffect(() => {
    const totalUnseenCount =
      notifications?.pages?.[0]?.[0]?.totalUnseenCount ?? 0;
    const totalUnreadCount =
      notifications?.pages?.[0]?.[0]?.totalUnreadCount ?? 0;

    if (totalUnseen !== totalUnseenCount) {
      setTotalUnseen(totalUnseenCount);
    }
    if (totalUnread !== totalUnreadCount) {
      setTotalUnread(totalUnreadCount);
    }
  }, [notifications, totalUnseen, totalUnread]);

  const observer = useRef<IntersectionObserver>();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const lastBookElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading],
  );

  const onToggle = (visible: boolean) => {
    const unseenNotifications = notifications?.pages
      .flat()
      .filter(n => !n.isSeen);
    if (visible && unseenNotifications && unseenNotifications?.length > 0) {
      markNotificationsAsSeen.mutate(unseenNotifications.map(un => un.id));
    }
    setOpen(visible);
  };

  useEffect(() => {
    const allNotifications = [...(notifications?.pages.flat() || [])];
    notifications?.pages.flat().forEach(notificationInState => {
      let existingNotification = allNotifications.find(
        a => a.id === notificationInState.id,
      );
      if (existingNotification) {
        existingNotification = notificationInState;
      } else {
        allNotifications.push(notificationInState);
      }
    });
  }, [notifications?.pages]);

  const close = () => {
    onToggle(false);
  };

  const openDetail = (notificationId: string) => {
    setCurrentNotificationId(notificationId);
    close();
  };

  const currentNotification = notifications?.pages
    .flat()
    .find(n => n.id === currentNotificationId);

  const resolveNotificationPane = (
    isLastElement: boolean,
    notificationItem: NotificationItem,
    key: number,
  ) => {
    let result;
    const notificationProps = {
      key: notificationItem.id,
      title: notificationItem.title,
      subtitle: notificationItem.subtitle ?? '',
      date: notificationItem.dateCreated,
      navigationLink: notificationItem.navigationLink,
      isRead: notificationItem.isRead,
      id: notificationItem.id,
      onClick: close,
      onClickDetail: openDetail,
      type: notificationItem.type,
    };
    if (!isLastElement) {
      result = <NotificationPane {...notificationProps} />;
    } else {
      result = (
        <div key={key} ref={lastBookElementRef}>
          <NotificationPane {...notificationProps} />
          {isLoading && (
            <LoadingOutlined
              style={{ fontSize: 80, color: '#f0f2f5', width: '98%' }}
              spin
            />
          )}
        </div>
      );
    }

    return result;
  };

  const contentTitle = (
    <div
      className={cx(
        'flex justify-between md:px-4 items-center',
        !isMobileModalOpen && 'mb-2',
      )}
    >
      <h3
        className={cx(
          'font-bold text-darkest',
          isMobileModalOpen ? 'text-xl' : 'text-base',
        )}
      >
        Notifications
      </h3>
      <Button
        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
        onClick={() => {
          markAllNotificationsAsRead.mutate();
          onToggle(false);
        }}
        type="default"
        htmlType="button"
        className={cx(
          'text-primary cursor-pointer hover:text-info',
          isMobileModalOpen ? 'text-sm font-medium' : 'font-semibold text-xs',
        )}
      >
        Mark all as read ({totalUnread})
      </Button>
    </div>
  );

  const content = (
    <div className="h-full md:max-h-[60vh] overflow-auto gap-y-2 md:gap-y-1 flex flex-col">
      {notifications?.pages.flat().map((data, i) => {
        const isLastElement: boolean =
          notifications.pages.flat().length === i + 1;
        return resolveNotificationPane(isLastElement, data, i);
      })}
    </div>
  );

  return (
    <>
      <Popover
        open={isDesktopPopoverOpen}
        content={
          <div
            className="bg-white w-full max-md:mt-10 max-md:h-full"
            data-test-id="navNotificationWindow"
          >
            {contentTitle}
            {content}
          </div>
        }
        trigger="click"
        placement="bottomRight"
        getPopupContainer={trigger => {
          if (trigger.parentElement) return trigger.parentElement;
          return trigger;
        }}
        onOpenChange={onToggle}
        overlayClassName={style['popover-notifications']}
      >
        <button
          className={cx(style['notif-bell'], style['icon-notif-bell-enabled'], {
            [style['icon-notif-bell-open']]: open,
          })}
          type="button"
          tabIndex={0}
          data-test-id="navNotificationBell"
        >
          <IconNotifBell className={cx('icon', style['icon-notif-bell'])} />
          <div className={style['badge-position']}>
            <Badge
              count={totalUnseen}
              overflowCount={99}
              data-test-id="navUnseenNotificationsNumber"
              classNames={{
                indicator:
                  'px-[5px] border-white border font-medium [&>*]:relative [&>*]:-top-[0.5px]',
              }}
            />
          </div>
        </button>
      </Popover>
      <FullPageModal
        isOpen={isMobileModalOpen}
        onClose={() => setOpen(false)}
        showFooter={false}
        title={contentTitle}
        dataTestId="navNotificationWindow"
      >
        {content}
      </FullPageModal>
      <NotificationDetailModal
        isVisible={!!currentNotification}
        notification={currentNotification}
        toggleModal={() => {
          setCurrentNotificationId(undefined);
        }}
      />
    </>
  );
};

export default NotificationsWrapper;
