import {
  BenchmarkOption,
  BenchmarkGroup as BenchmarkOptionGroupEnum,
} from '@aminsights/contract';
import { Button, Divider, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as Close } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Checkbox as MultiCheckBox } from '@/components/Checkbox';
import { DropdownItem } from '@/components/Dropdown/shared';
import { Search } from '@/pages/app/Explore/components/Search';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import NestedDrawer from '../NestedDrawer';
import style from './style.module.less';

export interface BenchmarksDropdownRef {
  close?: () => void;
}

interface BenchmarksDropdownProps {
  placeholder: string;
  value: string;
  benchmarkOptions: Array<BenchmarkOption>;
  className?: string;
  dropdownKey?: string;
  dataTestId?: string;
  excludedValues?: Array<string>;
  disabled?: boolean;
  hideCustomOptions?: boolean;
  onSelect: (
    value: string,
    group: BenchmarkOptionGroupEnum | undefined,
  ) => void;
  onClear?: () => void;
  isOptionsLoading?: boolean;
}

const BenchmarksDropdown = forwardRef<
  BenchmarksDropdownRef,
  BenchmarksDropdownProps
>(
  (
    {
      placeholder,
      value,
      benchmarkOptions,
      className,
      dropdownKey,
      dataTestId,
      excludedValues,
      disabled,
      hideCustomOptions,
      onSelect,
      onClear,
      isOptionsLoading,
    },
    ref,
  ) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [activeOption, setActiveOption] = useState<
      DropdownItem | undefined
    >();
    const [searchText, setSearchText] = useState<string>('');
    const [selectedGroups, setSelectedGroups] = useState<
      Array<BenchmarkOptionGroupEnum>
    >([]);
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
    const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

    useImperativeHandle(ref, () => ({
      close: () => setIsMenuVisible(false),
    }));

    const optionsFilteredByGroup = selectedGroups.length
      ? benchmarkOptions.filter(item => selectedGroups.includes(item.group))
      : benchmarkOptions;

    const lowerCaseSearchText = searchText.toLowerCase();

    const options = optionsFilteredByGroup
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }))
      // Filter benchmark options by search text
      .filter(({ label }) => label.toLowerCase().includes(lowerCaseSearchText))
      // Filter benchmark options by excluded values
      .filter(item => !excludedValues?.includes(item.value));

    const groupOptions = [
      { label: 'Equities', value: BenchmarkOptionGroupEnum.Equity },
      { label: 'Cash', value: BenchmarkOptionGroupEnum.Cash },
      { label: 'Bonds', value: BenchmarkOptionGroupEnum.Bonds },
      ...(!hideCustomOptions
        ? [{ label: 'Custom', value: BenchmarkOptionGroupEnum.Custom }]
        : []),
    ];
    // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
    useEffect(() => {
      if (isOptionsLoading) return;

      const newActiveOption = options.find(
        ({ value: itemValue }) => itemValue === value,
      );
      setActiveOption(newActiveOption);
    }, [value, isOptionsLoading]);

    const screenWidthMode = getScreenWidthMode();
    const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
    // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
    const menuItems = useMemo(
      () =>
        options
          .filter(i => !excludedValues?.some(val => val === i.value))
          .map(item => ({
            label: (
              <span>
                <span
                  className={
                    activeOption?.value === item.value
                      ? 'flex-1 active'
                      : 'flex-1'
                  }
                  data-test-id={`${dataTestId}Value${item.value}`}
                >
                  {item.label}
                </span>
                {activeOption?.value === item.value && (
                  <CheckActive className="icon text-sm" />
                )}
              </span>
            ),
            key: `${item.value.toString()}-${item.label}`,
            onClick: () => {
              setActiveOption(item);
              const group = benchmarkOptions.find(
                bo => bo.id === item.value,
              )?.group;
              onSelect(item.value, group);
              setIsMenuVisible(false);
            },
          })),
      [options, excludedValues],
    );

    const benchmarksDropdown = (
      <>
        <div className="flex flex-col gap-2 px-2 pt-2">
          <Search
            autoFocus={isMenuVisible}
            onChange={val => setSearchText(val || '')}
            key={dropdownKey}
            visible={isMenuVisible}
            setSearchText={setSearchText}
            input={searchText}
          />
          <MultiCheckBox
            data={groupOptions}
            value={selectedGroups}
            onChange={setSelectedGroups}
            direction="row"
          />
          <Divider className="m-0" />
        </div>
        <Menu
          className={style['benchmarks-dropdown-menu']}
          style={{ height: isMobile ? '95%' : 250 }}
          items={menuItems}
        />
      </>
    );

    return (
      <>
        <div className={style['benchmarks-dropdown']}>
          <Dropdown
            destroyPopupOnHide
            className={cx(className, 'p-1')}
            overlayClassName="w-[370px]"
            open={isMenuVisible}
            overlayStyle={{ maxWidth: '358px' }}
            dropdownRender={() => {
              return !isMobile ? (
                <div className={'pt-2 bg-white rounded shadow-modal'}>
                  {benchmarksDropdown}
                </div>
              ) : (
                <></>
              );
            }}
            trigger={['click']}
            placement="bottomLeft"
            disabled={disabled}
            autoAdjustOverflow={false}
          >
            <Button
              className={cx(style['dropdown-button'], 'group', {
                [style['dropdown-button-open']]: isMenuVisible,
              })}
              onClick={() => {
                if (isRestrictedAccess) {
                  toggleUpgradeModal(RESTRICTED_FEATURE.BENCHMARKS);
                } else {
                  if (isMobile) setMobileDrawerVisible(true);
                  else setIsMenuVisible(true);
                }
              }}
              data-test-id={dataTestId}
              loading={isOptionsLoading}
            >
              <p
                className={cx(
                  'truncate text-sm mb-0 flex-grow',
                  !activeOption?.label
                    ? '!text-neutral-100'
                    : '!text-neutral-700',
                )}
                data-test-id={`${dataTestId}SelectedLabel`}
              >
                {activeOption?.label || placeholder}
              </p>
              {onClear && Boolean(value) && (
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setActiveOption(undefined);
                    onClear();
                  }}
                  className={cx(style['close-button'])}
                >
                  <Close
                    className={cx(style['close-icon'], 'cursor-pointer')}
                  />
                </div>
              )}
              <div>
                <NextArrow
                  className={cx('icon', style['dropdown-icon-caret'], '!w-2.5')}
                />
              </div>
              {isRestrictedAccess && (
                <LockIcon
                  className={cx(
                    'ml-2 fill-neutral-100 group-hover:fill-primary',
                    'transition-all duration-300 ease-in-out',
                  )}
                />
              )}
            </Button>
          </Dropdown>
        </div>
        {isMobile && (
          <NestedDrawer
            menuItems={menuItems}
            subComponent={
              <div className="flex flex-col gap-2 px-4 pt-2">
                <Search
                  autoFocus={isMenuVisible}
                  onChange={val => setSearchText(val || '')}
                  key={dropdownKey}
                  visible={isMenuVisible}
                  setSearchText={setSearchText}
                  input={searchText}
                />
                <MultiCheckBox
                  data={groupOptions}
                  value={selectedGroups}
                  onChange={setSelectedGroups}
                  direction="row"
                />
              </div>
            }
            visible={mobileDrawerVisible}
            onClose={() => setMobileDrawerVisible(false)}
            title={'Select Index'}
            key={benchmarkOptions.length}
          />
        )}
        {isMenuVisible && (
          <div
            className={style['dropdown-overlay']}
            onClick={() => {
              setIsMenuVisible(false);
              setSelectedGroups([]);
              setSearchText('');
            }}
            onKeyUp={() => {
              setIsMenuVisible(false);
              setSelectedGroups([]);
              setSearchText('');
            }}
            role="button"
            tabIndex={0}
          />
        )}
      </>
    );
  },
);

export default BenchmarksDropdown;
