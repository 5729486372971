import { BucketsFolder } from '@aminsights/contract';
import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { useHistory } from 'react-router-dom';
import FolderCard from './FolderCard';
import { FoldersTabEmptyState } from './FoldersTabEmptyState';

// TODO: Replace with actual API call when backend is ready
const mockFolders: BucketsFolder[] = [
  {
    id: '1',
    name: 'High Risk Funds',
    buckets: [{ id: 'b1' }, { id: 'b2' }],
    watchlistId: '1',
    createdBy: '1',
  },
  {
    id: '2',
    name: 'Income Funds',
    buckets: [{ id: 'b3' }],
    watchlistId: '1',
    createdBy: '1',
  },
  {
    id: '3',
    name: 'ESG Investments',
    buckets: [{ id: 'b4' }, { id: 'b5' }, { id: 'b6' }],
    watchlistId: '1',
    createdBy: '1',
  },
];

const Folders = () => {
  // TODO: Replace with actual API call
  const folders = mockFolders;
  const history = useHistory();

  return (
    <div className="min-h-[70vh]">
      <p className="py-3 text-sm text-neutral-700">
        Select a folder to view its buckets or rearrange folders and buckets by
        using order watchlist.
      </p>
      {folders.length === 0 && <FoldersTabEmptyState />}
      <div className="flex flex-wrap gap-4 w-full">
        {folders.map(folder => (
          <div
            key={folder.id}
            className="min-w-[268px]"
            onClick={() => {
              history.push(`/${APP_ROUTE_WATCHLIST}/folder/${folder.id}`);
            }}
          >
            <FolderCard folder={folder} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Folders;
