import {
  AssetAllocationItem,
  AssetAllocationItemTypeEnum,
  CurrencyExposure,
  EquityStatsDetail,
  Fund,
  FundBaseShareClassDetails,
  FundBroadAssetClass,
  FundExploreItem,
  GeographyItem,
  MarketCapBase,
  Performance,
  PortfolioFund,
  SectorItem,
  SectorSource,
  SignificantChangesItem,
  WatchlistCurrencyResponseData,
} from '@aminsights/contract';

import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  DEFAULT_CURRENCY_CODE,
  EMPTY_DATA_POINT,
  EquityStatsLabel,
  FundType,
  INVESTMENT_TRUST_REG_EX,
  LEGAL_STRUCTURE,
  MonthCode,
  RipsReturnType,
  TotalReturnPeriod,
  roundOrEmpty,
  subtractNullable,
} from '..';
export const isEquityBroadAssetClass = (broadAssetClass: string) => {
  return broadAssetClass === FundBroadAssetClass.Equity;
};
export const isAllocationBroadAssetClass = (broadAssetClass: string) => {
  return broadAssetClass === FundBroadAssetClass.Allocation;
};
export const isFixedIncomeBroadAssetClass = (broadAssetClass: string) => {
  return broadAssetClass === FundBroadAssetClass.FixedIncome;
};
export const isFund = (
  item?:
    | Fund
    | Performance
    | FundExploreItem
    | PortfolioFund
    | SignificantChangesItem
    | FundBaseShareClassDetails
    | WatchlistCurrencyResponseData,
): item is Fund => (item && 'shareClassDetails' in item) || false;

export const isFundGeographyCountry = (
  item: GeographyItem | SectorItem | MarketCapBase | AssetAllocationItem,
): item is GeographyItem => 'id' in item && 'value' in item;

export type SectorWithExtremums = ICalculatedExtremumsFunds<number>;
export type MarketCapWithExtremums = ICalculatedExtremumsFunds<string>;
export type CountryWithExtremums = ICalculatedExtremumsFunds<string>;
export type CreditQualityWithExtremum = ICalculatedExtremumsFunds<string>;
export type EquityStatsWithExtremum = ICalculatedExtremumsFunds<string>;

export type CountryToShow = { countryId: string; label: string };

export type CurrencyToShow = { currencyId: string };
/**
 * Identify top items in the beggining of the array
 * @param {number} itemsToTake stands for N items to take
 * @param {({isin: string;} & ItemsSectorDetail)[]} allValuesForSpecificType all values of {type: T} of a specific field sorted by value, we start taking values from the 0 index.
 * @param {string[]} maximumExtremums extremums from the other end of the sorted allValuesForSpecificType, to prevent having the same isins in both,
 * if maximumExtremums.length > 0 then we clearly try to fill identifyTopMinimumExtremums; The first run of identifyTopNExtremums - for max values, the second - for min
 * @returns {string[]} isins of extremum Values
 */
function identifyTopNExtremums(
  itemsToTake: number,
  allValuesForSpecificType: {
    isin: string;
    value: number;
  }[],
  maximumExtremums: string[],
) {
  const result: string[] = [];
  for (let i = 0; i < itemsToTake; i++) {
    for (const valueForSpecificType of allValuesForSpecificType) {
      if (
        result.length >= itemsToTake ||
        maximumExtremums.some(m => m === valueForSpecificType.isin)
      ) {
        break;
      }
      //the first maximumExtremums contains either 0 or only max elements => we cannot push 0 values into empty maximumExtremums;
      if (maximumExtremums.length === 0 && valueForSpecificType.value === 0) {
        continue;
      }
      result.push(valueForSpecificType.isin);
      const duplicateValues = allValuesForSpecificType.filter(
        v =>
          v.isin !== valueForSpecificType.isin &&
          v.value === valueForSpecificType.value,
      );
      if (duplicateValues.length !== 0) {
        result.push(...duplicateValues.map(d => d.isin));
      }
    }
  }
  return result;
}

function isBroadAssetClassIncluded(
  broadAssetClass: string,
  broadAssetClassesFilter: FundBroadAssetClass[],
) {
  return broadAssetClassesFilter.some(b => b === broadAssetClass);
}

export type ICalculatedExtremumsFunds<T> = {
  isinsOfMaximum: string[];
  isinsOfMinimum: string[];
  type: T;
};

type SpecificFundFieldForExtremum =
  | 'sectorsEquity'
  | 'sectorsFixedIncome'
  | 'marketCapsLong'
  | 'calendarYearPerformancesPcl'
  | 'annualisedPerformancesPcl'
  | 'countriesFixedIncome'
  | 'countriesEquity'
  | 'bondCreditQualityBreakdown'
  | 'assetAllocation'
  | 'bondMaturityRange';

type Props<T> = {
  funds: Fund[];
  filters: T[];
  specificFundField: SpecificFundFieldForExtremum;
  broadAssetClassesToFilterBy: FundBroadAssetClass[];
};

const getValueOfFieldWithPresentValues = <T>(
  fund: Fund,
  specificFundField: SpecificFundFieldForExtremum,
  filters: T[],
) => {
  const valuesFromSpecificField = fund[specificFundField];

  if (!Array.isArray(valuesFromSpecificField)) {
    return [];
  }
  return valuesFromSpecificField
    ?.map(v => ({
      ...v,
      type: (isFundGeographyCountry(v) ? v.id : (v.type ?? '')) as unknown as T,
    }))
    .filter(mcl => filters.some(mcf => mcf === mcl.type));
};

export function calculateExtremumsForFundByBroadAssetClasses<T>({
  funds,
  filters,
  specificFundField,
  broadAssetClassesToFilterBy,
}: Props<T>): ICalculatedExtremumsFunds<T>[] | undefined {
  //We must have at least 1 equity fund in the bucket to calculate colors
  if (
    !funds.some(f =>
      isBroadAssetClassIncluded(f.broadAssetClass, broadAssetClassesToFilterBy),
    )
  ) {
    return;
  }

  const allEquityIsinsFiltered = funds
    .filter(
      f =>
        isBroadAssetClassIncluded(
          f.broadAssetClass,
          broadAssetClassesToFilterBy,
        ) &&
        (getValueOfFieldWithPresentValues(f, specificFundField, filters)
          ?.length ?? 0) !== 0,
    )
    .map(f => f._id);
  if (allEquityIsinsFiltered.length === 0) {
    return;
  }
  const specificFundFieldForIsins = funds
    //for calculations ignore funds that has no value for all specific field values of its specific field
    //or just no specific field and not an equity fund
    .filter(
      f =>
        isFund(f) &&
        isBroadAssetClassIncluded(
          f.broadAssetClass,
          broadAssetClassesToFilterBy,
        ) &&
        getValueOfFieldWithPresentValues(f, specificFundField, filters)?.every(
          mcl =>
            mcl.value !== undefined && mcl.value !== null && !isNaN(mcl.value),
        ),
    )
    .map(f => ({
      isin: (f as Fund)._id,
      valuesForSpecificField: getValueOfFieldWithPresentValues(
        f,
        specificFundField,
        filters,
      )
        ?.filter(s => s.value !== undefined) // Ensure s.value is defined
        .map(s => ({
          ...s,
          value: s.value! < 0.1 ? Math.fround(s.value!) : +s.value!.toFixed(1),
        })),
    }));
  const specificFundFieldOfAllFunds = specificFundFieldForIsins.flatMap(mI =>
    mI.valuesForSpecificField.map(mc => ({ ...mc, isin: mI.isin })),
  );
  const uniqueSpecificFundFieldOfAllFundsTypes = new Set(
    specificFundFieldOfAllFunds.map(f => f.type),
  );
  const result: ICalculatedExtremumsFunds<T>[] = [];
  for (const uniqueType of uniqueSpecificFundFieldOfAllFundsTypes) {
    const allValuesForSpecificFundFieldType = specificFundFieldOfAllFunds
      .filter(mc => mc.type === uniqueType)
      .sort((a, b) => {
        return b.value - a.value;
      });
    const isinsWithoutThisSpecificFundField = allEquityIsinsFiltered.filter(
      a => !allValuesForSpecificFundFieldType.some(s => s.isin === a),
    );
    const firstValueForSpecificFundField = allValuesForSpecificFundFieldType[0];
    if (!firstValueForSpecificFundField) {
      continue;
    }
    // Append 0 values at the end, for funds that must be included in calculations.
    // Check https://changing-digital.atlassian.net/browse/AMIP-1405
    isinsWithoutThisSpecificFundField.forEach(i => {
      allValuesForSpecificFundFieldType.push({
        ...firstValueForSpecificFundField,
        isin: i,
        value: 0,
      });
    });
    if (allValuesForSpecificFundFieldType.length < 2) {
      continue;
    }
    let itemsToTake = 1;

    const uniqueValuesForSpecificFundField =
      allValuesForSpecificFundFieldType.map(mc => mc.value);
    if (uniqueValuesForSpecificFundField.length > 6) {
      itemsToTake = 2;
    }

    const specificFundFieldForIsins = allValuesForSpecificFundFieldType.sort(
      (a, b) => {
        return b.value - a.value;
      },
    );

    const isinsOfMaximum = identifyTopNExtremums(
      itemsToTake,
      specificFundFieldForIsins,
      [],
    );

    const isinsOfMinimum = identifyTopNExtremums(
      itemsToTake,
      specificFundFieldForIsins.reverse(),
      isinsOfMaximum,
    );
    if (isinsOfMinimum.length === 0) {
      isinsOfMaximum.length = 0;
    }
    result.push({
      type: uniqueType,
      isinsOfMaximum,
      isinsOfMinimum,
    });
  }
  return result;
}

export function calculateTopNCountries(
  countries: GeographyItem[],
  sliceAmount: number,
): CountryToShow[] {
  const countriesByValueDesc = countries
    .sort((a, b) => b.value - a.value || a.name.localeCompare(b.name))
    .map(c => ({ countryId: c.id, label: c.name }));

  const countriesSet = new Map<string, CountryToShow>();
  for (const c of countriesByValueDesc) {
    if (countriesSet.size === sliceAmount) {
      break;
    }
    countriesSet.set(c.countryId, c);
  }
  const result = [...countriesSet.entries()].map(e => e[1]);
  return result;
}

export function calculateTopNCurrencies(
  currencies: CurrencyExposure[],
  sliceAmount: number,
): CurrencyToShow[] {
  const currenciesByValueDesc = currencies
    .sort(
      (a, b) => b.value - a.value || a.currencyId.localeCompare(b.currencyId),
    )
    .map(c => ({ currencyId: c.currencyId }));

  const currenciesSet = new Map<string, CurrencyToShow>();
  for (const c of currenciesByValueDesc) {
    if (currenciesSet.size === sliceAmount) {
      break;
    }
    currenciesSet.set(c.currencyId, c);
  }
  const result = [...currenciesSet.entries()].map(e => e[1]);
  return result;
}

/**
 * Returns FundType based on the legal structure:
 * - MPS if the legal structure is "MPS"
 * - INVESTMENT_TRUST if the legal structure contains the word "closed"
 * - FUND otherwise
 * @param legalStructure
 * @returns {FundType}
 */
export const getFundType = (legalStructure: string): FundType => {
  if (legalStructure === LEGAL_STRUCTURE.MPS) {
    return FundType.MPS;
  }
  if (legalStructure.match(INVESTMENT_TRUST_REG_EX)) {
    return FundType.INVESTMENT_TRUST;
  }
  return FundType.FUND;
};
/**
 * Returns closePrice value ready for UI
 * @param {Fund | undefined} fund
 * @returns {(string)}
 */
export const closePrice = (fund?: Fund) => {
  if (fund?.closePrice) {
    if (fund.closePrice.currencyId !== DEFAULT_CURRENCY_CODE) {
      return fund.closePrice.value.toString();
    } else {
      const calculatedValue = fund.closePrice.value * 100;
      const formattedValue =
        calculatedValue > 999
          ? calculatedValue.toFixed(0)
          : calculatedValue.toFixed(1);
      return `${formattedValue}p`;
    }
  }
  return EMPTY_DATA_POINT;
};

/**
 * Returns trust or fund based on isInvestmentTrust boolean
 * @param {bool} isInvestmentTrust
 * @returns {(string)}
 */
export const getFundOrInvestmentTrustTitle = (isInvestmentTrust: boolean) => {
  if (isInvestmentTrust) {
    return 'Trust';
  } else {
    return 'Fund';
  }
};

/**
 * Returns NAV. or Return based on isInvestmentTrust boolean
 * @param {bool} isInvestmentTrust
 * @returns {(string)}
 */
export const getPerformanceTableReturnColumnName = (
  isInvestmentTrust: boolean,
) => {
  if (isInvestmentTrust) {
    return 'NAV.';
  } else {
    return 'Return';
  }
};

/**
 * Uses isin to find a fund in an array of funds and returns the share class details
 * @param {(FundExploreItem | Fund | Performance)[]} data
 * @param {string} isin
 * @returns {(FundBaseShareClassDetails | undefined)}
 */
export const getFundShareClassDetailsFromArray = (
  data: (
    | FundExploreItem
    | Fund
    | Performance
    | WatchlistCurrencyResponseData
  )[],
  isin: string,
) => {
  const compareFundItem = data.find(item => {
    if (isFund(item) && item._id === isin) {
      return item;
    }
  });

  if (isFund(compareFundItem) && compareFundItem) {
    return { ...compareFundItem.shareClassDetails, isin: compareFundItem._id };
  }
};

/**
 * Used for performance tables color
 */
enum PerformanceStrength {
  UNTIL_25 = 25,
  UNTIL_50 = 50,
  UNTIL_75 = 75,
  UNTIL_100 = 100,
}

/**
 * Performance value of fund for a specific period based on value(PerformanceStrength)
 * @param {number} value
 * @returns {string} hex color value or empty string
 */
function performanceColorByValue(value: number) {
  let result = '';
  if (value <= PerformanceStrength.UNTIL_25) {
    result = COLOR_MAXIMUM_FEATURED_TABLE_CELL;
  } else if (value <= PerformanceStrength.UNTIL_50) {
    result = COLOR_MAXIMUM_TABLE_CELL;
  } else if (value <= PerformanceStrength.UNTIL_75) {
    result = COLOR_MINIMUM_TABLE_CELL;
  } else if (value <= PerformanceStrength.UNTIL_100) {
    result = COLOR_MINIMUM_FEATURED_TABLE_CELL;
  }
  return result;
}

export function calculateCalendarYearPerformanceTableBackgroundColor(
  item: Fund,
  type: TotalReturnPeriod,
) {
  let backgroundColor = '';

  const value = item?.calendarYearPerformancesPcl?.find(
    a => a.type === type,
  )?.value;
  backgroundColor = value ? performanceColorByValue(value) : '';
  return backgroundColor;
}

export function calculateAnnualisedPerformanceTableBackgroundColor(
  item: Fund,
  monthCode: MonthCode,
) {
  let backgroundColor = '';

  const value = item?.annualisedPerformancesPcl?.find(
    a => a.timePeriod === monthCode && a.type === SectorSource.Morningstar,
  )?.value;
  backgroundColor = value ? performanceColorByValue(value) : '';
  return backgroundColor;
}

export function mapAssetAllocation(fund: Fund): Fund['assetAllocation'] {
  const result: AssetAllocationItem[] = [
    {
      type: AssetAllocationItemTypeEnum.Equities,
      value: subtractNullable(fund.aaLongPctEquity, fund.aaShortPctEquity),
    },
    {
      type: AssetAllocationItemTypeEnum.FixedIncome,
      value: subtractNullable(fund.aaLongPctBond, fund.aaShortPctBond),
    },
    {
      type: AssetAllocationItemTypeEnum.Cash,
      value: subtractNullable(fund.aaLongPctCash, fund.aaShortPctCash),
    },
    {
      type: AssetAllocationItemTypeEnum.Others,
      value: subtractNullable(fund.aaLongPctOther, fund.aaShortPctOther),
    },
    {
      type: AssetAllocationItemTypeEnum.Convertibles,
      value: subtractNullable(
        fund.aaLongPctConvertible,
        fund.aaShortPctConvertible,
      ),
    },
    {
      type: AssetAllocationItemTypeEnum.Preferred,
      value: subtractNullable(
        fund.aaLongPctPreferred,
        fund.aaShortPctPreferred,
      ),
    },
  ];
  return result;
}

export function generateMarketCapData(fund: Fund) {
  return [
    {
      label: 'Giant',
      value: roundOrEmpty(fund?.marketCapLongPctGiant, 1, '%'),
    },
    {
      label: 'Large',
      value: roundOrEmpty(fund?.marketCapLongPctLarge, 1, '%'),
    },
    {
      label: 'Medium',
      value: roundOrEmpty(fund?.marketCapLongPctMedium, 1, '%'),
    },
    {
      label: 'Small',
      value: roundOrEmpty(fund?.marketCapLongPctSmall, 1, '%'),
    },
    {
      label: 'Micro',
      value: roundOrEmpty(fund?.marketCapLongPctMicro, 1, '%'),
    },
  ];
}

export function generateEquityStats(data?: EquityStatsDetail) {
  const compute = (value?: number) => {
    return roundOrEmpty(value, 1, '%');
  };

  return [
    {
      type: EquityStatsLabel.THREE_YEAR_EARNINGS_GROWTH,
      info: compute(data?.past3YearEarningsGrowth),
    },
    {
      type: EquityStatsLabel.ROE,
      info: compute(data?.roe),
    },
    {
      type: EquityStatsLabel.ROIC,
      info: compute(data?.roic),
    },
    {
      type: EquityStatsLabel.NET_MARGIN,
      info: compute(data?.netMargin),
    },
  ];
}

export function findReturnByMonthCode(
  fund: Fund,
  monthCode: MonthCode | undefined,
  returnType: RipsReturnType,
) {
  if (!monthCode) {
    return undefined;
  }

  const computedReturns =
    returnType === RipsReturnType.TotalReturn
      ? fund.computedNavAnnualisedReturns
      : fund.computedSharePriceAnnualisedReturns;

  const computedReturn = (computedReturns ?? []).find(
    computedReturn => computedReturn.timePeriod === monthCode,
  );
  return computedReturn?.percentage;
}

// Only morningstar percentile is supported for now
export function findPercentileByMonthCode(fund: Fund, monthCode: MonthCode) {
  if (!monthCode) {
    return undefined;
  }
  const percentileRank = (fund.annualisedPerformancesPcl ?? []).find(
    pr => pr.timePeriod === monthCode && pr.type === SectorSource.Morningstar,
  );

  return percentileRank?.value;
}
