import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('GMT');

export enum MonthCode {
  YTD = 'M0',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  M7 = 'M7',
  M8 = 'M8',
  M9 = 'M9',
  M10 = 'M10',
  M11 = 'M11',
  M12 = 'M12',
  M24 = 'M24',
  M36 = 'M36',
  M48 = 'M48',
  M60 = 'M60',
}

export const getMonthCodeByMonthCount = (
  months: number,
): MonthCode | undefined => {
  const absMonths = Math.abs(months);
  if (absMonths === 60) return MonthCode.M60;
  if (absMonths === 48) return MonthCode.M48;
  if (absMonths === 36) return MonthCode.M36;
  if (absMonths === 24) return MonthCode.M24;
  if (absMonths === 12) return MonthCode.M12;
  if (absMonths === 11) return MonthCode.M11;
  if (absMonths === 10) return MonthCode.M10;
  if (absMonths === 9) return MonthCode.M9;
  if (absMonths === 8) return MonthCode.M8;
  if (absMonths === 7) return MonthCode.M7;
  if (absMonths === 6) return MonthCode.M6;
  if (absMonths === 5) return MonthCode.M5;
  if (absMonths === 4) return MonthCode.M4;
  if (absMonths === 3) return MonthCode.M3;
  if (absMonths === 2) return MonthCode.M2;
  if (absMonths === 1) return MonthCode.M1;
  // if (absMonths === 0) return MonthCode.YTD;

  return undefined;
};

export const getYtdMonthNumber = (today: Dayjs) => {
  const isLastDayOfMonth = today.date() === today.daysInMonth();
  const currentMonth = today.month(); // 0-based (January is 0)

  // If it's not the last day of the month, use previous month's count
  const completedMonths = isLastDayOfMonth ? currentMonth + 1 : currentMonth;
  return completedMonths;
};
