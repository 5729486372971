import { useContext } from 'react';

import { UpgradeAccessContext } from '@/context/UpgradeAccessContext';
import { USER_PERMISSIONS } from '@aminsights/shared';

import useUserAccess from './useUserAccess';

const useUpgradeAccess = () => {
  const { isUpgradeModalOpen, toggleUpgradeModal, restrictedFeature } =
    useContext(UpgradeAccessContext);
  const { hasPermissions } = useUserAccess();

  const isRestrictedAccess = hasPermissions([
    USER_PERMISSIONS.advisor.restrict,
  ]);

  return {
    isUpgradeModalOpen,
    toggleUpgradeModal,
    isRestrictedAccess,
    restrictedFeature,
  };
};

export default useUpgradeAccess;
