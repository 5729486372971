import { AnalystBody, AnalystsApi, AnalystsRecord } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { invalidateDashboardResearch } from '../dashboard-hooks/useDashboardResearch';
import { ANALYSTS } from './query-keys';

const analystsApi = new AnalystsApi(openApiConfig());

const invalidateAnalysts = () => {
  queryClient.invalidateQueries({ queryKey: [ANALYSTS] });
};
const onSettled = () => {
  invalidateAnalysts();
  invalidateDashboardResearch();
};

export const useGetAnalysts = (fundMetadataId: string) => {
  return useQuery<AnalystsRecord | null, unknown>(
    [ANALYSTS, fundMetadataId],
    async () => {
      const result = await analystsApi.getAnalysts(fundMetadataId);
      return result.data;
    },
    { enabled: Boolean(fundMetadataId) },
  );
};

export const useCreateAnalysts = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; body: AnalystBody }) =>
      analystsApi.createAnalysts(input.fundMetadataId, input.body),
    {
      onSettled,
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Analysts has been added' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error adding analysts',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateAnalysts = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: {
      fundMetadataId: string;
      analystsId: string;
      body: AnalystBody;
    }) =>
      analystsApi.updateAnalysts(
        input.fundMetadataId,
        input.analystsId,
        input.body,
      ),
    {
      onSettled,
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating analysts',
        });
      },
    },
  );
  return mutation;
};
