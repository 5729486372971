import { Route, Switch } from 'react-router-dom';

import Research from './Research';
import ResearchHistory from './ResearchHistory';

export const ResearchSubRouter = () => {
  return (
    <Switch>
      <Route path="/fund/:id/details/research" exact component={Research} />
      <Route
        path={[
          '/fund/:id/details/research/history',
          '/fund/:id/details/research/history/:historyId',
        ]}
        exact
        component={ResearchHistory}
      />
    </Switch>
  );
};
