import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

interface EmptyStateBaseProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode | string;
}

const EmptyStateBase: React.FC<PropsWithChildren<EmptyStateBaseProps>> = ({
  icon,
  title,
  subtitle,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      'flex flex-col items-center justify-center w-full text-center p-5 py-16 md:py-32',
      props.className,
    )}
  >
    {icon}
    <h3 className="text-xl text-neutral-200 font-semibold mt-4 mb-2">
      {title}
    </h3>
    <p className="text-sm text-neutral-700">{subtitle}</p>
  </div>
);

export default EmptyStateBase;
