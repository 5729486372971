import { ResourceUserRoleEnum } from '@aminsights/contract';
import { LIMIT_FUNDS_PER_PORTFOLIO } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button, DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { STATIC_DATA_PORTFOLIO_LIMIT_MODAL } from '@/constants/modals-static-data';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import usePortfolioEdgeDates from '@/hooks/query-hooks/portfolio-hooks/usePortfolioEdgeDates';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import LimitReachModal from '@/partials/Modal/LimitReachModal';
import TablesActionToast from '@/partials/Toast/TablesActionToast';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { useProvidePortfolio } from '../../../useProvidePortfolio';
import EmptyPortfolioTable from '../../EmptyPortfolioTable';
import AddFundSearchBox from './columns/AddFundSearchBox';
import { generatePortfolioHoldingColumns } from './columns/generators';

const emptyPortfolio = {
  id: '',
  name: '',
  benchmarkName: '',
  funds: [],
  users: [],
  createdAt: 0,
  updatedAt: 0,
};

const PortfolioHoldingsTab: React.FC<PropsWithChildren> = () => {
  const {
    state,
    addFundToPortfolio,
    addFundSearchBoxToCurrentPortfolio,
    removeFundSearchBoxFromCurrentPortfolio,
    updateWeightingForFundInPortfolio,
    addDate,
    updateDateInPortfolioFund,
    deleteDateFromPortfolio,
    cleanEmptyDates,
    identifyFundForDelete,
    deleteFundsFromPortfolio,
    unmarkAllFundsForDelete,
    markAllFundsForDelete,
  } = useProvidePortfolio();

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );
  const uniqueFunds =
    currentPortfolio?.funds.filter((obj, index, self) => {
      return index === self.findIndex(t => t.isin === obj.isin);
    }) ?? [];

  const bottomRef = useRef<HTMLDivElement>(null);
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };
  const [addFundModalOpen, setAddFundModalOpen] = useState(false);
  const [fundToAdd, setFundToAdd] = useState<
    | {
        isin: string;
        fundName: string;
        code: string;
        broadAssetClass: string;
      }
    | undefined
  >();

  const [disabledAddFund, setDisabledAddFund] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode['max-sm'];

  const portfolioUsers = currentPortfolio?.users || [];
  const currentPortfolioUser = portfolioUsers.find(
    bu => bu.id === currentUser.sub,
  );

  const hasManagePermissions =
    ResourceUserRoleEnum.Editor === currentPortfolioUser?.role;

  const addFundToPortfolioCall = (
    isin: string,
    fundName: string,
    code: string,
    broadAssetClass: string,
  ) => {
    setDisabledAddFund(false);
    if (currentPortfolio) {
      return addFundToPortfolio(
        currentPortfolio._id ?? '',
        isin,
        fundName,
        code,
        broadAssetClass,
      );
    }
  };

  const [tableData, setTableData] = useState<{
    footer: IDataTableColumns[];
    columns: IDataTableColumns[];
  }>({ footer: [], columns: [] });

  const removeRowWithoutData = () => {
    removeFundSearchBoxFromCurrentPortfolio();
  };

  const markFundForDelete = (isin: string) => {
    identifyFundForDelete(isin);
  };

  const toggleAllFundsForDelete = (disableAll: boolean) => {
    if (disableAll) {
      unmarkAllFundsForDelete();
    } else {
      markAllFundsForDelete();
    }
  };

  const { data: portfolioEdgeDates } = usePortfolioEdgeDates(
    {
      portfolioId: currentPortfolio?._id,
    },
    {
      enabled: false,
    },
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (currentPortfolio) {
      const res = generatePortfolioHoldingColumns(
        currentPortfolio ?? emptyPortfolio,
        state.markedFundsForDelete,
        currentPortfolio.funds,
        addFundToPortfolioCall,
        updateWeightingForFundInPortfolio,
        addDate,
        updateDateInPortfolioFund,
        deleteDateFromPortfolio,
        cleanEmptyDates,
        markFundForDelete,
        toggleAllFundsForDelete,
        removeRowWithoutData,
        hasManagePermissions,
        screenWidthMode[ScreenWidthEnum.MaxMd],
      );
      setTableData(res);
      setDisabledAddFund(currentPortfolio.funds.some(f => f?.isin === ''));
    }
  }, [currentPortfolio, state.markedFundsForDelete, portfolioEdgeDates]);

  const { page, setPage } = useInfiniteScroll(
    currentPortfolio?.funds.length || 0,
    currentPortfolio?.funds || [],
    () => {
      /**/
    },
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setPage(0);
  }, [bottomRef.current]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const setPageAndNewData = () => {
      setPage(1);
    };
    setPageAndNewData();
  }, [state.currentPortfolioId]);

  const isLoading = state.arePortfoliosLoading;
  const isEmpty = !currentPortfolio || currentPortfolio.funds.length === 0;
  const hasItemsAndIsReady = !isLoading && !isEmpty;

  const handleAddFundModalClose = () => {
    setFundToAdd(undefined);
    setAddFundModalOpen(false);
    setDisabledAddFund(false);
  };

  return (
    <div>
      {isMobile && (
        <Modal
          title="Add fund"
          className="sm:hidden full-page-modal information-modal"
          open={addFundModalOpen}
          destroyOnClose={true}
          onCancel={handleAddFundModalClose}
          onClose={handleAddFundModalClose}
          footer={[
            <Button
              type="link"
              className="!text-neutral-700 !text-sm"
              onClick={handleAddFundModalClose}
              key="cancel-button"
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                if (fundToAdd) {
                  addFundToPortfolioCall(
                    fundToAdd.isin,
                    fundToAdd.fundName,
                    fundToAdd.code,
                    fundToAdd.broadAssetClass,
                  );
                }
                handleAddFundModalClose();
              }}
              disabled={!fundToAdd}
              key="add-button"
            >
              Add
            </Button>,
          ]}
        >
          <AddFundSearchBox
            onAdd={(...params) =>
              setFundToAdd({
                isin: params[0],
                fundName: params[1],
                code: params[2],
                broadAssetClass: params[3],
              })
            }
            onClose={() => {}}
            fundsInPortfolio={(currentPortfolio?.funds || []).map(p => ({
              isin: p.isin,
              broadAssetClass: p.broadAssetClass,
              fundName: p.shareClassDetails?.code ?? '',
            }))}
            dataTestId="addFundSearchBox"
          />
        </Modal>
      )}
      {isLoading && (
        <div className="p-4">
          <Loader loading row={5} component={<div />} />
        </div>
      )}
      {isEmpty && !isLoading && (
        <EmptyPortfolioTable
          currentPortfolioId={currentPortfolio?._id}
          onAddFundClick={addFundSearchBoxToCurrentPortfolio}
          hideEditActions={!hasManagePermissions}
          isHoldingsTab={true}
        />
      )}
      {
        <>
          {hasItemsAndIsReady && currentPortfolio.funds.length > 0 && (
            <div
              className={cx(
                'grid justify-items-start',
                // Custom styling to override table defaults. Precisely why we need to change table implementation
                '[&_th>div]:!my-0 [&_th>div]:px-2',
                // Ensure 8px padding on side extremities
                '[&_tr>*:first-child]:pl-2 [&_tr>*:last-child]:pr-2',
                '[&_tfoot_th:not(:first-child)>div]:justify-end [&_tfoot_th:not(:first-child)_p]:text-xs',
                '[&_tfoot_th:not(:first-child)_p]:mr-2',
              )}
            >
              <DataTable
                className="[&_.table-cell-inner-container]:p-0"
                loading={page === 1 && state.arePortfoliosLoading}
                data={uniqueFunds}
                uniqueKey={'isin'}
                columns={tableData.columns}
                footerData={tableData.footer}
                enableBorders
                disableVerticalRowPadding
              />
              {hasManagePermissions && (
                <Button
                  disabled={disabledAddFund}
                  type="link"
                  className={cx(
                    'm-0 p-4 text-sm border-none shadow-none !text-primary font-medium cursor-pointer hover:text-info',
                    '!disabled:text-neutral-400 disabled:cursor-not-allowed',
                  )}
                  onClick={() => {
                    if (
                      (currentPortfolio?.funds?.length ?? 0) >=
                      LIMIT_FUNDS_PER_PORTFOLIO
                    ) {
                      setIsLimitReachModalOpen(true);
                    } else {
                      setDisabledAddFund(true);
                      if (!isMobile) {
                        addFundSearchBoxToCurrentPortfolio();
                        return;
                      }
                      setAddFundModalOpen(true);
                    }
                  }}
                  data-test-id="portfolioAddFundButton"
                >
                  + Add fund
                </Button>
              )}
            </div>
          )}
          <LimitReachModal
            isFundTrust
            staticData={STATIC_DATA_PORTFOLIO_LIMIT_MODAL}
            isVisible={isLimitReachModalOpen}
            toggleModal={handleLimitReachModalOpen}
            showDeleteOtherFundsTrusts={false}
          />
        </>
      }
      {state.markedFundsForDelete.length > 0 && (
        <TablesActionToast
          isins={state.markedFundsForDelete}
          count={state.markedFundsForDelete.length}
          checkedRowsClassDetails={state.portfolios
            .flatMap(p => p.funds)
            .map(f => ({ isin: f.isin, code: f.shareClassDetails?.code ?? '' }))
            .filter(f => state.markedFundsForDelete.some(m => m === f.isin))}
          dataTableRenderedAt={DataTableRenderedAt.PortfolioHoldings}
          onClearCheckedRows={(_isins: string[]) => {
            unmarkAllFundsForDelete();
          }}
          deleteAction={() => deleteFundsFromPortfolio()}
        />
      )}
      <div ref={bottomRef} />
    </div>
  );
};

export default PortfolioHoldingsTab;
