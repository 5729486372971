import {
  useExploreDefaultFiltersQuery,
  useExploreQuery,
} from '@/pages/app/Explore/context';
import useExploreFilters from '@/pages/app/Explore/hooks/useExploreFilters';
import { convertParamsToState } from '@/pages/app/Explore/useProvideExplore';
import areFiltersApplied from '@/pages/app/Explore/utils/filterComparisonHelpers';
import { formatDate } from '@/utils';
import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Skeleton } from 'antd';
import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getLatesAsofDate } from './findLatestAsOfDate';

const ExploreHeaderAsOfDate: React.FC<{
  type: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE | WATCHLIST_TAB_KEY.RISK;
}> = ({ type }) => {
  const { filters } = useExploreFilters();
  const location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const searchParams = useMemo(() => {
    // For some reason location from useLocation is not updated. Maybe because of how we update the url in zustand
    return new URLSearchParams(window.location.search);
  }, [filters, location.search]);

  const { data: defaultfiltersOptions, isLoading: isLoadingDefaultFilters } =
    useExploreDefaultFiltersQuery();

  const { data, isLoading } = useExploreQuery({
    queryParams: convertParamsToState(
      searchParams,
      {
        categories: defaultfiltersOptions?.categories,
        iaSectors: defaultfiltersOptions?.iaSectors,
        advisors: defaultfiltersOptions?.advisorNames || [],
      },
      true,
    ),
    enabled: !isLoadingDefaultFilters && areFiltersApplied(filters),
  });

  /*
   * we only check the first page, because it is extremely unlikely that the asOfDate is different.
   * Also very heavy to check all pages each scroll (infinite scroll)
   */
  const pagesData = data?.pages[0].data.data ?? [];

  let computedAsOfDate;
  let riskMeasuresDetailsAsOfDate;
  if (type === WATCHLIST_TAB_KEY.RISK) {
    computedAsOfDate = getLatesAsofDate(pagesData, 'computedNavRiskDetails');
    riskMeasuresDetailsAsOfDate = getLatesAsofDate(
      pagesData,
      'riskMeasuresDetail',
      'endDate',
    );
    if (
      riskMeasuresDetailsAsOfDate &&
      riskMeasuresDetailsAsOfDate !== computedAsOfDate
    ) {
      computedAsOfDate = riskMeasuresDetailsAsOfDate;
    }
  } else if (type === WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE) {
    computedAsOfDate = getLatesAsofDate(
      pagesData,
      'computedNavAnnualisedReturns',
    );
  }

  return isLoading ? (
    <div>
      <Skeleton.Button
        className="flex"
        active
        size="small"
        style={{ width: '96px', height: '13px' }}
      />
    </div>
  ) : (
    <span className="text-xs italic font-bold leading-4 ml-4 text-darkest">
      {computedAsOfDate ? formatDate(computedAsOfDate) : 'No date available'}
    </span>
  );
};

export default memo(ExploreHeaderAsOfDate);
