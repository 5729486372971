import { Skeleton } from 'antd';

const NotesSkeletonLoader = () => {
  return (
    <>
      <div className="flex flex-row gap-2 justify-between">
        <Skeleton.Button
          active
          size="default"
          style={{ width: 64, height: 16 }}
        />
        <Skeleton.Button
          active
          size="default"
          style={{ width: 200, height: 16 }}
        />
      </div>
      <div className="mt-6 w-full flex flex-col h-ful pt-7 gap-6">
        <Skeleton.Node
          className="[&_*_.anticon]:hidden"
          active
          style={{ width: '100%', height: '184px' }}
        />
        <Skeleton.Node
          className="[&_*_.anticon]:hidden"
          active
          style={{ width: '100%', height: '184px' }}
        />
      </div>
    </>
  );
};

export default NotesSkeletonLoader;
