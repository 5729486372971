import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { HorizontalScroll, PageResults } from '@/components';
import useSignificantChangesInfiniteQuery from '@/hooks/query-hooks/significant-changes-hooks/useSignificantChangesInfiniteQuery';
import useProvideSignificantChanges from '@/pages/app/SignificantChanges/useProvideSignificantChanges';
import Search from '@/partials/SearchBox';
import useProvideSCSearch from '@/partials/SearchBox/useProvideSCSearch';
import SignificantChangesFilter from '@/partials/SignificantChanges/SignificantChangesFilter';
import SignificantChangesTable from '@/partials/SignificantChanges/SignificantTable';

interface SignificantChangesWrapperProps {
  isins: string[];
  bucketId?: string;
}

const EMPTY_SC_MESSAGE = 'There have been no changes on funds yet';

const SignificantChangesWrapper: React.FC<
  PropsWithChildren<SignificantChangesWrapperProps>
> = ({ isins }) => {
  const {
    state,
    setSignificantChangeParameters,
    anyFiltersSelected,
    clearFilter,
  } = useProvideSignificantChanges();
  const { state: searchState, clearSearch } = useProvideSCSearch();

  const [isClearSearch] = useState(false);

  const clearSearchState = () => {
    clearSearch();
    clearFilter();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    let significantChangesParamsToSet = state.significantChangesParameters;

    if (isins && isins.length > 0) {
      significantChangesParamsToSet = {
        ...significantChangesParamsToSet,
      };
    }

    if (
      searchState.searchTerm !== undefined &&
      searchState.searchTerm !== significantChangesParamsToSet.term
    ) {
      significantChangesParamsToSet = {
        ...significantChangesParamsToSet,
        term: searchState.searchTerm,
        // A workaround when we have entered tems and switched bucket(page === 1),
        // otherwise, this fires after the page set to 1 for switching bucket case
        page: state.significantChangesParameters.page === 1 ? 0 : 1,
      };
    }

    if (
      !_.isEqual(
        significantChangesParamsToSet,
        state.significantChangesParameters,
      )
    ) {
      setSignificantChangeParameters(significantChangesParamsToSet);
    }
  }, [state.significantChangesParameters, isins, searchState.searchTerm]);

  const significantChangesResponse = useSignificantChangesInfiniteQuery(isins);

  const totalNumberOfItems =
    significantChangesResponse?.data?.pages?.[0]?.total ?? 0;

  return (
    <section className="section-significant">
      <div className="flex flex-row">
        <div className="pt-1 w-full bg-white">
          <div>
            <div>
              <div className="flex flex-col pt-4 pl-4 md:flex-column">
                <div className="w-full pb-4 pr-4">
                  <Search
                    autofocusDeps={isins}
                    placeholder="Filter by name or ISIN"
                    isClear={isClearSearch}
                  />
                </div>
                <div className="flex flex-col sm:flex-row pb-2 pr-2">
                  <HorizontalScroll hideScrollbars>
                    <SignificantChangesFilter
                      state={state}
                      onClear={clearSearchState}
                      anyFiltersSelected={anyFiltersSelected()}
                      onParamsChange={params => {
                        setSignificantChangeParameters({ ...params, page: 0 });
                      }}
                    />
                  </HorizontalScroll>

                  <PageResults
                    totalCount={totalNumberOfItems}
                    className="w-full flex !justify-end pr-1 sm:pr-2 sm:w-32"
                  />
                </div>
              </div>
              <div>
                <SignificantChangesTable
                  isins={isins}
                  emptyMessage={EMPTY_SC_MESSAGE}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignificantChangesWrapper;
