import { Button, Modal, Select } from 'antd';
import cx from 'classnames';
import { FC } from 'react';

import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { MAX_ANALISTS } from '.';

interface AnalystFormModalProps {
  selectedAnalysts: string[];
  options: { value: string; label: string }[];
  isVisible: boolean;
  showModalOnAllLayouts?: boolean;
  close: () => void;
  onChangeAnalysts: (value: string[]) => void;
  onSubmitForm: () => void;
}

const AnalystFormModal: FC<AnalystFormModalProps> = ({
  isVisible,
  selectedAnalysts,
  options,
  close,
  onChangeAnalysts,
  onSubmitForm,
  showModalOnAllLayouts = false,
}) => {
  const screenWidthMode = getScreenWidthMode();
  return (
    <Modal
      open={
        isVisible &&
        (showModalOnAllLayouts || screenWidthMode[ScreenWidthEnum.MaxSm])
      }
      className={cx(
        '[&_.ant-modal-body]:mt-8 [&_.ant-modal-body]:p-4 [&_.ant-modal-close]:mr-4',
        showModalOnAllLayouts
          ? 'max-sm:full-page-modal sm:information-modal'
          : 'full-page-modal',
      )}
      onCancel={close}
      footer={[
        <Button size="large" type="link" onClick={close} key="cancel-button">
          Cancel
        </Button>,
        <Button
          size="large"
          type="primary"
          className="disabled:border-none disabled:bg-neutral-300 disabled:text-white"
          onClick={onSubmitForm}
          disabled={!selectedAnalysts.length}
          key="add-button"
        >
          Add
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-4">
        <h3 className="text-xl font-bold text-neutral-200">Add analyst</h3>
        <div className="flex-1 flex flex-col">
          <Select
            placement="bottomLeft"
            mode="multiple"
            data-test-id="researchAnalystSelect"
            placeholder="Ex. John, Jennifer,..."
            suffixIcon={null}
            className={`w-full min-h-10 custom-antd-selection 
              [&_.ant-select-selection-placeholder]:text-neutral-100 [&_.ant-select-selection-placeholder]:px-1
              [&_.ant-select-selector]:p-2 [&_.ant-select-selector]:rounded [&_.ant-select-selector]:ring-0
              [&_.ant-select-selector]:border-neutral-300 [&_.ant-select-selector]:hover:border-primary
              [&_.ant-select-selector]:focus-within:border-2 [&_.ant-select-selector]:focus-within:border-primary`}
            maxCount={MAX_ANALISTS}
            value={selectedAnalysts}
            options={options.map(option => ({ ...option, title: '' }))}
            onChange={onChangeAnalysts}
            optionFilterProp="label"
          />
          <p className="text-xs text-neutral mt-1">
            Search and select up to {MAX_ANALISTS} names from the list.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default AnalystFormModal;
