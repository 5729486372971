import { ResearchDeadlineWidgetTypeEnum } from '@aminsights/contract';
import { researchLegendList } from '@aminsights/shared';
import cx from 'classnames';
import React from 'react';

interface LegendProp {
  className?: string;
  researchType: ResearchDeadlineWidgetTypeEnum;
}

const ResearchLegendColor: React.FC<LegendProp> = ({
  className,
  researchType,
}) => {
  const typeColor = researchLegendList.find(
    i => i.type === researchType,
  )?.color;
  return typeColor ? (
    <div
      className={cx('w-2 h-2 rounded-full', className)}
      style={{ backgroundColor: typeColor }}
    />
  ) : null;
};

export default ResearchLegendColor;
