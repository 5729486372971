import { Bucket, Portfolio } from '@aminsights/contract';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Input } from '@/components';
import Button from '@/components/Button';
import { APP_ACTIONS, MAX_ENTITY_NAME, RESTRICTED_FEATURE } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useSectors } from '@/hooks/query-hooks/sector-hooks/useSectors';
import BenchmarksDropdown, {
  BenchmarksDropdownRef,
} from '@/partials/BenchmarksDropdown';
import SectorsDropdown from '@/partials/SectorsDropdown';
import { toCamelCase } from '@/utils/toCamelCase';

import LimitReachModal, { StaticDataForLimitModal } from '../LimitReachModal';
import { DefaultSelectedIndex } from '../types';

export interface StaticDataForAddNewEntityModal
  extends StaticDataForLimitModal {
  limitMessage?: string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
}
interface ModalProps {
  staticModalData: StaticDataForAddNewEntityModal;
  entities: (Portfolio | Bucket)[];
  onSaveClick: (
    name: string,
    benchmarkId?: string,
    sectorId?: string,
  ) => Promise<any>;
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  scrollOnBucketAdd?: () => void;
  restrictedFeature?: RESTRICTED_FEATURE;
}
const AddEntityWithBenchmarkModal: React.FC<PropsWithChildren<ModalProps>> = ({
  entities,
  staticModalData,
  onSaveClick,
  isVisible,
  className,
  toggleModal,
  scrollOnBucketAdd,
  restrictedFeature = undefined,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [entityName, setEntityName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(DefaultSelectedIndex);
  const [selectedSector, setSelectedSector] = useState('');
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [isEntityNameExists, setIsEntityNameExists] = useState(false);
  const [isEntityNameLong, setIsEntityNamaLong] = useState(false);
  const limitEntities = staticModalData.limitForEntities;
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const { data: sectors, isLoading: isSectorsLoading } = useSectors();
  const benchmarksDropdownRef = useRef<BenchmarksDropdownRef>(null);

  const history = useHistory();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (history.location.search.includes('current')) {
      scrollOnBucketAdd?.();
    }
  }, [history.location.search]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (entityName && entities.length) {
      setIsEntityNameExists(entities.some(b => b.name === entityName));
    }
  }, [entityName, entities, isVisible]);

  useEffect(() => {
    const isEntityNameEmpty = entityName.trim() === '';
    const isAllSpaces = /^\s*$/.test(entityName);
    const isPortfolioNameValid = !isEntityNameExists && !isAllSpaces;
    if (!isEntityNameEmpty && isPortfolioNameValid) {
      setIsCreateButtonDisabled(false);
    } else {
      setIsCreateButtonDisabled(true);
    }
  }, [entityName, isEntityNameExists]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const isAllSpaces = /^\s*$/.test(entityName);
    if (e.target.value.length > MAX_ENTITY_NAME && !isAllSpaces) {
      setIsEntityNamaLong(true);
    } else {
      setIsEntityNamaLong(false);
    }
    setEntityName(e.target.value);
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const onClick = () => {
    if (entities.length >= limitEntities) {
      setIsLimitReachModalOpen(true);
    } else {
      setIsProcessing(true);
      onSaveClick(entityName, selectedIndex.secId || '', selectedSector)
        .then(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: {
              text: `Successfully added a new ${staticModalData.entityName.toLowerCase()}`,
            },
          });
          scrollOnBucketAdd?.();
        })
        .catch(error => {
          setErrorMessage({
            dispatch: dispatchApp,
            error,
            errorAdditionalText: `${staticModalData.entityName} not added`,
          });
        })
        .finally(() => {
          handleCloseModal();
          setIsProcessing(false);
        });
    }
  };
  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };
  const entityNameText = staticModalData.entityName;
  const infoText = staticModalData.limitMessage ?? '';

  const handleCloseModal = () => {
    setSelectedIndex(DefaultSelectedIndex);
    setSelectedSector('');
    setEntityName('');
    setIsEntityNameExists(false);
    setIsEntityNamaLong(false);
    toggleModal();
    benchmarksDropdownRef.current?.close?.();
  };

  return (
    <>
      <Modal
        centered={true}
        destroyOnClose
        open={isVisible}
        onCancel={handleCloseModal}
        className={cx(
          'max-sm:full-page-modal action-modal action-modal-confirmation',
          '[&_.ant-modal-body]:!pt-0',
          className,
        )}
        title={`Add new ${entityNameText.toLowerCase()}`}
        footer={[
          <Button
            className="font-medium text-sm p-0 !text-neutral-700"
            size="large"
            type="link"
            key="secondary"
            onClick={handleCloseModal}
          >
            {staticModalData.secondaryBtnText || 'Cancel'}
          </Button>,
          <Button
            size="large"
            type="primary"
            className="font-medium h-10 rounded m-0 !bg-primary disabled:!bg-neutral-300 !outline-none"
            onClick={onClick}
            key="primary"
            disabled={
              isCreateButtonDisabled || isEntityNameExists || isProcessing
            }
            data-test-id="modalAddBucketSaveButton"
          >
            {staticModalData.primaryBtnText || 'Create'}
          </Button>,
        ]}
      >
        <div data-test-id={toCamelCase(`addNew${entityNameText}Modal`)}>
          <div className="font-normal text-sm text-neutral-700 mb-6">
            {infoText}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-darkest text-xs">{entityNameText} Name</p>
              <Input
                required
                autoFocus
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                placeholder={`Enter ${entityNameText} Name`}
                value={entityName}
                className="border border-[#bac0d0] px-[14px] placeholder:text-neutral-100"
                data-test-id="modalAddBucketNameInput"
              />
              {isEntityNameExists && (
                <div className="pl-1 text-xs font-medium text-danger">
                  {entityNameText} name already exists
                </div>
              )}
              {isEntityNameLong && (
                <div className="pl-1 text-xs font-medium text-danger">
                  We suggest you keep these as short as possible
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-darkest text-xs">Benchmark</p>
              <div data-test-id="modalAddBucketBenchmarkInput">
                <BenchmarksDropdown
                  dropdownKey={entityName}
                  className={cx(
                    '!h-10',
                    selectedIndex.secId
                      ? '[&_p]:!text-neutral'
                      : '[&_p]:!text-neutral-100',
                  )}
                  placeholder="Select benchmark"
                  value={selectedIndex.secId}
                  benchmarkOptions={
                    benchmarkOptionsResponse ? benchmarkOptionsResponse : []
                  }
                  onSelect={selectedValue => {
                    setSelectedIndex({
                      secId: selectedValue,
                      name:
                        benchmarkOptionsResponse?.find(
                          b => b.id === selectedValue,
                        )?.name || '',
                    });
                  }}
                  onClear={() => setSelectedIndex({ secId: '', name: '' })}
                  isOptionsLoading={isBenchmarkOptionsLoading}
                  hideCustomOptions
                  ref={benchmarksDropdownRef}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-darkest text-xs">Sector</p>
              <div data-test-id="modalAddBucketSectorInput">
                <SectorsDropdown
                  value={selectedSector}
                  options={(sectors || []).map(({ name, sectorId }) => ({
                    label: name,
                    value: sectorId,
                  }))}
                  isOptionsLoading={isSectorsLoading}
                  onSelect={selectedValue => setSelectedSector(selectedValue)}
                  onClear={() => setSelectedSector('')}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <LimitReachModal
        isFundTrust={false}
        staticData={staticModalData}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
        restrictedFeature={restrictedFeature}
      />
    </>
  );
};
export default AddEntityWithBenchmarkModal;
