import { ReactComponent as GraphicFile } from '@/assets/svg/graphic-file.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { Button } from 'antd';

export const FoldersTabEmptyState = () => {
  return (
    <div
      className={`min-h-[70vh] relative w-full rounded-lg 
        bg-white overflow-hidden text-center px-5`}
    >
      <div className="pt-48 flex flex-col items-center">
        <GraphicFile />
        <h1 className="pt-4 text-[#313341] text-xl font-semibold block">
          No folders added
        </h1>
        <p className="pt-2 text-sm text-neutral-700">
          Start organising your buckets by creating folders.
        </p>
        <div className="pt-4">
          <Button
            type="primary"
            className="h-10 text-sm"
            icon={<AddIcon className="icon !h-3" />}
          >
            Add folder
          </Button>
        </div>
      </div>
    </div>
  );
};
