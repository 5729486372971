import { PortfolioWeighting } from '@aminsights/contract';
import {
  DATE_FORMAT_DASHED,
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
} from '@aminsights/shared';
import dayjs from 'dayjs';

import { IDataTableColumns } from '@/components/Table/DataTable';

export interface IImportedFundsData {
  fundName: string;
  isin: string;
  shareClassDetails: {
    code: string;
  };
  weightings: PortfolioWeighting[];
}

export const ImportedPortfolioFundsDataTableColumns = (
  dates: string[],
  isMobile: boolean,
): Array<IDataTableColumns> => {
  return [
    {
      title: 'Funds/Investment Trust',
      render: (item: IImportedFundsData) => {
        return (
          <div className="text-sm text-neutral">
            {item?.shareClassDetails.code || item?.fundName || ''}
          </div>
        );
      },
      width: isMobile ? 150 : 350,
      renderType: 'text',
      isColumnFixed: true,
    },
    ...dates.map(d => ({
      title: dayjs(d, DATE_FORMAT_DASHED).format(DISPLAY_DATE_FORMAT),
      renderType: 'text' as const,
      render: (item: IImportedFundsData) => {
        const weighting = item.weightings.find(w => w.date === d)?.value;
        return (
          <div className="text-sm text-neutral">
            {!!weighting ? `${weighting}%` : EMPTY_DATA_POINT}
          </div>
        );
      },
    })),
  ];
};
