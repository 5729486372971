import { ReactComponent as PencilIcon } from '@/assets/svg/icons/icon-pencil-v3.svg';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  FUND_DETAILS_TAB,
  buildFundDetailsPath,
} from '@aminsights/shared';

import React, { PropsWithChildren, useState } from 'react';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { NoteRecord, NoteStatus } from '@aminsights/contract';
import { Button, Modal, Skeleton } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import CardNotes from '../../FundAndInvestmentTrust/components/Research/MainEmptyState/component/CardNotes';

type MyResearchData = {
  isin: string;
  fundName: string;
  notes: NoteRecord[];
  status: NoteStatus;
  rating?: string;
  analysts: string[];
  meetingDate?: string;
  noteUpdate?: string;
};

type ResearchTableProps = {
  isLoading: boolean;
  data: Array<MyResearchData>;
};

const titleContent = (item: MyResearchData) => {
  return item.fundName ? (
    <Link
      className={`text-xs font-bold text-start text-darkest
      `}
      to={buildFundDetailsPath(item.isin, FUND_DETAILS_TAB.RESEARCH)}
      onClick={e => {
        e.stopPropagation();
        window.scrollTo({ top: 0 });
      }}
    >
      {item.fundName}
    </Link>
  ) : (
    EMPTY_DATA_POINT
  );
};

const PUBLISHED_STATUSES = [
  NoteStatus.PublishedNew,
  NoteStatus.PublishedUpdate,
];

const ResearchColumns = (
  screenWidthMode: Record<ScreenWidthEnum, boolean>,
): IDataTableColumns[] => [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'fundName',
    sorter: {
      compare: (a: { fundName: string }, b: { fundName: string }) =>
        a.fundName.localeCompare(b.fundName),
      multiple: 3,
    },
    render: (item: MyResearchData) => {
      return (
        <div className="flex justify-center items-center gap-2 items-center py-3">
          <span className="font-medium text-neutral">{titleContent(item)}</span>
        </div>
      );
    },
    renderType: 'text',
    width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 312,
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'Status',
    sortKey: 'status',
    renderType: 'custom',
    width: 100,
    render: (research: MyResearchData) => {
      const isPublished = PUBLISHED_STATUSES.some(s => s === research.status);

      return (
        <p
          className={cx('px-1.5 py-1 rounded', {
            'bg-warning-200 text-warning-700': !isPublished,
            'bg-success-50 text-success-600': isPublished,
          })}
        >
          {isPublished ? 'Published' : 'Draft'}
        </p>
      );
    },
  },
  {
    title: 'Rating',
    sortKey: 'rating',
    renderType: 'text',
    width: 100,
    render: (research: MyResearchData) => {
      return (
        <span className="text-neutral">
          {research.rating ?? EMPTY_DATA_POINT}
        </span>
      );
    },
  },
  {
    title: 'Analyst(s)',
    sortKey: 'analysts',
    renderType: 'text',
    width: 150,
    render: (research: MyResearchData) => {
      return (
        <span className="text-neutral">
          {research.analysts.length !== 0
            ? research.analysts.join(',')
            : EMPTY_DATA_POINT}
        </span>
      );
    },
  },
  {
    title: 'Last Meeting Date',
    sortKey: 'meetingDate',
    renderType: 'custom',
    width: 150,
    render: (research: MyResearchData) => {
      const meetingDate = dayjs(research.meetingDate);
      return (
        <span className="text-neutral">
          {meetingDate.isValid()
            ? meetingDate.format(DISPLAY_DATE_FORMAT)
            : EMPTY_DATA_POINT}
        </span>
      );
    },
  },
  {
    title: 'Last Note Update',
    sortKey: 'noteUpdate',
    renderType: 'custom',
    width: 150,
    render: (research: MyResearchData) => {
      const noteUpdateDate = dayjs(research.noteUpdate);
      return (
        <span className="text-neutral">
          {noteUpdateDate.isValid()
            ? noteUpdateDate.format(DISPLAY_DATE_FORMAT)
            : EMPTY_DATA_POINT}
        </span>
      );
    },
  },
];

const MyResearchTable: React.FC<PropsWithChildren<ResearchTableProps>> = ({
  data,
  isLoading,
}) => {
  const screenWidthMode = getScreenWidthMode();
  const [modalData, setModalData] = useState<MyResearchData | null>();

  const onRowClick = (isin: string) => {
    setModalData(data.find(d => d.isin === isin));
  };

  return (
    <>
      <DataTable
        columns={ResearchColumns(screenWidthMode)}
        onRow={onRowClick}
        data={data}
        uniqueKey={'isin'}
        loading={isLoading}
        loaderSize={8}
        disableVerticalRowPadding={true}
      />
      <Modal
        open={!!modalData}
        onCancel={() => setModalData(null)}
        zIndex={1001}
        footer={[]}
        className={cx(
          'max-sm:full-page-modal action-modal',
          '[&_.ant-modal-body]:p-4 [&_.ant-modal-body]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0',
          'md:min-w-[560px]',
        )}
      >
        {modalData && (
          <div className="flex flex-col items-center justify-center gap-6">
            <div className="flex justify-between w-full">
              <h2 className="text-xl font-bold text-neutral-200">
                {modalData.fundName}
              </h2>
              <Button
                size="small"
                type="link"
                className="p-0 text-primary justify-self-center font-medium"
                onClick={() => window.scrollTo({ top: 0 })}
              >
                <Link
                  to={buildFundDetailsPath(
                    modalData.isin,
                    FUND_DETAILS_TAB.RESEARCH,
                  )}
                >
                  <span
                    className={
                      'p-2 mr-3flex items-center justify-center text-center'
                    }
                  >
                    <PencilIcon className="icon w-1 h-1 text-xl &_path:stroke-width-2.4 !fill-none [&>path]:fill-primary" />
                  </span>
                  <span>Go to fund</span>
                </Link>
              </Button>
            </div>
            {modalData.notes
              .sort((a, b) => b._updatedAt - a._updatedAt)
              .map(n => {
                return (
                  <CardNotes key={`modal-notes-${n}`} hideMeatball note={n} />
                );
              })}
          </div>
        )}
      </Modal>
    </>
  );
};

export default MyResearchTable;
