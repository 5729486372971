import { NoteCategory, NoteStatus } from '@aminsights/contract';

const notes = [
  {
    _id: '',
    fundMetadataId: 'LU1011997464',
    fundName: 'AB Concentrated Global Eq I USD',
    description:
      "AB Concentrated Global Equity is a focused, growth-oriented fund. The fund invests in a concentrated portfolio of global companies, with a focus on high-quality growth stocks. The investment philosophy is based on the belief that companies with sustainable competitive advantages, strong financials, and capable management teams are best positioned to generate long-term growth and shareholder value. The fund's approach emphasises thorough fundamental analysis and a long-term investment horizon.",
    category: NoteCategory.Performance,
    status: NoteStatus.PublishedNew,
    orgId: 'changing-digital-devs',
    createdBy: 'auth0|1',
    updatedBy: 'auth0|1',
    _createdAt: 1742202173000,
    _updatedAt: 1742202173000,
  },
  {
    _id: '',
    fundMetadataId: 'LU1011997464',
    fundName: 'AB Concentrated Global Eq I USD',
    description:
      'Dev Chakrabarti Dev Chakrabarti is a Portfolio Manager/Senior Research Analyst for Concentrated Global Growth. Prior to joining AB in December 2013, he was a portfolio manager/analyst on the global equity research and portfolio-management team at WPS Advisors. Chakrabarti joined W.P. Stewart in 2005 as a member of the European equity research and portfolio-management team, and moved to New York in 2008 to focus on global portfolios. Earlier in his career, he worked as an M&A analyst at Merrill Lynch, a financial analyst at Unilever and an equity analyst at J.P. Morgan Securities, where he specialized in European technology stocks. Chakrabarti holds a BSc (Hons) in economics from the University of Bristol and an MSc in finance from London Business School. Location: London',
    category: NoteCategory.People,
    status: NoteStatus.PublishedUpdate,
    orgId: 'changing-digital-devs',
    createdBy: 'auth0|1',
    updatedBy: 'auth0|1',
    _createdAt: 1705571854000,
    _updatedAt: 1705571854000,
  },
];

export const testMyResearchData = [
  {
    isin: 'LU1011997464',
    fundName: 'AB Concentrated Global Eq I USD',
    status: NoteStatus.PublishedNew,
    rating: 'Sell',
    analysts: ['Craig Baker'],
    meetingDate: '25 Feb 2024',
    noteUpdate: '28 Nov 2024',
    notes,
  },
  {
    isin: 'GB00BKBQC880',
    fundName: 'AI UK Listed Eq Ex Tobacco UKCorpAcc',
    status: NoteStatus.PublishedNew,
    rating: 'Hold',
    analysts: ['Craig Baker'],
    meetingDate: '12 Aug 2024',
    noteUpdate: '03 Mar 2024',
    notes,
  },
  {
    isin: 'GB00B11V7W98',
    fundName: 'Aliance Witan Ord',
    status: NoteStatus.DraftNew,
    rating: 'Strong Sell',
    analysts: ['Craig Baker', 'Mark Davis'],
    meetingDate: '09 Jan 2025',
    noteUpdate: '10 Dec 2024',
    notes,
  },
  {
    isin: 'LU1597252607',
    fundName: 'Allianz Income and Growth AMg2 H2 GBP',
    status: NoteStatus.PublishedNew,
    rating: 'Strong Buy',
    analysts: ['Craig Baker'],
    meetingDate: '08 Jul 2024',
    noteUpdate: '25 May 2024',
    notes,
  },
  {
    isin: 'LU0685229519',
    fundName: 'Allianz Income and Growth IT H2 EUR',
    status: NoteStatus.PublishedUpdate,
    rating: 'Buy',
    analysts: ['Craig Baker'],
    meetingDate: '25 Jun 2024',
    noteUpdate: '18 Apr 2024',
    notes,
  },
  {
    isin: 'GB00BF77ZV41',
    fundName: 'Schroder Blended Portfolio 5 Z Acc',
    status: NoteStatus.DraftUpdate,
    rating: 'Strong Buy',
    analysts: ['Craig Baker', 'Mark Davis'],
    meetingDate: '01 Dec 2024',
    noteUpdate: '17 Sep 2024',
    notes,
  },
  {
    isin: 'GB00BZCR4F60',
    fundName: 'Schroder Global MA Bal Port F Acc',
    status: NoteStatus.PublishedNew,
    rating: 'Buy',
    analysts: ['Craig Baker', 'Mark Davis'],
    meetingDate: '25 Sep 2024',
    noteUpdate: '21 Jun 2024',
    notes,
  },
  {
    isin: 'GB00B4R2F348',
    fundName: 'Vanguard LifeStrategy 60% Equity Fund',
    status: NoteStatus.PublishedNew,
    rating: 'Strong Sell',
    analysts: ['Craig Baker'],
    meetingDate: '08 Aug 2024',
    noteUpdate: '12 Mar 2024',
    notes,
  },
];
