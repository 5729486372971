import { SLIDER_MAX_RISK_RATING, SLIDER_MIN_RISK_RATING } from '@/constants';
import { countSelectedFilter } from '../utils/countSelectedFilter';

export interface RiskRatingFilterState {
  defaqto?: number[];
  dynamicPlanner?: number[];
  dynamicPlannerType?: string[];
  ev?: number[];
  oxford?: number[];
  synaptic?: number[];
}

export const defaultRiskSliderValue = [
  SLIDER_MIN_RISK_RATING,
  SLIDER_MAX_RISK_RATING,
];

export const riskRatingInitialState: RiskRatingFilterState = {
  defaqto: defaultRiskSliderValue,
  dynamicPlanner: defaultRiskSliderValue,
  ev: defaultRiskSliderValue,
  oxford: defaultRiskSliderValue,
  synaptic: defaultRiskSliderValue,
  dynamicPlannerType: undefined,
};

export const riskRatingMarks: Record<number, string> = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
};

export const riskRatingOxfordMarks: Record<number, string> = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
};

export const countRiskRatingFilters = (
  filterState?: RiskRatingFilterState,
): number => {
  if (!filterState) return 0;

  const a = [
    !!filterState.defaqto,
    !!filterState.dynamicPlanner,
    !!filterState.ev,
    !!filterState.oxford,
    !!filterState.synaptic,
    !!filterState.dynamicPlannerType,
  ];

  return countSelectedFilter(a);
};
