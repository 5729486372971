import DataTable, { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { Skeleton } from 'antd';
import style from '../Summary/style.module.less';

interface DFMItem {
  type: string;
  value: string | undefined | React.ReactNode;
}

interface ContactDetail {
  id: string;
  value: string | null | undefined;
}

const ContactDetails = ({ details }: { details: ContactDetail[] }) => {
  const allEmpty = details.every(
    detail => !detail.value || detail.value === '-',
  );

  if (allEmpty) {
    return <span>-</span>;
  }

  return (
    <div className="flex flex-col gap-1">
      {details.map(detail => (
        <div key={detail.id}>{detail.value || '-'}</div>
      ))}
    </div>
  );
};

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    renderType: 'text',
    render: (item: DFMItem) => (
      <span className="text-neutral-200 text-xs font-bold">{item.type}</span>
    ),
    minWidth: 240,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Type',
    isColumnFixed: true,
  },
  {
    title: 'Available',
    renderType: 'text',
    render: (item: DFMItem) => item.value || '-',
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Available',
    className: 'w-full',
    isColumnFixed: true,
  },
];

const DFMTable = () => {
  const { data: state, isLoading } = useFundByIsInParam();

  const dfmData = (() => {
    const getContactDetails = () => {
      const contactName = state?.fund.dfmDetails?.contactName;
      const contactEmail = state?.fund.dfmDetails?.contactEmail;
      const contactPhone = state?.fund.dfmDetails?.contactPhone;

      const details: ContactDetail[] = [
        { id: 'name', value: contactName },
        { id: 'email', value: contactEmail },
        { id: 'phone', value: contactPhone },
      ];

      return details;
    };

    return [
      {
        type: 'Ownership Structure',
        value: state?.fund.dfmDetails?.ownershipStructure,
      },
      {
        type: 'Profitable in the last 12 months',
        value: state?.fund.dfmDetails?.profitableInLast12Months ? 'Yes' : 'No',
      },
      {
        type: 'Holds debt',
        value: state?.fund.dfmDetails?.holdDebt ? 'Yes' : 'No',
      },
      { type: 'DFM Fee', value: `${state?.fund.dfmFee?.toFixed(1)}%` },
      {
        type: 'Contact details',
        value: <ContactDetails details={getContactDetails()} />,
      },
    ];
  })();

  return (
    <div className={style['summary-table']}>
      <DataTable
        uniqueKey="type"
        columns={columns}
        data={dfmData}
        loading={isLoading}
      />
    </div>
  );
};

export default DFMTable;
