import { Button, Modal, Tooltip } from 'antd';
import clsx from 'classnames';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowUpIcon } from '@/assets/svg/icons/icon-arrow-up.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/icons/icon-close-light.svg';
import { ReactComponent as StopIcon } from '@/assets/svg/icons/icon-stop.svg';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { useTranslateSearch } from '@/hooks/query-hooks/ai-hooks/translate-search';
import useExploreFilters from '@/pages/app/Explore/hooks/useExploreFilters';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import NoResultWarning from './NoResultWarning';
import SearchSpinner from './SearchSpinner';

export interface AISearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AISearchModal: FC<AISearchModalProps> = ({ isOpen, onClose }) => {
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { dispatch: dispatchApp } = useAppContext();
  const history = useHistory();
  const translateSearch = useTranslateSearch();
  const screenWidthMode = getScreenWidthMode();
  const { composeStateFromSearchString, emptyFilters } = useExploreFilters();

  const isSearchInputValid = searchText.length >= 3;

  const handleCloseModal = () => {
    setSearchText('');
    translateSearch.reset();
    onClose();
  };

  const handleSearch = async () => {
    try {
      if (!isSearchInputValid) return;
      setIsSearching(true);
      const response = await translateSearch.mutateAsync({ term: searchText });
      if (response?.data.result) {
        history.push(`/explore?${response.data.result}`);
        emptyFilters();
        composeStateFromSearchString();
        setSearchText('');
        handleCloseModal();
      }
    } catch (error) {
      setErrorMessage({
        dispatch: dispatchApp,
        error,
        errorAdditionalText: 'Error searching funds',
      });
    } finally {
      setIsSearching(false);
    }
  };

  const handleCancelSearch = () => {
    translateSearch.cancel();
    setIsSearching(false);
  };

  const isSuccessWithNoResults =
    translateSearch.isSuccess && !translateSearch.data?.data.result;

  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={handleCloseModal}
      className={clsx(
        { 'full-page-modal': screenWidthMode[ScreenWidthEnum.MaxSm] },
        'absolute sm:bottom-0 sm:right-0 [&_.ant-modal-body]:h-[100%] [&_.ant-modal-body]:p-4 [&_.ant-modal-close]:mr-4',
        'sm:[&_.ant-modal-content]:absolute sm:[&_.ant-modal-content]:right-4 sm:[&_.ant-modal-content]:bottom-4 [&_.ant-modal-content]:overflow-hidden',
        'sm:[&_.ant-modal-body]:border sm:[&_.ant-modal-body]:border-neutral-300 sm:[&_.ant-modal-body]:rounded-lg',
      )}
      mask={false}
      getContainer={false}
      destroyOnClose
    >
      <div className="h-full w-full flex items-end sm:w-96 pb-6 sm:pd-4">
        <div className="flex-1 flex flex-col gap-4 pt-6">
          {isSuccessWithNoResults && <NoResultWarning />}
          <p className="text-neutral-700 text-sm leading-5">Hello!</p>
          <p className="text-neutral-700 text-sm leading-5">
            Search or filter funds using words, e.g. “small cap Japan value”
          </p>
          <div className="flex flex-col gap-2 pt-2">
            <div className="h-4">{isSearching && <SearchSpinner />}</div>
            <div className="relative w-full">
              <input
                type="text"
                placeholder="What are you looking for?"
                className={clsx(
                  'w-full px-4 py-2 pr-10 rounded text-neutral-700 font-normal border border-neutral-300',
                  'placeholder:text-neutral-100 focus:ring focus:ring-blue-50 focus:border-blue-500 focus:outline-none',
                )}
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  translateSearch.reset();
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !isSearching) handleSearch();
                }}
                disabled={isSearching}
              />
              {isSuccessWithNoResults ? (
                <Button
                  className={
                    'absolute w-6 h-6 right-2 top-1/2 -translate-y-1/2 flex items-center justify-center rounded border-none'
                  }
                  size="large"
                  type="default"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setSearchText('');
                    translateSearch.reset();
                  }}
                />
              ) : (
                <Tooltip
                  placement="bottom"
                  title={isSearching ? 'Stop filtering data' : ''}
                  color="#313341"
                  arrow={false}
                >
                  <Button
                    className={clsx(
                      'absolute w-6 h-6 right-2 top-1/2 -translate-y-1/2 flex items-center justify-center rounded',
                      'disabled:bg-neutral-300 disabled:border-neutral-300',
                      { 'border-none bg-midLight': isSearching },
                    )}
                    size="large"
                    type={isSearching ? 'default' : 'primary'}
                    icon={isSearching ? <StopIcon /> : <ArrowUpIcon />}
                    onClick={isSearching ? handleCancelSearch : handleSearch}
                    disabled={!isSearchInputValid && !isSearching}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AISearchModal;
