import { Comment, CommentBody } from '@aminsights/contract';
import { Button, Modal } from 'antd';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import TextArea from '@/components/TextArea';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import {
  useCreateComment,
  useUpdateComment,
} from '@/hooks/query-hooks/internal-hooks/useManageCommentary';

interface ModalProps {
  isVisible: boolean;
  initialValue?: Comment;
  toggleModal: () => void;
}

const CommentaryFormModal: FC<ModalProps> = ({
  isVisible,
  initialValue,
  toggleModal,
}) => {
  const { data: fundState } = useFundByIsInParam();
  const { fundId, fundName } = fundState?.fund || {};
  const { control, handleSubmit, reset, formState } = useForm<CommentBody>({
    mode: 'all',
  });
  const { isValid, isSubmitting } = formState;

  const createComment = useCreateComment();
  const updateComment = useUpdateComment();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (initialValue) {
      reset({ description: initialValue.description });
    } else {
      reset({ description: '', fundId, fundName });
    }
  }, [fundName, initialValue]);

  const handleSaveComment = handleSubmit(async data => {
    if (!fundId || !fundName) return;

    if (initialValue?._id) {
      await updateComment.mutateAsync({
        commentId: initialValue._id,
        body: { ...data, fundId, fundName },
      });
    } else {
      await createComment.mutateAsync({
        body: { ...data, fundId, fundName },
      });
    }

    toggleModal();
    reset({ description: '' });
  });

  const handleCloseModal = () => {
    if (isSubmitting) return;
    toggleModal();
    reset({ description: '', fundName });
  };

  return (
    <Modal
      centered={true}
      destroyOnClose
      open={isVisible}
      onCancel={handleCloseModal}
      title="Commentary"
      className={cx(
        'action-modal max-sm:full-page-modal',
        'md:min-w-[600px] lg:min-w-[800px] [&_.ant-modal-body]:px-10 [&_.ant-modal-body]:py-0',
        '[&_.ant-modal-body]:pt-4 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-6',
      )}
      footer={[
        <Button
          key="cancel"
          size="large"
          type="link"
          onClick={handleCloseModal}
          disabled={createComment.isLoading || updateComment.isLoading}
        >
          Cancel
        </Button>,
        <Button
          key="primary"
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!isValid}
          onClick={handleSaveComment}
          loading={createComment.isLoading || updateComment.isLoading}
        >
          Save
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-2 h-full">
        <label
          className="leading-5 font-medium text-neutral-200 text-sm"
          htmlFor="description"
        >
          Description
        </label>
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'Description is required',
            maxLength: {
              value: 2000,
              message: `You have hit the 2,000 character limit.
              Reduce the characters and also let us know if this is a regular problem for you.`,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              name="description"
              className="max-sm:!h-full"
              value={value}
              onChange={onChange}
              autoFocus={isVisible}
              placeholder="Write something..."
              rows={8}
              error={error?.message}
              disabled={createComment.isLoading || updateComment.isLoading}
              style={{
                height: '150px', // Will be overwritten by the handle
              }}
            />
          )}
        />
      </div>
    </Modal>
  );
};
export default CommentaryFormModal;
