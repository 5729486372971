import { AdminSettingsApi, AdminSettingsBody } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { ADMIN_SETTINGS } from './query-keys';
const adminSettingsApi = new AdminSettingsApi(openApiConfig());

const invalidateAdminSettigns = () => {
  queryClient.invalidateQueries({ queryKey: [ADMIN_SETTINGS] });
};
const onSettled = () => {
  invalidateAdminSettigns();
};
/**
 * We need to use infiniteQuery to not break the parent hook. This should be refactored someday
 * if the component rendering the table is refactored.
 */
export const useGetAdminSettingsQuery = () => {
  return useQuery<AdminSettingsBody, unknown>([ADMIN_SETTINGS], async () => {
    const response = await adminSettingsApi.getAdminSettings();
    return response.data;
  });
};

export const usePatchAdminSettings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: {
      body: AdminSettingsBody;
    }) => adminSettingsApi.patchAdminSettings(input.body),
    {
      onSettled,
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating admin settings',
        });
      },
    },
  );
  return mutation;
};
