import { Modal } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useContext } from 'react';

import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';

import { WatchlistModalContext } from '../WatchlistModal/context';
import { withWatchlistModal } from '../WatchlistModal/context';

type ModalProps = {
  modalInfo: {
    title: string;
    description: string | React.ReactNode;
    btnName: string;
    succesMessage: string;
    errorMessage: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onClickAction: () => Promise<any | void>;
  closeIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  isUserSelectBucket: boolean;
  secondaryClickOption?: boolean;
};

const WrapperMultiSelectBucketModal: React.FC<
  PropsWithChildren<ModalProps>
> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  onClickAction,
  children,
  isUserSelectBucket,
  secondaryClickOption,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const { openNewBucketModal } = useContext(WatchlistModalContext);

  const onClick = () => {
    onClickAction()
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: modalInfo.errorMessage,
        });
      });
    toggleModal();
  };
  return (
    <Modal
      centered={true}
      open={isVisible}
      onCancel={toggleModal}
      maskClosable={false}
      className={cx(
        'max-sm:full-page-modal action-modal',
        'max-sm:[&_.ant-modal-header]:shadow-sm max-sm:[&_.ant-modal-footer]:drop-shadow-md',
        className,
      )}
      title={modalInfo.title}
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={secondaryClickOption ? openNewBucketModal : toggleModal}
          className="!text-neutral-700"
        >
          {secondaryClickOption ? '+ Add New Bucket' : 'Cancel'}
        </Button>,
        <Button
          className="primary-button h-10 font-medium w-24 text-[#0072e5] !bg-primary disabled:!bg-neutral-300 !outline-none m-0"
          type="primary"
          size="large"
          onClick={onClick}
          key="primary"
          loading={loading}
          disabled={isUserSelectBucket}
        >
          {modalInfo.btnName}
        </Button>,
      ]}
    >
      <div className="text-sm not-italic font-normal leading-6 text-neutral-700">
        {modalInfo.description}
      </div>
      {children}
    </Modal>
  );
};

export default withWatchlistModal(WrapperMultiSelectBucketModal);
