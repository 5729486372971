import { DynamicPlannerRiskType } from './dynamicPlannerType';

export enum ActivePassiveFilterType {
  All = 'All',
  Active = 'Active',
  Passive = 'Passive',
}

export enum GenderFilterType {
  Male = '1',
  Female = '0',
}

export enum CurrencyHedgedFilterType {
  Fully_Hedged = '1',
  Partially_Hedged = '2',
  No_Hedging_Information = 'N/A',
}

export enum DynamicPlannerRiskFilterType {
  RISK_PROFILED = '1',
  RISK_TARGETED = '2',
}

export const dynamicPlannerTypeFilterMap = {
  [DynamicPlannerRiskFilterType.RISK_PROFILED]:
    DynamicPlannerRiskType.RISK_PROFILED,
  [DynamicPlannerRiskFilterType.RISK_TARGETED]:
    DynamicPlannerRiskType.RISK_TARGETED,
};
