import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { openApiConfig } from '@/utils';
import { UpgradeAccessRequestPayload } from '@aminsights/contract';
import { HelpSupportApi } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

const helpSupportApi = new HelpSupportApi(openApiConfig());

const useUpgradeAccessRequest = () => {
  const { dispatch: dispatchApp } = useAppContext();

  const mutation = useMutation(
    (payload: UpgradeAccessRequestPayload) =>
      helpSupportApi.sendUpgradeAccessRequest(payload),
    {
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error sending upgrade access request',
        });
      },
    },
  );

  return mutation;
};

export default useUpgradeAccessRequest;
