import { Material } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Skeleton } from 'antd';
import { FC, useState } from 'react';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import { useDeleteMaterial } from '@/hooks/query-hooks/internal-hooks/useManageMaterials';
import useUserAccess from '@/hooks/useUserAccess';

import MaterialFormModal from '../MaterialFormModal';
import MaterialListItem from '../MaterialListItem';

interface MaterialsProps {
  isLoading?: boolean;
  materials: Material[];
}

const Materials: FC<MaterialsProps> = ({ materials, isLoading }) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<
    Material | undefined
  >();
  const { hasPermissions } = useUserAccess();

  const deleteMaterial = useDeleteMaterial();

  const handleToggleFormModal = () => {
    setIsFormModalOpen(prev => !prev);
  };

  const handleEditMaterial = (material: Material) => {
    setSelectedMaterial(material);
    handleToggleFormModal();
  };

  const hasMaterialsWritePermissions = hasPermissions([
    USER_PERMISSIONS.materials.write,
  ]);

  const areMaterialsAvailable = materials?.length;

  return (
    <div className="bg-white p-4 lg:rounded-lg">
      <p className="text-sm text-neutral-200 font-bold">Materials</p>
      {isLoading ? (
        <Skeleton
          title={false}
          paragraph={{ rows: 1, width: 150 }}
          style={{ marginTop: 10 }}
          active
        />
      ) : (
        <div className="flex flex-col gap-1">
          <div className="flex gap-x-1 flex-wrap">
            {materials.map((material, index) => (
              <>
                <MaterialListItem
                  key={material._id}
                  material={material}
                  onEditMaterial={handleEditMaterial}
                  onDeleteMaterial={async material => {
                    await deleteMaterial.mutateAsync({
                      materialId: material._id,
                    });
                  }}
                />
                {index < materials.length - 1 && (
                  <span className="text-neutral-200">,</span>
                )}
              </>
            ))}
          </div>
          {!areMaterialsAvailable && !hasMaterialsWritePermissions && (
            <p className="text-neutral-200">No link available</p>
          )}
          {hasMaterialsWritePermissions && (
            <div>
              <Button
                onClick={handleToggleFormModal}
                type="link"
                className="p-0 text-sm text-primary hover:text-primary-400 font-medium border-none shadow-none gap-1"
              >
                <PlusIcon className="icon text-xl" />
                Add link
              </Button>
            </div>
          )}
        </div>
      )}
      <MaterialFormModal
        isVisible={isFormModalOpen}
        initialValue={selectedMaterial}
        onCloseModal={() => {
          setSelectedMaterial(undefined);
          handleToggleFormModal();
        }}
      />
    </div>
  );
};

export default Materials;
