import { Avatar } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

import style from './style.module.less';

type AvatarProps = {
  size?: number | 'large' | 'small';
  type?: string;
  className?: string;
  shape?: 'circle' | 'square';
  color?: string;
};

const CustomAvatar: React.FC<PropsWithChildren<AvatarProps>> = ({
  size,
  type = '',
  className = '',
  shape,
  children,
  color = '',
}) => {
  return (
    <Avatar
      className={cx(className, style[type], style[color], style['ant-avatar'])}
      size={size}
      shape={shape}
    >
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
