import {
  Fund,
  FundRelativeRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  BenchmarkType,
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  LIMIT_FUNDS_FOR_CHARTING,
  MonthCode,
  buildFundDetailsPath,
  getFundShareClassDetailsFromArray,
  isFund,
} from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { PageResults } from '@/components';
import { DataTable } from '@/components';
import { MAX_COMPARE_TOTAL, TABLE_UNIQUE_KEY } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistRiskInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistRiskInfiniteQuery';
import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import NestedDrawer from '@/partials/NestedDrawer';
import TablesActionToast from '@/partials/Toast/TablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import RiskColumns from './WatchlistRiskColumns';

const WatchlistRiskTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  const { data, isLoading, fetchNextPage } = useWatchlistRiskInfiniteQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const currentPeriod = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item._id;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  let timePeriod: MonthCode = MonthCode.M12;
  switch (datePickerValue.mode) {
    case EDateFilterValues['1YR']:
      timePeriod = MonthCode.M12;
      break;
    case EDateFilterValues['3YR']:
      timePeriod = MonthCode.M36;
      break;
    case EDateFilterValues['5YR']:
      timePeriod = MonthCode.M60;
      break;
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const columns = RiskColumns({
    rowSelectionState: checkedRowState,
    dateRange: timePeriod,
    sortDirection,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item._id);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
  });

  const dropdownPortal = document.getElementById('dropdown-portal');
  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        dropdownPortal &&
        ReactDOM.createPortal(
          <>
            <Dropdown
              trigger={['click']}
              placement="bottom"
              className="dropdown"
              menu={{ items: isMobile ? [] : allowedPeriodsForDropdown }}
            >
              <div
                onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                className="hover:fill-[#40a9ff] text-neutral fill-[#545576]"
              >
                <Button className="w-full text-neutral-700 text-left flex items-center justify-between border border-light rounded hover:border-primary hover:text-primary">
                  <p className="truncate text-sm mb-0 flex-grow">
                    {
                      allowedPeriodsForDropdown.find(
                        p => p.key === currentPeriod,
                      )?.label
                    }
                  </p>
                  <NextArrow className="w-3 rotate-90 fill-current" />
                </Button>
              </div>
            </Dropdown>
            {isMobile && (
              <NestedDrawer
                menuItems={allowedPeriodsForDropdown}
                visible={isNestedDrawerVisible}
                onClose={() => setIsNestedDrawerVisible(false)}
                title="Select"
              />
            )}
          </>,
          dropdownPortal,
        )}
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          if (key === 'upsideDownsideSortKey') {
            //this swaps the sort key between upside and downside on each sort
            const type: keyof Pick<
              FundRelativeRiskMeasuresDetail,
              'captureRatioTypeOne' | 'captureRatioTypeTwo'
            > =
              direction === PageQueryParametersSortDirectionEnum.Asc
                ? 'captureRatioTypeTwo'
                : 'captureRatioTypeOne';
            setSortKey(
              `relativeRiskMeasuresDetail.${type}.${BenchmarkType.MORNINGSTAR_CATEGORY}.${timePeriod}`,
            );
          } else {
            setSortKey(key);
          }
          setSortDirection(direction);
          setPage(1); // Resets the infinite scroll page
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          if (!isFundRow) return {};
          const isFeaturedRow = currentBucket?.funds.find(
            f => rowData._id === f?.isin,
          )?.isFeatured;
          return {
            backgroundColor: switchTableRowColor(!!isFeaturedRow, !isFundRow),
            cursor: 'pointer',
          };
        }}
      />
      {checkedRows.length > 0 && (
        <TablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistRiskTable;
