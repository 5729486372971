import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { Button } from 'antd';
import BasePageWithMetadata from '../BasePageWithMetadata';

const OrderWatchlist = () => {
  return (
    <BasePageWithMetadata title="Order Watchlist">
      <SectionBackButton previousLabel="Back" />
      <div className="w-auto lg:p-4 max-sm:min-h-screen">
        <div className="bg-white lg:rounded-lg p-4 flex justify-center">
          <div className="max-w-[856px]">
            <h3 className="text-xl font-bold text-darkest pb-2 select-none">
              Order Watchlist
            </h3>
            <p className="text-neutral-700 font-normal pb-6 sm:pb-4">
              You can move the order of buckets and folders by dragging and
              placing it on the desired spot, or move multiple buckets and
              folders at once by using ‘Shift’.
            </p>
            <div className="flex flex-col-reverse gap-2 sm:flex-row w-full sm:justify-end pt-4">
              <Button
                size="large"
                type="link"
                key="secondary"
                onClick={() => history.back()}
                className="!text-neutral-700"
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                key="primary"
                className="disabled:bg-neutral-300 bg-primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BasePageWithMetadata>
  );
};

export default OrderWatchlist;
