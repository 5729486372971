import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';

import { SubRouter } from './index';
import { PORTFOLIO_SUB_ROUTES } from './routes';

const PortfolioSubRouter: React.FC<PropsWithChildren> = () => (
  <SubRouter routes={PORTFOLIO_SUB_ROUTES} rootPath={APP_ROUTE_PORTFOLIOS} />
);

export default PortfolioSubRouter;
