import {
  PortfolioPayload,
  PortfolioSettingsPayload,
  PortfolioWeighting,
  PortfoliosApi,
  SharePortfolioRequestBody,
} from '@aminsights/contract';
import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { APP_ACTIONS } from '@/constants';
import {
  UPDATE_FUND_FOR_PORTFOLIO_FAIL,
  UPDATE_FUND_FOR_PORTFOLIO_SUCCESS,
} from '@/constants/text-messages';
import { useAppContext } from '@/context/AppContext';
import { PORTFOLIO_EDGE_DATES } from '@/hooks/query-hooks/portfolio-hooks/query-keys';
import {
  CHARTS,
  CHARTS_GENERATE_CHART_COLORS,
  CHARTS_GENERATE_CHART_COLORS_FOR_PORTFOLIO,
  CHARTS_PORTFOLIO_ABSOLUTE,
  CHARTS_PORTFOLIO_RELATIVE,
  CHARTS_PORTFOLIO_RISK_RETURN,
} from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { openApiConfig } from '@/utils';

import { usePortfolioContext } from './context';
import { PORTFOLIO_ACTIONS, PORTFOLIO_TAB } from './types';

export const useProvidePortfolio = () => {
  const { app, dispatch: dispatchApp } = useAppContext();
  const { state, dispatch } = usePortfolioContext();
  const history = useHistory();
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [disabledAddFund, setDisabledAddFund] = useState(false);
  const queryClient = useQueryClient();

  const portfolioApi = new PortfoliosApi(openApiConfig());

  const invalidatePortfolioChartsAndEdgeDates = () => {
    queryClient.invalidateQueries([CHARTS, CHARTS_PORTFOLIO_ABSOLUTE]);
    queryClient.invalidateQueries([CHARTS, CHARTS_PORTFOLIO_RELATIVE]);
    queryClient.invalidateQueries([CHARTS, CHARTS_PORTFOLIO_RISK_RETURN]);
    queryClient.invalidateQueries([CHARTS, CHARTS_GENERATE_CHART_COLORS]);
    queryClient.invalidateQueries([
      CHARTS,
      CHARTS_GENERATE_CHART_COLORS_FOR_PORTFOLIO,
    ]);
    queryClient.invalidateQueries([PORTFOLIO_EDGE_DATES]);
  };

  const fetchPortfolios = async (page?: number, size?: number) => {
    try {
      if (state.portfolios.length === 0 && !state.arePortfoliosLoading) {
        dispatch({
          type: PORTFOLIO_ACTIONS.SET_IS_LOADING,
        });
      }

      const response = await portfolioApi.getPortfolios(page, size);
      if (response) {
        dispatch({
          type: PORTFOLIO_ACTIONS.SET_PORTFOLIOS,
          payload: response.data?.portfolios || [],
        });
        return response.data?.portfolios || [];
      }
      return [];
    } catch (err) {
      throw new Error(
        `Something went wrong while fetching portfolios: ${JSON.stringify(err)}`,
      );
    }
  };

  const identifyFundForDelete = (isin: string) => {
    dispatch({
      type: PORTFOLIO_ACTIONS.IDENTIFY_FUND_FOR_DELETE,
      payload: isin,
    });
  };

  const unmarkAllFundsForDelete = () => {
    dispatch({
      type: PORTFOLIO_ACTIONS.UNMARK_ALL_FUNDS_FOR_DELETE,
    });
  };

  const markAllFundsForDelete = () => {
    dispatch({
      type: PORTFOLIO_ACTIONS.MARK_ALL_FUNDS_FOR_DELETE,
    });
  };

  const setPortfolio = (portfolioId: string) => {
    dispatch({
      type: PORTFOLIO_ACTIONS.SET_CURRENT_PORTFOLIO,
      payload: portfolioId,
    });
  };

  const addFundToPortfolio = (
    portfolioId: string,
    isin: string,
    fundName: string,
    code: string,
    broadAssetClass: string,
  ) => {
    dispatch({
      type: PORTFOLIO_ACTIONS.SET_IS_PERFORMANCE_TAB_LOADING,
      payload: true,
    });
    portfolioApi.addFundToPortfolio(portfolioId, { isin }).finally(() => {
      invalidatePortfolioChartsAndEdgeDates();
      fetchPortfolios();
    });
    dispatch({
      type: PORTFOLIO_ACTIONS.ADD_FUND_TO_CURRENT_PORTFOLIO,
      payload: {
        isin,
        fundName,
        code,
        broadAssetClass,
      },
    });
  };

  const addFundSearchBoxToCurrentPortfolio = () => {
    dispatch({
      type: PORTFOLIO_ACTIONS.ADD_FUND_SEARCH_BOX_TO_CURRENT_PORTFOLIO,
    });
  };

  const removeFundSearchBoxFromCurrentPortfolio = () => {
    dispatch({
      type: PORTFOLIO_ACTIONS.REMOVE_FUND_SEARCH_BOX_FROM_CURRENT_PORTFOLIO,
    });
  };

  const deleteFundsFromPortfolio = async () => {
    if (!state.currentPortfolioId) {
      return;
    }
    dispatch({
      type: PORTFOLIO_ACTIONS.REMOVE_FUND_FROM_CURRENT_PORTFOLIO,
    });

    await portfolioApi
      .deleteFundsFromPortfolio(
        state.currentPortfolioId,
        state.markedFundsForDelete,
      )
      .catch(() => {
        fetchPortfolios();
      });
  };

  const createNewPortfolio = async (portfolio: PortfolioPayload) => {
    try {
      const response = await portfolioApi.createNewPortfolio(portfolio);
      await fetchPortfolios();
      history.push(
        `/${APP_ROUTE_PORTFOLIOS}/${response.data.portfolioId}/${PORTFOLIO_TAB.HOLDINGS}`,
      );
    } catch (error) {
      throw new Error(
        `Something went wrong while creating a new portfolio: ${JSON.stringify(error)}`,
      );
    }
  };

  const updatePortfolioSettings = async (
    portfolioId: string,
    settings: PortfolioSettingsPayload,
  ) => {
    try {
      await portfolioApi.updatePortfolioSettings(portfolioId, settings);
    } finally {
      invalidatePortfolioChartsAndEdgeDates();
      await fetchPortfolios();
    }
  };

  const sharePortfolio = async (
    sharePortfolioBody: SharePortfolioRequestBody,
  ) => {
    try {
      await portfolioApi.sharePortfolio(sharePortfolioBody);
    } finally {
      await fetchPortfolios();
    }
  };

  // @deprecated - please refactor to react-query mutation and implement error catching with `setErrorMessage`
  const importPortfolio = async (portfolio: PortfolioPayload) => {
    const response = await portfolioApi.importPortfolio(portfolio);
    await fetchPortfolios();
    history.push(
      `/${APP_ROUTE_PORTFOLIOS}/${response.data.portfolioId}/${PORTFOLIO_TAB.HOLDINGS}`,
    );
  };

  const updateWeightingForFundInPortfolio = (
    portfolioId: string,
    isin: string,
    portfolioWeighting: PortfolioWeighting,
  ) => {
    portfolioApi
      .updateWeightingForFundInPortfolio(isin, portfolioId, portfolioWeighting)
      .then((res: { data: { warningMessage: string } }) => {
        if (!!res.data.warningMessage) {
          //dispatchApp is needed twice, otherwise the error message could already be set for 10 seconds, while we are getting another message,
          //since it will not be fired the second time, with a new message, no matter if the content is different
          if (app.messageState?.message) {
            dispatchApp({
              type: APP_ACTIONS.UNSET_MESSAGE,
            });
          }
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: res.data.warningMessage,
          });
        } else {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: UPDATE_FUND_FOR_PORTFOLIO_SUCCESS },
          });
        }
      })
      .catch((err: any) => {
        fetchPortfolios();
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload:
            err?.message?.toString() ?? err ?? UPDATE_FUND_FOR_PORTFOLIO_FAIL,
        });
      })
      .finally(() => {
        invalidatePortfolioChartsAndEdgeDates();
      });

    dispatch({
      type: PORTFOLIO_ACTIONS.UPDATE_WEIGHTING,
      payload: { isin, portfolioWeighting },
    });
  };

  const addDate = () => {
    if (state.currentPortfolioId) {
      dispatch({
        type: PORTFOLIO_ACTIONS.ADD_DATE,
      });
    }
  };

  const cleanEmptyDates = () => {
    if (state.currentPortfolioId) {
      dispatch({
        type: PORTFOLIO_ACTIONS.CLEAN_EMPTY_DATES,
      });
    }
  };

  const updateDateInPortfolioFund = async (
    portfolioId: string,
    oldDate: string,
    newDate: string,
  ) => {
    portfolioApi
      .updateDateForPortfolio(portfolioId, {
        oldDate,
        newDate,
      })
      .catch(fetchPortfolios)
      .finally(() => {
        invalidatePortfolioChartsAndEdgeDates();
      });

    dispatch({
      type: PORTFOLIO_ACTIONS.UPDATE_DATE,
      payload: { oldDate, newDate },
    });
  };

  const deleteDateFromPortfolio = async (portfolioId: string, date: string) => {
    portfolioApi
      .deleteDateFromPortfolio(portfolioId, { date })
      .finally(() => {
        invalidatePortfolioChartsAndEdgeDates();
      })
      .catch(fetchPortfolios);

    dispatch({
      type: PORTFOLIO_ACTIONS.DELETE_DATE,
      payload: { date },
    });
  };

  const deletePortfolio = async (portfolioIdToRemoved: string) => {
    portfolioApi
      .deletePortfolio(portfolioIdToRemoved)
      .finally(() => fetchPortfolios());
    dispatch({
      type: PORTFOLIO_ACTIONS.DELETE_PORTFOLIO,
      payload: { portfolioIdToRemoved },
    });

    if (state.portfolios && state.portfolios.length > 1) {
      const indexToRemove = state.portfolios.findIndex(
        portfolio => portfolio._id === portfolioIdToRemoved,
      );

      const portfolio =
        state.portfolios[indexToRemove !== 0 ? indexToRemove - 1 : 1];
      setPortfolio(portfolio._id);
      history.push(
        `/${APP_ROUTE_PORTFOLIOS}/${portfolio._id}/${PORTFOLIO_TAB.HOLDINGS}`,
      );
    } else {
      history.push(`/${APP_ROUTE_PORTFOLIOS}`);
    }
  };

  const revokePortfolioAccess = async (portfolioId: string) => {
    portfolioApi
      .revokePortfolioAccess(portfolioId)
      .finally(() => fetchPortfolios());
    dispatch({
      type: PORTFOLIO_ACTIONS.DELETE_PORTFOLIO,
      payload: { portfolioIdToRemoved: portfolioId },
    });

    if (state.portfolios && state.portfolios.length > 1) {
      const indexToRemove = state.portfolios.findIndex(
        portfolio => portfolio._id === portfolioId,
      );
      const portfolio =
        state.portfolios[indexToRemove !== 0 ? indexToRemove - 1 : 1];

      setPortfolio(portfolio._id);

      history.push(
        `/${APP_ROUTE_PORTFOLIOS}/${portfolio._id}/${PORTFOLIO_TAB.HOLDINGS}`,
      );
    } else {
      history.push(`/${APP_ROUTE_PORTFOLIOS}`);
    }
  };

  const acceptPortfolioInvite = async (token: string) => {
    return portfolioApi.acceptSharedPortfolioInvite(token);
  };

  const declinePortfolioInvite = async (token: string) => {
    return portfolioApi.declineSharedPortfolioInvite(token);
  };

  return {
    state,
    fetchPortfolios,
    setPortfolio,
    addFundToPortfolio,
    addFundSearchBoxToCurrentPortfolio,
    removeFundSearchBoxFromCurrentPortfolio,
    importPortfolio,
    deleteFundsFromPortfolio,
    createNewPortfolio,
    updateWeightingForFundInPortfolio,
    addDate,
    cleanEmptyDates,
    updateDateInPortfolioFund,
    deleteDateFromPortfolio,
    identifyFundForDelete,
    unmarkAllFundsForDelete,
    markAllFundsForDelete,
    deletePortfolio,
    updatePortfolioSettings,
    sharePortfolio,
    acceptPortfolioInvite,
    declinePortfolioInvite,
    isLimitReachModalOpen,
    setIsLimitReachModalOpen,
    disabledAddFund,
    setDisabledAddFund,
    revokePortfolioAccess,
  };
};
