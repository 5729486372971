export enum NotificationType {
  SignificantChange = 'SignificantChange',
  BucketShared = 'BucketShared',
  PortfolioShared = 'PortfolioShared',
  CommentAdded = 'CommentAdded',
  WeeklyUpdate = 'WeeklyUpdate',
  NotePublished = 'NotePublished',
  NoteStale = 'NoteStale',
  MeetingGap = 'MeetingGap',
}
