import { ReactComponent as CloseIcon } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import { Input } from 'antd';

const CommentarySearchFilter = ({
  searchInputValue,
  onSearchChange,
  onSearch,
  setSearchInputValue,
}: {
  searchInputValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (value: string) => void;
  setSearchInputValue: (value: string) => void;
}) => {
  return (
    <Input
      placeholder="Search keyword (i.e. growth)"
      className={`w-full lg:max-w-[440px] md:max-w-[320px] h-10 rounded border-light
              [&_.ant-input]:placeholder:text-neutral-100 
              [&_.ant-input]:placeholder:text-sm 
              [&_.ant-input]:placeholder:font-normal
              [&.ant-input-affix-wrapper-focused]:border-primary
              [&.ant-input-affix-wrapper-focused]:border-2
              [&.ant-input-affix-wrapper]:transition-colors`}
      value={searchInputValue}
      onChange={onSearchChange}
      onPressEnter={e => onSearch(e.currentTarget.value)}
      prefix={<IconSearch className="icon text-neutral-100 ml-2 mr-1" />}
      suffix={
        searchInputValue && (
          <CloseIcon
            className="icon text-neutral ml-2 mr-1 !w-2 !h-2"
            onClick={() => {
              setSearchInputValue('');
              onSearch('');
            }}
          />
        )
      }
    />
  );
};

export default CommentarySearchFilter;
