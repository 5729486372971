import { Skeleton } from 'antd';
import React from 'react';

const SkeletonFundPerformance: React.FC = () => {
  return (
    <div className="pb-6 mb-6 bg-white rounded-lg">
      <div className="p-4 flex flex-row">
        <div className="flex">
          <Skeleton.Button
            active
            size="small"
            style={{ width: '96px', height: '13px' }}
          />
        </div>
      </div>
      <div className="flex flex-col mx-4 pt-2 gap-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: used index as key
            key={index}
          >
            <div className="flex h-8 flex-row items-center w-full gap-2">
              <div>
                <Skeleton.Avatar
                  className="flex"
                  active
                  size="default"
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <Skeleton.Button
                  className="flex"
                  active
                  size="large"
                  style={{ width: '218px', height: '13px' }}
                />
                <Skeleton.Button
                  className="flex"
                  active
                  size="large"
                  style={{ width: '90px', height: '13px' }}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="flex flex-col justify-end items-center gap-1 pt-4">
          <Skeleton.Button
            className="flex"
            active
            size="large"
            style={{ width: '96px', height: '13px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonFundPerformance;
