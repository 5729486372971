import { ADVISOR_ALLOWED_SECTORS } from '@aminsights/shared';
import cx from 'classnames';

import AbsoluteSectorsChart from '@/partials/Charts/absolute/AbsoluteSectorsChart';
import RangeDatePicker, { useDatePickerContext } from '@/partials/DatePicker';

import Loader from '../FundAndInvestmentTrust/components/Loader';

const SectorsPerformanceChart = () => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();

  return (
    <div className="w-full bg-white md:rounded-lg p-4 pb-8">
      <div
        className={cx(
          'grid grid-cols-[1fr_auto_auto] gap-y-2 items-center sm:justify-between',
          'sm:grid-cols-[1fr_auto_auto]',
          '[&_.dropdown]:pt-4 [&_.dropdown]:w-full md:[&_.dropdown]:w-auto md:[&_.dropdown]:pt-0',
        )}
      >
        <h5
          data-test-id="absoluteChartLabel"
          className="text-sm w-full font-bold text-darkest col-span-3 sm:col-span-1"
        >
          <Loader width="150px" component={<>Sector Performance</>} />
        </h5>
        <div
          className="relative-date-picker-wrapper"
          onClick={e => e.stopPropagation()}
        >
          <RangeDatePicker
            onChange={handleDatePickerChange}
            value={datePickerValue}
            btnClassName="justify-self-end w-full"
            dataTestId="absoluteSectorsChartDropdown"
          />
        </div>
      </div>
      <AbsoluteSectorsChart sectors={ADVISOR_ALLOWED_SECTORS} />
    </div>
  );
};

export default SectorsPerformanceChart;
