import { SignificantChangesItem } from '@aminsights/contract';
import {
  ChangeTypeEnum,
  DEFAULT_CURRENCY_CODE,
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  FundType,
  buildFundDetailsPath,
  formatTotalChange,
  getChangeTypeLabel,
  getFundType,
} from '@aminsights/shared';
import { Alert, List } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useGetSignificantChangeForModal from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangeForModal';
import useGetSignificantChangePerformance from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangePerformance';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import CustomPremiumDiscountProgressBar from '@/partials/PremiumDiscountProgressBar';

import ModalSectionItem from './ModalSectionItem';
import { TwoColumnTableSection } from './ModalSectionItem/TwoColumnTableSection';
import {
  PerSectionModalData,
  PerSectionModalItems,
} from './PerSectionModalData';
import style from './style.module.less';

type ModalSignificantChangesProps = {
  data: SignificantChangesItem;
  toggleModal: () => void;
};

const SignificantChangesContentModal: React.FC<
  PropsWithChildren<ModalSignificantChangesProps>
> = ({ data }) => {
  const [sectionInfo, setSectionInfo] = useState<PerSectionModalItems>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: performanceData, isLoading: isPerformanceDataLoading } =
    useGetSignificantChangePerformance(data.isin, [data.updatedDate ?? '']);
  const { data: legalStructureData, isLoading: isModalDataLoading } =
    useGetSignificantChangeForModal(data._id);
  const isInvestmentTrust = getFundType(
    legalStructureData?.fundDetails?.legalStructure ?? '',
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (data?.isin && data.changeType && data.updatedDate) {
      setIsLoading(isPerformanceDataLoading || isModalDataLoading);
      setSectionInfo(
        PerSectionModalData(
          data,
          {
            OneMonthPct: performanceData?.oneMonthPerformanceChange || 0,
            YtdPct: performanceData?.YTDPerformanceChange || 0,
          },
          isInvestmentTrust === FundType.INVESTMENT_TRUST,
        ),
      );
    }
  }, [data, performanceData, legalStructureData, isInvestmentTrust]);

  const calculateTrendColorClass = (value: number | string | string[]) => {
    if (value === EMPTY_DATA_POINT) {
      return undefined;
    }
    const parsedValue = Number(value);
    return typeof parsedValue === 'number' && parsedValue > 0
      ? 'text-success-trend'
      : 'text-danger-trend';
  };

  return (
    <div className={style['significant-changes-modal-container']}>
      <Alert
        message={`This fund has seen a change in ${getChangeTypeLabel(
          data?.changeType,
        )}`}
        type="info"
        showIcon
      />
      <div className="py-6 border-b">
        {sectionInfo?.fundDetailsSection.map(item => {
          return (
            <div key={item.key} className="flex pb-3">
              <Loader
                width="100px"
                loading={isLoading}
                component={
                  <div
                    className={cx(
                      style['significant-changes-modal-container__label'],
                      'w-1/4',
                    )}
                  >
                    {item.label}
                  </div>
                }
              />
              <Loader
                width="200px"
                loading={isLoading}
                component={
                  <div
                    className={cx(
                      item.key === 'FundOrTrustColumnId'
                        ? style[
                            'significant-changes-modal-container__value-color'
                          ]
                        : '',
                      'w-full pr-8',
                    )}
                  >
                    {(() => {
                      switch (item.key) {
                        case 'FundOrTrustColumnId':
                          return (
                            <Link to={buildFundDetailsPath(data.isin)}>
                              {item.value}
                            </Link>
                          );
                        case 'DateColumnId':
                          return (
                            item.value &&
                            typeof item.value === 'string' &&
                            dayjs(item.value).isValid() &&
                            dayjs(item.value).format(DISPLAY_DATE_FORMAT)
                          );
                        default:
                          return item.value;
                      }
                    })()}
                  </div>
                }
              />
            </div>
          );
        })}
      </div>
      <div className="py-5">
        <div className="pb-2 text-sm font-semibold text-darkest">
          {getChangeTypeLabel(data?.changeType || '')}
        </div>
        {data?.changeType === ChangeTypeEnum.StyleDrift && (
          <TwoColumnTableSection
            sectionInfo={sectionInfo?.changeTypeStyleDriftSection}
            isLoading={isLoading}
          />
        )}
        {data?.changeType === ChangeTypeEnum.ManagerChange && (
          <TwoColumnTableSection
            sectionInfo={sectionInfo?.changeTypeManagerChangeSection}
            isLoading={isLoading}
          />
        )}
        {data?.changeType === ChangeTypeEnum.Flow && (
          <div className={style['sc-change-type-section-vertical-flex']}>
            <div className={style['sc-change-type-section-flow']}>
              <ModalSectionItem
                loading={isLoading}
                // TODO: WTF is this, why are we having this tight dependency both ways :facepalming:
                // We currently have this bizzarre declarative way of implementing UI on the modal, in attempt, I guess
                // to write a little bit less JSX and "reuse", but instead we are paying price in insane declarative complexity
                // and if circular dependencies. Ideally we should completelly get rid of PerSectionModalItems and use good old
                // composition with conditional rendering while properly breaking down components. One day.
                sectionId={2}
                title="Est. %"
                content={(data.pctChangeAbsolute?.toFixed(1) || 0).toString()}
                trend={data.trend}
                changeType={data?.changeType ?? ''}
                broadAssetClass={data?.broadAssetClass ?? ''}
                currencyCode={DEFAULT_CURRENCY_CODE}
              />
              <ModalSectionItem
                loading={isLoading}
                sectionId={1}
                title="Est. £"
                content={(data.newValue || 0).toString()}
                trend={data.trend}
                changeType={ChangeTypeEnum.Flow}
                broadAssetClass={data?.broadAssetClass ?? ''}
                currencyCode={DEFAULT_CURRENCY_CODE}
                formatFlow={true}
              />
            </div>
            {dayjs(data.updatedDate).isValid() &&
              dayjs(data.previousDate).isValid() && (
                <h5 className="text-xs w-auto font-regular text-neutral self-center whitespace-pre-wrap">
                  {dayjs(data.previousDate).isValid() &&
                    dayjs(data.previousDate).format(DISPLAY_DATE_FORMAT)}
                  {'   -   '}
                  {dayjs(data.updatedDate).isValid() &&
                    dayjs(data.updatedDate).format(DISPLAY_DATE_FORMAT)}
                </h5>
              )}
          </div>
        )}
        {[
          ChangeTypeEnum.FundSize,
          ChangeTypeEnum.ModifiedDuration,
          ChangeTypeEnum.Holdings,
          ChangeTypeEnum.Ocf,
          ChangeTypeEnum.PremiumDiscount,
          ChangeTypeEnum.Yield,
        ].includes(data?.changeType as ChangeTypeEnum) && (
          <div
            className={
              style[
                data?.changeType === ChangeTypeEnum.PremiumDiscount
                  ? 'sc-change-type-section-premium-discount'
                  : 'sc-change-type-section'
              ]
            }
          >
            <ModalSectionItem
              loading={isLoading}
              // TODO: WTF is this, why are we having this tight dependency both ways :facepalming:
              // We currently have this bizzarre declarative way of implementing UI on the modal, in attempt, I guess
              // to write a little bit less JSX and "reuse", but instead we are paying price in insane declarative complexity
              // and if circular dependencies. Ideally we should completelly get rid of PerSectionModalItems and use good old
              // composition with conditional rendering while properly breaking down components. One day.
              sectionId={0}
              title="Latest"
              content={(data.newValue || 0).toString()}
              dateData={data.updatedDate ?? ''}
              trend={data.trend}
              changeType={data?.changeType ?? ''}
              broadAssetClass={data?.broadAssetClass ?? ''}
              currencyCode={DEFAULT_CURRENCY_CODE}
            />
            <ModalSectionItem
              loading={isLoading}
              sectionId={1}
              title="Previous"
              content={(data.oldValue || 0).toString()}
              dateData={
                data.previousDate
                  ? dayjs(data.previousDate).format(DISPLAY_DATE_FORMAT)
                  : EMPTY_DATA_POINT
              }
              trend={data.trend}
              changeType={data?.changeType ?? ''}
              broadAssetClass={data?.broadAssetClass ?? ''}
              currencyCode={DEFAULT_CURRENCY_CODE}
            />
            {data.changeType !== ChangeTypeEnum.StyleDrift && (
              <ModalSectionItem
                loading={isLoading}
                sectionId={2}
                title="Total Change"
                content={`${formatTotalChange(data)}`}
                dateData={data.updatedDate ?? ''}
                trend={data.trend}
                changeType={data?.changeType ?? ''}
                broadAssetClass={data?.broadAssetClass ?? ''}
                currencyCode={DEFAULT_CURRENCY_CODE}
              />
            )}
            {data?.changeType === ChangeTypeEnum.PremiumDiscount && (
              <div className={style['sc-change-type-section-premium-disc-bar']}>
                <CustomPremiumDiscountProgressBar isin={data.isin} />
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="pb-2 text-sm font-semibold text-darkest">
          Performance (to {dayjs(data.updatedDate)?.format('DD MMMM')})
        </div>
        <div className="border rounded">
          <Loader
            loading={isLoading}
            component={
              <List
                dataSource={sectionInfo?.performanceSection}
                renderItem={item => (
                  <List.Item key={item.key} className="flex p-3">
                    <div
                      className={cx(
                        style['significant-changes-modal-container__label'],
                        'w-1/2',
                      )}
                    >
                      {item.label}
                    </div>
                    <div className={cx(calculateTrendColorClass(item.value))}>
                      {typeof item.value === 'number' && item.value > 0
                        ? '+'
                        : ''}
                      {typeof item.value === 'string' && Number(item.value) > 0
                        ? '+'
                        : ''}
                      {item.value}
                      {item.value === EMPTY_DATA_POINT ? '' : '%'}
                    </div>
                  </List.Item>
                )}
              />
            }
          />
        </div>
      </div>
      {/* TODO: Uncomment when we decide to show sc history  */}
      {/* <div className="px-8 pb-6">
        <div className="pb-2 text-sm font-semibold text-darkest">
          {historyTitle(data)}
        </div>
        <div className="border rounded">
          <Loader
            loading={isLoading}
            component={
              data?.changeType === ChangeTypeEnum.ManagerChange ? (
                <List
                  dataSource={sectionInfo?.historyManagerChangeSection}
                  renderItem={item => (
                    <List.Item key={item.key} className="flex p-3">
                      <Loader
                        loading={isLoading}
                        component={
                          <div
                            className={cx(
                              style[
                                'significant-changes-modal-container__label'
                              ],
                              'w-1/2',
                            )}
                          >
                            {item.label}
                          </div>
                        }
                      />
                      <Loader
                        loading={isLoading}
                        component={<div>{item.value}</div>}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                significantChangeHistory.length > 0 && (
                  <List
                    dataSource={significantChangeHistory.slice(0, 3)}
                    renderItem={significantChangeHistoryItem => (
                      <List.Item
                        key={significantChangeHistoryItem._id}
                        className="flex p-3"
                      >
                        <Loader
                          loading={isLoading}
                          component={
                            <div
                              className={cx(
                                style[
                                  'significant-changes-modal-container__label'
                                ],
                                'w-1/2',
                              )}
                            >
                              {dayjs(
                                significantChangeHistoryItem.updatedDate,
                              ).format(DISPLAY_DATE_FORMAT)}
                            </div>
                          }
                        />
                        <Loader
                          loading={isLoading}
                          component={
                            <div>
                              {formatChangeType(
                                significantChangeHistoryItem.changeType,
                                significantChangeHistoryItem.newValue ?? 0,
                                significantChangeHistoryItem.currencyCode ??
                                  DEFAULT_CURRENCY_CODE,
                              )}
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                )
              )
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default SignificantChangesContentModal;
