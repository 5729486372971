import { FundBroadAssetClass, Stylebox } from '@aminsights/contract';
import {
  BoxLabels,
  BroadAssetClassColor,
  buildFundDetailsPath,
  getLongHumanReadableStyleboxName,
  getShortHumanReadableStyleboxName,
} from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useScreenWidth, { EScreenSize } from '@/hooks/screenWidth';

interface IStyleBoxWrapperProps {
  styleBoxData: Stylebox[];
  fundTypeTitle: FundBroadAssetClass;
  fundBoxBGcolor: { [key: number]: string };
  XandYLabels: BoxLabels;
  boxBackgroundColor: BroadAssetClassColor;
}

const StyleBoxWrapper: React.FC<PropsWithChildren<IStyleBoxWrapperProps>> = ({
  fundTypeTitle,
  styleBoxData,
  fundBoxBGcolor,
  XandYLabels,
  boxBackgroundColor,
}) => {
  const STYLE_BOX_COUNT = 9;
  const { isCurrentWidth } = useScreenWidth();
  const isMobile =
    isCurrentWidth(EScreenSize.xs) || isCurrentWidth(EScreenSize.sm);
  const [hoverIndex, setHoverIndex] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [fundDisplayList, setFundDisplayList] = useState<Stylebox[]>([]);
  const [fundListModal, setFundListModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFundBox, setSelectedFundBox] = useState<Stylebox[]>([]);
  const currentWatchlist = useCurrentWatchlist();
  const currentBucketId = useCurrentBucketId();
  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  const filterFundByIndex = (index: number, fund: Stylebox[]): Stylebox[] => {
    const filterCondition = (item: Stylebox) =>
      fundTypeTitle === FundBroadAssetClass.Equity
        ? item.equityStyleBox === index
        : item.bondStatistics?.styleBox === index;

    return fund.filter(filterCondition);
  };

  const countStyleBoxByIndexValue = (index: number): number => {
    const filterCondition = (item: Stylebox) =>
      fundTypeTitle === FundBroadAssetClass.Equity
        ? item.equityStyleBox === index
        : item.bondStatistics?.styleBox === index;

    return styleBoxData.filter(filterCondition).length;
  };

  const onClickSetFundListDisplay = (index: number): void => {
    const filterFundBox = filterFundByIndex(index, styleBoxData);
    setSelectedFundBox(filterFundBox);
    setSelectedIndex(index);
    setIsModalOpen(isMobile);
  };

  const showFundList = (index: number): void => {
    const filteredData = filterFundByIndex(index, styleBoxData);
    if (filteredData.length > 0) {
      setHoverIndex(index);
    }
    setFundListModal(true);
    setFundDisplayList(filteredData);
  };

  const boxBGcolor = (index: number): number => {
    return countStyleBoxByIndexValue(index) % 2 === 0
      ? countStyleBoxByIndexValue(index)
      : countStyleBoxByIndexValue(index) - 1;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setSelectedFundBox([]);
    setSelectedIndex(0);
    setIsModalOpen(false);
  }, [currentBucketId]);

  const renderBox = (): JSX.Element[] => {
    const boxes = [];
    for (let i = 0; i < STYLE_BOX_COUNT; i += 3) {
      boxes.push(
        <div
          className={cx(
            'flex border-l h-1/3 w-full',
            fundTypeTitle === FundBroadAssetClass.Equity
              ? 'border-l-[#99CCFF]'
              : 'border-l-[#97D17D]',
          )}
          key={i}
        >
          {[i + 1, i + 2, i + 3].map(index => (
            <div
              key={index}
              className={cx(
                'w-full h-full flex justify-center items-center p-0.5',
                fundTypeTitle === FundBroadAssetClass.Equity
                  ? 'border-r border-b border-[#99CCFF]'
                  : 'border-r border-b border-[#97D17D]',
              )}
              style={{
                backgroundColor: boxBackgroundColor,
              }}
            >
              <div
                className={cx(
                  'grid w-full h-full place-items-center',
                  countStyleBoxByIndexValue(index) !== 0 && 'cursor-pointer',
                  fundTypeTitle === FundBroadAssetClass.Equity &&
                    'hover:cursor-pointer hover:border-4 hover:border-[#003e7d50]',
                  fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                    'hover:cursor-pointer hover:border-4 hover:border-[#00520050]',
                  selectedIndex === index &&
                    fundTypeTitle === FundBroadAssetClass.Equity &&
                    'cursor-pointer border-4 border-[#003e7d50]',
                  selectedIndex === index &&
                    fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                    'cursor-pointer border-4 border-[#00520050]',
                  countStyleBoxByIndexValue(index) === 0 &&
                    'pointer-events-none',
                  'text-white',
                )}
                style={{
                  backgroundColor:
                    countStyleBoxByIndexValue(index) === 0
                      ? 'unset'
                      : fundBoxBGcolor[boxBGcolor(index)],
                }}
                onClick={() => onClickSetFundListDisplay(index)}
                onMouseOver={() => {
                  showFundList(index);
                }}
                onMouseOut={() => {
                  setFundDisplayList([]);
                  setHoverIndex(selectedIndex);
                }}
              >
                {countStyleBoxByIndexValue(index) === 0
                  ? ''
                  : countStyleBoxByIndexValue(index)}
              </div>
            </div>
          ))}
        </div>,
      );
    }
    return boxes;
  };

  const XorYLabel = (labelData: string[], axis: string): JSX.Element[] => {
    const labelClassName =
      axis === 'y'
        ? 'font-normal text-xs text-neutral relative h-[6em] pl-2 sm:h-[7em]'
        : 'font-normal text-xs text-neutral w-[6em] sm:w-[6.9em] text-start pt-2 sm:pt-0 pb-2';

    const styles: React.CSSProperties =
      axis === 'y'
        ? {
            writingMode: 'vertical-rl',
            textOrientation: 'sideways',
          }
        : {};
    return labelData.map(value => (
      <div key={value} className={labelClassName} style={styles}>
        {value}
      </div>
    ));
  };
  const bucket = useMemo(() => {
    return currentWatchlist.data?.buckets.find(
      bucket => bucket.id === currentBucketId,
    );
  }, [currentWatchlist.data, currentBucketId]);

  const isFeatured = (isin: string): boolean => {
    return bucket
      ? bucket.funds.some(fund => fund.isin === isin && fund.isFeatured)
      : false;
  };

  const FundGroupList = (selectedFundBox: Stylebox[]): JSX.Element => {
    return (
      <div className="mb-2">
        <div className="mt-14 border border-light rounded-lg py-3 px-4">
          <div className="mb-2 text-darkest font-semibold text-xs">
            {fundTypeTitle === FundBroadAssetClass.Equity
              ? getShortHumanReadableStyleboxName(hoverIndex, XandYLabels)
              : getLongHumanReadableStyleboxName(hoverIndex, XandYLabels)}
          </div>
          {selectedFundBox?.map((_item: Stylebox) => (
            <p
              className={cx(
                'text-xs leading-5 py-1 px-2 font-medium text-neutral-700',
                isFeatured(_item._id) &&
                  'bg-[#e6f9f6] flex items-center gap-2 self-stretch',
              )}
              key={_item._id}
            >
              <Link to={buildFundDetailsPath(_item._id)}>{_item.fundName}</Link>
            </p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {fundListModal && (
        <Modal
          title={
            fundTypeTitle === FundBroadAssetClass.Equity ? (
              <div className="text-darkest pb-2">
                {getShortHumanReadableStyleboxName(hoverIndex, XandYLabels)}{' '}
                <span className="text-neutral font-normal">
                  ({selectedFundBox.length})
                </span>
              </div>
            ) : (
              <div className="text-darkest pb-2">
                {getLongHumanReadableStyleboxName(hoverIndex, XandYLabels)}{' '}
                <span className="text-neutral font-normal">
                  ({selectedFundBox.length})
                </span>
              </div>
            )
          }
          className="max-sm:confirmation-modal action-modal [&_.ant-modal-body]:pb-10"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          centered={true}
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {selectedFundBox?.map((_item: Stylebox) => (
              <p
                className={cx(
                  'leading-5 py-1 px-2 font-normal text-neutral',
                  isFeatured(_item._id) &&
                    'bg-[#e6f9f6] text-neutral-450 flex items-center gap-2 self-stretch',
                )}
                key={_item._id}
              >
                <Link to={buildFundDetailsPath(_item._id)}>
                  {_item.fundName}
                </Link>
              </p>
            ))}
          </div>
        </Modal>
      )}
      <div className="px-5 py-5 bg-white rounded-lg min-w-min flex gap-4">
        <div>
          <div className="font-bold text-sm text-darkest">
            Morningstar {fundTypeTitle} Style Box
          </div>
          <div className="flex pt-2 sm:pt-10 sm:pl-5">
            <div
              className={cx(
                'border-t w-[216px] sm:w-[243px] h-[216px] sm:h-[243px]',
                fundTypeTitle === FundBroadAssetClass.Equity
                  ? 'border-t-[#99CCFF]'
                  : 'border-t-[#97D17D]',
              )}
            >
              {renderBox()}
            </div>
            <div className="grid grid-row-3 w-6">
              {XorYLabel(XandYLabels.yName, 'y')}
            </div>
            <div
              style={{ writingMode: 'vertical-lr' }}
              className="flex flex-col items-center justify-between text-xs leading-5 pl-1 text-neutral font-normal"
            >
              {XandYLabels.outerX}
            </div>
          </div>
          <div className="pl-5 flex items-center">
            {XorYLabel(XandYLabels.xName, 'x')}
          </div>
          <div className="flex flex-col items-center justify-between text-xs leading-5 pl-1 text-neutral font-normal pr-3">
            {XandYLabels.outerY}
          </div>
        </div>
        {!isMobile && (
          <div className="w-[400px]">
            {fundDisplayList?.length > 0 && FundGroupList(fundDisplayList)}
            {selectedFundBox.length > 0 &&
              fundDisplayList.length === 0 &&
              FundGroupList(selectedFundBox)}
          </div>
        )}
      </div>
    </>
  );
};
export default StyleBoxWrapper;
