import { Material } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Divider, Tooltip } from 'antd';
import { FC, useContext, useState } from 'react';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import useUserAccess from '@/hooks/useUserAccess';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

interface MaterialListItemProps {
  material: Material;
  onEditMaterial: (material: Material) => void;
  onDeleteMaterial: (material: Material) => void;
}

const MaterialListItem: FC<MaterialListItemProps> = ({
  material,
  onEditMaterial,
  onDeleteMaterial,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const { hasPermissions } = useUserAccess();

  const handleToggleDeleteModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleOpenEditModal = () => {
    onEditMaterial(material);
    setIsTooltipOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsTooltipOpen(false);
    setIsDeleteModalOpen(true);
  };

  const hasMaterialsAdminPermissions = hasPermissions([
    USER_PERMISSIONS.materials.admin,
  ]);

  const hasMaterialsWritePermissions = hasPermissions([
    USER_PERMISSIONS.materials.write,
  ]);

  const showWriteActions =
    hasMaterialsAdminPermissions ||
    (hasMaterialsWritePermissions && material.createdBy === currentUser?.sub);

  return (
    <div className="relative">
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete link?',
          description: 'This action cannot be undone.',
          primaryActionLabel: 'Delete',
          succesMessage: 'Successfully deleted a link',
          errorMessage: 'Unable to delete link',
        }}
        isVisible={isDeleteModalOpen}
        toggleModal={handleToggleDeleteModal}
        onConfirm={async () => {
          await onDeleteMaterial(material);
        }}
      />

      <Tooltip
        title={
          <div className="flex items-center w-full gap-x-2 py-1 px-2">
            <a
              className="text-sm hover:underline hover:text-white truncate my-1.5 flex-1"
              href={material.link}
              target="_blank"
              rel="noreferrer"
            >
              {material.link}
            </a>
            {showWriteActions && (
              <div className="flex items-center">
                <Divider type="vertical" className="border-white h-5" />
                <Button
                  type="text"
                  className="text-white px-1 md:px-2 hover:text-white"
                  onClick={handleOpenEditModal}
                >
                  Edit
                </Button>
                <Button
                  type="text"
                  className="text-white px-1 md:px-2 hover:text-white"
                  onClick={handleOpenDeleteModal}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        }
        open={isTooltipOpen}
        trigger="click"
        placement="bottomLeft"
        color="#313341"
        arrow={false}
        overlayClassName="max-w-none w-[calc(100vw-60px)] sm:w-116"
        getPopupContainer={triggerNode =>
          triggerNode.parentElement as HTMLElement
        }
        onOpenChange={visible => {
          if (visible !== isTooltipOpen) setIsTooltipOpen(visible);
        }}
      >
        <Button
          type="link"
          className="p-0 text-sm text-primary hover:text-primary-400 font-normal border-none shadow-none underline text-left break-all whitespace-break-spaces h-auto"
          onClick={() => setIsTooltipOpen(true)}
        >
          {material.text}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MaterialListItem;
