import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

interface BaseProps {
  title: string;
}

const BasePageWithMetadata: React.FC<PropsWithChildren<BaseProps>> = ({
  title,
  children,
}) => {
  return (
    <>
      <Helmet defer={false}>
        <title>{`${title} - AM Insights`}</title>
      </Helmet>
      {children}
    </>
  );
};

export default BasePageWithMetadata;
