import { AreaConfig } from '@ant-design/charts';
import { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetPortfolioAbsoluteChartData from '@/hooks/query-hooks/chart-hooks/useGetPortfolioAbsoluteChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import RangeDatePicker, {
  parseRangeValues,
  useDatePickerContext,
} from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import {
  getLegendCardClasses,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { PortfolioChartsProps } from '../components/BaseChart';
import { PerformanceCardDetailsWithPortfolio } from '../utils/chart-data';
import chartStyles from '../utils/chart.module.less';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';
import { useChartColorsForPortfolio } from '../utils/useChartColors';

const PortfolioAbsoluteChart = ({
  portfolioId,
  emptyState,
}: PortfolioChartsProps) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<
    PerformanceCardDetailsWithPortfolio[]
  >([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading } = useGetPortfolioAbsoluteChartData({
    portfolioId,
    period,
  });
  const absoluteData = data?.data || [];
  const chartColors = useChartColorsForPortfolio({
    legend: data?.legendData || [],
    portfolioId: portfolioId || '',
  });

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          value: f.value || '',
          id: f.id || '',
          label: f.label || '',
          color: chartColors[f.id || ''] || '',
          date: f.date || '',
          dataTag: f.dataTag,
        })),
      );
    }
  }, [data, chartColors]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: absoluteData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [absoluteData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!absoluteData.length && !isLoading) {
    return (
      <ChartEmptyState
        subtitle={emptyState.subTitle}
        title={emptyState.title}
        isDataError={!portfolioId}
      />
    );
  }

  return (
    <div className="px-6">
      <div className={chartStyles.header}>
        <h5
          data-test-id="absolutePortfolioChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader width="150px" loading={isLoading} component={<>Absolute</>} />
        </h5>
        <RangeDatePicker
          disabled={isLoading}
          loading={isLoading}
          onChange={handleDatePickerChange}
          value={datePickerValue}
          btnClassName="justify-self-end col-span-2 sm:col-span-1 w-full"
          dataTestId="absoluteChartDropdown"
        />
      </div>
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          dataTestId="absolutePortfolioChart"
          onUpdateLegend={setLegendData}
          tooltipOrder={tooltipOrder}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="absolutePortfolioChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                id={legendItem.id}
                tooltip={legendItem.tooltip || legendItem.label}
                color={legendItem.color}
                value={legendItem.value}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                date={legendItem.date}
                targetLink={getLegendTargetLink(legendItem)}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default PortfolioAbsoluteChart;
