import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as BrandLogo } from '@/assets/svg/icons/icon-logo-without-text.svg';
import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { useProvidePortfolio } from '@/pages/app/Portfolio/useProvidePortfolio';

import style from './style.module.less';

const defaultAcceptErrorMessage =
  'Error occurred while accepting the invitation';
const defaultDeclineErrorMessage =
  'Error occurred while declining the invitation';

const SharePortfolioInvitation = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { acceptPortfolioInvite, declinePortfolioInvite } =
    useProvidePortfolio();

  const appContext = useAppContext();

  const handleAcceptPortfolioInvite = async (token: string, id: string) => {
    acceptPortfolioInvite(token)
      .then(async res => {
        if (id) {
          if (res.data.success === true) {
            history.push(`/portfolios/${id}/holdings`);
          } else {
            history.push('/portfolios');
          }
          appContext.dispatch({
            type:
              res.data.status === 200
                ? APP_ACTIONS.SET_SUCCESS_MESSAGE
                : APP_ACTIONS.SET_REGULAR_MESSAGE,
            payload: {
              text: `${res.data.message}`,
            },
          });
        }
      })
      .catch(error => {
        history.push('/portfolios');
        setErrorMessage({
          dispatch: appContext.dispatch,
          error,
          errorAdditionalText: defaultAcceptErrorMessage,
        });
      });
  };

  const handleDeclinePortfolioInvite = async (token: string) => {
    declinePortfolioInvite(token)
      .then(async res => {
        history.push('/portfolios');
        appContext.dispatch({
          type: APP_ACTIONS.SET_REGULAR_MESSAGE,
          payload: {
            text: `${res.data.message}`,
          },
        });
      })
      .catch(error => {
        history.push('/portfolios');
        setErrorMessage({
          dispatch: appContext.dispatch,
          error,
          errorAdditionalText: defaultDeclineErrorMessage,
        });
      });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');
    const action = searchParams.get('action');
    const id = searchParams.get('id');

    if (token && id && action === 'accept') {
      handleAcceptPortfolioInvite(token, id);
    } else if (token && action === 'decline') {
      handleDeclinePortfolioInvite(token);
    } else {
      history.push('/portfolios');
      appContext.dispatch({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: defaultAcceptErrorMessage,
      });
    }
  }, [search]);

  return (
    <div className={style['loader-bg']}>
      <div className={style['loader-main']}>
        <div className={style['brand']}>
          <BrandLogo />
          <p className={style['brand-text']}>AM Insights</p>
        </div>
        <div className={style['loading-infinite']}>
          <div className={style['loading-infinite-loader']} />
        </div>
      </div>
    </div>
  );
};

export default SharePortfolioInvitation;
