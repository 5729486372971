import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';
import React, { PropsWithChildren, useRef, useState } from 'react';

import { ReactComponent as IconCopy } from '@/assets/svg/icons/icon-copy.svg';
import { ReactComponent as IconError } from '@/assets/svg/icons/icon-remove.svg';
import { Button } from '@/components';
import { openJiraForm } from '@/utils/jiraForm';

import style from './style.module.less';

export interface CustomErrorProps {
  error: string;
}

const CustomError: React.FC<PropsWithChildren<CustomErrorProps>> = ({
  error,
}) => {
  const [copyState, setCopyState] = useState('Copy');
  const errorMessage = useRef<HTMLTextAreaElement>(null);
  const { logout } = useAuth0();

  const forceReload = () => {
    window.location.replace(window.location.href);
  };

  const copyErrorMessage = () => {
    if (errorMessage.current) {
      navigator.clipboard.writeText(errorMessage.current.value);
      setCopyState('Copied');
      setTimeout(() => {
        setCopyState('Copy');
      }, 1000);
    }
  };

  return (
    <div className={style.error}>
      <div className={style['error-body']}>
        <IconError className={cx('icon text-danger', style['error-icon'])} />
        <h1 className={style['error-heading']}>Something went wrong</h1>
        <p className={style['error-paragraph']}>
          We encountered an error while processing your request. Please reload
          the page and try again.
        </p>
        <p className={style['error-paragraph']}>
          If you continue to encounter this error{' '}
          <button
            type="button"
            className={style.link}
            onClick={() => {
              openJiraForm();
            }}
          >
            get in touch
          </button>
          .
        </p>
        <div className={style['error-message']}>
          <textarea ref={errorMessage} value={error} rows={1} disabled />
          <button type="button" onClick={copyErrorMessage}>
            <IconCopy className={cx('icon', style['copy-icon'])} />
            {copyState}
          </button>
        </div>
        <Button type="primary" className="py-5" onClick={forceReload}>
          Try Again
        </Button>
        <div className="flex justify-center py-4">
          <button
            type="button"
            className="outline-none font-medium text-primary hover:text-info"
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomError;
