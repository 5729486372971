import { SLIDER_MAX, SLIDER_MIN } from '@/constants';

import { MonthCode } from '@aminsights/shared';
import { countSelectedFilter } from '../utils/countSelectedFilter';

export interface RiskFilterState {
  betaRange?: number[];
  upsideRange?: number[];
  downsideRange?: number[];
  trackingErrorRange?: number[];
  stdDeviationRange?: number[];
  maximumDrawdownRange?: number[];
  sortinoRatioRange?: number[];
  timePeriod?: MonthCode;
}

export const defaultRiskSliderValue = [SLIDER_MIN, SLIDER_MAX];

export const riskInitialState: RiskFilterState = {
  betaRange: defaultRiskSliderValue,
  upsideRange: defaultRiskSliderValue,
  downsideRange: defaultRiskSliderValue,
  trackingErrorRange: defaultRiskSliderValue,
  stdDeviationRange: defaultRiskSliderValue,
  maximumDrawdownRange: defaultRiskSliderValue,
  sortinoRatioRange: defaultRiskSliderValue,
  timePeriod: MonthCode.M12,
};

export const betaMarks: Record<number, string> = {
  0: '0',
  25: '0.5',
  50: '1',
  75: '1.5',
  100: '2>',
};

export const upsideAndDownSideMarks: Record<number, string> = {
  0: '0%',
  25: '50%',
  50: '100%',
  75: '150%',
  100: '200%>',
};

export const trackingErrorMarks: Record<number, string> = {
  0: '0%',
  25: '5%',
  50: '10%',
  75: '15%',
  100: '20%>',
};

export const stdDeviationMarks: Record<number, string> = {
  0: '0%',
  20: '10%',
  40: '20%',
  60: '30%',
  80: '40%',
  100: '50%>',
};

export const maximumDrawdownMarks: Record<number, string> = {
  0: '<-40%',
  25: '-30%',
  50: '-20%',
  75: '-10%',
  100: '0',
};

export const sortinoRatioMarks: Record<number, string> = {
  0: '<-2',
  25: '-1',
  50: '0',
  75: '1',
  100: '2>',
};

export const countRiskFilters = (filterState?: RiskFilterState): number => {
  if (!filterState) return 0;

  const a = [
    !!filterState.betaRange,
    !!filterState.upsideRange,
    !!filterState.downsideRange,
    !!filterState.trackingErrorRange,
    !!filterState.stdDeviationRange,
    !!filterState.maximumDrawdownRange,
    !!filterState.sortinoRatioRange,
  ];

  return countSelectedFilter(a);
};
