import ESGTable from '../InfoAndStats/ESG';
import GenderPayGapTable from '../InfoAndStats/GenderPayGap';
import style from './style.module.less';

const ESG = () => {
  return (
    <div className="mt-4 grid grid-cols-1 gap-y-5 gap-x-5 lg:grid-cols-2">
      <div
        className={style['section']}
        data-test-id="fundDetailsPortfolioESGTable"
      >
        <h4 className={style['section-title']}>ESG</h4>
        <ESGTable />
      </div>
      <div
        className={style['section']}
        data-test-id="fundDetailsPortfolioESGGenderPayGap"
      >
        <h4 className={style['section-title']}>Gender Pay Gap</h4>
        <GenderPayGapTable />
      </div>
    </div>
  );
};

export default ESG;
