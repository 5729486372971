import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import {
  APP_ROUTES,
  APP_ROUTE_CHARTS,
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_HELP,
  APP_ROUTE_INSIGHTS,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_RESEARCH,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_WATCHLIST,
  USER_PERMISSIONS,
} from '@aminsights/shared';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import cx from 'classnames';
import React, {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as ChartingToolIcon } from '@/assets/svg/icons/icon-charting-tool.svg';
import { ReactComponent as CogIcon } from '@/assets/svg/icons/icon-cog.svg';
import { ReactComponent as EyeIcon } from '@/assets/svg/icons/icon-eye-hide.svg';
import { ReactComponent as FlipIcon } from '@/assets/svg/icons/icon-flip.svg';
import { ReactComponent as HelpIcon } from '@/assets/svg/icons/icon-help.svg';
import { ReactComponent as HomeIcon } from '@/assets/svg/icons/icon-home.svg';
import { ReactComponent as PortfolioIcon } from '@/assets/svg/icons/icon-portfolio.svg';
import { ReactComponent as ResearchIcon } from '@/assets/svg/icons/icon-research.svg';
import { ReactComponent as SearchIcon } from '@/assets/svg/icons/icon-search.svg';
import { AISearchWidget } from '@/components/AISearchWidget';
import { LocalFeatureSwitch } from '@/components/LocalFeatureSwtich/LocalFeatureSwitch';
import Navbar from '@/components/Navbar';
import {
  DASHBOARD_SIDER_COLLAPSED_WIDTH,
  DASHBOARD_SIDER_WIDTH,
  RESTRICTED_FEATURE,
  getConfigValue,
} from '@/constants';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { NOTIFICATIONS } from '@/hooks/query-hooks/notification-hooks/query-keys';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { useChartSettingsState } from '@/hooks/useChartSettingsState';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import useUserAccess from '@/hooks/useUserAccess';
import useProvideSignificantChanges from '@/pages/app/SignificantChanges/useProvideSignificantChanges';
import Disclaimer from '@/partials/Disclaimer';
import NotificationPermissionModal from '@/partials/NotificationPermissionModal';
import useProvideSCSearch from '@/partials/SearchBox/useProvideSCSearch';
import queryClient from '@/queryClient';
import { openJiraForm } from '@/utils/jiraForm';

const { Content, Sider } = Layout;

type MenuItem = NonNullable<MenuProps['items']>[number] & {
  'data-test-id': string;
  isFeatureHidden?: boolean;
};

const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(APP_ROUTE_DASHBOARD);
  const location = useLocation();
  const { setFullTerm } = useSearch();
  const { clearSearch: clearSCSearch } = useProvideSCSearch();
  const { clearFilter: clearSCFilters } = useProvideSignificantChanges();
  const history = useHistory();
  const { currentWidth } = useScreenWidth();
  const isMobileView = currentWidth < screenBreakpoints.md;
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;
  const { resetChartSettingsFilter } = useChartSettingsState();
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const path = location.pathname?.split('/').filter(Boolean)?.[0];
    const currentPathname = APP_ROUTES.find(word => path?.includes(word));

    // When user changes page we want to clear the chart settings
    if (currentPathname !== activeKey) {
      resetChartSettingsFilter();
    }

    if (currentPathname) {
      setActiveKey(currentPathname);
    }
  }, [location]);

  useEffect(() => {
    // Force a refetch on notification and bucket watchlist every 5 mins
    const interval = setInterval(() => {
      queryClient.invalidateQueries([NOTIFICATIONS]);
      queryClient.invalidateQueries([WATCHLIST]);
    }, 300000); // 5 mins

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (currentWidth >= screenBreakpoints.xl) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [currentWidth]);

  const clearSearchState = () => {
    clearSCFilters();
    clearSCSearch();
    setFullTerm('');
  };

  const onItemClick: MenuProps['onClick'] = ({ key }) => {
    if (isMobileView) {
      setCollapsed(true);
    }

    if (key === APP_ROUTE_HELP) {
      openJiraForm();
      return;
    }

    // Check if the feature is restricted
    if (isRestrictedAccess) {
      if (key === APP_ROUTE_PORTFOLIOS) {
        toggleUpgradeModal(RESTRICTED_FEATURE.PORTFOLIO);
        return;
      } else if (key === APP_ROUTE_RESEARCH) {
        toggleUpgradeModal(RESTRICTED_FEATURE.RESEARCH_DASHBOARD);
        return;
      }
    }

    clearSearchState();

    history.push(`/${key}`);
  };

  const onToggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const getMenuItems = (): NonNullable<
    ComponentProps<typeof Menu>['items']
  > => {
    const menus: MenuItem[] = [
      {
        label: 'Dashboard',
        key: APP_ROUTE_DASHBOARD,
        icon: <HomeIcon className="icon" />,
        'data-test-id': 'mainNavHome',
        title: isMobileView ? '' : 'Dashboard',
      },
      {
        label: 'Watchlist',
        key: APP_ROUTE_WATCHLIST,
        icon: <EyeIcon className="icon" />,
        'data-test-id': 'mainNavWatchlist',
        title: isMobileView ? '' : 'Watchlist',
      },
      {
        label: isRestrictedAccess ? (
          <span className="flex items-center justify-between gap-1 group">
            Portfolio
            <LockIcon
              className={cx(
                'fill-neutral-100 group-hover:fill-primary',
                'transition-all duration-300 ease-in-out mr-2 !w-5 !h-5',
              )}
            />
          </span>
        ) : (
          'Portfolio'
        ),
        key: APP_ROUTE_PORTFOLIOS,
        icon: <PortfolioIcon className="icon" />,
        'data-test-id': 'mainNavPortfolio',
        title: isMobileView ? '' : 'Portfolio',
        isFeatureHidden: isAppLimitedAccessEnabled,
      },
      {
        label: 'Significant Changes',
        key: APP_ROUTE_SIGNIFICANT_CHANGES,
        icon: <FlipIcon className="icon" />,
        'data-test-id': 'mainNavSignificantChanges',
        title: isMobileView ? '' : 'Significant Changes',
      },
      {
        label: 'Explore',
        key: APP_ROUTE_EXPLORE,
        icon: <SearchIcon className="icon" />,
        'data-test-id': 'mainNavExplore',
        title: isMobileView ? '' : 'Explore',
        isFeatureHidden: isAppLimitedAccessEnabled,
      },
      {
        label: 'Charting',
        key: APP_ROUTE_CHARTS,
        icon: <ChartingToolIcon className="icon !fill-none" />,
        'data-test-id': 'mainNavCharts',
        title: isMobileView ? '' : 'Charting',
        isFeatureHidden: isAppLimitedAccessEnabled,
      },
      ...(hasResearchViewPermissions
        ? [
            {
              label: isRestrictedAccess ? (
                <span className="flex items-center justify-between gap-1 group">
                  Research
                  <LockIcon
                    className={cx(
                      'fill-neutral-100 group-hover:fill-primary',
                      'transition-all duration-300 ease-in-out mr-2 !w-5 !h-5',
                    )}
                  />
                </span>
              ) : (
                'Research'
              ),
              key: APP_ROUTE_RESEARCH,
              icon: <ResearchIcon className="icon !fill-none" />,
              'data-test-id': 'mainNavResearch',
              title: isMobileView ? '' : 'Research',
              isFeatureHidden: !featureSwitch.state.isResearchDashboardEnabled,
            },
          ]
        : []),
      {
        label: 'Insights',
        key: APP_ROUTE_INSIGHTS,
        icon: <ChartingToolIcon className="icon !fill-none" />,
        'data-test-id': 'mainNavInsights',
        title: isMobileView ? '' : 'Insights',
        isFeatureHidden: !(
          featureSwitch.state.isInsightsEnabled && isRestrictedAccess
        ),
      },
      {
        label: 'Settings',
        key: APP_ROUTE_SETTINGS,
        icon: <CogIcon className="icon" />,
        'data-test-id': 'mainNavSettings',
        title: isMobileView ? '' : 'Settings',
      },
      {
        label: 'Help/Support',
        key: APP_ROUTE_HELP,
        icon: <HelpIcon className="icon" />,
        'data-test-id': 'mainNavHelp',
        title: isMobileView ? '' : 'Help/Support',
      },
    ];

    return menus
      .filter(menu => !menu.isFeatureHidden)
      .map(menu => ({ ...menu, isFeatureHidden: undefined }));
  };
  return (
    <Layout>
      {createPortal(
        <NotificationPermissionModal />,
        document.getElementById('notification-permission-portal') as Element,
      )}
      <Navbar collapsed={collapsed} onToggleMenu={() => onToggleMenu()} />
      <Layout>
        <Sider
          theme="light"
          trigger={null}
          width={isMobileView ? '100vw' : DASHBOARD_SIDER_WIDTH + 1} // +1 to account for the border
          collapsible
          collapsed={collapsed}
          collapsedWidth={
            isMobileView ? 0 : DASHBOARD_SIDER_COLLAPSED_WIDTH + 1 // +1 to account for the border
          }
          className={cx(
            'bg-white fixed z-40 top-0 pt-14 h-full overflow-auto sm:overflow-visible sm:h-auto sm:relative sm:pt-0 [&>*]:pt-0',
            'border-r border-r-gray-200',
          )}
        >
          <Menu
            // Bare with this monstrosity, I honestly find it easier than bouncing around less files 😂 - but theming will make it easier some day
            className={cx(
              'bg-white flex flex-col', // Base
              'sm:sticky w-full sm:h-[calc(100vh-48px)] top-14 sm:top-12 [&>li]:flex [&>li]:items-center [&>li]:justify-start [&>li>.icon]:shrink-0 [&>li]:rounded xl:[&>li]:w-full [&>li>.icon]:duration-200', // Positioning
              'px-4 py-2 pt-3 [&>li]:p-0 [&>li]:shrink-0 [&>li]:mb-3 [&>li]:mx-0 [&>li]:font-medium [&_svg]:size-6', // Spacing & sizing
              'h-full max-h-[calc(100vh-48px)] overflow-auto', //  Handle an edge case where a device is in landspace mode
              !collapsed && '[&>li>svg]:ml-4',
              collapsed && '[&>li>svg]:mx-2 [&>li]:w-[40px]',
              '[&>li]:text-[#262626] [&>li.ant-menu-item-selected]:text-white [&>li.ant-menu-item-selected>svg]:text-white [&>li>svg]:text-neutral-100', // Color
              '[&>li:hover:not(.ant-menu-item-selected)]:text-[#1890ff] [&>li:hover:not(.ant-menu-item-selected)>svg]:text-primary', // hover
              '[&>li:nth-last-child(2)]:mt-auto [&>li:last-child]:mb-6',
              '[&>li:first-child]:mt-6',
              isRestrictedAccess && '[&>li>span]:w-full',
              isRestrictedAccess &&
                '[&>li.ant-menu-item-selected>span>span>svg]:fill-white [&>li.ant-menu-item-selected>span>span>svg]:hover:fill-white',
            )}
            theme="dark" // We set it to dark because the active item is as the figma - and it is easier to adjust the colors
            selectedKeys={[activeKey]}
            onClick={onItemClick}
            items={getMenuItems()}
          />
        </Sider>
        <Content>
          {children}
          <Disclaimer />
        </Content>
      </Layout>
      {!isAppLimitedAccessEnabled && <AISearchWidget />}
      {getConfigValue('REACT_APP_NODE_ENV') !== 'production' && (
        <LocalFeatureSwitch />
      )}
    </Layout>
  );
};

export default DashboardLayout;
