import DataTable, { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import style from '../Summary/style.module.less';

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    renderType: 'text',
    render: item => (
      <span className="text-neutral-200 text-xs font-bold">{item.type}</span>
    ),
    minWidth: 240,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Platform',
    isColumnFixed: true,
  },
  {
    title: 'Rating',
    renderType: 'text',
    render: item => item.rating,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Rating',
    minWidth: 150,
    isColumnFixed: true,
  },
  {
    title: '',
    render: () => '',
    renderType: 'text',
    className: 'w-full',
  },
];

const RiskRatings = () => {
  const { data: state, isLoading } = useFundByIsInParam();
  const { riskRating } = state?.fund || {};

  const RISK_RATINGS = [
    {
      type: 'Defaqto',
      rating: riskRating?.defaqto?.toFixed(0),
    },
    {
      type: 'Dynamic Planner',
      rating: riskRating?.dynamicPlanner
        ? `${riskRating?.dynamicPlanner?.toFixed(0)} (${riskRating?.dynamicPlannerType})`
        : undefined,
    },
    {
      type: 'EV',
      rating: riskRating?.ev?.toFixed(0),
    },
    {
      type: 'FinaMetrica',
      rating: riskRating?.finaMetrica?.toFixed(0),
    },
    {
      type: 'Oxford',
      rating: riskRating?.oxford?.toFixed(0),
    },
    {
      type: 'Synaptic',
      rating: riskRating?.synaptic?.toFixed(0),
    },
  ];

  const riskRatingsAvailability = RISK_RATINGS.map(p => ({
    type: p.type,
    rating: p.rating ? p.rating : EMPTY_DATA_POINT,
  }));

  return (
    <div className={style['summary-table']}>
      <DataTable
        uniqueKey="type"
        columns={columns}
        data={riskRatingsAvailability}
        loading={isLoading}
      />
    </div>
  );
};

export default RiskRatings;
