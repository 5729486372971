import { ReactComponent as GraphicSearch } from '@/assets/svg/graphic-search.svg';

interface CommentaryEmptyFilterProps {
  selectedFilter: string;
}

const CommentaryEmptyFilter = ({
  selectedFilter,
}: CommentaryEmptyFilterProps) => {
  const messages = {
    mine: {
      title: 'No comments by you',
      description: 'Write a comment to see results here',
    },
    pinned: {
      title: 'No pinned comments',
      description: 'Pin a comment to see results here',
    },
    all: {
      title: 'No results found',
      description: 'Try adjusting your search or filter options',
    },
  };

  const { title, description } =
    messages[selectedFilter as keyof typeof messages] || messages.all;

  return (
    <div className="flex flex-row lg:p-4 h-full min-h-[350px]">
      <div className="w-full overflow-hidden bg-white lg:rounded-lg flex items-center justify-center">
        <div className="flex flex-col relative items-center justify-center text-center px-5 gap-2">
          <GraphicSearch />
          <h1 className="text-darkest text-xl font-bold block">{title}</h1>
          <p className="text-neutral-700 text-sm">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CommentaryEmptyFilter;
