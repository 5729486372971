import {
  PageQueryParametersSortDirectionEnum,
  RangePair,
  RangePairSerializer,
} from '@aminsights/contract';
import { WritableDraft, createDraft, finishDraft } from 'immer';
import _ from 'lodash';
import { Paths } from 'type-fest';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { SLIDER_MIN } from '@/constants';

import { MonthCode } from '@aminsights/shared';
import {
  convertParamsToState,
  convertStateToString,
} from '../useProvideExplore';
import {
  getMarksFromValue,
  getValueFromMarks,
} from '../utils/getValueFromMarks';
import {
  BondsFilterState,
  getAvgCreditQualitySliderValueFromActualValue,
  getExploreValueRangeFromAvgQuality,
  modifiedDurationMarks,
  yieldMaturityMarks,
} from './bondsSubState';
import { MorningStarCategories } from './categoriesSubState';
import { equitiesFilterMarks } from './equitiesSubState';
import { EquitiesFilterState } from './equitiesSubState';
import { EsgFilterState } from './esgSubState';
import {
  FundSizeState,
  SLIDER_MAX_FUND_SIZE,
  convertFundSize,
  getFundSizeFromValues,
} from './fundSizeSubState';
import { IaSectors } from './iaSectorsSubState';
import {
  MoreFilterState,
  noOfHoldingMarks,
  yieldMarks,
} from './moreFiltersSubState';
import { OCFState, ocfRangeMarks } from './ocfSubState';
import { riskRatingMarks, riskRatingOxfordMarks } from './riskRatingSubState';
import {
  RiskFilterState,
  betaMarks,
  maximumDrawdownMarks,
  sortinoRatioMarks,
  stdDeviationMarks,
  trackingErrorMarks,
  upsideAndDownSideMarks,
} from './riskSubState';

export interface Filters {
  // General search filters
  term?: string;
  size?: number;
  page?: number;
  sortKey?: string;
  sortDirection?: PageQueryParametersSortDirectionEnum;

  // Various field specific filters
  morningStarCategories?: MorningStarCategories;
  iaSectors?: IaSectors;
  esgFilters?: EsgFilterState;
  riskFilters?: RiskFilterState;
  equitiesFilters?: EquitiesFilterState;
  bondsFilters?: BondsFilterState;
  moreFilters?: MoreFilterState;
  fundSizeFilter?: FundSizeState;
  ocfFilter?: OCFState;
}

type CheckboxValueType = string[] | undefined;

const mapDatePairs = (dates?: CheckboxValueType) => {
  const serializedDates = dates?.map(date => {
    const parsedPair: RangePair = JSON.parse(date.toString()) as RangePair;
    return RangePairSerializer.serialize(parsedPair);
  });
  return serializedDates;
};

const mapSerializedDatePairsToJson = (serializedDates?: string[]) => {
  const jsonDates = serializedDates?.map(date => {
    const parsedPair = RangePairSerializer.deserialize(date);
    return JSON.stringify(parsedPair);
  });
  return jsonDates;
};

interface ExploreFilters {
  filters: Filters;
  tentativeFilters: Filters;

  updateTentativeFilters: (filters: Filters) => void;
  getMutableFilters: () => Filters;

  composeStateFromSearchString: () => void;

  clearAllTentativeFilters: () => void;
  resetFilters: () => void;

  syncFilters: (callback?: (queryString: string) => void) => void;
  syncFilterByPath: (path: Paths<Filters>) => void;

  emptyFilters: () => void;

  convertStateToUrl: (f: Filters) => string;
}

const useExploreFilters = create<ExploreFilters>()(
  immer((set, get) => ({
    filters: {},

    tentativeFilters: {},

    syncFilterByPath: path => {
      set(state => {
        _.set(state.filters, path, _.get(state.tentativeFilters, path));
        const urlString = state.convertStateToUrl(state.filters);
        window.history.replaceState(null, '', `/explore?${urlString}`);
      });
    },
    syncFilters: callback => {
      set(state => {
        state.filters = state.tentativeFilters;
        const urlString = state.convertStateToUrl(state.filters);

        if (callback) {
          callback(urlString);
        } else {
          window.history.replaceState(null, '', `/explore?${urlString}`);
        }
      });
    },

    // Not actually a stateful function
    convertStateToUrl: (f: Filters) => {
      const urlString = convertStateToString({
        term: f.term,
        sortDirection: f.sortDirection,
        sortKey: f.sortKey,
        page: f.page,

        categories:
          f.morningStarCategories?.selectedMorningStarCategoriesByIndex,

        iaSectors: f.iaSectors?.selectedIaSectors,

        sfdr: f?.esgFilters?.checkedSFDR,
        tcfdDates: mapDatePairs(f?.esgFilters?.datesTCFD),
        unpriDates: mapDatePairs(f?.esgFilters?.datesUNPRI),
        financeForBiodiversityDates: mapDatePairs(
          f?.esgFilters?.financeForBiodiversityDates,
        ),
        fundSize: f.fundSizeFilter?.fundSizeRange
          ? [
              convertFundSize[f.fundSizeFilter.fundSizeRange[0]],
              convertFundSize[f.fundSizeFilter.fundSizeRange[1]],
            ]
          : undefined,
        ocf: getValueFromMarks(f.ocfFilter?.ocfRange, ocfRangeMarks, [
          undefined,
          112,
        ]),
        actDates: mapDatePairs(f?.esgFilters?.actDates),
        shareActionRatings: f?.esgFilters?.shareActionRatings?.map(
          rating => rating.value,
        ),
        stewardshipCode: f?.esgFilters?.stewardshipCode,
        netZeroAssetManager: f?.esgFilters?.netZeroAssetManager,

        betaRange: getValueFromMarks(f.riskFilters?.betaRange, betaMarks, [
          undefined,
          100,
        ]),
        upsideRange: getValueFromMarks(
          f.riskFilters?.upsideRange,
          upsideAndDownSideMarks,
          [undefined, 100],
        ),
        downsideRange: getValueFromMarks(
          f.riskFilters?.downsideRange,
          upsideAndDownSideMarks,
          [undefined, 100],
        ),
        trackingErrorRange: getValueFromMarks(
          f.riskFilters?.trackingErrorRange,
          trackingErrorMarks,
          [undefined, 100],
        ),
        stdDeviationRange: getValueFromMarks(
          f.riskFilters?.stdDeviationRange,
          stdDeviationMarks,
          [undefined, 100],
        ),
        maximumDrawdownRange: getValueFromMarks(
          f.riskFilters?.maximumDrawdownRange,
          maximumDrawdownMarks,
          [0, undefined],
        ),
        sortinoRatioRange: getValueFromMarks(
          f.riskFilters?.sortinoRatioRange,
          sortinoRatioMarks,
          [0, 100],
        ),
        yearDropDown: (() => {
          // Only apply the risk filter if at least one of the risk filters is selected
          if (
            f.riskFilters?.betaRange ||
            f.riskFilters?.upsideRange ||
            f.riskFilters?.downsideRange ||
            f.riskFilters?.trackingErrorRange ||
            f.riskFilters?.stdDeviationRange ||
            f.riskFilters?.maximumDrawdownRange ||
            f.riskFilters?.sortinoRatioRange
          ) {
            return f.riskFilters?.timePeriod;
          }
          return undefined;
        })(),

        equityStyle: f.equitiesFilters?.equityStyle,
        roicRange: getValueFromMarks(
          f.equitiesFilters?.roicRange,
          equitiesFilterMarks,
          [undefined, 100],
        ),
        netMarginRange: getValueFromMarks(
          f.equitiesFilters?.netMarginRange,
          equitiesFilterMarks,
          [undefined, 100],
        ),

        bondStyle: f.bondsFilters?.bondsStyle,
        modifiedDurationRange: getValueFromMarks(
          f.bondsFilters?.modifiedDurationRange,
          modifiedDurationMarks,
          [undefined, 100],
        ),
        yieldMaturityRange: getValueFromMarks(
          f.bondsFilters?.yieldMaturityRange,
          yieldMaturityMarks,
          [undefined, 99],
        ),

        avgCreditQualityRange: getExploreValueRangeFromAvgQuality([
          f.bondsFilters?.avgCreditQualityRange?.[0],
          f.bondsFilters?.avgCreditQualityRange?.[1],
        ]),

        managementGroup: f.moreFilters?.selectedManagementGroup,
        domiciles: f.moreFilters?.selectedDomiciles,
        currencies: f.moreFilters?.selectedCurrencies,
        platforms: f.moreFilters?.selectedPlatforms,

        broadAssetClasses: f.moreFilters?.selectedBroadAssetClasses,
        currencyHedgedType: f.moreFilters?.currencyHedgedType,
        legalStructure: f.moreFilters?.legalStructure,
        filterByOldestShareClassOnly:
          f.moreFilters?.filterByOldestShareClassOnly,
        noOfHoldingsRange: getValueFromMarks(
          f.moreFilters?.noOfHoldingsRange,
          noOfHoldingMarks,
          [undefined, 96],
        ),
        indexFund: f.moreFilters?.filterByActiveOrPassive,
        genders: f.moreFilters?.genders,
        yieldRange: getValueFromMarks(f.moreFilters?.yieldRange, yieldMarks, [
          undefined,
          96,
        ]),
        defaqto: f.moreFilters?.riskRating?.defaqto,
        dynamicPlanner: f.moreFilters?.riskRating?.dynamicPlanner,
        dynamicPlannerType: f.moreFilters?.riskRating?.dynamicPlannerType,
        ev: f.moreFilters?.riskRating?.ev,
        oxford: f.moreFilters?.riskRating?.oxford,
        synaptic: f.moreFilters?.riskRating?.synaptic,
      });

      return urlString;
    },

    updateTentativeFilters: (filters: WritableDraft<Filters>) => {
      set(state => {
        const finishedDraft = finishDraft(filters);
        state.tentativeFilters = finishedDraft;
      });
    },

    getMutableFilters: () => {
      const cloned = createDraft(get().tentativeFilters);
      return cloned;
    },

    composeStateFromSearchString: () => {
      const urlString = new URLSearchParams(window.location.search);
      const exploreStateFromUrl = convertParamsToState(urlString, null, true);

      const hasTerm =
        exploreStateFromUrl.term !== '' &&
        exploreStateFromUrl.term !== undefined;
      const defaultSortKey = hasTerm ? '_score' : 'fundSize.value';

      set(state => {
        const f = state.filters;
        if (exploreStateFromUrl.fundSize) {
          f.fundSizeFilter = {
            fundSizeRange: getFundSizeFromValues(
              exploreStateFromUrl.fundSize,
            ) ?? [SLIDER_MIN, SLIDER_MAX_FUND_SIZE],
          };
        }
        if (exploreStateFromUrl.ocf) {
          state.filters.ocfFilter = {
            ocfRange:
              getMarksFromValue(exploreStateFromUrl.ocf, ocfRangeMarks, [
                undefined,
                1.75,
              ]) ?? [],
          };
        }

        f.term = exploreStateFromUrl.term ?? '';
        f.page = exploreStateFromUrl.page;
        f.size = exploreStateFromUrl.size;
        f.sortKey = exploreStateFromUrl.sortKey ?? defaultSortKey;
        f.sortDirection =
          (exploreStateFromUrl.sortDirection as PageQueryParametersSortDirectionEnum) ??
          PageQueryParametersSortDirectionEnum.Desc;

        f.riskFilters = {
          betaRange: getMarksFromValue(
            exploreStateFromUrl.betaRange,
            betaMarks,
            [undefined, 2],
          ),
          upsideRange: getMarksFromValue(
            exploreStateFromUrl.upsideRange,
            upsideAndDownSideMarks,
            [undefined, 200],
          ),
          downsideRange: getMarksFromValue(
            exploreStateFromUrl.downsideRange,
            upsideAndDownSideMarks,
            [undefined, 200],
          ),
          trackingErrorRange: getMarksFromValue(
            exploreStateFromUrl.trackingErrorRange,
            trackingErrorMarks,
            [undefined, 20],
          ),
          stdDeviationRange: getMarksFromValue(
            exploreStateFromUrl.stdDeviationRange,
            stdDeviationMarks,
            [undefined, 50],
          ),
          maximumDrawdownRange: getMarksFromValue(
            exploreStateFromUrl.maximumDrawdownRange,
            maximumDrawdownMarks,
            [-40, undefined],
          ),
          sortinoRatioRange: getMarksFromValue(
            exploreStateFromUrl.sortinoRatioRange,
            sortinoRatioMarks,
            [-2, 2],
          ),

          timePeriod: (() => {
            if (
              exploreStateFromUrl.yearDropDown &&
              [MonthCode.M12, MonthCode.M36, MonthCode.M60].includes(
                exploreStateFromUrl.yearDropDown as MonthCode,
              )
            ) {
              return exploreStateFromUrl.yearDropDown as MonthCode;
            } else {
              return undefined;
            }
          })(),
        };

        f.esgFilters = {
          checkedSFDR: exploreStateFromUrl.sfdr,
          datesTCFD: mapSerializedDatePairsToJson(
            exploreStateFromUrl.tcfdDates,
          ),
          datesUNPRI: mapSerializedDatePairsToJson(
            exploreStateFromUrl.unpriDates,
          ),
          financeForBiodiversityDates: mapSerializedDatePairsToJson(
            exploreStateFromUrl.financeForBiodiversityDates,
          ),
          actDates: mapSerializedDatePairsToJson(exploreStateFromUrl.actDates),
          shareActionRatings: exploreStateFromUrl.shareActionRatings?.map(
            rating => ({
              value: rating,
              label: rating,
            }),
          ),
          stewardshipCode: exploreStateFromUrl.stewardshipCode,
          netZeroAssetManager: exploreStateFromUrl.netZeroAssetManager,
        };

        f.equitiesFilters = {
          equityStyle: exploreStateFromUrl.equityStyle,
          roicRange: getMarksFromValue(
            exploreStateFromUrl.roicRange,
            equitiesFilterMarks,
            [undefined, 20],
          ),
          netMarginRange: getMarksFromValue(
            exploreStateFromUrl.netMarginRange,
            equitiesFilterMarks,
            [undefined, 20],
          ),
        };

        f.bondsFilters = {
          bondsStyle: exploreStateFromUrl.bondStyle,
          modifiedDurationRange: getMarksFromValue(
            exploreStateFromUrl.modifiedDurationRange,
            modifiedDurationMarks,
            [undefined, 10],
          ),
          yieldMaturityRange: getMarksFromValue(
            exploreStateFromUrl.yieldMaturityRange,
            yieldMaturityMarks,
            [undefined, 6],
          ),
        };

        const avgCreditQualityRangeFirst = exploreStateFromUrl
          .avgCreditQualityRange?.length
          ? getAvgCreditQualitySliderValueFromActualValue(
              exploreStateFromUrl.avgCreditQualityRange[0],
            )
          : 0;
        const avgCreditQualityRangeSecond = exploreStateFromUrl
          .avgCreditQualityRange?.length
          ? getAvgCreditQualitySliderValueFromActualValue(
              exploreStateFromUrl.avgCreditQualityRange[1],
            )
          : 0;

        const avgCreditQualityRangeMin = Math.min(
          avgCreditQualityRangeFirst,
          avgCreditQualityRangeSecond,
        );
        const avgCreditQualityRangeMax = Math.max(
          avgCreditQualityRangeFirst,
          avgCreditQualityRangeSecond,
        );

        f.bondsFilters.avgCreditQualityRange =
          avgCreditQualityRangeMin && avgCreditQualityRangeMax
            ? [avgCreditQualityRangeMin, avgCreditQualityRangeMax]
            : undefined;

        const riskRating = {
          defaqto: getMarksFromValue(
            exploreStateFromUrl.defaqto,
            riskRatingMarks,
            [undefined, 10],
          ),
          dynamicPlanner: getMarksFromValue(
            exploreStateFromUrl.dynamicPlanner,
            riskRatingMarks,
            [undefined, 10],
          ),
          dynamicPlannerType: exploreStateFromUrl.dynamicPlannerType,
          ev: getMarksFromValue(exploreStateFromUrl.ev, riskRatingMarks, [
            undefined,
            10,
          ]),
          oxford: getMarksFromValue(
            exploreStateFromUrl.oxford,
            riskRatingOxfordMarks,
            [undefined, 7],
          ),
          synaptic: getMarksFromValue(
            exploreStateFromUrl.synaptic,
            riskRatingMarks,
            [undefined, 10],
          ),
        };

        f.moreFilters = {
          selectedManagementGroup: exploreStateFromUrl.managementGroup,
          selectedDomiciles: exploreStateFromUrl.domiciles,
          selectedCurrencies: exploreStateFromUrl.currencies,
          selectedPlatforms: exploreStateFromUrl.platforms,
          selectedBroadAssetClasses: exploreStateFromUrl.broadAssetClasses,
          currencyHedgedType: exploreStateFromUrl.currencyHedgedType,
          legalStructure: exploreStateFromUrl.legalStructure,
          filterByOldestShareClassOnly:
            exploreStateFromUrl.filterByOldestShareClassOnly,
          noOfHoldingsRange: getMarksFromValue(
            exploreStateFromUrl.noOfHoldingsRange,
            noOfHoldingMarks,
            [undefined, 150],
          ),
          filterByActiveOrPassive: exploreStateFromUrl.indexFund,
          genders: exploreStateFromUrl.genders,
          yieldRange: getMarksFromValue(
            exploreStateFromUrl.yieldRange,
            yieldMarks,
            [undefined, 12],
          ),
          riskRating,
        };

        if (exploreStateFromUrl.categories) {
          f.morningStarCategories = {
            selectedMorningStarCategoriesByIndex:
              exploreStateFromUrl.categories,
          };
          f.moreFilters = {
            ...f.moreFilters,
            filterByOldestShareClassOnly: true,
          };
        }

        if (exploreStateFromUrl.iaSectors) {
          f.iaSectors = {
            selectedIaSectors: exploreStateFromUrl.iaSectors,
          };
        }
        state.tentativeFilters = state.filters;
      });
    },

    resetFilters: () => {
      set(state => {
        state.filters = {
          sortKey: 'fundSize.value',
          sortDirection: PageQueryParametersSortDirectionEnum.Desc,
        };
        state.tentativeFilters = {
          sortKey: 'fundSize.value',
          sortDirection: PageQueryParametersSortDirectionEnum.Desc,
        };
        const urlString = state.convertStateToUrl(state.filters);

        // Reset only the search params
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?${urlString}`,
        );
      });
    },

    emptyFilters: () => {
      set(state => {
        state.filters = {};
        state.tentativeFilters = {};
      });
    },

    clearAllTentativeFilters: () => {
      set(state => {
        state.tentativeFilters = {
          sortKey: 'fundSize.value',
          sortDirection: PageQueryParametersSortDirectionEnum.Desc,
        };
      });
    },
  })),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
useExploreFilters.subscribe(state => {
  // const testTentativeFilters = state.tentativeFilters.morningStarCategories;
  // eslint-disable-next-line no-console
  // console.log(
  //   'Explore filters state changed - tentative filters \n',
  //   JSON.stringify(state, null, 2),
  // );
});

export default useExploreFilters;
