import { DataSourceType, Fund, HoldingDetail } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  getShortHumanReadableStyleboxName,
  isAllocationBroadAssetClass,
  isFixedIncomeBroadAssetClass,
  shortenLongNumber,
} from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';

import Stylebox from '../../Stylebox';
import { IDataTablePortfolioColumns } from '../types';

export type HoldingDetailWithFundDetails = HoldingDetail & {
  fund?: Fund;
};

export const holdingsColumns = (
  broadAssetClass: string,
  hasHoldingDetailsForIsin: boolean,
  isInvestmentTrust?: boolean,
): IDataTablePortfolioColumns[] => {
  const isMultiasset = isAllocationBroadAssetClass(broadAssetClass);
  const showLayoutForMultiAsset = isMultiasset && hasHoldingDetailsForIsin;

  return [
    {
      title: 'Investment',
      sorter: {
        compare: (a: { fund: string }, b: { fund: string }) =>
          a.fund.localeCompare(b.fund),
        multiple: 3,
      },
      render: (item: HoldingDetailWithFundDetails) =>
        item.fund && item.isin ? (
          <Link
            className="w-[144px] sm:w-[200px] font-bold text-neutral-200 hover:text-primary"
            to={`/fund/${item.isin}/details/investors`}
          >
            {item.externalName ?? EMPTY_DATA_POINT}
          </Link>
        ) : (
          <span className="w-[144px] sm:w-[200px] font-bold text-neutral-200">
            {item.externalName ?? EMPTY_DATA_POINT}
          </span>
        ),
      renderType: 'text',
      isColumnFixed: true,
    },
    {
      title: isInvestmentTrust ? 'Trust' : 'Fund',
      render: (item: HoldingDetailWithFundDetails) => (
        <span className="text-neutral">
          {!item.weighting ? EMPTY_DATA_POINT : `${item.weighting.toFixed(1)}%`}
        </span>
      ),
      renderType: 'number' as const,
      sorter: {
        compare: (a: { fund: string }, b: { fund: string }) =>
          a.fund.localeCompare(b.fund),
        multiple: 3,
      },
      width: showLayoutForMultiAsset ? 75 : 100,
    },
    ...(!showLayoutForMultiAsset
      ? [
          {
            title: 'Index',
            render: (item: HoldingDetailWithFundDetails) => (
              <span className="text-neutral">
                {item.index ? `${item.index.toFixed(1)}%` : EMPTY_DATA_POINT}
              </span>
            ),
            renderType: 'number' as const,
            sorter: {
              compare: (a: { index: string }, b: { index: string }) =>
                a.index.localeCompare(b.index),
              multiple: 3,
            },
            width: 100,
            align: 'right',
            isVisible: (
              fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
            ) =>
              !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
              fund.portfolioDataSource !== DataSourceType.Padi,
          },
          {
            title: 'Relative',
            render: (item: HoldingDetailWithFundDetails) => (
              <span className="text-neutral">
                {item.relative
                  ? `${item.relative.toFixed(1)}%`
                  : EMPTY_DATA_POINT}
              </span>
            ),
            renderType: 'number' as const,
            sorter: {
              compare: (a: { relative: string }, b: { relative: string }) =>
                a.relative.localeCompare(b.relative),
              multiple: 3,
            },
            width: 100,
            align: 'right',
            isVisible: (
              fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
            ) =>
              !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
              fund.portfolioDataSource !== DataSourceType.Padi,
          },
          {
            title: 'Sector',
            render: (item: HoldingDetailWithFundDetails) => (
              <span className="text-neutral">
                {item.sector ?? EMPTY_DATA_POINT}
              </span>
            ),
            renderType: 'text' as const,
            sorter: {
              compare: (a: { sector: string }, b: { sector: string }) =>
                a.sector.localeCompare(b.sector),
              multiple: 3,
            },
            align: 'right',
            width: 150,
          },
        ]
      : []),
    {
      title: isMultiasset ? 'Category' : 'Country',
      render: (item: HoldingDetailWithFundDetails) => {
        return (
          <span className="text-neutral">
            {(isMultiasset ? item.fund?.msCategoryDefinition : item.country) ??
              EMPTY_DATA_POINT}
          </span>
        );
      },
      sorter: {
        compare: (a: { country: string }, b: { country: string }) =>
          a.country.localeCompare(b.country),
        multiple: 3,
      },
      renderType: 'text',
      align: 'right',
      width: 150,
    },
    ...(showLayoutForMultiAsset
      ? [
          {
            title: 'Style Box',
            render: (item: HoldingDetailWithFundDetails) => {
              const styleboxValue = item.fund?.broadAssetClassValues?.styleBox;
              const isFixedIncome = item.fund?.broadAssetClass
                ? isFixedIncomeBroadAssetClass(item.fund.broadAssetClass)
                : false;
              const styleboxLabels = isFixedIncome
                ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
                : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ;

              return styleboxValue ? (
                <div className="flex gap-x-2 items-center">
                  <div className="h-5 w-5">
                    <Stylebox
                      broadAssetClass={
                        item.fund?.broadAssetClass ?? EMPTY_DATA_POINT
                      }
                      positionPair={[[styleboxValue]]}
                    />
                  </div>
                  <p className="text-darkest text-sm">
                    {getShortHumanReadableStyleboxName(
                      styleboxValue,
                      styleboxLabels,
                    )}
                  </p>
                </div>
              ) : (
                EMPTY_DATA_POINT
              );
            },
            sorter: {
              compare: (a: { country: string }, b: { country: string }) =>
                a.country.localeCompare(b.country),
              multiple: 3,
            },
            renderType: 'text' as const,
            align: 'right',
            width: 200,
          },
          {
            title: 'Fund Size',
            render: (item: HoldingDetailWithFundDetails) => {
              const { data: currencyData } = useGetCurrencyRates();
              return (
                <div className="text-sm text-neutral w-full text-right">
                  {!item.fund ||
                  !item.fund.fundSize ||
                  !item.fund.fundSize?.value ||
                  !currencyData?.currencyCode
                    ? EMPTY_DATA_POINT
                    : `${getSymbolFromCurrency(
                        currencyData.currencyCode,
                      )}${shortenLongNumber(item.fund.fundSize.value)}`}
                </div>
              );
            },
            sorter: {
              compare: (
                a: { fundSize: { value: number } },
                b: { fundSize: { value: number } },
              ) => (a.fundSize?.value ?? 0) - (b.fundSize?.value ?? 0),
              multiple: 3,
            },
            renderType: 'text' as const,
            align: 'right',
            width: 80,
          },
          {
            title: 'Launch Date',
            render: (item: HoldingDetailWithFundDetails) => {
              return (
                <span className="text-neutral">
                  {!item.fund?.shareClassDetails?.inceptionDate
                    ? EMPTY_DATA_POINT
                    : dayjs(item.fund?.shareClassDetails?.inceptionDate).format(
                        DISPLAY_DATE_FORMAT,
                      )}
                </span>
              );
            },
            sorter: {
              compare: (
                a: { shareClassDetails: { inceptionDate: string } },
                b: { shareClassDetails: { inceptionDate: string } },
              ) =>
                dayjs(a.shareClassDetails.inceptionDate).diff(
                  b.shareClassDetails.inceptionDate,
                ),
              multiple: 3,
            },
            renderType: 'text' as const,
            align: 'right',
            width: 150,
          },
        ]
      : []),
    ...(!showLayoutForMultiAsset
      ? [
          {
            title: 'First Bought',
            render: (item: HoldingDetailWithFundDetails) => (
              <span className="text-neutral">
                {item.firstBoughtDate
                  ? new Date(item.firstBoughtDate).toLocaleString('default', {
                      year: 'numeric',
                      month: 'short',
                    })
                  : EMPTY_DATA_POINT}
              </span>
            ),
            renderType: 'text' as const,
            sorter: {
              compare: (
                a: { firstBoughtDate: string },
                b: { firstBoughtDate: string },
              ) => a.firstBoughtDate.localeCompare(b.firstBoughtDate),
              multiple: 3,
            },
            width: 150,
          },
        ]
      : []),
  ];
};
