import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import {
  useExploreDefaultFiltersQuery,
  useExploreQuery,
} from '@/pages/app/Explore/context';
import useExploreFilters from '@/pages/app/Explore/hooks/useExploreFilters';
import { convertParamsToState } from '@/pages/app/Explore/useProvideExplore';
import areFiltersApplied from '@/pages/app/Explore/utils/filterComparisonHelpers';
import { formatDate } from '@/utils';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { memo, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLatesAsofDate } from './findLatestAsOfDate';

const ExploreColumnAsOfDate: React.FC<{
  type: 'risk' | 'annualised';
  fundAsOfDate: string | undefined;
}> = ({ type, fundAsOfDate }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { filters } = useExploreFilters();
  const location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const searchParams = useMemo(() => {
    // For some reason location from useLocation is not updated. Maybe because of how we update the url in zustand
    return new URLSearchParams(window.location.search);
  }, [filters, location.search]);

  const { data: defaultfiltersOptions, isLoading: isLoadingDefaultFilters } =
    useExploreDefaultFiltersQuery();

  const { data } = useExploreQuery({
    queryParams: convertParamsToState(
      searchParams,
      {
        categories: defaultfiltersOptions?.categories,
        iaSectors: defaultfiltersOptions?.iaSectors,
        advisors: defaultfiltersOptions?.advisorNames || [],
      },
      true,
    ),
    enabled: !isLoadingDefaultFilters && areFiltersApplied(filters),
  });

  /*
   * we only check the first page, because it is extremely unlikely that the asOfDate is different.
   * Also very heavy to check all pages each scroll (infinite scroll)
   */
  const pagesData = data?.pages[0].data.data ?? [];

  let computedAsOfDate;
  let riskMeasuresDetailsAsOfDate;
  let displayTooltip = false;
  if (type === 'risk') {
    computedAsOfDate = getLatesAsofDate(pagesData, 'computedNavRiskDetails');
    riskMeasuresDetailsAsOfDate = getLatesAsofDate(
      pagesData,
      'riskMeasuresDetail',
      'endDate',
    );
    if (
      riskMeasuresDetailsAsOfDate &&
      riskMeasuresDetailsAsOfDate !== computedAsOfDate
    ) {
      computedAsOfDate = riskMeasuresDetailsAsOfDate;
    }
    displayTooltip = fundAsOfDate !== computedAsOfDate;
  } else if (type === 'annualised') {
    computedAsOfDate = getLatesAsofDate(
      pagesData,
      'computedNavAnnualisedReturns',
    );
    displayTooltip = fundAsOfDate !== computedAsOfDate;
  }

  return (
    <div>
      {displayTooltip && (
        <div>
          <Tooltip
            title={
              fundAsOfDate ? formatDate(fundAsOfDate) : 'No date available'
            }
            placement="bottom"
            color="#313341"
            open={tooltipVisible}
            onOpenChange={setTooltipVisible}
            align={{ offset: [0, 10] }}
            zIndex={50}
          >
            <InfoIcon
              className={cx('icon text-neutral', {
                'text-neutral-100': tooltipVisible,
              })}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default memo(ExploreColumnAsOfDate);
