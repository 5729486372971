import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import { STATIC_DATA_ADD_PORTFOLIO_MODAL } from '@/constants/modals-static-data';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import AddPortfolioModal from '@/partials/Modal/AddPortfolioModal';
import NestedDrawer from '@/partials/NestedDrawer';
import PortfolioRouter from '@/router/Portfolio';
import PortfolioSubRouter from '@/router/PortfolioSub';
import { PORTFOLIO_SUB_ROUTES, generatePaths } from '@/router/routes';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import RestrictedAccessPage from '@/components/RestrictedAccessPage';
import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import BasePageWithMetadata from '../../BasePageWithMetadata';
import Loader from '../../FundAndInvestmentTrust/components/Loader';
import { useProvidePortfolio } from '../useProvidePortfolio';
import NoPortfolioPage from './NoPortfolioPage';
import { PortfoliosScrollTab } from './components';
import style from './style.module.less';

export const PortfolioComponent = () => {
  const history = useHistory();
  const { portfolioId, tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const { isRestrictedAccess } = useUpgradeAccess();

  if (isRestrictedAccess) {
    return (
      <RestrictedAccessPage restrictedFeature={RESTRICTED_FEATURE.PORTFOLIO} />
    );
  }

  const { state, setPortfolio, unmarkAllFundsForDelete, createNewPortfolio } =
    useProvidePortfolio();

  const [portfolioName, setPortfolioName] = useState<string>('');

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (
      !!portfolioId &&
      state.portfolios.length !== 0 &&
      portfolioId !== state.currentPortfolioId
    ) {
      const currentPortfolio =
        state?.portfolios?.find(data => data._id === portfolioId) ??
        state?.portfolios[0];
      setPortfolio(currentPortfolio._id);
    }
    if (portfolioId) {
      const currentPortfolio =
        state?.portfolios?.find(data => data._id === portfolioId) ??
        state?.portfolios[0];
      setPortfolioName(currentPortfolio?.name);
    }
  }, [portfolioId, state.portfolios.length]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (state.markedFundsForDelete.length !== 0) {
      unmarkAllFundsForDelete();
    }
  }, [portfolioId, tab]);
  const [isAddNewPortfolioModalOpen, setIsAddNewPortfolioModalOpen] =
    useState(false);

  const handleAddNewPortfolioModalOpen = () => {
    setIsAddNewPortfolioModalOpen(prev => !prev);
  };

  const onSaveClick = (payload: UpsertPortfolioFields) => {
    return createNewPortfolio({ ...payload, funds: [] });
  };

  const isEmpty = !state.arePortfoliosLoading && state.portfolios.length === 0;

  const menuItems = [
    {
      label: <ElementWithDataTestId label="Import portfolio" />,
      key: 'ImportPortfolio',
      onClick: () => history.push(`/${APP_ROUTE_PORTFOLIOS}/import-portfolio`),
    },
  ];

  return (
    <BasePageWithMetadata
      title={`Portfolio${tab ? ` - ${capitalizeFirstLetter(tab)}` : ''}${
        portfolioName ? ` - ${portfolioName}` : ''
      }`}
    >
      <div className={style['section-portfolio-heading']}>
        <h2 className="text-xl font-bold" data-test-id={'portfolio'}>
          Portfolio
        </h2>
      </div>
      {!isEmpty ? (
        <>
          <div
            className="
              bg-white relative grid gap-1 items-center h-[46px]
              grid-cols-[minmax(0,_1fr),_max-content] px-4
            "
          >
            <div className="w-full">
              <Loader
                loading={state.arePortfoliosLoading}
                component={<PortfoliosScrollTab />}
              />
            </div>
            <div className="z-1 border-primary flex items-center gap-x-2 min-w-0">
              <Button
                className="max-md:!p-2 min-w-10 !h-10"
                type="primary"
                onClick={() => {
                  setIsAddNewPortfolioModalOpen(true);
                }}
                data-test-id="portfolioAddButton"
              >
                <AddIcon className="icon text-md" />
                <span className="hidden text-sm font-medium md:block landscape:block">
                  Add Portfolio
                </span>
              </Button>
              <div data-test-id="dropDownMeatBallMenu" className="m-auto">
                <Dropdown
                  data-test-id="portfolioAddButton"
                  menu={{ items: isMobile ? [] : menuItems }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <MeatballMenu
                    data-test-id="portfolioMeatballMenu"
                    className="
                      p-2 rounded-full border border-light cursor-pointer self-center
                      [&_span]:text-[#6f707a] fill-neutral-100 hover:bg-[#e6e9ee]
                      max-md:min-w-9 h-9 hover:shadow-md transition-shadow duration-300
                    "
                    onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                  />
                </Dropdown>
              </div>
              {isMobile && (
                <NestedDrawer
                  visible={isNestedDrawerVisible}
                  menuItems={menuItems}
                  onClose={() => setIsNestedDrawerVisible(false)}
                  title="Select"
                />
              )}
            </div>
          </div>
          <div className={cx('py-4 lg:px-4')}>
            <PortfolioSubRouter />
          </div>
        </>
      ) : (
        <NoPortfolioPage
          setIsAddNewPortfolioModalOpen={setIsAddNewPortfolioModalOpen}
        />
      )}
      <AddPortfolioModal
        entities={state.portfolios}
        isVisible={isAddNewPortfolioModalOpen}
        toggleModal={handleAddNewPortfolioModalOpen}
        onSaveClick={onSaveClick}
        staticModalData={STATIC_DATA_ADD_PORTFOLIO_MODAL}
      />
    </BasePageWithMetadata>
  );
};

const Portfolio: React.FC<PropsWithChildren> = () => {
  const { fetchPortfolios } = useProvidePortfolio();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    fetchPortfolios();
  }, []);

  return (
    <section className={style['section-portfolio']}>
      <Switch>
        <Route
          path={[
            ...generatePaths(PORTFOLIO_SUB_ROUTES, APP_ROUTE_PORTFOLIOS),
          ].map(p => {
            return `/${p}`;
          })}
        >
          <PortfolioComponent />
        </Route>
        <PortfolioRouter />
      </Switch>
    </section>
  );
};

export default Portfolio;
