import { FundBroadAssetClass } from '@aminsights/contract';
import { LEGAL_STRUCTURE } from '@aminsights/shared';

import {
  SLIDER_MAX_RISK_RATING,
  SLIDER_MIN,
  SLIDER_MIN_RISK_RATING,
} from '@/constants';

import { countSelectedFilter } from '../utils/countSelectedFilter';
import { RiskRatingFilterState } from './riskRatingSubState';

export const SLIDER_MAX_NO_HOLDINGS = 96;
export const SLIDER_MAX_YIELD = 96;
export const SLIDER_STEP = 16;

export const yieldMarks: Record<number, string> = {
  0: '0',
  16: '2%',
  32: '4%',
  48: '6%',
  64: '8%',
  80: '10%',
  96: '12%>',
};

export enum SearchBoxKeyType {
  MANAGEMENT_GROUP = 'managementGroup',
  DOMICILES = 'domiciles',
  CURRENCIES = 'currencies',
  PLATFORMS = 'platforms',
}

export enum SearchBoxFilterType {
  MANAGEMENT_GROUP_FILTER = 'managementGroupFilter',
  DOMICILES_FILTER = 'domicilesFilter',
  CURRENCIES_FILTER = 'currenciesFilter',
  PLATFORMS_FILTER = 'platformsFilter',
}

export enum SearchBoxListType {
  MANAGEMENT_GROUP_LIST = 'managementGroupList',
  DOMICILES_LIST = 'domicilesList',
  CURRENCIES_LIST = 'currenciesList',
  PLATFORMS_LIST = 'platformsList',
}

export const noOfHoldingMarks: Record<number, string> = {
  0: '0',
  16: '35',
  32: '50',
  48: '75',
  64: '100',
  80: '125',
  96: '150>',
};

export interface MoreFilterState {
  yieldRange?: number[];
  noOfHoldingsRange?: number[];
  selectedManagementGroup?: string[];
  selectedBroadAssetClasses?: FundBroadAssetClass[];
  legalStructure?: LEGAL_STRUCTURE[];
  selectedDomiciles?: string[];
  selectedCurrencies?: string[];
  selectedPlatforms?: string[];
  filterByOldestShareClassOnly?: boolean;
  filterByActiveOrPassive?: string[];
  genders?: string[];
  currencyHedgedType?: string[];
  riskRating?: RiskRatingFilterState;
}

export const YIELD_MATURITY_SLIDER_MAX = 99;

export const moreInitialState: MoreFilterState = {
  yieldRange: [SLIDER_MIN, SLIDER_MAX_YIELD],
  noOfHoldingsRange: [SLIDER_MIN, SLIDER_MAX_NO_HOLDINGS],
  selectedManagementGroup: [],
  selectedBroadAssetClasses: [],
  legalStructure: undefined,
  selectedDomiciles: [],
  selectedCurrencies: [],
  selectedPlatforms: [],
  filterByOldestShareClassOnly: false,
  filterByActiveOrPassive: undefined,
  genders: undefined,
  currencyHedgedType: undefined,
  riskRating: {
    defaqto: [SLIDER_MIN_RISK_RATING, SLIDER_MAX_RISK_RATING],
    dynamicPlanner: [SLIDER_MIN_RISK_RATING, SLIDER_MAX_RISK_RATING],
    ev: [SLIDER_MIN_RISK_RATING, SLIDER_MAX_RISK_RATING],
    oxford: [SLIDER_MIN_RISK_RATING, SLIDER_MAX_RISK_RATING],
    synaptic: [SLIDER_MIN_RISK_RATING, SLIDER_MAX_RISK_RATING],
  },
};

export const countMoreFilters = (filterState?: MoreFilterState) => {
  if (!filterState) return 0;

  const a = [
    !!filterState.yieldRange,
    !!filterState.noOfHoldingsRange,
    !!filterState.selectedManagementGroup,
    !!filterState.selectedBroadAssetClasses,
    !!filterState.legalStructure,
    !!filterState.selectedDomiciles,
    !!filterState.selectedCurrencies,
    !!filterState.selectedPlatforms,
    !!filterState.filterByOldestShareClassOnly,
    !!filterState.filterByActiveOrPassive,
    !!filterState.genders,
    !!filterState.currencyHedgedType,
    !!filterState.riskRating?.defaqto,
    !!filterState.riskRating?.dynamicPlanner,
    !!filterState.riskRating?.ev,
    !!filterState.riskRating?.oxford,
    !!filterState.riskRating?.synaptic,
    !!filterState.riskRating?.dynamicPlannerType,
  ];

  return countSelectedFilter(a);
};
