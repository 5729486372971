import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Tooltip } from 'antd';
import cx from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useCreateAnalysts,
  useGetAnalysts,
  useUpdateAnalysts,
} from '@/hooks/query-hooks/research-hooks/analysts-hooks';
import useUserAccess from '@/hooks/useUserAccess';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import SubCardSkeletonLoader from '../Loaders/SubCard';
import AnalystForm from './AnalystForm';
import AnalystFormModal from './AnalystFormModal';
import AnalystListing from './AnalystListing';

export const MAX_ANALISTS = 3;

interface AnalystsProps {
  fundMetadataId?: string;
}

const Analyst: React.FC<PropsWithChildren<AnalystsProps>> = () => {
  const [showAnalystForm, setShowAnalystForm] = useState(false);
  const [seletectAnalysts, setSelectedAnalysts] = useState<string[]>([]);
  const { data: usersData } = useOrganizationUsers();
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAnalysts(fundMetadataId);
  const createAnalysts = useCreateAnalysts();
  const updateAnalysts = useUpdateAnalysts();
  const { dispatch: dispatchApp } = useAppContext();
  const screenWidthMode = getScreenWidthMode();
  const { hasPermissions } = useUserAccess();

  useEffect(() => {
    if (data) setSelectedAnalysts(data.analysts);
  }, [data]);

  const handleAssignAnalysts = () => {
    if (!data) {
      createAnalysts.mutateAsync({
        fundMetadataId,
        body: { fundMetadataId, analysts: seletectAnalysts },
      });
    } else {
      updateAnalysts
        .mutateAsync({
          fundMetadataId,
          analystsId: data._id,
          body: { fundMetadataId, analysts: seletectAnalysts },
        })
        .then(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Analysts has been added' },
          });
        });
    }
    setShowAnalystForm(false);
  };

  const handleRemoveAnalyst = (id: string, name: string) => {
    if (!data) return;
    updateAnalysts
      .mutateAsync({
        fundMetadataId,
        analystsId: data._id,
        body: {
          fundMetadataId,
          analysts: seletectAnalysts.filter(analyst => analyst !== id),
        },
      })
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: `${name} has been removed` },
        });
      });
  };

  const hasResearchWritePermissions = hasPermissions([
    USER_PERMISSIONS.research.write,
  ]);

  const maxAnalystsReached = seletectAnalysts.length >= MAX_ANALISTS;
  const hasAssignedAnalysts = Boolean(data?.analysts?.length);
  return (
    <>
      {isLoading ? (
        <div className="flex flex-col self-stretch min-h-48 p-4 pb-6 lg:rounded-lg bg-white">
          <SubCardSkeletonLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 self-stretch min-h-48 p-4 pb-6 lg:rounded-lg bg-white">
          <div>
            <div className="flex justify-between items-center">
              <h4 className="text-sm font-bold text-neutral-200">Analyst</h4>
              {hasAssignedAnalysts &&
                hasResearchWritePermissions &&
                !showAnalystForm && (
                  <Tooltip
                    title={
                      maxAnalystsReached
                        ? 'You can only assign up to three analysts per fund.'
                        : ''
                    }
                  >
                    <Button
                      className={cx(
                        'w-auto h-10 px-4 py-2 text-sm font-medium rounded',
                        'disabled:border-none disabled:bg-neutral-300 disabled:text-white',
                      )}
                      type="primary"
                      onClick={() => setShowAnalystForm(true)}
                      disabled={maxAnalystsReached}
                    >
                      Add an analyst
                    </Button>
                  </Tooltip>
                )}
            </div>
            {showAnalystForm && screenWidthMode[ScreenWidthEnum.MinSm] ? (
              <AnalystForm
                selectedAnalysts={seletectAnalysts}
                options={(usersData ?? []).map(user => ({
                  value: user.user_id,
                  label: user.name,
                }))}
                toggleForm={() => {
                  setSelectedAnalysts(data?.analysts || []);
                  setShowAnalystForm(prev => !prev);
                }}
                onChangeAnalysts={setSelectedAnalysts}
                onSubmitForm={handleAssignAnalysts}
              />
            ) : (
              <AnalystListing
                analysts={(usersData ?? []).filter(user =>
                  seletectAnalysts.includes(user.user_id),
                )}
                onShowAnalystForm={() => setShowAnalystForm(true)}
                onRemoveAnalyst={handleRemoveAnalyst}
              />
            )}
            <AnalystFormModal
              isVisible={showAnalystForm}
              selectedAnalysts={seletectAnalysts}
              options={(usersData ?? []).map(user => ({
                value: user.user_id,
                label: user.name,
              }))}
              close={() => setShowAnalystForm(prev => !prev)}
              onChangeAnalysts={setSelectedAnalysts}
              onSubmitForm={handleAssignAnalysts}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Analyst;
