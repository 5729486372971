import { CommentBody, InternalApi } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { INTERNAL } from '../watchlist-hooks/query-keys';

const internalApi = new InternalApi(openApiConfig());

const invalidateInternal = () => {
  queryClient.invalidateQueries({ queryKey: [INTERNAL] });
};

export const useCreateComment = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { body: CommentBody }) => internalApi.createComment(input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Commentary has been added' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error adding commentary',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateComment = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { commentId: string; body: CommentBody }) =>
      internalApi.updateComment(input.commentId, input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Commentary has been updated' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating commentary',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteComment = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { commentId: string }) =>
      internalApi.deleteComment(input.commentId),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Commentary has been deleted' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting commentary',
        });
      },
    },
  );
  return mutation;
};

export const usePinComment = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { commentId: string }) => internalApi.pinComment(input.commentId),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully pinned a commentary' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error pinning a commentary',
        });
      },
    },
  );

  return mutation;
};

export const useUnpinComment = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { commentId: string }) => internalApi.unpinComment(input.commentId),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully unpinned a commentary' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error unpinning a commentary',
        });
      },
    },
  );

  return mutation;
};
