import {
  getFundOrInvestmentTrustTitle,
  isAllocationBroadAssetClass,
  isEquityBroadAssetClass,
} from '@aminsights/shared';
import { Tabs } from 'antd';
import cx from 'classnames';

import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { useState } from 'react';
import EquityStats from '../InfoAndStats/EquityStats';
import Fees from '../InfoAndStats/Fees';
import FundAndTrustBasic from '../InfoAndStats/FundAndTrustBasic';
import Income from '../InfoAndStats/Income';
import Summary from '../InfoAndStats/Summary';
import Platforms from '../Platforms';
import style from './style.module.less';
import { TAB_KEYS } from './types';

const Overview = () => {
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const { data } = useFundByIsInParam();
  const [activeTab, setActiveTab] = useState(TAB_KEYS.DETAILS);

  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const broadAssetClass = data?.fund.broadAssetClass ?? '';
  const isEquity = isEquityBroadAssetClass(broadAssetClass);
  const isMultiAsset = isAllocationBroadAssetClass(broadAssetClass);

  const handleTabChange = (key: string) => {
    if (isRestrictedAccess && key === TAB_KEYS.EQUITY_STATS) {
      toggleUpgradeModal(RESTRICTED_FEATURE.FUND_EQUITY_STATS);
    } else {
      setActiveTab(key as TAB_KEYS);
    }
  };

  return (
    <div className={cx('flex flex-col gap-y-5', 'max-w-[100vw]')}>
      <Summary />
      <div
        className={cx(style['section'], 'lg:rounded-t-lg')}
        data-test-id="fundDetailsSummaryTabFundDetails"
      >
        <Tabs
          className="sm:max-w-[unset] [&_.ant-table-cell]:w-1/2"
          activeKey={activeTab}
          onChange={handleTabChange}
          items={[
            {
              key: TAB_KEYS.DETAILS,
              label: (
                <span data-test-id="detailsTab">
                  {getFundOrInvestmentTrustTitle(!!isInvestmentTrust)} Details
                </span>
              ),
              children: <FundAndTrustBasic />,
            },
            {
              key: TAB_KEYS.FEES,
              label: <span data-test-id="feesTab">Fees</span>,
              children: <Fees />,
            },
            {
              key: TAB_KEYS.INCOME,
              label: <span data-test-id="incomeTab">Income</span>,
              children: <Income />,
            },
            ...(isEquity || isMultiAsset
              ? [
                  {
                    key: TAB_KEYS.EQUITY_STATS,
                    label: (
                      <div
                        data-test-id="equityStatsTab"
                        className="flex gap-x-2 group"
                      >
                        Equity Stats
                        {isRestrictedAccess && (
                          <LockIcon
                            className={cx(
                              'fill-neutral-100 group-hover:fill-primary-400',
                              'w-4 h-4 transition-all duration-300 ease-in-out',
                            )}
                          />
                        )}
                      </div>
                    ),
                    children: <EquityStats />,
                  },
                ]
              : []),
            {
              key: TAB_KEYS.PLATFORMS,
              label: <span data-test-id="platformsTab">Platforms</span>,
              children: <Platforms />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Overview;
