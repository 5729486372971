import ResearchLegend from '@/components/ResearchLegend';
import { Skeleton } from 'antd';
import React from 'react';

interface SkeletonButtonGroupProps {
  isLoading: boolean;
  label: string;
  buttonStyles?: { width: string; height: string }[];
}

const HeaderButtonGroup: React.FC<SkeletonButtonGroupProps> = ({
  isLoading,
  label,
  buttonStyles,
}) => {
  return isLoading ? (
    <div className="flex flex-row justify-between gap-y-4">
      <div>
        <Skeleton.Button
          className="flex"
          active
          size="small"
          style={
            buttonStyles ? buttonStyles[0] : { width: '96px', height: '13px' }
          }
        />
      </div>
      <div className="flex gap-x-2">
        <Skeleton.Button
          className="flex"
          active
          size="small"
          style={
            buttonStyles ? buttonStyles[1] : { width: '48px', height: '13px' }
          }
        />
        <Skeleton.Button
          className="flex"
          active
          size="small"
          style={
            buttonStyles ? buttonStyles[2] : { width: '56px', height: '13px' }
          }
        />
      </div>
    </div>
  ) : (
    <div className="w-full flex justify-between">
      <h5 className="text-sm font-bold text-darkest">{label}</h5>
      <div className="flex justify-between gap-4">
        <ResearchLegend className="gap-4" />
      </div>
    </div>
  );
};

export default HeaderButtonGroup;
