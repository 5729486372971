import { SignificantChangesItem } from '@aminsights/contract';
import {
  ChangeTypeEnum,
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  formatChangeType,
  formatTotalChange,
  getFundManagerDescription,
  getShortHumanReadableStyleboxName,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';

interface SectionItemProps {
  data: SignificantChangesItem;
  length: number;
}

const GetChangeColumn: React.FC<PropsWithChildren<SectionItemProps>> = ({
  data,
  length,
}) => {
  const [caretChange, setCaretChange] = useState<React.ReactNode>(null);
  const [managerChangeColumnData, setManagerChangeColumnData] =
    useState<React.ReactNode>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (data.newValue !== undefined && data.oldValue !== undefined) {
      const isChange = data.newValue > data.oldValue;
      setCaretChange(
        <span
          className={cx(isChange ? 'text-success-trend' : 'text-danger-trend')}
        >
          {' '}
          {isChange ? <CaretUpOutlined /> : <CaretDownOutlined />}{' '}
          {formatTotalChange(data)}%
        </span>,
      );
    }
    if (
      data.changeType === ChangeTypeEnum.Flow &&
      data.pctChange !== undefined
    ) {
      const isChangeUpward = data.trend === 'Upward';
      setCaretChange(
        <span
          className={cx(
            isChangeUpward ? 'text-success-trend' : 'text-danger-trend',
          )}
        >
          {' '}
          {isChangeUpward ? <CaretUpOutlined /> : <CaretDownOutlined />}{' '}
          {formatChangeType(
            data.changeType,
            Number(data.pctChangeAbsolute),
            data.broadAssetClass,
          )}
        </span>,
      );
    }
  }, [data.newValue, data.oldValue, data.pctChangeAbsolute, data.pctChange]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const managerChangeObject = getFundManagerDescription(data, length);

    if (managerChangeObject) {
      setManagerChangeColumnData(
        <div className="text-neutral-700 font-normal">
          <span>
            <Tooltip
              placement="bottom"
              title={
                !!managerChangeObject.incomingManagerDescriptionTruncated &&
                managerChangeObject.incomingManagerDescription
              }
              overlayClassName="md:max-w-none"
              overlayInnerStyle={{ borderRadius: 4 }}
              arrowPointAtCenter
            >
              {managerChangeObject.incomingManagerDescriptionTruncated ||
                managerChangeObject.incomingManagerDescription}
            </Tooltip>
          </span>
          {managerChangeObject.incomingManagerDescription &&
            managerChangeObject.removedManagerDescription && <br />}
          <span>
            <Tooltip
              placement="bottom"
              title={
                !!managerChangeObject.removedManagerDescriptionTruncated &&
                managerChangeObject.removedManagerDescription
              }
              overlayClassName="md:max-w-none"
              overlayInnerStyle={{ borderRadius: 4 }}
              arrowPointAtCenter
            >
              {managerChangeObject.removedManagerDescriptionTruncated ||
                managerChangeObject.removedManagerDescription}
            </Tooltip>
          </span>
        </div>,
      );
    }
  }, [data.newManagerList, data.oldManagerList]);

  if (data.changeType === ChangeTypeEnum.ManagerChange) {
    return <>{managerChangeColumnData}</>;
  }

  if (data.changeType === ChangeTypeEnum.StyleDrift) {
    if (!data.broadAssetClass || (!data.newValue && !data.oldValue))
      return <>{EMPTY_DATA_POINT}</>;
    const boxLabels = isFixedIncomeBroadAssetClass(data.broadAssetClass)
      ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
      : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ;

    return (
      <div className="text-neutral-700 font-normal">
        {getShortHumanReadableStyleboxName(data.oldValue, boxLabels)} to{' '}
        {getShortHumanReadableStyleboxName(data.newValue, boxLabels)}
      </div>
    );
  }

  if (data.changeType === ChangeTypeEnum.Flow) {
    return (
      <div className="text-neutral-700 font-normal">
        {caretChange} (est.{' '}
        {formatChangeType(
          data.changeType,
          Number(data.newValue),
          data.broadAssetClass,
          true,
        )}
        )
      </div>
    );
  }

  if (
    data.changeType === ChangeTypeEnum.FundSize ||
    data.changeType === ChangeTypeEnum.ModifiedDuration
  ) {
    return (
      <div className="text-neutral-700 font-normal">
        {caretChange}
        {caretChange ? ' to ' : ''}
        {formatChangeType(
          data.changeType,
          Number(data.newValue),
          data.broadAssetClass,
        )}
      </div>
    );
  }

  return (
    <div className="text-neutral-700 font-normal">
      {caretChange}
      <span>
        {' '}
        {caretChange ? 'to' : ''}{' '}
        {formatChangeType(
          data.changeType || '',
          Number(data.newValue),
          data.broadAssetClass,
        )}
      </span>
    </div>
  );
};

export default GetChangeColumn;
