import './style.less';

import { YesNoOption } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import dayjs from 'dayjs';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { DataTable } from '@/components/Table';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { ESGBadge } from '@/partials/ESG/ESGBadge';
import { ESGLegend } from '@/partials/ESG/ESGLegend';

interface ESGData {
  id: string;
  label: React.ReactNode;
  info: React.ReactNode;
}

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    idSubText: 'type',
    width: 200,
    render: (data: ESGData) => {
      return <div>{data.label}</div>;
    },
    renderType: 'text',
  },
  {
    title: 'Info',
    idSubText: 'info',
    width: 200,
    render: (data: ESGData) => {
      return <div>{data.info}</div>;
    },
    renderType: 'text',
  },
];

const InfoStatsESG: React.FC<PropsWithChildren> = () => {
  const [data, setData] = useState<ESGData[]>([]);
  const { data: state } = useFundByIsInParam();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (state?.fund) {
      const { fund } = state;
      setData([
        {
          id: 'SFDR',
          label: (
            <div>
              <b>SFDR - Sustainable Finance Disclosure Regulation </b>(Fund)
            </div>
          ),
          info: fund.sfdrClass || EMPTY_DATA_POINT,
        },
        {
          id: 'TCFD',
          label: (
            <div>
              <b>TCFD - Task Force on Climate-Related Financial Disclosure </b>
              (Provider)
            </div>
          ),
          info:
            (fund.tcfdDate && dayjs(fund.tcfdDate).format('D MMM YYYY')) ||
            EMPTY_DATA_POINT,
        },
        {
          id: 'UNPRI',
          label: (
            <div>
              <b>UNPRI - UN Principles for Responsible Investment </b>(Provider)
            </div>
          ),
          info:
            (fund.unpriDate && dayjs(fund.unpriDate).format('D MMM YYYY')) ||
            EMPTY_DATA_POINT,
        },

        {
          id: 'NetZero',
          label: (
            <div>
              <b>Net Zero - Net Zero Asset Managers </b>(Provider)
            </div>
          ),
          info: fund.netZeroAssetManagers ? YesNoOption.Yes : EMPTY_DATA_POINT,
        },
        {
          id: 'UKSC',
          label: (
            <div>
              <b>UK SC - Stewardship Code </b>(Provider)
            </div>
          ),
          info: fund.stewardshipCode ? YesNoOption.Yes : EMPTY_DATA_POINT,
        },
        {
          id: 'F4B',
          label: (
            <div>
              <b>F4B - Finance for Biodiversity </b>(Provider)
            </div>
          ),
          info:
            (fund.financeForBiodiversityDate &&
              dayjs(fund.financeForBiodiversityDate).format('D MMM YYYY')) ||
            EMPTY_DATA_POINT,
        },
        {
          id: 'ACT',
          label: (
            <div>
              <b>City Hive ACT Corporate Culture Standard</b>
              <br />
              (Provider)
            </div>
          ),
          info: fund.actDate || EMPTY_DATA_POINT,
        },
        {
          id: 'Ranking',
          label: (
            <div>
              <b>Ranking</b>
              <span>
                <br />
                ShareAction 2023 Survey
              </span>
            </div>
          ),
          info: fund.shareActionSurvey?.ranking || EMPTY_DATA_POINT,
        },
        {
          id: 'Rating',
          label: (
            <div>
              <b>Rating</b>
              <span>
                <br />
                ShareAction 2023 Survey
              </span>
            </div>
          ),
          info: fund.shareActionSurvey?.rating || EMPTY_DATA_POINT,
        },
        {
          id: 'Governance',
          label: (
            <div>
              <b>Governance</b>
              <span>
                <br />
                ShareAction 2023 Survey
              </span>
            </div>
          ),
          info: (
            <ESGBadge
              value={fund.shareActionSurvey?.responsibleInvestmentGovernance}
            />
          ),
        },
        {
          id: 'Stewardship',
          label: (
            <div>
              <b>Stewardship</b>
              <br />
              <span>ShareAction 2023 Survey</span>
            </div>
          ),
          info: <ESGBadge value={fund.shareActionSurvey?.stewardship} />,
        },
        {
          id: 'Climate',
          label: (
            <div>
              <b>Climate</b>
              <br />
              <span>ShareAction 2023 Survey</span>
            </div>
          ),
          info: <ESGBadge value={fund.shareActionSurvey?.climateChange} />,
        },
        {
          id: 'Biodiversity',
          label: (
            <div>
              <b>Biodiversity</b>
              <br />
              <span>ShareAction 2023 Survey</span>
            </div>
          ),
          info: <ESGBadge value={fund.shareActionSurvey?.biodiversity} />,
        },
        {
          id: 'Social',
          label: (
            <div>
              <b>Social</b>
              <br />
              <span>ShareAction 2023 Survey</span>
            </div>
          ),
          info: <ESGBadge value={fund.shareActionSurvey?.social} />,
        },
      ]);
    }
  }, [state?.fund]);

  return (
    <div className="info-stats-esg">
      <DataTable uniqueKey="id" columns={columns} data={data} />
      <ESGLegend />
    </div>
  );
};

export default InfoStatsESG;
