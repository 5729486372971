import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import {
  BACK_BUTTON_HEIGHT,
  NAVBAR_HEIGHT,
  RESTRICTED_FEATURE,
  TAB_HEIGHT,
} from '@/constants';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import useUserAccess from '@/hooks/useUserAccess';
import Portfolio from '@/pages/app/FundAndInvestmentTrust/components/Portfolio';
import ShareClasses from '@/pages/app/FundAndInvestmentTrust/components/ShareClasses';
import SignificantChanges from '@/pages/app/FundAndInvestmentTrust/components/SignificantChanges';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import {
  FUND_DETAILS_TAB,
  FundType,
  USER_PERMISSIONS,
  getFundType,
} from '@aminsights/shared';
import { Tabs } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Internal from './Internal';
import Investors from './Investors';
import PerfRisk from './PerfRisk';
import ResearchLayout from './Research/ResearchLayout';
import Summary from './Summary';
import SummaryMPS from './Summary/SummaryMPS';

interface IFundTabsProps {
  sectionHeight?: number;
}

interface IFundTabs {
  title: React.ReactNode;
  component: React.ReactNode;
  fundType?: FundType;
  disabled?: boolean;
  tabId: string;
  dataTestId?: string;
}

const EXTRA_PADDING = 16;
const HEIGHT = BACK_BUTTON_HEIGHT + TAB_HEIGHT + NAVBAR_HEIGHT + EXTRA_PADDING;

enum FUND_DETAILS_DATA_TEST_ID {
  SUMMARY = 'fundDetailsTabSummary',
  PERFORMANCE_AND_RISK = 'fundDetailsTabPerfAndRisk',
  PORTFOLIO = 'fundDetailsTabPortfolio',
  PLATFORMS = 'fundDetailsTabPlatforms',
  SIGNIFICANT_CHANGES = 'fundDetailsTabSignificantChanges',
  SHARE_CLASSES = 'fundDetailsTabShareClasses',
  INVESTORS = 'fundDetailsTabInvestors',
  INTERNAL = 'fundDetailsTabInternal',
  RESEARCH = 'fundDetailsTabResearch',
}

export const FundDetailTabs: React.FC<PropsWithChildren<IFundTabsProps>> = ({
  sectionHeight,
}) => {
  const { id: fundId } = useParams<{ id: string }>();
  const history = useHistory();
  const { data: fundDetailsState, isLoading } = useFundByIsInParam();
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const screenWidthMode = getScreenWidthMode();
  const { hasPermissions } = useUserAccess();
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const { state: featureSwitchState } = useFeatureSwitchContext();
  const { tab } = useParams<{ tab: string }>();

  const evaluateFundDetailsSubRoute = (tab?: string) => {
    return tab && Object.values(FUND_DETAILS_TAB).some(p => p === tab)
      ? tab
      : FUND_DETAILS_TAB.SUMMARY;
  };

  const hasInternalReadPermissions = hasPermissions([
    USER_PERMISSIONS.internal.read,
  ]);

  const hasResearchReadPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const FUND_TABS: IFundTabs[] = useMemo(() => {
    const result: IFundTabs[] = [];
    const fundType = getFundType(fundDetailsState?.fund?.legalStructure || '');
    if (fundType !== FundType.MPS) {
      result.push({
        title: 'Summary',
        component: <Summary />,
        tabId: FUND_DETAILS_TAB.SUMMARY,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.SUMMARY,
      });
    } else {
      result.push({
        title: 'Summary',
        component: <SummaryMPS />,
        tabId: FUND_DETAILS_TAB.SUMMARY,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.SUMMARY,
      });
    }
    result.push(
      {
        title: 'Perf & Risk',
        component: <PerfRisk />,
        tabId: FUND_DETAILS_TAB.PERFORMANCE_AND_RISK,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.PERFORMANCE_AND_RISK,
      },
      {
        title: 'Portfolio',
        component: <Portfolio />,
        tabId: FUND_DETAILS_TAB.PORTFOLIO,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.PORTFOLIO,
      },
    );

    if (fundType !== FundType.MPS) {
      result.push(
        {
          title: 'Significant Changes',
          component: (
            <SignificantChanges
              isins={
                fundDetailsState?.fund?._id ? [fundDetailsState.fund?._id] : []
              }
            />
          ),
          tabId: FUND_DETAILS_TAB.SIGNIFICANT_CHANGES,
          dataTestId: FUND_DETAILS_DATA_TEST_ID.SIGNIFICANT_CHANGES,
        },
        {
          title: 'Share Classes',
          component: <ShareClasses />,
          fundType: FundType.FUND,
          tabId: FUND_DETAILS_TAB.SHARE_CLASSES,
          dataTestId: FUND_DETAILS_DATA_TEST_ID.SHARE_CLASSES,
        },
        ...(!featureSwitchState.isAppLimitedAccessEnabled
          ? [
              {
                title: 'Investors',
                component: <Investors />,
                tabId: FUND_DETAILS_TAB.INVESTORS,
                dataTestId: FUND_DETAILS_DATA_TEST_ID.INVESTORS,
              },
            ]
          : []),
        ...(!featureSwitchState.isAppLimitedAccessEnabled &&
        hasInternalReadPermissions
          ? [
              {
                title: 'Internal',
                component: <Internal />,
                tabId: FUND_DETAILS_TAB.INTERNAL,
                dataTestId: FUND_DETAILS_DATA_TEST_ID.INTERNAL,
              },
            ]
          : []),
      );
    }

    if (hasResearchReadPermissions) {
      result.push({
        title: 'Research',
        component: <ResearchLayout />,
        tabId: FUND_DETAILS_TAB.RESEARCH,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.RESEARCH,
      });
    }

    return result;
  }, [
    buckets,
    fundDetailsState?.fund?._id,
    hasInternalReadPermissions,
    featureSwitchState.isAppLimitedAccessEnabled,
  ]);

  if (isLoading || !fundDetailsState) return null;

  return (
    <div className="[&_.ant-tabs-nav]:bg-white [&_.ant-tabs-nav]:border-b [&_.ant-tabs-nav]:border-solid [&_.ant-tabs-nav]:border-grey-light-50 [&_.ant-tabs-nav]:w-full [&_.ant-tabs-tabpane]:overflow-auto">
      <Tabs
        defaultActiveKey={tab}
        activeKey={tab}
        className="[&>.ant-tabs-nav]:mb-4"
        onChange={(tab: string) => {
          if (isRestrictedAccess && tab === FUND_DETAILS_TAB.INVESTORS) {
            toggleUpgradeModal(RESTRICTED_FEATURE.FUND_INVESTORS);
            return;
          }
          history.replace(`/fund/${fundId}/details/${tab}`);
        }}
        items={FUND_TABS.filter(
          item =>
            item.fundType ===
              getFundType(fundDetailsState.fund.legalStructure) ||
            !item.fundType,
        ).map(({ title, component, disabled, tabId, dataTestId }) => ({
          label: (
            <div data-test-id={dataTestId} className="flex gap-x-2 group">
              {title}
              {isRestrictedAccess && tabId === FUND_DETAILS_TAB.INVESTORS && (
                <LockIcon
                  className={cx(
                    'fill-neutral-100 group-hover:fill-primary-400',
                    'w-4 h-4 transition-all duration-300 ease-in-out',
                  )}
                />
              )}
            </div>
          ),
          key: tabId,
          children: component,
          style: {
            height: screenWidthMode[ScreenWidthEnum.MinMd]
              ? `calc(100vh - ${sectionHeight && sectionHeight + HEIGHT}px)`
              : 'auto',
          },
          disabled,
        }))}
      />
    </div>
  );
};
