import { Fund } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  FundType,
  closePrice,
  getFundType,
  isFixedIncomeBroadAssetClass,
  shortenLongNumber,
} from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import React from 'react';

import { ComingSoonBadge } from '@/components/Badges/ComingSoon';
import { UseGetCurrencyRatesResponse } from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';

export interface ISection {
  label: string;
  value?: string | React.ReactNode;
  fundType?: FundType; // show section if fundType is not set
  date?: string;
  dataTestId: string;
}

export const formHeadingInfo = (
  rates: UseGetCurrencyRatesResponse | undefined,
  isMobile: boolean,
  fund?: Fund,
): ISection[] => {
  const hasEquityAndBroadClassStyleBox =
    typeof fund?.equityStyleBox === 'number' &&
    fund?.equityStyleBox >= 1 &&
    typeof fund?.bondStatistics?.styleBox === 'number' &&
    fund?.bondStatistics?.styleBox >= 1;

  const fundType = fund?.legalStructure && getFundType(fund.legalStructure);

  const formSection: ISection[] = [
    {
      label: 'Price',
      value: closePrice(fund),
      // TODO: probably we can get rid of fundType: FundType.INVESTMENT_TRUST here - and related places,
      // since we use isInvestmentTrust arg - otherwise we can confuse ourselves.
      fundType: FundType.INVESTMENT_TRUST,
      date:
        (fund?.closePrice &&
          `${fund.closePrice.currencyId}, as of ${dayjs(
            fund.closePrice.date,
          ).format(DISPLAY_DATE_FORMAT)}`) ||
        undefined,
      dataTestId: 'Price',
    },
    {
      label: (() => {
        switch (fundType) {
          case FundType.MPS:
            return 'MPS Assets';
          case FundType.INVESTMENT_TRUST:
            return 'Market Cap';
          default:
            return 'Fund Size';
        }
      })(),
      value:
        !fund || !fund.fundSize || !fund.fundSize?.value || !rates?.currencyCode
          ? EMPTY_DATA_POINT
          : `${getSymbolFromCurrency(rates?.currencyCode)}${shortenLongNumber(
              fund.fundSize?.value,
            )}`,
      date:
        (fund?.fundSize?.date &&
          `As of ${dayjs(fund.fundSize?.date).format(DISPLAY_DATE_FORMAT)}`) ||
        undefined,
      dataTestId: (() => {
        switch (fundType) {
          case FundType.MPS:
            return 'MPSAssets';
          case FundType.INVESTMENT_TRUST:
            return 'MarketCap';
          default:
            return 'FundSize';
        }
      })(),
    },
    {
      label: 'OCF',
      value:
        fund &&
        (fund.ocfPct ? `${fund.ocfPct.toFixed(2) ?? ''}%` : EMPTY_DATA_POINT),
      date:
        (fund?.ocfPct &&
          fund.ocfDate &&
          `As of ${dayjs(fund.ocfDate).format(DISPLAY_DATE_FORMAT)}`) ||
        undefined,
      dataTestId: 'OCF',
    },
  ];

  if (fund && fundType === FundType.MPS) {
    formSection.push({
      label: 'DFM Fee',
      value: fund.dfmFee
        ? `${fund.dfmFee.toFixed(2) ?? ''}%`
        : EMPTY_DATA_POINT,
      dataTestId: 'dfmFee',
    });
  }

  if (fund && isFixedIncomeBroadAssetClass(fund.broadAssetClass)) {
    formSection.push({
      label: 'Modified Duration',
      fundType: FundType.FUND,
      value:
        fund &&
        (fund.broadAssetClassValues?.bonds?.modifiedDuration
          ? fund.broadAssetClassValues?.bonds?.modifiedDuration.toFixed(1)
          : EMPTY_DATA_POINT),
      date:
        (fund?.portfolioUpdateDateMs &&
          `As of ${dayjs(fund.portfolioUpdateDateMs).format(
            DISPLAY_DATE_FORMAT,
          )}`) ||
        undefined,
      dataTestId: 'ModifiedDuration',
    });
    if (!isMobile) {
      formSection.push({
        label: 'Yield to Maturity',
        fundType: FundType.FUND,
        value:
          fund &&
          (fund.broadAssetClassValues?.bonds?.yieldToMaturity
            ? `${fund.broadAssetClassValues?.bonds?.yieldToMaturity.toFixed(
                1,
              )}%`
            : EMPTY_DATA_POINT),
        date:
          (fund?.portfolioUpdateDateMs &&
            `As of ${dayjs(fund.portfolioUpdateDateMs).format(
              DISPLAY_DATE_FORMAT,
            )}`) ||
          undefined,
        dataTestId: 'YieldToMaturity',
      });
    }
    formSection.push({
      label: 'Avg. Credit Quality',
      fundType: FundType.FUND,
      value:
        fund &&
        (fund.broadAssetClassValues?.bonds?.averageCreditQualityDefinition
          ? fund.broadAssetClassValues?.bonds?.averageCreditQualityDefinition
          : EMPTY_DATA_POINT),
      date:
        (fund?.portfolioUpdateDateMs &&
          `As of ${dayjs(fund.portfolioUpdateDateMs).format(
            DISPLAY_DATE_FORMAT,
          )}`) ||
        undefined,
      dataTestId: 'AverageCreditQuality',
    });
  } else {
    formSection.push(
      {
        label: 'Holdings',
        value:
          fund &&
          (fund.numberOfHoldings ? fund.numberOfHoldings : EMPTY_DATA_POINT),
        date:
          (fund?.noOfHoldingsDate &&
            `As of ${dayjs(fund.noOfHoldingsDate).format(
              DISPLAY_DATE_FORMAT,
            )}`) ||
          undefined,
        dataTestId: 'Holdings',
      },
      ...(!hasEquityAndBroadClassStyleBox
        ? [
            {
              label: 'Turnover',
              value:
                fund &&
                (fund.shareClassFundTurnoverRatio
                  ? `${fund.shareClassFundTurnoverRatio.toFixed(0)}%`
                  : EMPTY_DATA_POINT),

              fundType: FundType.FUND,
              date:
                (fund?.portfolioUpdateDateMs &&
                  `As of ${dayjs(fund.portfolioUpdateDateMs).format(
                    DISPLAY_DATE_FORMAT,
                  )}`) ||
                undefined,
              dataTestId: 'Turnover',
            },
          ]
        : []),
    );
  }

  return formSection;
};

// just a placeholder for the sections
export const emptyValues: ISection[] = [
  {
    label: 'Fund Size',
    value: EMPTY_DATA_POINT,
    dataTestId: 'FundSize',
  },
  {
    label: 'OCF',
    value: EMPTY_DATA_POINT,
    dataTestId: 'OCF',
  },
  {
    label: 'Holdings',
    value: EMPTY_DATA_POINT,
    dataTestId: 'Holdings',
  },
  {
    label: 'Turnover',
    value: EMPTY_DATA_POINT,
    dataTestId: 'Turnover',
  },
  {
    label: 'Active Share',
    value: <ComingSoonBadge />,
    dataTestId: 'ActiveShare',
  },
];
