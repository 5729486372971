import { COLOR_MINIMUM_FEATURED_TABLE_CELL } from '@aminsights/shared';
import { COLOR_MAXIMUM_FEATURED_TABLE_CELL } from '@aminsights/shared';
import {
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  WATCHLIST_TAB_KEY,
} from '@aminsights/shared';
import { RESTRICTED_FEATURE } from './features';

export const NOT_DISCLOSED = 'Not Disclosed';
export const SLIDER_MIN = 0;
export const SLIDER_MAX = 100;
export const SLIDER_MIN_RISK_RATING = 1;
export const SLIDER_MAX_RISK_RATING = 10;
export const SLIDER_MAX_RISK_RATING_OXFORD = 7;
export const TOTAL_LIMIT = 10000;
export const SIZE_PER_PAGE = 20;
export const MAX_ENTITY_NAME = 15;
export const CHARTS_YAXIS_SECTION_AMOUNT_MAX = 6;
export const LIMIT_FUNDS = 462; // 11 max funds per bucket, 42 max buckets per watchlist
export const LIMIT_BUCKETS = 42;
export const LIMIT_ADVISOR_BUCKETS = 5;
export const TABLE_UNIQUE_KEY = '_id';
export const DATE_FORMAT_CHARTS = 'MMM DD, YYYY';
export const DATE_FORMAT_CHARTS_SHORT = 'DD MMM';
export const DATE_FORMAT_CHARTS_LONG = `${DATE_FORMAT_CHARTS_SHORT}\nYYYY`;
export const WATCHLIST_ACTION_ITEM_ADD_FUND = 'add-funds';
export const WATCHLIST_ACTION_ITEM_ADD_BUCKET = 'add-bucket';
export const WATCHLIST_ACTION_ITEM_VIEW_EDIT_BUCKET = 'view-edit-buckets';
export const WATCHLIST_ACTION_DROPDOWN_ITEMS = [
  {
    label: 'Add funds/trusts',
    value: WATCHLIST_ACTION_ITEM_ADD_FUND,
  },
  {
    label: 'Add new bucket',
    value: WATCHLIST_ACTION_ITEM_ADD_BUCKET,
  },
  {
    label: 'View/Edit buckets',
    value: WATCHLIST_ACTION_ITEM_VIEW_EDIT_BUCKET,
  },
];

export const EXTREMUM_TABLE_COLORS_LEGEND = [
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
];

export const EXTREMUM_PERFORMANCE_TABLE_COLORS_LEGEND = [
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
];

export const SHARE_ACTION_SURVEY_COLORS = [
  '#3AAF7A',
  '#7CBA6C',
  '#CEC75B',
  '#FFCF51',
  '#FFB65B',
  '#FF9C65',
  '#FF836E',
  '#FF6977',
  '#FF6978',
  '#FF6979',
];
const SHARE_ACTION_SURVEY_DEFAULT_COLOR = '#F2F2F5';

export const SHARE_ACTION_SURVEY_COLOR_MAP: Record<string, string> = {
  '0 > 12.5': SHARE_ACTION_SURVEY_COLORS[7],
  '12.5 > 25': SHARE_ACTION_SURVEY_COLORS[6],
  '25 > 37.5': SHARE_ACTION_SURVEY_COLORS[5],
  '37.5 > 50': SHARE_ACTION_SURVEY_COLORS[4],
  '50 > 62.5': SHARE_ACTION_SURVEY_COLORS[3],
  '62.5 > 75': SHARE_ACTION_SURVEY_COLORS[2],
  '75 > 87.5': SHARE_ACTION_SURVEY_COLORS[1],
  '87.5 > 100': SHARE_ACTION_SURVEY_COLORS[0],
};

export const getShareActionColor = (value: string | undefined) => {
  if (!value || !Object.keys(SHARE_ACTION_SURVEY_COLOR_MAP).includes(value)) {
    return SHARE_ACTION_SURVEY_DEFAULT_COLOR;
  }

  return SHARE_ACTION_SURVEY_COLOR_MAP[value];
};

/**
 * Simple helper function to deal with undefined values
 * Returns 0 - 100 range slider value if passed value is undefined
 *
 * Technically it is better to pull from initial state of sub states,
 * but it is rather common to have 0-100 sliders in the UI
 */
export const getSliderValueOrDefault = (v: number[] | undefined) => {
  if (v) {
    return v;
  }
  return [SLIDER_MIN, SLIDER_MAX];
};

// @deprecated
export const WATCHLIST_TABS = [
  {
    label: <span data-test-id="tabSummary">Summary</span>,
    key: WATCHLIST_TAB_KEY.SUMMARY,
  },
  {
    label: <span data-test-id="tabAnnualisedPerformance">Ann. Perf</span>,
    key: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
    lockedForRestrictedAccess:
      RESTRICTED_FEATURE.EXPLORE_TAB_ANNUALISED_PERFORMANCE,
  },
  {
    label: <span data-test-id="tabCalendarYearPerformance">Cal. Perf</span>,
    key: WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
    lockedForRestrictedAccess:
      RESTRICTED_FEATURE.EXPLORE_TAB_CALENDAR_YEAR_PERFORMANCE,
  },
  {
    label: <span data-test-id="tabRisk">Risk</span>,
    key: WATCHLIST_TAB_KEY.RISK,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.EXPLORE_TAB_RISK,
  },
  {
    label: <span data-test-id="tabIncome">Income</span>,
    key: WATCHLIST_TAB_KEY.INCOME,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.EXPLORE_TAB_INCOME,
  },
  {
    label: <span data-test-id="tabESG">ESG</span>,
    key: WATCHLIST_TAB_KEY.ESG,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.EXPLORE_TAB_ESG,
  },
  {
    label: <span data-test-id="tabGenderPayGap">Gender Pay Gap</span>,
    key: WATCHLIST_TAB_KEY.GENDER_PAY_GAP,
    lockedForRestrictedAccess: RESTRICTED_FEATURE.EXPLORE_TAB_GENDER_PAY_GAP,
  },
];

export const RENDER_BOX_COLOR_EQUITY = {
  1: '#99CCFF',
  2: '#4DA5FF',
  4: '#007FFF',
  6: '#0072E6',
  8: '#005FBF',
  10: '#003E7D',
};

export const RENDER_BOX_COLOR_BOND = {
  1: '#B7DEB9',
  2: '#81C584',
  4: '#4BAC4F',
  6: '#449B47',
  8: '#38813B',
  10: '#255427',
};

export const MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS = 10;
export const MAXIMUM_HOLDINGS_ROWS_PER_FUND = 10;
export const TOTAL_HOLDINGS_ROWS = 15;
export const COMPARE_TOOL_DECIMALS = 1;
export const MAX_COMPARE_TOTAL = 4;

export enum LEGEND_TYPE_ENUM {
  ESG = 'esgLegend',
  EXTREMUM = 'extremumLegend',
  EXTREMUM_PERFORMANCE = 'extremumPerformanceLegend',
}
