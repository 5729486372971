import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  LEGAL_STRUCTURE,
  LIMIT_FUNDS_FOR_CHARTING,
  buildFundDetailsPath,
  getFundShareClassDetailsFromArray,
  isFixedIncomeBroadAssetClass,
  isFund,
  isInvestmentTrust,
} from '@aminsights/shared';
import { LoadingOutlined } from '@ant-design/icons';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import { MAX_COMPARE_TOTAL, TABLE_UNIQUE_KEY } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistSummaryInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistSummaryInfiniteQuery';
import {
  useCurrentBucket,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import TablesActionToast from '@/partials/Toast/TablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import { getWatchlistSummaryColumns } from './WatchlistSummaryColumns';

const WatchlistSummaryTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentWatchlist = useCurrentWatchlist();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useWatchlistSummaryInfiniteQuery({
      sortKey: sortKey,
      sortDirection: sortDirection,
      bucketId: currentBucket?.id,
    });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item._id;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const allItemsAreFixedIncome = useMemo(() => {
    if (!currentBucket?.funds) return false;
    return currentBucket.funds.every(f =>
      isFixedIncomeBroadAssetClass(f.broadAssetClass),
    );
  }, [currentBucket?.funds]);

  const hasInvestmentTrust = useMemo(() => {
    if (!currentBucket?.funds) {
      const allFunds = currentWatchlist.data?.buckets.flatMap(b => b.funds);
      return allFunds?.some(f => isInvestmentTrust(f.legalStructure)) ?? false;
    }
    return (
      currentBucket.funds.some(f => isInvestmentTrust(f.legalStructure)) ??
      false
    );
  }, [currentBucket?.funds, currentWatchlist.data]);

  const showDFMColumn = useMemo(() => {
    return Boolean(
      flattenedData?.some(f => f.legalStructure === LEGAL_STRUCTURE.MPS),
    );
  }, [flattenedData]);

  // TODO Maybe memoize
  const columns = getWatchlistSummaryColumns({
    rowSelectionState: checkedRowState,
    allItemsAreFixedIncome,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item._id);
        setCheckedRows(topIsins);
      } else {
        setCheckedRows([]);
      }
    },
    hasInvestmentTrust,
    showDFMColumn,
  });

  // For performance reasons we create a map of all isins that are featured
  const isFeaturedIsinInSomeBucket: string[] = useMemo(() => {
    const isins: string[] = [];
    const allFunds = currentWatchlist.data?.buckets.flatMap(b => b.funds);
    allFunds?.forEach(f => {
      if (f.isFeatured) {
        isins.push(f.isin);
      }
    });
    return isins;
  }, [currentWatchlist.data]);

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
          setPage(1); // Resets the infinite scroll page
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          if (!isFundRow) return {};
          const isByBucketFeatured = currentBucket?.funds.find(
            f => rowData._id === f?.isin,
          )?.isFeatured;

          const isByWatchlistFeatured = isFeaturedIsinInSomeBucket.includes(
            rowData._id,
          );

          const isFeaturedRow = isByBucketFeatured ?? isByWatchlistFeatured;

          return {
            backgroundColor: switchTableRowColor(!!isFeaturedRow, !isFundRow),
            cursor: 'pointer',
          };
        }}
      />
      {isFetchingNextPage && (
        <LoadingOutlined
          className="py-2"
          style={{ fontSize: 60, color: '#f0f2f5', width: '98%' }}
          spin
        />
      )}
      {checkedRows.length > 0 && (
        <TablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistSummaryTable;
