import { Tabs } from 'antd';
import cx from 'classnames';
import { useState } from 'react';

import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import ESG from './ESG';
import ManagerTable from './ManagerTable';
import Overview from './Overview';
import style from './style.module.less';

const Summary = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const handleTabChange = (key: string) => {
    if (isRestrictedAccess && key === 'esg') {
      toggleUpgradeModal(RESTRICTED_FEATURE.FUND_ESG);
    } else {
      setActiveTab(key);
    }
  };

  return (
    <div
      className={cx(
        style['root'],
        'lg:px-4',
        activeTab === 'esg' && style['root-esg-active'],
      )}
    >
      <Tabs
        items={[
          {
            label: 'Overview',
            key: 'overview',
            children: <Overview />,
          },
          {
            label: (
              <div className="flex gap-x-2 group">
                ESG
                {isRestrictedAccess && (
                  <LockIcon
                    className={cx(
                      'fill-neutral-100 group-hover:fill-primary-400',
                      'w-4 h-4 transition-all duration-300 ease-in-out',
                    )}
                  />
                )}
              </div>
            ),
            key: 'esg',
            children: <ESG />,
          },
        ]}
        className={cx(
          style['tabs-container'],
          '[&_.ant-tabs-nav]:lg:rounded-t-lg',
        )}
        onChange={handleTabChange}
        activeKey={activeTab}
        data-test-id="fundDetailsOverviewESGTabs"
      />
      <div className="flex flex-col gap-y-5">
        <ManagerTable />
      </div>
    </div>
  );
};

export default Summary;
