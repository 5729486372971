import React, { PropsWithChildren, useEffect, useState } from 'react';

import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { countRiskFilters } from '../hooks/riskSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { ExploreModalProps } from '../types';
import RiskFilterPanels from './RiskFilterPanels';

export const RiskFilterModal: React.FC<
  PropsWithChildren<ExploreModalProps>
> = ({ isOpen, onClose }) => {
  const {
    filters,
    tentativeFilters,
    updateTentativeFilters,
    getMutableFilters,
    syncFilterByPath,
  } = useExploreFilters();

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.riskFilters) ===
        JSON.stringify(filters.riskFilters),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(
      applyDisabled && countRiskFilters(filters.riskFilters) === 0,
    );
  });

  const handleOnApply = () => {
    syncFilterByPath('riskFilters');
    onClose();
  };

  return (
    <ExploreFilterModal
      title="Risk"
      description="Vs. category index"
      showDropdown={true}
      isVisible={isOpen}
      isDisableApply={applyDisabled}
      isDisableClear={clearDisabled}
      toggleModal={onClose}
      onClear={() => {
        const f = getMutableFilters();
        f.riskFilters = undefined;
        updateTentativeFilters(f);
      }}
      onApply={handleOnApply}
    >
      <RiskFilterPanels />
    </ExploreFilterModal>
  );
};
