import useWatchlistAnnualisedPerformanceInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistAnnualisedPerformanceInfiniteQuery';
import useWatchlistRiskInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistRiskInfiniteQuery';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { formatDate } from '@/utils';
import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Skeleton } from 'antd';
import { memo } from 'react';
import { getLatesAsofDate } from './findLatestAsOfDate';

const WatchlistHeaderAsOfDate: React.FC<{
  type: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE | WATCHLIST_TAB_KEY.RISK;
  className?: string;
}> = ({ type, className }) => {
  const currentBucketId = useCurrentBucketId();

  const { data: performanceData, isLoading: isLoadingPerformanceData } =
    useWatchlistAnnualisedPerformanceInfiniteQuery({
      bucketId: currentBucketId,
    });
  const { data: riskData, isLoading: isLoadingRiskData } =
    useWatchlistRiskInfiniteQuery({
      bucketId: currentBucketId,
    });
  const isLoading = isLoadingPerformanceData || isLoadingRiskData;
  let computedAsOfDate;
  let riskMeasuresDetailsAsOfDate;
  if (type === WATCHLIST_TAB_KEY.RISK) {
    const pagesData = riskData?.pages[0].data;
    computedAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'computedNavRiskDetails')
      : null;
    riskMeasuresDetailsAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'riskMeasuresDetail', 'endDate')
      : null;
    if (
      riskMeasuresDetailsAsOfDate &&
      riskMeasuresDetailsAsOfDate !== computedAsOfDate
    ) {
      computedAsOfDate = riskMeasuresDetailsAsOfDate;
    }
  } else if (type === WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE) {
    const pagesData = performanceData?.pages[0].data;
    const latestAnnualisedPerformanceAsOfDate = pagesData
      ? getLatesAsofDate(pagesData, 'computedNavAnnualisedReturns')
      : null;
    computedAsOfDate = latestAnnualisedPerformanceAsOfDate;
  }

  return isLoading ? (
    <div>
      <Skeleton.Button
        className="flex"
        active
        size="small"
        style={{ width: '96px', height: '13px' }}
      />
    </div>
  ) : (
    <span
      className={`text-xs italic font-bold leading-4 text-darkest ${className}`}
    >
      {computedAsOfDate ? formatDate(computedAsOfDate) : 'No date available'}
    </span>
  );
};

export default memo(WatchlistHeaderAsOfDate);
