import { Tabs } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { PropsWithChildren, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ReactComponent as ChartingToolIcon } from '@/assets/svg/icons/icon-charting-tool.svg';
import { PageResults } from '@/components';
import useGetPortfolioAbsoluteChartData from '@/hooks/query-hooks/chart-hooks/useGetPortfolioAbsoluteChartData';
import usePortfolioEdgeDates from '@/hooks/query-hooks/portfolio-hooks/usePortfolioEdgeDates';
import { useDatePickerContext } from '@/partials/DatePicker/Context';
import { parseRangeValues } from '@/partials/DatePicker/Kinds/RangeDatePicker';
import { EChartsRoutes } from '@/router/routes';
import { evaluatePortfolioSubRoute, formatDate } from '@/utils';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';
import { APP_ROUTE_CHARTS } from '@aminsights/shared';

import { getLatesAsofDate } from '@/partials/AsOfDates/findLatestAsOfDate';
import { PORTFOLIO_TAB } from '../types';
import { useProvidePortfolio } from '../useProvidePortfolio';
import PortfolioChartsTab from './Tabs/PortfolioChartsTab';
import PortfolioHoldingsTab from './Tabs/PortfolioHoldingsTab';
import PortfolioPerformanceTab from './Tabs/PortfolioPerformanceTab';
import PortfolioPlatformsTab from './Tabs/PortfolioPlatformsTab';
import PortfolioSettingsTab from './Tabs/PortfolioSettingsTab';
import style from './style.module.less';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('GMT');

const PortfolioPagesWrapper: React.FC<PropsWithChildren> = () => {
  const history = useHistory();
  const [totalFunds, setTotalFunds] = React.useState<number | undefined>(0);
  const { state } = useProvidePortfolio();
  const { tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const { value: datePickerValue } = useDatePickerContext();
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];
  const { data, isLoading } = useGetPortfolioAbsoluteChartData({
    portfolioId: state.currentPortfolioId,
    period: period,
  });
  const absoluteData = data?.data || [];
  const isPortfolioReady = absoluteData.length && !isLoading;

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setTotalFunds(currentPortfolio?.funds.length);
  }, [state]);

  // We just need the hook to fire
  usePortfolioEdgeDates({
    portfolioId: state.currentPortfolioId,
  });
  const pagesData = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  )?.funds;

  const latestAnnualisedPerformanceAsOfDate = pagesData
    ? getLatesAsofDate(pagesData, 'computedNavAnnualisedReturns')
    : null;

  const renderTabBarContent = () => {
    return (
      <div
        className={cx('flex items-center gap-2', {
          'h-[52px] justify-between py-2 px-4': isMobile,
        })}
      >
        {tab === PORTFOLIO_TAB.PERFORMANCE &&
          latestAnnualisedPerformanceAsOfDate && (
            <span className="text-xs italic font-bold leading-4">
              {formatDate(latestAnnualisedPerformanceAsOfDate)}{' '}
            </span>
          )}
        {isPortfolioReady ? (
          <Link
            to={`/${APP_ROUTE_CHARTS}/${EChartsRoutes.ABSOLUTE}?portfolio=${state.currentPortfolioId}`}
            className="flex items-center gap-1 text-sm font-medium text-primary hover:text-primary-400 pl-2"
          >
            <ChartingToolIcon className="w-5 h-5" />
            <span>Charting</span>
          </Link>
        ) : (
          <div className="flex items-center gap-1 text-sm font-medium text-neutral-300 cursor-not-allowed pl-2">
            <ChartingToolIcon className="w-5 h-5" />
            <span>Charting</span>
          </div>
        )}
        {(tab === PORTFOLIO_TAB.HOLDINGS ||
          tab === PORTFOLIO_TAB.PERFORMANCE) &&
          !!totalFunds && (
            <PageResults
              totalCount={
                state.portfolios.find(p => p._id === state.currentPortfolioId)
                  ?.totalFunds || 0
              }
            />
          )}
      </div>
    );
  };

  return (
    <div className={cx('bg-white lg:rounded-lg')}>
      <div className={cx(style['portfolio-tabs'], 'relative')}>
        <Tabs
          activeKey={tab}
          onChange={(tab: string) => {
            history.push(`${evaluatePortfolioSubRoute(tab)}`);
          }}
          tabBarExtraContent={!isMobile ? renderTabBarContent() : null}
          defaultActiveKey={PORTFOLIO_TAB.HOLDINGS}
          items={[
            {
              label: <span data-test-id="tabPortfolioHoldings">Holdings</span>,
              key: PORTFOLIO_TAB.HOLDINGS,
              children: (
                <>
                  {isMobile ? renderTabBarContent() : ''}
                  <PortfolioHoldingsTab
                    key={`holdings-${currentPortfolio?._id}`}
                  />
                </>
              ),
            },
            {
              label: (
                <span data-test-id="tabPortfolioPerformance">Performance</span>
              ),
              key: PORTFOLIO_TAB.PERFORMANCE,
              children: currentPortfolio && (
                <>
                  {isMobile ? renderTabBarContent() : ''}
                  <PortfolioPerformanceTab
                    key={`performance-${currentPortfolio?._id}`}
                  />
                </>
              ),
            },
            {
              label: <span data-test-id="tabPortfolioCharts">Charts</span>,
              key: PORTFOLIO_TAB.CHARTS,
              children: currentPortfolio && (
                <>
                  {isMobile ? renderTabBarContent() : ''}
                  <PortfolioChartsTab />
                </>
              ),
            },
            {
              label: (
                <span data-test-id="tabPortfolioPlatforms">Platforms</span>
              ),
              key: PORTFOLIO_TAB.PLATFORMS,
              children: currentPortfolio && (
                <>
                  {isMobile ? renderTabBarContent() : ''}
                  <PortfolioPlatformsTab />
                </>
              ),
            },
            {
              label: (
                <span data-test-id="tabPortfolioSettings">
                  Portfolio Settings
                </span>
              ),
              key: PORTFOLIO_TAB.SETTINGS,
              children: currentPortfolio && (
                <>
                  {isMobile ? renderTabBarContent() : ''}
                  <PortfolioSettingsTab />
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PortfolioPagesWrapper;
