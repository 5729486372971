import React, { PropsWithChildren } from 'react';

import CustomProgress from '../Progress';

type BoardListProps = {
  label: string;
  progressPercentage?: number;
  barColor?: string;
};

const BoardList: React.FC<PropsWithChildren<BoardListProps>> = ({
  label,
  progressPercentage,
  barColor,
}) => {
  return (
    <div className="py-1 flex">
      <div className="text-neutral text-sm w-5/12">
        <span>{label}</span>
      </div>
      <div className="w-5/12">
        <CustomProgress
          percent={progressPercentage}
          strokeColor={barColor}
          showInfo={false}
        />
      </div>
      <div className="flex justify-end text-neutral text-sm info w-2/12">
        <span>{progressPercentage}%</span>
      </div>
    </div>
  );
};

export default BoardList;
