import { ChartDataTag } from '@aminsights/contract';

import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartWithPortfolio from '@/partials/Charts/relative/RelativeChartWithPortfolio';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RelativeChartingToolPage = () => {
  const {
    isins,
    benchmark,
    sector,
    setSector,
    setBenchmark,
    setIsins,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const { data, isLoading } = useBenchmarkOptions();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length || (benchmark && sector))) {
    const title = !!isins.length
      ? 'No data available'
      : 'No added funds/trusts/portfolios to chart';
    const subTitle = !!isins.length
      ? 'Select an Index to see the relative chart'
      : 'Search funds/portfolios to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }

  const matchingBenchmark = data?.find(b => b.secId === benchmark)?.name;

  const evaluateEmptyState = (
    isins: string[],
    portfolios: string[],
    benchmark: string,
  ) => {
    if (!benchmark) {
      return {
        title: 'No data available',
        subTitle: 'Select an Index to see the relative chart',
      };
    }
    if (isins.length === 0 && portfolios.length === 0) {
      return {
        title: 'No added funds/trusts/portfolios to chart',
        subTitle: 'Search funds/portfolios to chart',
      };
    } else {
      return {
        title: 'No data for this time period',
        subTitle: 'Try a different time range',
      };
    }
  };

  return (
    <>
      <RelativeChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        benchmarkName={isLoading ? '' : matchingBenchmark}
        showDropdown={true}
      />
      <RelativeChartWithPortfolio
        emptyState={evaluateEmptyState(isins, portfolios, benchmark)}
        onRemoveFund={(id, dataTag?: ChartDataTag) => {
          if (dataTag === ChartDataTag.Sector) {
            setSector('');
            return;
          }
          if (dataTag === ChartDataTag.Benchmark) {
            setBenchmark('');
            return;
          }
          if (dataTag === ChartDataTag.Portfolio) {
            setPortfolios([id], true);
            const targetId =
              legendData.find(ld => ld.id?.includes(id))?.id || '';
            const targetColor = chartColors[targetId];
            targetColor && chartingCtx.setColorsRemoved(targetColor);
            return;
          }
          const targetId = legendData.find(ld => ld.isin === id)?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          setIsins([id], true);
        }}
        showDropdown={true}
        isins={isins}
        benchmarkId={benchmark}
        sectorId={sector}
        hasInvestmentTrust={hasInvestmentTrust}
        resetInvReturnFilterOnChange={false}
        portfolios={portfolios}
        chartColors={chartColors}
      />
    </>
  );
};

export default RelativeChartingToolPage;
