import {
  Fund,
  FundRelativeRiskMeasuresDetail,
  FundRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { MonthCode, isInvestmentTrust } from '@aminsights/shared';
import { FC, useEffect, useState } from 'react';

import { DataTable } from '@/components';

import RiskColumns from './CompareRiskColumns';

interface CompareRiskProps {
  funds: Fund[];
  dateRange?: MonthCode;
  loading?: boolean;
}

export interface IFundRiskData {
  key: string;
  name?: string;
  riskMeasuresDetail?: FundRiskMeasuresDetail;
  relativeRiskMeasuresDetail?: FundRelativeRiskMeasuresDetail;
  maximumDrawdown?: number;
}

const CompareRisk: FC<CompareRiskProps> = ({
  funds,
  dateRange = MonthCode.M12,
  loading,
}) => {
  const [sortedData, setSortedData] = useState<Fund[]>(funds);

  const isAllInvestmentTrust = !!funds?.every(f =>
    isInvestmentTrust(f.legalStructure),
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Because we want to reset sort on date range dropdown change
  useEffect(() => {
    setSortedData(funds);
  }, [funds, dateRange]);

  const getRiskValue = (fund: Fund) =>
    fund.computedNavRiskDetails?.find(d => d.timePeriod === dateRange)
      ?.standardDeviation ?? Number.NEGATIVE_INFINITY;

  return (
    <DataTable
      className="4xl:table-fixed"
      loading={loading}
      loaderSize={7}
      uniqueKey="key"
      columns={RiskColumns({
        isInvestmentTrust: isAllInvestmentTrust,
        timePeriod: dateRange,
      })}
      onSort={async (
        key: string,
        direction: PageQueryParametersSortDirectionEnum,
      ) => {
        setSortedData(
          [...funds].sort((a, b) => {
            const aValue = getRiskValue(a);
            const bValue = getRiskValue(b);

            return direction === PageQueryParametersSortDirectionEnum.Asc
              ? aValue - bValue
              : bValue - aValue;
          }),
        );
      }}
      data={sortedData}
    />
  );
};

export default CompareRisk;
