import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as NoPortfolioIcon } from '@/assets/svg/icons/icon-no-portfolio.svg';
import Button from '@/components/Button';

interface NoPortfolioPageProps {
  setIsAddNewPortfolioModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const NoPortfolioPage: React.FC<PropsWithChildren<NoPortfolioPageProps>> = ({
  setIsAddNewPortfolioModalOpen,
}) => {
  return (
    <div className="block p-[0_1.5rem_1.5rem_1.5rem]">
      <div className="bg-white rounded-lg h-[calc(100vh-145px)]">
        <div className="w-full h-full p-5 flex flex-col items-center justify-center gap-y-4">
          <NoPortfolioIcon />
          <div className="flex flex-col gap-2 items-center justify-center">
            <b className="text-lg font-bold text-center text-darkest">
              No portfolio added
            </b>
            <span className="text-sm font-normal text-center text-neutral-700">
              Create your portfolio and start adding funds
            </span>
          </div>
          <div className="flex flex-col gap-1 items-center justify-center">
            <Button
              className={'flex items-center justify-center mt-2 rounded'}
              type="primary"
              onClick={() => {
                setIsAddNewPortfolioModalOpen(true);
              }}
              data-test-id="portfolioAddButton"
            >
              <AddIcon className="icon text-md" />
              <p className="ml-2 text-sm font-medium md:block">Add Portfolio</p>
            </Button>
            <span>
              or{' '}
              <Link
                to={`/${APP_ROUTE_PORTFOLIOS}/import-portfolio`}
                className="text-primary"
              >
                import
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPortfolioPage;
