import { ResourceUser, ResourceUserRoleEnum } from '@aminsights/contract';
import { Modal, Select } from 'antd';
import { Button } from 'antd';
import { FC, useContext, useMemo, useState } from 'react';

import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';

interface RevokeBucketAccessModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  bucketUsers: ResourceUser[];
  onRevokeBucketAccess: (usersWithEditAccess: string[]) => void;
}

const RevokeBucketAccessModal: FC<RevokeBucketAccessModalProps> = ({
  isVisible,
  toggleModal,
  bucketUsers,
  onRevokeBucketAccess,
}) => {
  const [selectedusers, setSelectedUsers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isRevokingAccess, setIsRevokingAccess] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const { data: usersData } = useOrganizationUsers();
  const currentUser = authState.decodedToken;
  const bucketCurrentUser = bucketUsers.find(bu => bu.id === currentUser.sub);
  const hasManagePermissions =
    ResourceUserRoleEnum.Editor === bucketCurrentUser?.role;
  const hasBucketUsersWithEditAccess = bucketUsers
    .filter(bu => bu.id !== currentUser.sub)
    .some(bu => bu.role === ResourceUserRoleEnum.Editor);

  const handleSearchTermChange = (e: string) => {
    if (e.includes(',')) {
      setSelectedUsers(prev => [...prev, e.replace(',', '')]);
      setSearchTerm('');
      return;
    }
    setSearchTerm(e);
  };

  const userOptions = useMemo(() => {
    return (usersData || [])
      .filter(
        u =>
          u.user_id !== currentUser?.sub &&
          bucketUsers.find(bu => bu.id === u.user_id),
      )
      .map(({ name, user_id }) => ({
        label: name,
        value: user_id,
      }));
  }, [usersData, bucketUsers, currentUser]);

  const renderModalDescription = () => {
    if (!hasManagePermissions || hasBucketUsersWithEditAccess) {
      return 'This action cannot be undone.';
    } else if (!hasBucketUsersWithEditAccess) {
      return (
        <div className="flex flex-col gap-y-4">
          <p className="text-sm">
            You cannot delete a shared bucket without another editor. Assign an
            editor before deleting for yourself.
          </p>
          <p className="text-sm">This action cannot be undone.</p>
        </div>
      );
    }

    return <p className="text-sm">This action cannot be undone.</p>;
  };

  return (
    <Modal
      open={isVisible}
      onCancel={toggleModal}
      centered={true}
      className="max-sm:full-page-modal action-modal action-modal-confirmation"
      title={
        <div className="flex gap-x-2">
          <InfoWarningRed className="mt-0.5" width={22} height={22} />
          <h3 className="text-xl font-bold">Delete for me</h3>
        </div>
      }
      footer={[
        <Button
          type="link"
          key="secondary"
          disabled={isRevokingAccess}
          onClick={toggleModal}
        >
          Cancel
        </Button>,
        <Button
          key="primary"
          type="primary"
          size="large"
          loading={isRevokingAccess}
          disabled={
            isRevokingAccess ||
            (selectedusers.length === 0 && !hasBucketUsersWithEditAccess)
          }
          onClick={async () => {
            setIsRevokingAccess(true);
            await onRevokeBucketAccess(selectedusers);
            toggleModal();
            setSelectedUsers([]);
            setSearchTerm('');
            setIsRevokingAccess(false);
          }}
          danger
        >
          {hasBucketUsersWithEditAccess ? 'Delete' : 'Assign and delete for me'}
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-4">
        <div className="text-sm font-normal text-neutral">
          {renderModalDescription()}
        </div>
        {!hasBucketUsersWithEditAccess && (
          <div className="flex-1 flex flex-col mt-2">
            <Select
              mode="multiple"
              className={`w-full min-h-10 custom-antd-selection
                [&_.ant-select-selection-placeholder]:text-neutral-100 [&_.ant-select-selection-placeholder]:px-1
                [&_.ant-select-selector]:p-2 [&_.ant-select-selector]:rounded [&_.ant-select-selector]:ring-0
                [&_.ant-select-selector]:border-neutral-300 [&_.ant-select-selector]:hover:border-primary
                [&_.ant-select-selector]:focus-within:border-2 [&_.ant-select-selector]:focus-within:border-primary
              `}
              value={selectedusers}
              onChange={val => {
                setSelectedUsers(val);
                setSearchTerm('');
              }}
              onSearch={handleSearchTermChange}
              searchValue={searchTerm}
              placeholder="Ex. John, Jennifer,..."
              options={userOptions}
              filterOption={(_, opt) => {
                return Boolean(
                  opt?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
                );
              }}
              suffixIcon={null}
            />
            <p className="text-xs text-neutral-400 mt-1">
              Share to one or more recipients, separated by commas.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RevokeBucketAccessModal;
