import { ReactComponent as IconAction } from '@/assets/svg/icons/icon-action-transparent.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconArrowDownStroke } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import BasePageWithMetadata from '../../BasePageWithMetadata';
import { FolderEmptyState } from './FolderEmptyState';

const folder = {
  id: 'xxxxxxx',
  name: 'Folder Name',
  buckets: [],
};

const Folder = () => {
  const { folderId } = useParams<{ folderId: string }>();
  const buckets = [];
  const history = useHistory();

  return (
    <BasePageWithMetadata title="Folder">
      <SectionBackButton previousLabel={'Back'} />
      <section className="h-full flex flex-col lg:p-4">
        <div className="flex-1 flex flex-col shadow-[0px_3px_6px_@boxShadow] pt-4 pb-0 px-4 bg-white lg:rounded-lg">
          <div className="min-h-[70vh]">
            <div className="flex items-center justify-between">
              <h2
                data-test-id="Buckets"
                className="text-xl font-bold text-darkest m-0"
              >
                {folder.name}
              </h2>
              <div className="flex items-center gap-2">
                <Dropdown
                  placement="bottomRight"
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        label: 'Existing Bucket',
                        key: 'existing-bucket',
                      },
                      {
                        label: 'New Bucket',
                        key: 'new-bucket',
                      },
                    ],
                  }}
                >
                  <Button
                    type="primary"
                    className="h-10"
                    icon={<AddIcon className="icon !h-3" />}
                  >
                    Add Bucket
                    <IconArrowDownStroke className="icon !h-2" />
                  </Button>
                </Dropdown>
                <Dropdown
                  placement="bottomRight"
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        label: 'Rename',
                        key: 'rename',
                      },
                      {
                        label: 'Import funds/trusts',
                        key: 'import-funds-trusts',
                        onClick: () => {
                          history.push(`/${APP_ROUTE_WATCHLIST}/import-funds`);
                        },
                      },
                      {
                        label: 'Reorder buckets',
                        key: 'reorder-buckets',
                      },
                      {
                        label: 'Delete',
                        key: 'delete',
                        danger: true,
                      },
                    ],
                  }}
                >
                  <div>
                    <IconAction className="w-10 h-10 hover:bg-midLight rounded" />
                  </div>
                </Dropdown>
              </div>
            </div>
            <p className="py-3 text-sm text-neutral-700">
              You can move funds/trusts between buckets and move buckets into
              folder.
            </p>
            {buckets.length === 0 && <FolderEmptyState />}
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Folder;
