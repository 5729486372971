import { NotificationsSettings, SettingsApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

const NOTIFICATION_SETTINGS_QUERY_KEY = 'notification-settings';
export const invalidateNotificationSettings = () => {
  queryClient.invalidateQueries({
    queryKey: [NOTIFICATION_SETTINGS_QUERY_KEY],
  });
};

const useNotificationSettings = (
  options?: Omit<
    UseQueryOptions<NotificationsSettings, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const settingsApi = new SettingsApi(openApiConfig());

  return useQuery<NotificationsSettings, unknown>(
    [NOTIFICATION_SETTINGS_QUERY_KEY],
    async () => {
      const response = await settingsApi.getNotificationsSettings();
      return response.data;
    },
    options,
  );
};

export default useNotificationSettings;
