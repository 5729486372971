import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import { searchSCReducer } from './reducers';
import { SearchSCActionTypes, SearchSCState } from './types';

export type SearchSCContextProps = {
  state: SearchSCState;
  dispatch: React.Dispatch<SearchSCActionTypes>;
};

const initialState: SearchSCState = {
  searchTerm: '',
};

const Context = createContext<SearchSCContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const initializer = (state: SearchSCState) => state;

const SearchSCProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(
    searchSCReducer,
    initialState,
    initializer,
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { SearchSCProvider };

export const useSearchSCContext = (): SearchSCContextProps => {
  return useContext(Context);
};

export default Context;
