import {
  LIMIT_FUNDS_PER_BUCKET,
  LIMIT_FUNDS_PER_PORTFOLIO,
  LIMIT_PORTFOLIOS,
} from '@aminsights/shared';

import { StaticDataForAddNewEntityModal } from '@/partials/Modal/AddEntityWithBenchmarkModal';
import { StaticDataForLimitModal } from '@/partials/Modal/LimitReachModal';

import { LIMIT_ADVISOR_BUCKETS, LIMIT_BUCKETS } from './misc';
import { ADVISOR_BUCKET_LIMIT_TEXT, BUCKET_LIMIT_TEXT } from './text-messages';

export const STATIC_DATA_BUCKET_LIMIT_MODAL: StaticDataForLimitModal = {
  entityName: 'Bucket',
  limitForEntities: LIMIT_BUCKETS,
  limitForFundsOrTrusts: LIMIT_FUNDS_PER_BUCKET,
};

export const STATIC_DATA_ADVISOR_BUCKET_LIMIT_MODAL: StaticDataForLimitModal = {
  entityName: 'Bucket',
  limitForEntities: LIMIT_ADVISOR_BUCKETS,
  limitForFundsOrTrusts: LIMIT_FUNDS_PER_BUCKET,
};

export const STATIC_DATA_ADD_BUCKET_MODAL: StaticDataForAddNewEntityModal = {
  ...STATIC_DATA_BUCKET_LIMIT_MODAL,
  limitMessage: BUCKET_LIMIT_TEXT,
  primaryBtnText: 'Add',
};

export const STATIC_DATA_ADD_ADVISOR_BUCKET_MODAL: StaticDataForAddNewEntityModal =
  {
    ...STATIC_DATA_ADVISOR_BUCKET_LIMIT_MODAL,
    limitMessage: ADVISOR_BUCKET_LIMIT_TEXT,
    primaryBtnText: 'Add',
  };

export const STATIC_DATA_PORTFOLIO_LIMIT_MODAL: StaticDataForLimitModal = {
  entityName: 'Portfolio',
  limitForEntities: LIMIT_PORTFOLIOS,
  limitForFundsOrTrusts: LIMIT_FUNDS_PER_PORTFOLIO,
};

export const STATIC_DATA_ADD_PORTFOLIO_MODAL: StaticDataForAddNewEntityModal = {
  ...STATIC_DATA_PORTFOLIO_LIMIT_MODAL,
};
