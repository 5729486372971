import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Dropdown } from 'antd';
import { useContext, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Option } from '@/components';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import { WATCHLIST_TAB_GROUP } from '../WatchlistTabItems';

const FixedIncomeMenu = () => {
  const { setActiveTab, activeTabMenuItem, setActiveTabMenuItem } =
    useContext(TabContext);
  const currentBucketId = useCurrentBucketId();
  const { data: tabsToShow } = useTabsToShowOnWatchlist(currentBucketId);

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const { isRestrictedAccess } = useUpgradeAccess();

  const formatLabel = (value?: string) => {
    switch (value) {
      case WATCHLIST_TAB_KEY.STYLE_FI:
        return 'FI: Style';
      case WATCHLIST_TAB_KEY.SECTORS_FI:
        return 'FI: Sectors';
      case WATCHLIST_TAB_KEY.GEOGRAPHY_FI:
        return 'FI: Geog';
      case WATCHLIST_TAB_KEY.MATURITY:
        return 'FI: Maturity';
      case WATCHLIST_TAB_KEY.CREDIT_QUALITY:
        return 'FI: Credit Quality';
      default:
        return 'Fixed Income';
    }
  };

  const menuOptions = [
    {
      value: WATCHLIST_TAB_KEY.STYLE_FI,
      label: 'Style',
    },
    {
      value: WATCHLIST_TAB_KEY.SECTORS_FI,
      label: 'Sectors',
    },
    {
      value: WATCHLIST_TAB_KEY.GEOGRAPHY_FI,
      label: 'Geog',
    },
    {
      value: WATCHLIST_TAB_KEY.MATURITY,
      label: 'Maturity',
    },
    {
      value: WATCHLIST_TAB_KEY.CREDIT_QUALITY,
      label: 'Credit Quality',
    },
  ].filter(option => (tabsToShow ? tabsToShow.includes(option.value) : true));

  if (!menuOptions.length) {
    return null;
  }

  const menuItems = menuOptions.map(option => {
    const isActive = option.value === activeTabMenuItem;
    return {
      key: option.value,
      label: (
        <Option
          isActive={isActive && !isMobile}
          dataTestId={`tabGroupFixedIncome-${option.value}`}
          label={option.label}
        />
      ),
      selected: isActive,
      onClick: () => {
        isMobile && setIsNestedDrawerVisible(false);
        setActiveTabMenuItem(option.value);
        setActiveTab(WATCHLIST_TAB_GROUP.FIXED_INCOME);
      },
    };
  });

  return (
    <div>
      <Dropdown
        trigger={['hover', 'click']}
        placement="bottomLeft"
        menu={{
          items: isMobile ? [] : menuItems,
        }}
        disabled={isRestrictedAccess}
      >
        <div
          className="flex items-center justify-start gap-x-3 group"
          onClick={() => {
            isMobile && !isRestrictedAccess && setIsNestedDrawerVisible(true);
          }}
        >
          {formatLabel(activeTabMenuItem)}{' '}
          <NextArrow className="h-3 w-3 fill-current text-[#545576] group-hover:text-primary-400 transform rotate-90 transition-all duration-300 ease-in-out" />
        </div>
      </Dropdown>
      {isMobile && (
        <NestedDrawer
          menuItems={menuItems}
          visible={isNestedDrawerVisible}
          onClose={() => setIsNestedDrawerVisible(false)}
          title="Fixed Income"
        />
      )}
    </div>
  );
};

export default FixedIncomeMenu;
