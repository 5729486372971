import {
  SignificantChangesItem,
  SignificantChangesManagerList,
} from '@aminsights/contract';
import * as _ from 'lodash';

export const getFundManagerChange = (
  managerListOne: SignificantChangesManagerList[],
  managerListTwo: SignificantChangesManagerList[],
) => {
  const resultsManager = managerListOne.filter(object1 => {
    return !managerListTwo.some(object2 => {
      return object1.name === object2.name;
    });
  });
  return resultsManager.map(o => o.name).join(', ');
};

export const getFundManagerDescription = (
  data: SignificantChangesItem,
  length?: number,
):
  | {
      incomingManagerDescription: string;
      removedManagerDescription: string;
      incomingManagerDescriptionTruncated: string;
      removedManagerDescriptionTruncated: string;
    }
  | false => {
  if (!data.oldManagerList || !data.newManagerList) return false;
  const incomingManager = getFundManagerChange(
    data.newManagerList,
    data.oldManagerList,
  );
  const outgoignManager = getFundManagerChange(
    data.oldManagerList,
    data.newManagerList,
  );

  const change = {
    removedManagerDescription: outgoignManager ? `- ${outgoignManager}` : '',
    incomingManagerDescription: incomingManager ? `+ ${incomingManager}` : '',
  };
  return {
    ...change,
    removedManagerDescriptionTruncated:
      length !== undefined && change.removedManagerDescription.length > length
        ? _.truncate(change.removedManagerDescription, {
            length: length,
          })
        : '',
    incomingManagerDescriptionTruncated:
      length !== undefined && change.incomingManagerDescription.length > length
        ? _.truncate(change.incomingManagerDescription, {
            length: length,
          })
        : '',
  };
};
