import {
  PremiumDiscountApi,
  PremiumDiscountPerformance,
} from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import Tooltip from '@/components/Tooltip';
import { openApiConfig } from '@/utils';

import style from './style.module.less';

type PremiumDiscountProgressBarProps = {
  isin: string;
  isFundDetails?: boolean;
};

interface customProgressBarStyle {
  width: string;
  color: string;
  justifyContent?: string;
  paddingLeft?: string;
  backgroundColor: string;
}

const getState = (
  state?: PremiumDiscountPerformance,
): PremiumDiscountPerformance => {
  if (!state) {
    return {
      minimum: 0,
      maximum: 0,
      current: 0,
    };
  }
  return (Object.keys(state) as Array<keyof PremiumDiscountPerformance>).reduce(
    (obj, key) => ({
      ...obj,
      [key]: state[key] || 0,
    }),
    state,
  );
};

const CustomPremiumDiscountProgressBar: React.FC<
  PropsWithChildren<PremiumDiscountProgressBarProps>
> = ({ isin, isFundDetails }) => {
  const premiumDiscountApi = new PremiumDiscountApi(openApiConfig());
  const [state, setState] = useState<PremiumDiscountPerformance>(getState());
  const [loading, setLoading] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isin) {
      setLoading(true);
      premiumDiscountApi
        .getPremiumDiscountBar(isin)
        .then(d => {
          setState(getState(d.data));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      setState(getState());
    };
  }, [isin]);

  if (loading) {
    return <Skeleton.Input active />;
  }

  const calculatePremiumDiscount = (): string => {
    return (
      ((state.current - state.minimum) * 100) /
      (state.maximum - state.minimum)
    ).toFixed(1);
  };

  const progressBarStyle = (
    calculatedPercentage: string,
  ): customProgressBarStyle => {
    const isLightOrDark = +calculatedPercentage >= 20;
    return {
      width: `${calculatedPercentage}%`,
      backgroundColor: Math.sign(state.current) === 1 ? '#008A00' : '#DC2626',
      ...(isLightOrDark
        ? { color: '#fff' }
        : {
            color: '#313341',
            justifyContent: 'unset',
            paddingLeft: `${calculatedPercentage}%`,
          }),
    };
  };

  const show12MonthTip = (() => {
    if (!!state.to && !!state.from) {
      return dayjs(dayjs(state.to).diff(state.from)).month() >= 11;
    }
    return false;
  })();

  return (
    <Tooltip
      title={
        <span className="text-center">
          {show12MonthTip ? (
            <>
              Figure inside the bar represents the current premium/discount.
              Figures either side represent the high and low of the range over
              the last 12 months.
            </>
          ) : (
            <>
              From {dayjs(state.from).format(DISPLAY_DATE_FORMAT)}
              <br />
              to {dayjs(state.to).format(DISPLAY_DATE_FORMAT)}
            </>
          )}
        </span>
      }
      placement="bottom"
      color="#3E414B"
      data-html="true"
      overlayClassName={style.tooltip}
    >
      <div
        className={cx(
          style['custom-progress-bar-main-wrapper'],
          isFundDetails && style['fund-details'],
        )}
      >
        <p
          className={cx(
            style['custom-progress-bar-main-wrapper--heading'],
            isFundDetails && style['fund-details'],
          )}
        >
          <span className={style['span-line-break']}>Premium/Discount</span>
          <span className={style['span-line-break']}>
            {show12MonthTip ? ' (12 m range)' : ''}
          </span>
        </p>

        <div className={style['progress-bar--wrapper']}>
          <div className={style['progress-bar']}>
            <div
              className={cx(
                style['progress-bar--base'],
                isFundDetails && style['fund-details'],
              )}
            >
              <div
                className={style['progress-bar-process']}
                style={progressBarStyle(calculatePremiumDiscount())}
              >
                <span
                  className={style['progress-bar-percent']}
                >{`${state.current.toFixed(1)}%`}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <p className={style['progress-bar--min-max']}>
              {state.minimum.toFixed(1)}%
            </p>
            <p className={style['progress-bar--min-max']}>
              {state.maximum.toFixed(1)}%
            </p>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CustomPremiumDiscountProgressBar;
