import React, { PropsWithChildren, useEffect, useState } from 'react';

import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { countMoreFilters } from '../hooks/moreFiltersSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { ExploreModalProps } from '../types';
import MoreFiltersPanels from './MoreFiltersPanels';

export const MoreFiltersModal: React.FC<
  PropsWithChildren<ExploreModalProps>
> = ({ isOpen, onClose }) => {
  const {
    syncFilterByPath,
    getMutableFilters,
    updateTentativeFilters,
    tentativeFilters,
    filters,
  } = useExploreFilters();

  const handleOnApply = () => {
    syncFilterByPath('moreFilters');
    onClose();
  };

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.moreFilters) ===
        JSON.stringify(filters.moreFilters),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(
      applyDisabled && countMoreFilters(filters.moreFilters) === 0,
    );
  });

  return (
    <ExploreFilterModal
      title="More Filters"
      isVisible={isOpen}
      isDisableApply={applyDisabled}
      isDisableClear={clearDisabled}
      toggleModal={onClose}
      onClear={() => {
        const f = getMutableFilters();
        f.moreFilters = undefined;
        updateTentativeFilters(f);
      }}
      onApply={handleOnApply}
    >
      <MoreFiltersPanels />
    </ExploreFilterModal>
  );
};
