import { BenchmarkOption, CompositeBenchmark } from '@aminsights/contract';
import { Button, Checkbox, InputRef, Modal } from 'antd';
import cx from 'classnames';
import Decimal from 'decimal.js';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import { ReactComponent as RemoveIcon } from '@/assets/svg/icons/icon-remove-red.svg';
import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import { Input } from '@/components';
import {
  useBenchmarkOptions,
  useCreateCompositeBenchmark,
  useUpdateCompositeBenchmark,
} from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';
import BenchmarksDropdown, {
  BenchmarksDropdownRef,
} from '@/partials/BenchmarksDropdown';
import BenchmarkWeightInput from '@/partials/Forms/Input/BenchmarkWeightInput';

interface CompositeBenchmarkForm {
  name: string;
  shared: boolean;
  benchmarkItems: {
    secId: string;
    name: string;
    weighting: string;
  }[];
}

interface ModalProps {
  initialValue?: CompositeBenchmark;
  isVisible: boolean;
  toggleModal: () => void;
  benchmarks: Omit<BenchmarkOption, 'group' | 'source' | 'secId'>[];
  onSubmit?: (benchmark: CompositeBenchmark) => void;
}

const DEFAULT_BENCHMARK_ITEM = {
  secId: '',
  name: '',
  weighting: '',
};

const CompositeBenchmarkFormModal: FC<ModalProps> = ({
  initialValue,
  isVisible,
  toggleModal,
  benchmarks,
  onSubmit,
}) => {
  const createBenchmark = useCreateCompositeBenchmark();
  const updateBenchmark = useUpdateCompositeBenchmark();
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const autoFocusRef = useAutoFocusRef<InputRef>([isVisible], true);
  const benchmarksDropdownRef = useRef<BenchmarksDropdownRef>(null);

  const { control, handleSubmit, formState, setValue, clearErrors } =
    useForm<CompositeBenchmarkForm>({
      mode: 'all',
      defaultValues: {
        name: '',
        benchmarkItems: [DEFAULT_BENCHMARK_ITEM],
        shared: true,
      },
    });
  const {
    fields: benchmarkItemFields,
    append,
    remove,
    update,
    replace,
  } = useFieldArray({
    control,
    name: 'benchmarkItems',
    rules: {
      //Check total is 100 and no benchmarks without secId or with weighting == 0
      validate: values => {
        const totalWeighting = values.reduce(
          (acc, curr) => Decimal.add(acc, +curr.weighting ?? 0).toNumber(),
          0,
        );

        const isValid =
          (totalWeighting === 100 &&
            values.every(v => v.secId !== '' && +v.weighting > 0)) ||
          values.length === 0;
        if (isValid) {
          clearErrors('benchmarkItems');
        }

        return isValid;
      },
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (initialValue) {
      const { name, shared, benchmarkItems } = initialValue;
      const itemsMapper = benchmarkItems.map(bi => ({
        ...bi,
        weighting: String(bi.weighting),
      }));
      setValue('name', name);
      setValue('shared', shared === undefined ? true : shared);
      replace(itemsMapper);
    } else {
      setValue('name', '');
      setValue('shared', true);
      replace([DEFAULT_BENCHMARK_ITEM]);
    }
  }, [initialValue]);

  const handleCloseModal = () => {
    if (formState.isSubmitting) return;
    toggleModal();
    setValue('name', '');
    setValue('shared', true);
    replace([DEFAULT_BENCHMARK_ITEM]);
    clearErrors('name');
    benchmarksDropdownRef?.current?.close?.();
  };

  const handleSaveBenchmark = handleSubmit(async data => {
    const mappedBenchmarkItems = data.benchmarkItems.map(bi => ({
      name: bi.name,
      secId: bi.secId,
      weighting: +bi.weighting,
    }));

    if (initialValue?._id) {
      await updateBenchmark.mutateAsync({
        id: initialValue._id,
        body: { ...data, benchmarkItems: mappedBenchmarkItems },
      });
    } else {
      const result = await createBenchmark.mutateAsync({
        body: { ...data, benchmarkItems: mappedBenchmarkItems },
      });
      if (onSubmit) onSubmit(result.data);
    }
    setValue('name', '');
    setValue('shared', true);
    replace([DEFAULT_BENCHMARK_ITEM]);
    toggleModal();
  });

  const handleBenchmarkChange = (selectedValue: string, index: number) => {
    const benchmark = benchmarkItemFields.find((_bm, i) => i === index);
    if (!benchmark) {
      throw new Error('Not able to find benchmark item to update');
    }
    benchmark.secId = selectedValue;
    benchmark.name =
      benchmarkOptionsResponse?.find(b => b.id === selectedValue)?.name || '';
    update(index, benchmark);
  };

  const handleWeightChange = (value: string, index: number) => {
    const benchmark = benchmarkItemFields.find((_b, i) => i === index);
    if (benchmark) {
      benchmark.weighting = value;
      update(index, benchmark);
    }
  };

  const totalWeighting = useMemo(() => {
    return benchmarkItemFields.reduce(
      (acc, curr) => Decimal.add(acc, +curr.weighting ?? 0).toNumber(),
      0,
    );
  }, [benchmarkItemFields]);

  const isEditing = Boolean(initialValue);
  const isTotalWeightInvalid =
    totalWeighting !== 100 &&
    benchmarkItemFields.some(bi => bi.secId !== '' && +bi.weighting > 0);

  return (
    <Modal
      centered={true}
      open={isVisible}
      onCancel={handleCloseModal}
      className="max-sm:full-page-modal action-modal"
      title={`${isEditing ? 'Edit' : 'Add'} benchmark`}
      footer={[
        <Button
          className="p-0 border-0"
          type="link"
          size="large"
          key="cancel"
          htmlType="button"
          onClick={handleCloseModal}
          data-test-id="customBenchmarksModalCancelButton"
        >
          Cancel
        </Button>,
        <Button
          className="h-10 w-20"
          type="primary"
          size="large"
          key="primary"
          htmlType="submit"
          disabled={!formState.isValid}
          loading={formState.isSubmitting}
          onClick={handleSaveBenchmark}
          data-test-id="customBenchmarksModalAddSaveButton"
        >
          {isEditing ? 'Save' : 'Add'}
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-4">
        <form className="flex flex-col">
          <div
            className={cx(
              'max-h-80 w-full flex flex-col justify-around overflow-auto',
            )}
          >
            <div>
              <Controller
                key="name"
                control={control}
                name="name"
                rules={{
                  required: 'Benchmark name is required',
                  validate: {
                    duplicatedName: value => {
                      const hasDuplicate = benchmarks.some(bm => {
                        if (
                          bm.id === initialValue?._id &&
                          initialValue?._id !== ''
                        ) {
                          return false;
                        }
                        return bm.name === value.trim();
                      });
                      if (hasDuplicate) {
                        return 'Benchmark name already exists';
                      }
                      return;
                    },
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-1 mb-4">
                    <label
                      htmlFor="name"
                      className="text-xs font-medium text-neutral-200"
                    >
                      Name
                    </label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={value}
                      placeholder="Enter benchmark name"
                      onChange={onChange}
                      error={error?.message}
                      dataTestId="customBenchmarksModalNameInput"
                      ref={autoFocusRef}
                      className="placeholder:!text-neutral-100"
                    />
                  </div>
                )}
              />
              <div className="flex flex-col gap-y-4">
                {benchmarkItemFields.map(({ secId, weighting }, index) => (
                  <div
                    key={secId}
                    className={cx(
                      'flex flex-row gap-x-2 relative items-center',
                    )}
                  >
                    <div className="grid grid-cols-[1fr_80px] w-full">
                      <div className="mr-2 min-w-0">
                        {index === 0 && (
                          <label className="mb-1 text-xs text-neutral-200 font-medium">
                            Benchmark
                          </label>
                        )}
                        <Controller
                          control={control}
                          name={`benchmarkItems.${index}.name`}
                          render={({
                            field: { onChange: onBenchmarkChange },
                          }) => (
                            <BenchmarksDropdown
                              dropdownKey={`benchmark-dropdown-${index}`}
                              className="!h-10"
                              placeholder="Select index"
                              value={secId}
                              benchmarkOptions={benchmarkOptionsResponse ?? []}
                              onSelect={selectedValue => {
                                onBenchmarkChange(selectedValue);
                                handleBenchmarkChange(selectedValue, index);
                                handleWeightChange('', index);
                              }}
                              excludedValues={benchmarkItemFields
                                .filter(item => item.secId !== secId)
                                .map(nb => nb.secId)}
                              isOptionsLoading={isBenchmarkOptionsLoading}
                              hideCustomOptions
                              dataTestId="customBenchmarksModalBenchmarkDropdown"
                              ref={benchmarksDropdownRef}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {index === 0 && (
                          <label className="mb-1 text-xs text-neutral-200 font-medium">
                            Weight
                          </label>
                        )}
                        <Controller
                          control={control}
                          name={`benchmarkItems.${index}.weighting`}
                          render={({ field: { onChange: onWeightChange } }) => (
                            <BenchmarkWeightInput
                              uniqueKey={index.toString()}
                              value={weighting}
                              saveState={value => {
                                onWeightChange(value, index);
                                handleWeightChange(value, index);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {benchmarkItemFields.length > 1 && (
                      <div
                        onClick={e => {
                          e.preventDefault();
                          remove(index);
                        }}
                        role="button"
                        className={cx(index === 0 && 'mt-5')}
                      >
                        <RemoveIcon className="text-xl icon" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="py-2">
                <button
                  className={cx(
                    'flex flex-row items-center text-primary font-medium cursor-pointer',
                    'hover:text-info',
                  )}
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    append(DEFAULT_BENCHMARK_ITEM);
                  }}
                >
                  <PlusIcon className="icon text-xl" />
                  <p
                    className="pl-1 text-sm font-medium"
                    data-test-id="customBenchmarksModalAddButton"
                  >
                    Add benchmark
                  </p>
                </button>
              </div>
            </div>
            <div>
              <div
                className={cx(
                  'flex flex-row gap-x-2 bg-grey-lighter mt-2',
                  'border-b border-[#eaeaea]',
                )}
              >
                <div className="flex-1 flex items-center justify-end">
                  {isTotalWeightInvalid && (
                    <InfoWarningRed width={15} height={15} />
                  )}
                  <p className="p-2 text-sm text-right font-normal text-neutral">
                    Total
                  </p>
                </div>
                <p
                  className={cx(
                    'px-4 py-2 text-sm text-neutral font-semibold text-center overflow-visible',
                    'min-w-24 border-b border-[#eaeaea]',
                    isTotalWeightInvalid ? 'text-destructive-500' : '',
                  )}
                >
                  {totalWeighting}%
                </p>
              </div>
              <p className="text-xs font-normal text-destructive-500 text-right mt-2 min-h-4">
                {isTotalWeightInvalid && 'Weight does not total 100%'}
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col pt-6 gap-y-2">
              <p className="text-sm font-normal text-[#545576]">
                Custom benchmarks are automatically shared with your
                organisation for use in shared buckets & portfolios. If you
                don't want it to be shared, select the checkbox below.
              </p>

              <Controller
                control={control}
                name="shared"
                render={({ field: { value, onChange } }) => (
                  <div data-test-id="customBenchmarksModalShareCheckbox">
                    <Checkbox
                      className="text-sm font-normal text-neutral-700 px-2"
                      value={!value}
                      checked={!value}
                      onChange={() => {
                        onChange(!value);
                      }}
                    >
                      Do not share with my organisation
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CompositeBenchmarkFormModal;
