import { Fund } from '@aminsights/contract';
import { CreditQualityBreakdownTypeCodes } from '@aminsights/shared';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { DataTable } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { ICreditQuality, columns } from './columns';

/**
 * @deprecated - consolidated into /utils/data-mappers/creditQualityMapper.tsx
 */
const formCreditQuality = (fund: Fund): ICreditQuality[] => {
  const result = [];
  result.push(
    ...(fund.bondCreditQualityBreakdown?.map(maturity => {
      const definition = CreditQualityBreakdownTypeCodes.find(
        v => v.typeCode === maturity.type,
      )?.definition;
      return {
        creditQuality: definition || '',
        fund: maturity.value,
      };
    }) || []),
  );

  result.sort((a, b) => (a.fund < b.fund ? 1 : -1));
  return result;
};

const CreditQualityTable: React.FC<PropsWithChildren> = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();

  const [maturityList, setMaturityList] = useState<ICreditQuality[]>([]);

  useEffect(() => {
    if (state?.fund) {
      setMaturityList(formCreditQuality(state.fund));
    }
  }, [state]);

  return (
    <>
      <div
        className="bg-white rounded-lg"
        data-test-id="fundDetailsPortfolioTabCreditQualityTable"
      >
        <DataTable
          uniqueKey="sector"
          columns={columns(!!isInvestmentTrust)}
          data={maturityList}
          noDataLabel="No portfolio data provided"
        />
      </div>
      <div className="py-4 px-2 w-full">
        <p className="text-xs text-neutral">Only captures long positions</p>
      </div>
    </>
  );
};

export default CreditQualityTable;
