import { Modal, Select } from 'antd';
import { Button } from 'antd';
import React, { PropsWithChildren, useState } from 'react';

import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';

import style from './style.module.less';

type ModalProps = {
  isVisible: boolean;
  toggleModal: () => void;
  userOptions: { label: string; value: string }[];
  onSubmitClick: (users: string[]) => Promise<void>;
  hasManagePermissions?: boolean;
  hasActiveUsersWithEditAccess?: boolean;
  hasPendingUsersWithEditAccess?: boolean;
};

const RevokePortfolioAccessModal: React.FC<PropsWithChildren<ModalProps>> = ({
  isVisible,
  toggleModal,
  userOptions,
  onSubmitClick,
  hasManagePermissions,
  hasActiveUsersWithEditAccess,
  hasPendingUsersWithEditAccess,
}) => {
  const [selectedusers, setSelectedUsers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch: dispatchApp } = useAppContext();

  const handleSearchTermChange = (e: string) => {
    if (e.includes(',')) {
      setSelectedUsers(prev => [...prev, e.replace(',', '')]);
      setSearchTerm('');
      return;
    }
    setSearchTerm(e);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    // @deprecated - please refactor to react-query mutation and implement error catching with `setErrorMessage`
    onSubmitClick(selectedusers)
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully deleted a portfolio' },
        });
        toggleModal();
        setSelectedUsers([]);
        setSearchTerm('');
      })
      .catch(error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting portfolio',
        });
      })
      .finally(() => setIsSaving(false));
  };

  const hasUsersWithEditAccess =
    hasActiveUsersWithEditAccess || hasPendingUsersWithEditAccess;
  const hasOnlyPendingUsersWithEditAccess =
    !hasActiveUsersWithEditAccess && hasPendingUsersWithEditAccess;

  const renderModalDescription = () => {
    if (!hasManagePermissions || hasOnlyPendingUsersWithEditAccess) {
      return 'This action cannot be undone.';
    } else if (!hasUsersWithEditAccess) {
      return (
        <div className="flex flex-col gap-y-4">
          <p className="text-sm">
            You cannot delete a shared portfolio without another editor. Assign
            an editor before deleting for yourself.
          </p>
          <p className="text-sm">This action cannot be undone.</p>
        </div>
      );
    }
    return <p className="text-sm">This action cannot be undone.</p>;
  };

  return (
    <Modal
      open={isVisible}
      onCancel={toggleModal}
      centered={true}
      className="max-sm:full-page-modal action-modal action-modal-confirmation"
      title={
        <div className="flex gap-x-2">
          <InfoWarningRed className="mt-0.5" width={22} height={22} />
          <h3 className="text-xl font-bold">Delete for me</h3>
        </div>
      }
      footer={[
        <Button
          className={style['revoke-access-modal__cancel']}
          type="link"
          key="secondary"
          disabled={isSaving}
          onClick={toggleModal}
        >
          Cancel
        </Button>,
        <Button
          key="primary"
          type="primary"
          size="large"
          loading={isSaving}
          disabled={
            isSaving || (selectedusers.length === 0 && !hasUsersWithEditAccess)
          }
          onClick={handleSubmit}
          danger
        >
          {hasUsersWithEditAccess ? 'Delete' : 'Assign and delete for me'}
        </Button>,
      ]}
    >
      <div className={style['revoke-access-modal__container']}>
        <div className={style['revoke-access-modal__description']}>
          {renderModalDescription()}
        </div>
        {!hasUsersWithEditAccess && (
          <div className="flex-1 flex flex-col mt-2">
            <Select
              mode="multiple"
              value={selectedusers}
              onChange={val => {
                setSelectedUsers(val);
                setSearchTerm('');
              }}
              onSearch={handleSearchTermChange}
              className={style['select-users']}
              searchValue={searchTerm}
              placeholder="Ex. John, Jennifer,..."
              options={userOptions}
              filterOption={(_, opt) => {
                return Boolean(
                  opt?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
                );
              }}
              suffixIcon={null}
            />
            <p className="text-xs text-neutral-400 mt-1">
              Share to one or more recipients, separated by commas.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RevokePortfolioAccessModal;
