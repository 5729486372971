import { RatingBody, RatingRecord, RatingsApi } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { RATINGS } from './query-keys';

const ratingsApi = new RatingsApi(openApiConfig());

const invalidateRatings = () => {
  queryClient.invalidateQueries({ queryKey: [RATINGS] });
};

export const useGetRatings = (fundMetadataId: string) => {
  return useQuery<RatingRecord[] | null, unknown>(
    [RATINGS, fundMetadataId],
    async () => {
      const result = await ratingsApi.getRatings(fundMetadataId);
      return result.data;
    },
    { enabled: Boolean(fundMetadataId) },
  );
};

export const useCreateRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; body: RatingBody }) =>
      ratingsApi.createRatings(input.fundMetadataId, input.body),
    {
      onSettled: () => invalidateRatings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Rating has been added' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error adding rating',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; ratingId: string; body: RatingBody }) =>
      ratingsApi.updateRatings(
        input.fundMetadataId,
        input.ratingId,
        input.body,
      ),
    {
      onSettled: () => invalidateRatings(),
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating rating',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; ratingId: string }) =>
      ratingsApi.deleteRatings(input.fundMetadataId, input.ratingId),
    {
      onSettled: () => invalidateRatings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Rating has been deleted' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting rating',
        });
      },
    },
  );
  return mutation;
};
