import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconError } from '@/assets/svg/icons/icon-error.svg';
import Button from '@/components/Button';
import { RESTRICTED_FEATURE } from '@/constants/features';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { openJiraForm } from '@/utils/jiraForm';

export interface StaticDataForLimitModal {
  entityName: string;
  limitForEntities: number;
  limitForFundsOrTrusts: number;
}

type ModalProps = {
  isFundTrust: boolean;
  staticData: StaticDataForLimitModal;
  isVisible: boolean;
  className?: string;
  onApply?: () => void;
  toggleModal: () => void;
  showDeleteOtherFundsTrusts?: boolean;
  restrictedFeature?: RESTRICTED_FEATURE;
};

enum modalContent {
  BUCKET = 'bucket',
  FUND_TRUST = 'funds/trusts',
}

const LimitReachModal: React.FC<PropsWithChildren<ModalProps>> = ({
  isFundTrust,
  staticData,
  isVisible,
  className,
  toggleModal,
  showDeleteOtherFundsTrusts = true,
  restrictedFeature = undefined,
}) => {
  const { toggleUpgradeModal } = useUpgradeAccess();
  const generateModalTitle = () => {
    const modalTitle = `You've reached the ${
      isFundTrust
        ? `${staticData.limitForFundsOrTrusts} ${modalContent.FUND_TRUST}`
        : `${
            staticData.limitForEntities
          } ${staticData.entityName.toLocaleLowerCase()}s`
    } limit ${
      isFundTrust ? `per ${staticData.entityName.toLocaleLowerCase()}` : ''
    }`;
    return modalTitle;
  };
  const generateAddMoreText = () => {
    const addMoreText = `If you want to add more ${
      isFundTrust
        ? modalContent.FUND_TRUST
        : `${staticData.entityName.toLowerCase()}s`
    }, please contact us.`;
    return addMoreText;
  };

  return (
    <>
      <Modal
        centered={true}
        open={isVisible}
        onCancel={toggleModal}
        className={cx(
          '[&_.ant-modal-body]:pt-10 [&_.ant-modal-footer]:!justify-center [&_.ant-modal-footer]:flex-col',
          '[&_.ant-modal-footer]:items-center',
          className,
        )}
        footer={[
          <Button
            size="large"
            type="primary"
            className="font-semibold !w-max"
            onClick={
              restrictedFeature
                ? () => toggleUpgradeModal(restrictedFeature)
                : openJiraForm
            }
            key="primary"
          >
            {restrictedFeature ? 'Request an Upgrade' : 'Get in Touch'}
          </Button>,
          showDeleteOtherFundsTrusts ? (
            <div className="text-center" key="delete-option">
              {isFundTrust ? (
                <div>
                  or{' '}
                  <Link
                    className="text-[#0072e6]"
                    to={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
                  >
                    delete other funds/trusts
                  </Link>{' '}
                  from your watchlist
                </div>
              ) : null}
            </div>
          ) : null,
        ]}
      >
        <div className="text-center">
          <IconError className="text-danger m-auto" width={64} height={64} />
          <div className="pt-6 pl-2 text-lg font-semibold text-neutral-900">
            {generateModalTitle()}
          </div>
          <div className="text-sm font-normal text-neutral-450">
            {generateAddMoreText()}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LimitReachModal;
