import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { ReactComponent as IconInfo } from '@/assets/svg/icons/icon-info.svg';
import BoardList from '@/components/BoardList';
import { Table } from '@/components/Table';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { EthnicityType, GenderType } from './constants';
import style from './style.module.less';

interface TitleWithTooltipProps {
  title: string;
  tooltipInfo: string;
}

const columns = [
  {
    title: 'Summary Stats',
    dataIndex: 'name',
  },
  {
    title: '',
    dataIndex: 'value',
    width: 95,
  },
];

const TitleWithTooltip: React.FC<TitleWithTooltipProps> = ({
  title,
  tooltipInfo,
}) => {
  return (
    <div className="flex items-center gap-2 text-darkest text-sm font-bold py-2">
      <span>{title}</span>
      <Tooltip
        placement={'bottom'}
        title={tooltipInfo}
        className="cursor-pointer"
      >
        <IconInfo className="text-neutral hover:text-neutral-100" />
      </Tooltip>
    </div>
  );
};

const transformDate = (dateString: string): string => {
  if (!dateString) {
    return 'N/A';
  }

  const parsedDate = dayjs(
    dateString,
    ['DD/MM/YYYY', 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'],
    true,
  );

  if (!parsedDate.isValid()) {
    return 'N/A';
  }

  return `As of ${parsedDate.format('DD MMM YYYY')}`;
};

const SummaryStats: React.FC<PropsWithChildren> = () => {
  const { data: state } = useFundByIsInParam();

  const [summaryStatsData, setSummaryStatsData] = useState<
    { name: ReactNode; value: ReactNode }[]
  >([]);
  const [genderData, setGenderData] = useState<
    { label: string; barColor: string; progressPercentage: number }[]
  >([]);
  const [ethnicityData, setEthnicityData] = useState<
    { label: string; barColor: string; progressPercentage: number }[]
  >([]);

  useEffect(() => {
    if (state?.fund) {
      setSummaryStatsData(
        formSummaryStats(
          state.fund,
          state?.fund?.boardDiversity?.independenceAsOfDate || '',
        ),
      );
      setGenderData(formGenderData(state.fund));
      setEthnicityData(formEthnicityData(state.fund));
    }
  }, [state]);

  const formSummaryStats = (fund: Fund, tooltipInfo: string) => {
    const summaryStatsData = [
      {
        name: (
          <TitleWithTooltip
            title="Independence"
            tooltipInfo={transformDate(tooltipInfo)}
          />
        ),
        value: (
          <div className="flex justify-end">
            {fund.boardDiversity?.independence
              ? `${Math.round(Number(fund.boardDiversity?.independence))}%`
              : EMPTY_DATA_POINT}
          </div>
        ),
      },
    ];
    return summaryStatsData;
  };

  const formGenderData = (fund: Fund) => {
    const genderData = [
      {
        label: GenderType.Male,
        barColor: '#F6BF26',
        progressPercentage: fund.boardDiversity?.gendersAmount?.male
          ? Math.round(Number(fund.boardDiversity?.gendersAmount?.male))
          : 0,
      },
      {
        label: GenderType.Female,
        barColor: '#EF6C00',
        progressPercentage: fund.boardDiversity?.gendersAmount?.female
          ? Math.round(Number(fund.boardDiversity?.gendersAmount?.female))
          : 0,
      },
      {
        label: GenderType.PreferNotToSay,
        barColor: '#A5550B',
        progressPercentage: fund.boardDiversity?.gendersAmount?.nonBinary
          ? Math.round(Number(fund.boardDiversity?.gendersAmount?.nonBinary))
          : 0,
      },
    ];
    return genderData;
  };

  const formEthnicityData = (fund: Fund) => {
    const ethnicityData = [
      {
        label: EthnicityType.White,
        barColor: '#0072E6',
        progressPercentage:
          Math.round(Number(fund.boardDiversity?.ethnicitiesAmount?.white)) ||
          0,
      },
      {
        label: EthnicityType.Asian,
        barColor: '#9BBF31',
        progressPercentage:
          Math.round(Number(fund.boardDiversity?.ethnicitiesAmount?.asian)) ||
          0,
      },
      {
        label: EthnicityType.Black,
        barColor: '#EA50B4',
        progressPercentage:
          Math.round(Number(fund.boardDiversity?.ethnicitiesAmount?.black)) ||
          0,
      },
      {
        label: EthnicityType.Mixed,
        barColor: '#9A4EF6',
        progressPercentage:
          Math.round(Number(fund.boardDiversity?.ethnicitiesAmount?.mixed)) ||
          0,
      },
      {
        label: EthnicityType.Others,
        barColor: '#3BDEBC',
        progressPercentage:
          Math.round(Number(fund.boardDiversity?.ethnicitiesAmount?.others)) ||
          0,
      },
      {
        label: EthnicityType.PreferNotToSay,
        barColor: '#A88648',
        progressPercentage:
          Math.round(
            Number(fund.boardDiversity?.ethnicitiesAmount?.preferNotToSay),
          ) || 0,
      },
    ];
    return ethnicityData;
  };

  return (
    <div className="w-full">
      <Table
        className={style['summary-stats__table']}
        columns={columns}
        data={summaryStatsData}
        paginateTotalCount={0}
        uniqueKey="value"
      />
      <div>
        <div className="px-4 pt-6 pb-8">
          <TitleWithTooltip
            title="Gender"
            tooltipInfo={transformDate(
              state?.fund?.boardDiversity?.genderAsOfDate ?? '',
            )}
          />
          {genderData.map(data => (
            <BoardList
              label={data.label}
              key={data.label}
              progressPercentage={data.progressPercentage}
              barColor={data.barColor}
            />
          ))}
        </div>
        <hr />
        <div className="px-4 pt-6 pb-8">
          <TitleWithTooltip
            title="Ethnicity"
            tooltipInfo={transformDate(
              state?.fund?.boardDiversity?.ethnicityAsOfDate ?? '',
            )}
          />
          {ethnicityData.map(data => (
            <BoardList
              label={data.label}
              key={data.label}
              progressPercentage={data.progressPercentage}
              barColor={data.barColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SummaryStats;
