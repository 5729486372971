// import { Table } from '@/components/Table';
import DataTable, { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { Skeleton } from 'antd';
import style from '../Summary/style.module.less';

interface ObjectiveItem {
  objective: string;
  value: string | undefined;
}

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    renderType: 'text',
    render: (item: ObjectiveItem) => (
      <span className="text-neutral-200 text-xs font-bold">
        {item.objective}
      </span>
    ),
    minWidth: 240,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Type',
    isColumnFixed: true,
  },
  {
    title: 'Available',
    renderType: 'text',
    render: (item: ObjectiveItem) => (item.value ? 'Yes' : 'No'),
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Available',
    className: 'w-full',
    isColumnFixed: true,
  },
];

const Objectives = () => {
  const { data: state, isLoading } = useFundByIsInParam();

  const objectivesData = [
    {
      objective: 'Sustainable Objective',
      value: state?.fund.investmentObjectives?.sustainableObjective,
    },
    {
      objective: 'Impact Objective',
      value: state?.fund.investmentObjectives?.impactObjective,
    },
  ];

  return (
    <div className={style['summary-table']}>
      <DataTable
        uniqueKey="objective"
        columns={columns}
        data={objectivesData}
        loading={isLoading}
      />
    </div>
  );
};

export default Objectives;
