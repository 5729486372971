import { Button, Modal } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useState } from 'react';

import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';

type ModalProps = {
  modalInfo: {
    title?: string;
    description?: string | React.ReactNode;
    primaryActionLabel: string;
    primaryActionDisabled?: boolean;
    secondaryActionLabel?: string;
    succesMessage?: string;
    errorMessage?: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onConfirm: () => Promise<any | void> | void;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  withWarningIcon?: boolean;
  destroyOnClose?: boolean;
};

const ConfirmationModalDanger: React.FC<PropsWithChildren<ModalProps>> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  onConfirm,
  children,
  width = 400,
  withWarningIcon = true,
  destroyOnClose,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const onClick = async () => {
    setIsProcessing(true);
    await onConfirm()
      ?.then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: modalInfo.errorMessage,
        });
      })
      .finally(() => {
        toggleModal();
        setIsProcessing(false);
      });
  };
  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(
        'action-modal',
        'action-modal-confirmation',
        'action-modal-confirmation-danger',
        className,
      )}
      destroyOnClose={destroyOnClose}
      title={
        <div className="flex gap-x-2">
          {withWarningIcon && (
            <div className="flex w-6 h-6 items-center justify-center">
              <InfoWarningRed className="h-[22px] mt-0.5 w-[22px] shrink-0 [&_path]:fill-destructive-500" />
            </div>
          )}
          <div className="flex-1 text-xl font-bold text-darkest">
            {modalInfo.title}
          </div>
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={toggleModal}
          data-test-id="modalDeleteBucketCancelButton"
          className="!text-neutral-700"
        >
          {modalInfo.secondaryActionLabel || 'Cancel'}
        </Button>,
        <Button
          danger
          size="large"
          onClick={onClick}
          key="primary"
          type="primary"
          loading={loading}
          disabled={loading || modalInfo.primaryActionDisabled || isProcessing}
          data-test-id="modalDeleteBucketConfirmButton"
        >
          {modalInfo.primaryActionLabel}
        </Button>,
      ]}
    >
      <div className="text-sm font-normal leading-6 text-neutral-700">
        {modalInfo.description}
      </div>
      {children}
    </Modal>
  );
};

export default ConfirmationModalDanger;
