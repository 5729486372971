import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useCallback, useContext } from 'react';

const useUserAccess = () => {
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;

  const hasPermissions = useCallback(
    (permissions: string[]) => {
      const userPermissions = currentUser.permissions || [];

      return permissions.every(permission =>
        userPermissions.includes(permission),
      );
    },
    [currentUser],
  );

  return { currentUser, hasPermissions };
};

export default useUserAccess;
