import { ReactComponent as Congratulations } from '@/assets/svg/research/congratulations.svg';
import {
  EMPTY_DATA_POINT,
  FUND_DETAILS_TAB,
  buildFundDetailsPath,
} from '@aminsights/shared';

import React, { PropsWithChildren } from 'react';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import ResearchLegendColor from '@/partials/ResearchWidget/ResearchLegendColor';
import { ResearchTableType } from '@/types/research';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import {
  NotificationsSettingsForNotesAndMeetings,
  ResearchDeadlineRecord,
  ResearchDeadlineWidgetTypeEnum,
} from '@aminsights/contract';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

type ResearchTableProps = {
  isLoading: boolean;
  data: Array<ResearchDeadlineRecord>;
  tableType: ResearchTableType;
  notificationSettings: NotificationsSettingsForNotesAndMeetings | undefined;
  title?: string;
};
type GetColumnsProps = {
  tableType: ResearchTableType;
  notificationSettings: NotificationsSettingsForNotesAndMeetings;
  title?: string;
  isMobile: boolean;
};

const titleContent = (item: ResearchDeadlineRecord) => {
  return item.fundName ? (
    <Link
      className="font-medium text-neutral block truncate py-3"
      to={buildFundDetailsPath(item.fundMetadataId, FUND_DETAILS_TAB.RESEARCH)}
      onClick={e => {
        e.stopPropagation();
        window.scrollTo({ top: 0 });
      }}
    >
      {item.fundName}
    </Link>
  ) : (
    EMPTY_DATA_POINT
  );
};

const getColumns = ({
  tableType,
  notificationSettings,
  title,
  isMobile,
}: GetColumnsProps): Array<IDataTableColumns> => [
  {
    title: title || ResearchTableType[tableType],
    render: (item: ResearchDeadlineRecord) => {
      return (
        <div className="flex items-center gap-x-2 truncate">
          <div>
            <ResearchLegendColor researchType={item.type} />
          </div>

          <Tooltip
            placement="bottom"
            title={item.fundName.length > 30 ? item.fundName : ''}
            overlayClassName="max-w-60 md:max-w-none"
            overlayInnerStyle={{ borderRadius: 4 }}
          >
            {titleContent(item)}
          </Tooltip>
        </div>
      );
    },
    renderType: 'text',
    width: isMobile ? 190 : 210,
  },
  {
    title: '',
    render: (research: ResearchDeadlineRecord) => {
      let result: any = EMPTY_DATA_POINT;

      if (tableType === ResearchTableType.Overdue) {
        const daysLeft = dayjs().diff(dayjs(research.date), 'days');
        result = (
          <p className="px-1.5 py-1 bg-destructive-50 rounded text-destructive-500 w-max">
            {daysLeft} day{daysLeft > 0 ? 's' : ''} overdue
          </p>
        );
      } else if (tableType === ResearchTableType.Upcoming) {
        const dayjsDue = dayjs(research.date);
        const dueDate =
          research.type === ResearchDeadlineWidgetTypeEnum.Meeting
            ? dayjsDue
            : dayjsDue.add(
                notificationSettings.meetingGap.monthsPeriod,
                'months',
              );
        result = (
          <p className="px-1.5 py-1 bg-warning-100 rounded text-warning-800 w-max">
            Due {dueDate.format('DD MMM ‘YY')}
          </p>
        );
      }

      return result;
    },
    renderType: 'number',
  },
];

const ResearchTable: React.FC<PropsWithChildren<ResearchTableProps>> = ({
  data,
  isLoading,
  notificationSettings,
  tableType,
  title,
}) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  return (
    <DataTable
      tableDivClassName="rounded-t-lg"
      className="border-grey-lighter border border-solid"
      uniqueKey="key"
      emptyState={
        <div
          className={`w-full p-5 pt-10 flex flex-col items-center 
        justify-center gap-y-4`}
        >
          <Congratulations />
          <span className="text-sm font-normal text-center text-neutral-700">
            No meetings or notes are{' '}
            {tableType === ResearchTableType.Overdue ? 'overdue' : 'upcoming'}
          </span>
        </div>
      }
      columns={
        !notificationSettings
          ? []
          : getColumns({
              tableType,
              notificationSettings: notificationSettings,
              title: title,
              isMobile,
            })
      }
      data={!notificationSettings ? [] : data}
      loading={isLoading}
      loaderSize={4}
      disableVerticalRowPadding={!isLoading}
    />
  );
};

export default ResearchTable;
