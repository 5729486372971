import {
  PortfolioInviteDetailResponse,
  PortfoliosApi,
} from '@aminsights/contract';
import { PORTFOLIO_REFETCH_KEY } from '@aminsights/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { openApiConfig } from '@/utils';

import { PORTFOLIOS } from './query-keys';

const portfoliosApi = new PortfoliosApi(openApiConfig());

const defaultAcceptErrorMessage =
  'Error occurred while accepting the invitation';
const defaultDeclineErrorMessage =
  'Error occurred while declining the invitation';

export const usePortfolioInviteDetail = (token?: string) => {
  return useQuery<PortfolioInviteDetailResponse, unknown>(
    [PORTFOLIOS, token],
    async () => {
      const result = await portfoliosApi.getPortfolioInviteDetail(token, {});

      return result.data;
    },
    {
      enabled: !!token,
    },
  );
};

export const useAcceptPortfolioInvite = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const history = useHistory();

  const mutation = useMutation(
    (input: { inviteLink: string }) => {
      const token = input.inviteLink.split('?token=')[1];
      if (!token) throw new Error('No token provided');

      return portfoliosApi.acceptSharedPortfolioInvite(token);
    },
    {
      onSuccess: async (res, input) => {
        const navLink = input.inviteLink.split('?')[0] || '/portfolios';
        dispatchApp({
          type:
            res.data.status === 200
              ? APP_ACTIONS.SET_SUCCESS_MESSAGE
              : APP_ACTIONS.SET_REGULAR_MESSAGE,
          payload: {
            text: `${res.data.message}`,
          },
        });
        if (res.data.success === true) {
          history.push(`${navLink}?${PORTFOLIO_REFETCH_KEY}=true` || '#');
        } else {
          history.push('/portfolios');
        }
      },
      onError: (error: AxiosError) => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: defaultAcceptErrorMessage,
        });
        history.push('/portfolios');
      },
    },
  );

  return mutation;
};

export const useDeclinePortfolioInvite = () => {
  const { dispatch: dispatchApp } = useAppContext();

  const mutation = useMutation(
    (input: { inviteLink: string }) => {
      const token = input.inviteLink.split('?token=')[1];
      if (!token) throw new Error('No token provided');

      return portfoliosApi.declineSharedPortfolioInvite(token);
    },
    {
      onSuccess: async res => {
        dispatchApp({
          type: APP_ACTIONS.SET_REGULAR_MESSAGE,
          payload: {
            text: `${res.data.message}`,
          },
        });
      },
      onError: (error: AxiosError) => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: defaultDeclineErrorMessage,
        });
      },
    },
  );

  return mutation;
};
