import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useInternal from '@/hooks/query-hooks/internal-hooks/useInternal';
import Commentary from '@/partials/Internal/Commentary';
import Materials from '@/partials/Internal/Materials';
const Internal = () => {
  const { data: fundState } = useFundByIsInParam();
  const { fundId } = fundState?.fund || {};

  const { data, isLoading } = useInternal(fundId);

  return (
    <div className="flex flex-col py-2 lg:px-4 gap-y-4">
      <Materials materials={data?.materials ?? []} isLoading={isLoading} />
      <Commentary comments={data?.comments ?? []} isLoading={isLoading} />
    </div>
  );
};

export default Internal;
