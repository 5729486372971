import { ReactComponent as IconAction } from '@/assets/svg/icons/icon-action-transparent.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconArrowDownStroke } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { Button, Dropdown, Tabs } from 'antd';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BasePageWithMetadata from '../../BasePageWithMetadata';
import Buckets from '../Buckets';
import Folders from '../Folders';

export enum WATCHLIST_MANAGE_TAB {
  FOLDERS = 'folders',
  BUCKETS = 'buckets',
  ORDER_WATCHLIST = 'order-watchlist',
}

const ManageWatchlist = () => {
  const history = useHistory();
  const { tab } = useParams<{ tab?: string }>();
  const [activeTab, setActiveTab] = useState<string>(
    tab || WATCHLIST_MANAGE_TAB.FOLDERS,
  );

  const handleTabChange = (newTab: string) => {
    history.push(`/watchlist/manage-watchlist/${newTab.toLowerCase()}`);
    setActiveTab(newTab);
  };

  return (
    <BasePageWithMetadata title="Manage Watchlist">
      <SectionBackButton previousLabel={'Back'} />
      <section className="h-full flex flex-col lg:p-4">
        <div className="flex-1 flex flex-col shadow-[0px_3px_6px_@boxShadow] pt-4 pb-0 px-4 bg-white lg:rounded-lg">
          <div className="flex items-center justify-between">
            <h2
              data-test-id="Buckets"
              className="text-xl font-bold text-darkest m-0"
            >
              Manage Watchlist
            </h2>
            <div className="flex items-center gap-2">
              <Dropdown
                placement="bottomRight"
                trigger={['click']}
                menu={{
                  items: [
                    {
                      label: 'Folder',
                      key: 'folder',
                    },
                    {
                      label: 'Bucket',
                      key: 'bucket',
                    },
                  ],
                }}
              >
                <Button
                  type="primary"
                  className="h-10"
                  icon={<AddIcon className="icon !h-3" />}
                >
                  Add
                  <IconArrowDownStroke className="icon !h-2" />
                </Button>
              </Dropdown>
              <Dropdown
                placement="bottomRight"
                trigger={['click']}
                menu={{
                  items: [
                    {
                      label: 'Order watchlist',
                      key: 'order-watchlist',
                      onClick: () => {
                        history.push(
                          `/watchlist/${WATCHLIST_MANAGE_TAB.ORDER_WATCHLIST}`,
                        );
                      },
                    },
                    {
                      label: 'Import funds/trusts',
                      key: 'import-funds-trusts',
                      onClick: () =>
                        history.push(`/${APP_ROUTE_WATCHLIST}/import-funds`),
                    },
                  ],
                }}
              >
                <div>
                  <IconAction className="w-10 h-10 hover:bg-midLight rounded" />
                </div>
              </Dropdown>
            </div>
          </div>
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            items={[
              {
                key: WATCHLIST_MANAGE_TAB.FOLDERS,
                label: 'Folders',
                children: <Folders />,
              },
              {
                key: WATCHLIST_MANAGE_TAB.BUCKETS,
                label: 'Buckets Not in Folder',
                children: <Buckets />,
              },
            ]}
          />
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default ManageWatchlist;
