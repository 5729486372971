import React, { PropsWithChildren } from 'react';

import Accordion, { Panel } from '@/components/Accordion';

import useExploreFilters from '../hooks/useExploreFilters';
import lengthOrEmpty from '../utils/lengthOrEmpty';
import EsgActFilter from './IndividualFilters/EsgFilters/EsgActFilter';
import EsgActionSurveyRatingsFilter from './IndividualFilters/EsgFilters/EsgActionSurveyRatingsFilter';
import EsgF4BFilter from './IndividualFilters/EsgFilters/EsgF4BFilter';
import SfdrEsgFilter from './IndividualFilters/EsgFilters/EsgSfdrFilter';
import EsgTcfdFilter from './IndividualFilters/EsgFilters/EsgTcfdFilter';
import EsgUnpriFilter from './IndividualFilters/EsgFilters/EsgUnpriFilter';
import EsgYesNoFilter from './IndividualFilters/EsgFilters/EsgYesNoFilter';
import styles from './style.module.less';

const EsgFilterPanels: React.FC<PropsWithChildren> = () => {
  const { tentativeFilters } = useExploreFilters();
  return (
    <div className={styles['share-actions-mobile']}>
      <Accordion defaultActiveKey="1">
        <Panel
          header="SFDR (Fund Level)"
          key="1"
          extra={lengthOrEmpty(tentativeFilters.esgFilters?.checkedSFDR)}
        >
          <SfdrEsgFilter />
        </Panel>

        <Panel
          header="Task Force on Climate-Related Financial Disclosures (TCFD)"
          key="2"
          extra={lengthOrEmpty(tentativeFilters.esgFilters?.datesTCFD)}
        >
          <EsgTcfdFilter />
        </Panel>

        <Panel
          header="UN Principles for Responsible Investment (UN PRI)"
          key="3"
          extra={lengthOrEmpty(tentativeFilters.esgFilters?.datesUNPRI)}
        >
          <EsgUnpriFilter />
        </Panel>

        <Panel
          header="Net Zero Asset Managers (Net Zero)"
          key="4"
          extra={
            tentativeFilters.esgFilters?.netZeroAssetManager !== undefined
              ? '(1)'
              : ''
          }
        >
          <div data-test-id={'ESGLabelNetZero'}>
            <EsgYesNoFilter filterKey="netZeroAssetManager" />
          </div>
        </Panel>

        <Panel
          header="Stewardship Code (UK SC)"
          key="5"
          extra={
            tentativeFilters.esgFilters?.stewardshipCode !== undefined
              ? '(1)'
              : ''
          }
        >
          <div data-test-id={'ESGLabelUKSC'}>
            <EsgYesNoFilter filterKey="stewardshipCode" />
          </div>
        </Panel>

        <Panel
          header="Finance for Biodiversity (F4B)"
          key="6"
          extra={lengthOrEmpty(
            tentativeFilters.esgFilters?.financeForBiodiversityDates,
          )}
        >
          <div data-test-id={'ESGLabelF4B'}>
            <EsgF4BFilter />
          </div>
        </Panel>

        <Panel
          header="ACT"
          key="7"
          extra={lengthOrEmpty(tentativeFilters.esgFilters?.actDates)}
        >
          <div data-test-id={'ESGLabelACT'}>
            <EsgActFilter />
          </div>
        </Panel>

        <Panel
          header="Share Action Ratings"
          key="8"
          extra={lengthOrEmpty(tentativeFilters.esgFilters?.shareActionRatings)}
        >
          <div data-test-id={'ESGLabelShareActionRatings'}>
            <EsgActionSurveyRatingsFilter />
          </div>
        </Panel>
      </Accordion>
    </div>
  );
};

export default EsgFilterPanels;
