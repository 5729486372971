import { ResearchApi, ResearchCardItem } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { RESEARCH_CARDS } from './query-keys';

const useGetResearchCardsQuery = (areAdminSettingsPresent: boolean) => {
  const researchApi = new ResearchApi(openApiConfig());

  return useQuery<ResearchCardItem[], unknown>(
    [RESEARCH_CARDS],
    async () => {
      const response = await researchApi.getResearchCards();
      return response.data;
    },
    { enabled: areAdminSettingsPresent },
  );
};

export default useGetResearchCardsQuery;
