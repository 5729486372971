import { Fund } from '@aminsights/contract';
import { MaturityRangeTypeCodes, SalesPosition } from '@aminsights/shared';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { DataTable } from '@/components/Table';
import { useFundIsInvestmentTrust } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { IMaturity, columns } from './columns';

const formMaturityData = (fund: Fund): IMaturity[] => {
  const result = [];
  result.push(
    ...(fund.bondMaturityRange
      ?.filter(v => v.salePosition === SalesPosition.Long)
      .map(maturity => {
        const definition = MaturityRangeTypeCodes.find(
          v => v.typeCode === maturity.type,
        )?.definition;
        return {
          maturity: definition || '',
          fund: maturity.value,
        };
      }) || []),
  );

  result.sort((a, b) => (a.fund < b.fund ? 1 : -1));
  return result;
};

const MaturityTable: React.FC<PropsWithChildren> = () => {
  const { data: state } = useFundByIsInParam();
  if (!state) return null;
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [maturityList, setMaturityList] = useState<IMaturity[]>([]);

  useEffect(() => {
    if (state?.fund) {
      setMaturityList(formMaturityData(state.fund));
    }
  }, [state]);

  return (
    <>
      <div
        className="bg-white rounded-lg"
        data-test-id="fundDetailsPortfolioTabMaturityTable"
      >
        <DataTable
          uniqueKey="sector"
          columns={columns(isInvestmentTrust)}
          data={maturityList}
          noDataLabel="No portfolio data provided"
        />
      </div>
      <div className="py-4 px-2 w-full">
        <p className="text-xs text-neutral">Only captures long positions</p>
      </div>
    </>
  );
};

export default MaturityTable;
