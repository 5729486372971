import RestrictedAccessPage from '@/components/RestrictedAccessPage';
import { RESTRICTED_FEATURE } from '@/constants/features';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import Risk from './InfoAndStats/Risk';
import Performance from './Performance';

const PerfRisk = () => {
  const { isRestrictedAccess } = useUpgradeAccess();

  return (
    <>
      <Performance />
      <div className="lg:px-4">
        <div
          className="bg-white my-4 lg:rounded-lg"
          data-test-id="fundDetailsPerfRiskTabRisk"
        >
          <h2 className="text-sm text-darkest font-bold p-4 pb-0">Risk</h2>
          {isRestrictedAccess ? (
            <RestrictedAccessPage
              restrictedFeature={RESTRICTED_FEATURE.FUND_PERF_AND_RISK}
              title="Upgrade to unlock this feature"
              description="Interested in upgrading? Tell us a bit more about what you need, and a member of our team will be in touch."
              cta={{ text: 'Enquire now' }}
              className="!h-80"
            />
          ) : (
            <Risk />
          )}
        </div>
      </div>
    </>
  );
};

export default PerfRisk;
