import { GetUserProfileResponse } from '@aminsights/contract';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Input from '@/components/Input';
import { SimpleAlert } from '@/components/SimpleAlert';
import { UpdateProfileFormProps, updateProfileFields } from '@/constants/auth';

import { Button } from 'antd';
import style from './style.module.less';

type UpdateProfileProps = {
  user: GetUserProfileResponse;
  submitting: boolean;
  onSubmit: (values: UpdateProfileFormProps) => void;
  error: string | null;
};

export const UpdateProfileForm: React.FC<
  PropsWithChildren<UpdateProfileProps>
> = ({ onSubmit, submitting, user, error }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<UpdateProfileFormProps>({
    defaultValues: {
      email: user.email,
      firstName: user.given_name || '',
      lastName: user.family_name || '',
      phone: user.phone_number || '',
      company: user.company || '',
    },
  });

  const renderFields = updateProfileFields.map(fields => {
    const disabledFields = ['email', 'company'];
    return (
      <div
        key={`${fields[0].name}-row`}
        className={style['update-profile__fields-input']}
      >
        {fields.map((field, i) => {
          const addMargin = fields.length - 1 !== i;
          return (
            <div
              key={field.name}
              className={cx('w-full flex mb-4', { 'mr-5': addMargin })}
            >
              <Controller
                control={control}
                name={field.name}
                rules={field.validation}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error: fieldError },
                }) => (
                  <Input
                    autoFocus={field.autoFocus}
                    id={field.name}
                    dataTestId={`${field.name}Input`}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={fieldError?.message}
                    value={value}
                    disabled={disabledFields.includes(field.name)}
                    placeholder={field.placeholder}
                    maxLength={field.maxLength}
                  />
                )}
              />
            </div>
          );
        })}
      </div>
    );
  });

  const onFormSubmit = async (values: UpdateProfileFormProps) => {
    await onSubmit(values);
    reset({}, { keepValues: true });
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className={style['update-profile__form']}
    >
      {renderFields}
      {error && (
        <div className="mb-6">
          <SimpleAlert type="warning" message={error} showIcon closable />
        </div>
      )}
      <div className="flex flex-row justify-end">
        <Button
          data-test-id="saveProfileButton"
          disabled={submitting || !isDirty || Object.keys(errors).length !== 0}
          type="primary"
          size="large"
          htmlType="submit"
        >
          {submitting ? 'Please wait...' : 'Save Changes'}
        </Button>
      </div>
    </form>
  );
};
