import { NoteCategory, NoteRecord, NoteStatus } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Dropdown, Modal, Tooltip } from 'antd';
import cx from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as IconPublish } from '@/assets/svg/icons/publish.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { ReactComponent as SuccessIcon } from '@/assets/svg/research/success.svg';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import {
  useDeleteNotes,
  useGetNotes,
  usePublishNotes,
} from '@/hooks/query-hooks/research-hooks/notes-hooks';
import useUserAccess from '@/hooks/useUserAccess';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import NotesSkeletonLoader from './Loaders/NotesSkeletonLoader';
import MainEmptyState from './MainEmptyState';
import CardNotes from './MainEmptyState/component/CardNotes';
import DropdownMenuWithModal from './MainEmptyState/component/DropdownMenuWithModal';

const Research: React.FC = () => {
  const history = useHistory();
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const { data: fundData } = useFundByIsInParam();
  const { data, isLoading: isNotesDataLoading } = useGetNotes(fundMetadataId);
  const { mutate: deleteNotes } = useDeleteNotes();
  const [noteData, setNoteData] = useState<NoteRecord[]>([]);
  const [categories, setCategories] = useState<NoteCategory[]>([]);
  const [isThereNewToPublish, setIsThereNewToPublish] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [successPublishedModalShow, setSuccessPublishedModalShow] =
    useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isMobileFirstTap, setIsMobileFirstTap] = useState(true);
  const screenWidthMode = getScreenWidthMode();
  const isTablet = screenWidthMode[ScreenWidthEnum.MaxLg];
  const { hasPermissions } = useUserAccess();

  const draftNotes = data?.filter(
    item =>
      item.status === NoteStatus.DraftNew ||
      item.status === NoteStatus.DraftUpdate,
  );

  useEffect(() => {
    if (data) {
      // Define the order of categories
      const categoryOrder = [
        NoteCategory.Summary,
        NoteCategory.People,
        NoteCategory.Philosophy,
        NoteCategory.Process,
        NoteCategory.Portfolio,
        NoteCategory.Performance,
      ];

      // Filter out deleted notes and sort by category order
      const sortedData = data.sort((a, b) => {
        return (
          categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
        );
      });

      setNoteData(sortedData);

      // Extract categories
      const categories = sortedData.map(item => item.category);
      setCategories(categories);

      // Check for "Draft" status
      const hasDraftStatus = data.some(
        item =>
          item.status === NoteStatus.DraftNew ||
          item.status === NoteStatus.DraftUpdate,
      );
      setIsThereNewToPublish(hasDraftStatus);
    }
  }, [data]);

  const { mutate: publishNotes, isSuccess } = usePublishNotes();

  useEffect(() => {
    if (isSuccess) {
      setSuccessPublishedModalShow(true);
    }
  }, [isSuccess]);

  const handlePublishNotes = async () => {
    if (!isTablet || !isMobileFirstTap) {
      try {
        if (!draftNotes) {
          return;
        }
        await publishNotes({
          fundMetadataId,
          notes: draftNotes,
          fundName: fundData?.fund.shareClassDetails.code || 'Unknown Fund',
        });
      } catch (error) {
        console.error('Failed to publish notes:', error);
      }
    }
  };

  const handleTooltipVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean),
  ) => {
    setTooltipVisible(visible);
    if (!visible) {
      setIsMobileFirstTap(true);
    }
    if (isMobileFirstTap) {
      setTooltipVisible(true);
      setIsMobileFirstTap(false);
    }
  };

  const hasResearchWritePermissions = hasPermissions([
    USER_PERMISSIONS.research.write,
  ]);

  return (
    <>
      {isNotesDataLoading ? (
        <NotesSkeletonLoader />
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold text-neutral-900">Notes</h3>
            {noteData.length > 0 && (
              <div className="flex gap-4 items-center justify-end">
                <Tooltip
                  title="This will notify your organisation. We recommend publishing once you have finished."
                  placement="bottom"
                  color="#313341"
                  align={{ offset: [0, 15] }}
                  overlayStyle={{ width: '240px' }}
                  trigger={isTablet ? 'click' : 'hover'}
                  open={tooltipVisible && !!draftNotes?.length}
                  onOpenChange={handleTooltipVisibleChange}
                >
                  {hasResearchWritePermissions && (
                    <Button
                      className={cx(
                        'text-sm font-medium leading-5 rounded !border max-sm:w-10 max-sm:p-0',
                        isThereNewToPublish
                          ? '!border-primary !text-primary fill-primary hover:!text-primary-400 hover:!border-primary-400 hover:fill-primary-400'
                          : '!bg-[unset] !text-neutral-300 !border-neutral-300 fill-neutral-300',
                      )}
                      type="default"
                      size="large"
                      disabled={!isThereNewToPublish}
                      onClick={handlePublishNotes}
                    >
                      <IconPublish />
                      <span className="max-sm:hidden">Publish</span>
                    </Button>
                  )}
                </Tooltip>
                {hasResearchWritePermissions && (
                  <DropdownMenuWithModal
                    noteCategory={categories}
                    hideTextOnSmallScreen
                  />
                )}
                {hasResearchWritePermissions && (
                  <Dropdown
                    menu={{
                      className:
                        'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
                      items: [
                        {
                          key: 'view-history',
                          label: 'View history',
                          onClick: () => {
                            history.push(
                              `/fund/${fundMetadataId}/details/research/history`,
                            );
                          },
                        },
                        {
                          key: 'delete',
                          label: 'Delete all',
                          onClick: () => {
                            setIsDeleteModalVisible(true);
                          },
                          danger: true,
                          disabled: false,
                        },
                      ],
                    }}
                    placement="bottomRight"
                    autoAdjustOverflow={false}
                    align={{ offset: [0, 10] }}
                    trigger={['click']}
                  >
                    <div className="flex w-10 h-10 justify-center items-center hover:bg-grey-light-50 cursor-pointer rounded">
                      <MeatballMenu
                        width={16}
                        height={16}
                        className="text-neutral-700 mx-2"
                      />
                    </div>
                  </Dropdown>
                )}
              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="flex-1 flex flex-col items-center">
            {noteData.length === 0 ? (
              <div className="h-full mb-2 flex flex-col items-center justify-center">
                <MainEmptyState
                  onClick={() => {}}
                  title="You don’t have any notes yet"
                  subTitle="Start adding your notes by clicking on the button below."
                  buttonLabel="Add a note"
                />
                {hasResearchWritePermissions && (
                  <DropdownMenuWithModal noteCategory={categories} />
                )}
              </div>
            ) : (
              <div className="mt-6 w-full flex flex-col gap-6 min-h-48">
                {noteData.map(note => (
                  <CardNotes key={note._id} note={note} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        centered
        open={successPublishedModalShow}
        footer={null}
        width={480}
        onCancel={() => setSuccessPublishedModalShow(false)}
      >
        <div className="py-10 flex flex-col justify-center items-center">
          <SuccessIcon />
          <p className="text-xl font-bold my-2">Successfully published!</p>
          <span className="text-sm mb-6">
            This note is now visible to everyone in your organisation
          </span>
          <Button
            size="large"
            type="primary"
            onClick={() => setSuccessPublishedModalShow(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <ConfirmationModalDanger
        isVisible={isDeleteModalVisible}
        modalInfo={{
          title: 'Delete notes?',
          description: (
            <span className="text-sm font-normal text-neutral-700">
              All notes will be deleted. This action cannot be undone.
            </span>
          ),
          primaryActionLabel: 'Delete',
        }}
        toggleModal={() => setIsDeleteModalVisible(false)}
        onConfirm={() => {
          deleteNotes({
            fundMetadataId,
            noteBody: noteData,
          });
        }}
      />
    </>
  );
};

export default memo(Research);
