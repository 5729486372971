import { ChartsApi, UnboxedChartDataResponse } from '@aminsights/contract';
import {
  RipsReturnType,
  RollingReturnPeriod,
  unboxChartDataItemsToChartDataSeries,
} from '@aminsights/shared';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { CHARTS, CHARTS_ROLLING_RETURNS } from '../watchlist-hooks/query-keys';

export interface RollingReturnsChartParams {
  period: string;
  rollingPeriod: RollingReturnPeriod;
  isins: string[];
  benchmarkId?: string;
  sectors: string[];
  portfolios?: string[];
  investmentTrustReturn?: RipsReturnType;
  featuredIsins?: string[];
  retainSortOrder?: boolean;
  excludeRecentLaunches?: boolean;
}

const useGetRollingReturnsChartData = (
  params: RollingReturnsChartParams,
  options?: Omit<
    UseQueryOptions<
      UnboxedChartDataResponse | null,
      unknown,
      UnboxedChartDataResponse | null
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    period,
    isins,
    rollingPeriod,
    benchmarkId,
    sectors,
    portfolios,
    investmentTrustReturn,
    featuredIsins,
    retainSortOrder,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());
  const enabled =
    (isins.length !== 0 || sectors.length !== 0 || benchmarkId) && period;

  return useQuery<
    UnboxedChartDataResponse | null,
    unknown,
    UnboxedChartDataResponse | null
  >(
    [CHARTS, CHARTS_ROLLING_RETURNS, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getRollingReturnsChartData(
        isins,
        benchmarkId,
        sectors,
        portfolios,
        period,
        investmentTrustReturn,
        rollingPeriod,
        featuredIsins,
        retainSortOrder,
      );
      const unboxedData = unboxChartDataItemsToChartDataSeries(
        response.data?.data,
      );
      return {
        ...response?.data,
        data: unboxedData,
      };
    },
    {
      ...options,
    },
  );
};

export default useGetRollingReturnsChartData;
