import { Checkbox, Divider } from 'antd';
import cx from 'classnames';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { toCamelCase } from '@/utils/toCamelCase';

import { RESTRICTED_FEATURE } from '@/constants';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import { useExploreDefaultFiltersQuery } from '../../../context';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { Search } from '../../Search';
import VirtualizedList from '../../VirtualizedList';
import style from '../../style.module.less';

const IaFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const [filter, setFilter] = useState('');
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const { selectedIaSectors } = tentativeFilters.iaSectors ?? {};

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const iaSectors = useMemo(
    () =>
      defaultfiltersOptions.iaSectors?.map((i, idx) => ({
        label: i.label,
        checked: Boolean(selectedIaSectors?.includes(`${idx}`)),
        value: `${idx}`,
      })),
    [defaultfiltersOptions.iaSectors],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const searchedIaSectors = useMemo(() => {
    return iaSectors?.filter(provider =>
      provider.label.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [defaultfiltersOptions.iaSectors, filter]);

  const itemCallback = useCallback(
    (index: number) => {
      const item = searchedIaSectors[index];
      return <IaItem key={item.label} label={item.label} value={item.value} />;
    },
    [searchedIaSectors],
  );

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  return (
    <div>
      <div className={`${!shouldUseMobileFilter && 'px-2'}`}>
        {!isRestrictedAccess && <Search onChange={setFilter} input={filter} />}
      </div>
      <div className={`py-1 ${!shouldUseMobileFilter && 'px-2'}`}>
        <div className="flex justify-between items-center">
          <Checkbox
            checked={selectedIaSectors?.length === searchedIaSectors.length}
            className="px-2"
            onChange={({ target }) => {
              const f = getMutableFilters();
              if (target.checked) {
                f.iaSectors = {
                  selectedIaSectors: searchedIaSectors.map(item => item.value),
                };
              } else {
                f.iaSectors = undefined;
              }
              updateTentativeFilters(f);
            }}
          >
            Select all
          </Checkbox>
          <span className="font-bold text-sm text-neutral">
            {selectedIaSectors?.length && !shouldUseMobileFilter
              ? `(${selectedIaSectors.length})`
              : ''}
          </span>
        </div>
        <Divider className="m-0" />
      </div>

      <div
        className={cx(
          'w-full min-w-80',
          shouldUseMobileFilter
            ? 'min-h-[154px] [&>div>div]:max-h-[180px]' // Mobile styles
            : 'min-h-52 [&>div>div]:max-h-[150px]', // Desktop styles
        )}
      >
        <VirtualizedList
          items={searchedIaSectors}
          renderItemByIndex={itemCallback}
        />
      </div>
      {shouldUseMobileFilter && (
        <div className={cx(style['button-search-dropdown-form-note'], 'mt-4')}>
          {isRestrictedAccess ? (
            <p>
              <span className="font-bold">Note:</span> To see all IA sectors,{' '}
              <a
                onClick={() => {
                  toggleUpgradeModal(RESTRICTED_FEATURE.IA_SECTORS);
                }}
                className="underline cursor-pointer"
              >
                contact us
              </a>{' '}
              to upgrade
            </p>
          ) : (
            <p>
              <span className="font-bold">Note:</span> Returns primary share
              classes only.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Memoized sub components. Do not export or move to separate file to prevent confusion.
 */

const IaItem: FC<{
  label: string;
  value: string;
}> = memo(({ label, value }) => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const selectedIaSectors = tentativeFilters.iaSectors?.selectedIaSectors;

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  return (
    <Checkbox
      data-test-id={toCamelCase(label)}
      value={value}
      className={`${shouldUseMobileFilter ? 'px-2' : 'px-4'}`}
      checked={selectedIaSectors?.includes(value)}
      onChange={({ target }) => {
        const f = getMutableFilters();
        const newItems = f.iaSectors?.selectedIaSectors ?? [];

        if (target.checked) {
          newItems.push(value);
        } else {
          newItems.splice(newItems.indexOf(value), 1);
        }
        f.iaSectors = {
          selectedIaSectors: newItems,
        };

        updateTentativeFilters(f);
      }}
    >
      {label}
    </Checkbox>
  );
});

export default IaFilter;
