import './style.less';

import {
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  FundType,
  getExploreCategoryLink,
  getFundType,
  getShortHumanReadableStyleboxName,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';
import { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import ManagerList from '@/pages/app/FundAndInvestmentTrust/components/ManagerList';
import {
  ISection,
  emptyValues,
  formHeadingInfo,
} from '@/pages/app/FundAndInvestmentTrust/components/SectionItems';
import CustomPremiumDiscountProgressBar from '@/partials/PremiumDiscountProgressBar';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { ReactComponent as LockIcon } from '@/assets/svg/icons/icon-lock.svg';
import { RESTRICTED_FEATURE } from '@/constants';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useUpgradeAccess from '@/hooks/useUpgradeAccess';
import cx from 'classnames';
import { useExploreDefaultFiltersQuery } from '../../Explore/context';
import Loader from './Loader';
import SectionItem from './SectionItem';
import Stylebox from './Stylebox/index';

const FundDetailsSection = forwardRef<HTMLDivElement>((_, ref) => {
  const { data: state, isLoading } = useFundByIsInParam();
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const { data } = useGetCurrencyRates();
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const [headingInfo, setHeadingInfo] = useState<ISection[]>(emptyValues);
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;
  const { isRestrictedAccess, toggleUpgradeModal } = useUpgradeAccess();

  const styleBondStatisticsValue = state?.fund?.bondStatistics?.styleBox;
  const styleBoxEquity = state?.fund?.equityStyleBox;
  const isFixedIncome = isFixedIncomeBroadAssetClass(
    state?.fund?.broadAssetClass || '',
  );
  const categoryLink = getExploreCategoryLink(
    state?.fund?.msCategoryDefinition || '',
    defaultfiltersOptions.categories,
  );

  const fundType = getFundType(state?.fund.legalStructure ?? '');
  const isInvestmentTrust = fundType === FundType.INVESTMENT_TRUST;

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (state?.fund) {
      setHeadingInfo(formHeadingInfo(data, isMobile, state.fund));
    }
  }, [state?.fund]);

  if (isLoading || !state) return null;

  const StyleboxWithLoader = ({
    styleValue,
    fixedIncome,
  }: {
    styleValue: number;
    fixedIncome?: boolean;
  }) => (
    <Loader
      className="mt-2"
      loading={isLoading}
      component={
        styleValue ? (
          <div className="flex items-center gap-x-2">
            <div className="w-5 h-5">
              <Stylebox
                broadAssetClass={state.fund.broadAssetClass}
                positionPair={[[styleValue]]}
                fixedIncome={fixedIncome}
              />
            </div>
            <p className="text-sm font-bold text-darkest">
              {getShortHumanReadableStyleboxName(
                styleValue,
                fixedIncome
                  ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
                  : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ,
              )}
            </p>
          </div>
        ) : null
      }
    />
  );

  return (
    <div
      ref={ref}
      className="flex flex-col px-4 px-5 pt-4 bg-white border-t border-b border-solid md:flex-row md:flex-wrap border-grey-light-50"
    >
      <div className="flex pb-3">
        {headingInfo
          .filter(
            item =>
              item.fundType === getFundType(state.fund.legalStructure) ||
              !item.fundType,
          )
          .map(item => (
            <SectionItem
              loading={isLoading}
              key={item.label}
              title={item.label}
              content={item.value}
              tooltipTitle={item.date}
              dataTestId={item.dataTestId}
            />
          ))}
      </div>

      {!isInvestmentTrust && isMobile && state.fund && isFixedIncome && (
        <div className="mr-10">
          <div className="flex items-center w-full w-30 md:items-start md:flex-col md:min-w-38 md:w-auto md:pr-4 md:pb-4">
            <Loader
              width="50px"
              loading={isLoading}
              component={<h5 className="section-title">YTM</h5>}
            />
            <Loader
              className="mt-2"
              loading={isLoading}
              component={
                <h1 className="text-base font-semibold">
                  {state.fund &&
                    (state.fund.broadAssetClassValues?.bonds?.yieldToMaturity
                      ? `${state.fund.broadAssetClassValues?.bonds?.yieldToMaturity.toFixed(
                          1,
                        )}%`
                      : EMPTY_DATA_POINT)}
                </h1>
              }
            />
          </div>
        </div>
      )}

      {state.fund && fundType === FundType.FUND && !isFixedIncome && (
        <div className="mr-10">
          <div
            className="flex items-center w-full w-30 md:items-start md:flex-col md:min-w-38 md:w-auto md:pr-4 md:pb-4"
            data-test-id="fundDetailsHeaderStyleBox"
          >
            <Loader
              width="50px"
              loading={isLoading}
              component={
                <h5
                  className={cx('section-title', {
                    'flex gap-x-2 group hover:text-primary-400 cursor-pointer':
                      isRestrictedAccess,
                  })}
                  onClick={() => {
                    if (isRestrictedAccess)
                      toggleUpgradeModal(RESTRICTED_FEATURE.FUND_STYLE_BOX);
                  }}
                >
                  Style Box
                  {isRestrictedAccess && (
                    <LockIcon
                      className={cx(
                        'w-4 h-4 fill-neutral-100 group-hover:fill-primary-400',
                      )}
                    />
                  )}
                </h5>
              }
            />
            {!isRestrictedAccess && (
              <div className="flex gap-2">
                {styleBoxEquity && (
                  <StyleboxWithLoader styleValue={styleBoxEquity} />
                )}
                {styleBondStatisticsValue && (
                  <StyleboxWithLoader
                    styleValue={styleBondStatisticsValue}
                    fixedIncome={true}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {!!isInvestmentTrust && state.fund?._id && (
        <div
          className="mr-10 md:pb-4"
          data-test-id="fundDetailsHeaderPremiumDiscount"
        >
          <CustomPremiumDiscountProgressBar
            isin={state.fund._id}
            isFundDetails
          />
        </div>
      )}

      <div
        className="category-and-manager-wrapper"
        data-test-id="fundDetailsHeaderCategory"
      >
        {fundType !== FundType.MPS && (
          <div className="flex mb-1">
            <Loader
              loading={isLoading}
              width="150px"
              component={
                <>
                  <p className="category-title">Category</p>
                  {categoryLink && !isAppLimitedAccessEnabled ? (
                    <Link
                      to={categoryLink}
                      className="category-data hover:text-primary"
                    >
                      {state.fund?.msCategoryDefinition || EMPTY_DATA_POINT}
                    </Link>
                  ) : (
                    <h5 className="category-data">
                      {state.fund?.msCategoryDefinition || EMPTY_DATA_POINT}
                    </h5>
                  )}
                </>
              }
            />
          </div>
        )}
        <div className="flex" data-test-id="fundDetailsHeaderManager">
          <Loader
            loading={isLoading}
            className="mt-2"
            width="150px"
            component={
              <ManagerList
                managersListRaw={state.fund?.managerList}
                providerName={state.fund?.providerName}
                isInvestmentTrust={!!isInvestmentTrust}
              />
            }
          />
        </div>
      </div>
    </div>
  );
});

export default FundDetailsSection;
