import { APP_ROUTE_SETTINGS } from '@aminsights/shared';
import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { SETTINGS_ROUTES } from './routes';

const SettingsRouter: React.FC<PropsWithChildren> = () => (
  <SubRouter routes={SETTINGS_ROUTES} rootPath={APP_ROUTE_SETTINGS}>
    <Route
      exact
      path={`/${APP_ROUTE_SETTINGS}`}
      render={() => <Redirect to={`/${APP_ROUTE_SETTINGS}/profile`} />}
    />
  </SubRouter>
);

export default SettingsRouter;
