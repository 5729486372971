import { Slider } from 'antd';

import {
  moreInitialState,
  noOfHoldingMarks,
  yieldMarks,
} from '../../../hooks/moreFiltersSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../../../utils/adjustMarksToAvoidDuplicates';
import { getRangeSliderClassName } from '../../../utils/getRangeSliderClassName';
import style from '../../style.module.less';

const MoreFiltersNumberOfHoldings = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const noOfHoldings =
    tentativeFilters.moreFilters?.noOfHoldingsRange ??
    moreInitialState.noOfHoldingsRange;

  return (
    <div
      className={style['range-slider']}
      data-test-id={'moreFiltersSliderNumberOfHoldings'}
    >
      <Slider
        className={
          style[
            getRangeSliderClassName(
              !!tentativeFilters.moreFilters?.noOfHoldingsRange,
            )
          ]
        }
        tooltip={{ open: false }}
        range
        step={16}
        max={96}
        marks={noOfHoldingMarks}
        value={noOfHoldings}
        onChange={value => {
          const filters = getMutableFilters();
          filters.moreFilters = filters.moreFilters ?? {};
          filters.moreFilters.noOfHoldingsRange = adjustMarksToAvoidDuplicates(
            value,
            yieldMarks,
          );
          updateTentativeFilters(filters);
        }}
      />
    </div>
  );
};

export default MoreFiltersNumberOfHoldings;
