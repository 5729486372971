import { getLatestDate } from '@/utils/getLatestDate';

export const getLatesAsofDate = (
  data: any[],
  key:
    | 'computedNavRiskDetails'
    | 'riskMeasuresDetail'
    | 'computedNavAnnualisedReturns',
  dateKey = 'asOfDate',
) => {
  const dates = data?.map((item: any) =>
    item[key]
      ? getLatestDate(item[key].map((entry: any) => entry[dateKey]))
      : null,
  );
  return getLatestDate(dates);
};
