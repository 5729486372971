import { InternalApi, MaterialBody } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { INTERNAL } from '../watchlist-hooks/query-keys';

const internalApi = new InternalApi(openApiConfig());

const invalidateInternal = () => {
  queryClient.invalidateQueries({ queryKey: [INTERNAL] });
};

export const useCreateMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { body: MaterialBody }) => internalApi.createMaterial(input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully added a link' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error adding link',
        });
      },
    },
  );
  return mutation;
};

export const useUpdateMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { materialId: string; body: MaterialBody }) =>
      internalApi.updateMaterial(input.materialId, input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully updated a link' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating link',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { materialId: string }) =>
      internalApi.deleteMaterial(input.materialId),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully deleted a link' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting link',
        });
      },
    },
  );
  return mutation;
};
