export enum RESTRICTED_FEATURE {
  EXPLORE_CATEGORY = 'Explore - M* Category',
  FUND_DETAILS = 'Fund Details Page',
  FUND_STYLE_BOX = 'Fund Details - Style Box',
  FUND_PORTFOLIO = 'Fund Details - Portfolio',
  FUND_INVESTORS = 'Fund Details - Investors',
  FUND_COMPARE = 'Fund Details - Compare',
  FUND_PERF_AND_RISK = 'Fund Details - Perf and Risk',
  FUND_ESG = 'Fund Details - ESG',
  FUND_EQUITY_STATS = 'Fund Details - Equity Stats',
  CHART_RELATIVE = 'Charts - Relative',
  CHART_PREMIUM_DISCOUNT = 'Charts - Premium/Discount',
  IA_SECTORS = 'IA Sectors',
  BENCHMARKS = 'Benchmarks',
  WATCHLIST_CURRENY = 'Watchlist - Currency',
  WATCHLIST_EQUITY = 'Watchlist - Equity',
  WATCHLIST_FIXED_INCOME = 'Watchlist - Fixed Income',
  WATCHLIST_PERF_AND_RISK = 'Watchlist - Perf and Risk',
  WATCHLIST_CORRELATION = 'Watchlist - Correlation Matrix',
  WATCHLIST_INCOME = 'Watchlist - Income',
  WATCHLIST_ESG = 'Watchlist - ESG',
  WATCHLIST_GENDER_PAY_GAP = 'Watchlist - Gender Pay Gap',
  WATCHLIST_BUCKETS = 'Watchlist - Buckets Limit',
  COMPARE = 'Compare',
  EXPLORE_RISK = 'Explore - Risk',
  EXPLORE_EQUITIES = 'Explore - Equities',
  EXPLORE_BONDS = 'Explore - Bonds',
  EXPLORE_ESG = 'Explore - ESG',
  EXPLORE_BOND_RISK = 'Explore - Bond Risk',
  EXPLORE_EQUITY_RISK = 'Explore - Equity Risk',
  EXPLORE_CURRENCY = 'Explore - Currency',
  EXPLORE_CURRENCY_HEDGED = 'Explore - Currency Hedged',
  EXPLORE_PLATFORM_AVAILABILITY = 'Explore - Platform Availability',
  EXPLORE_OLD_SHARE_CLASS = 'Explore - Old Share Class',
  EXPLORE_NUMBER_OF_HOLDINGS = 'Explore - Number of Holdings',
  EXPLORE_ACTIVE_PASSIVE = 'Explore - Active/Passive',
  EXPLORE_MANAGER_GENDER = 'Explore - Manager Gender',
  EXPLORE_YIELD = 'Explore - Yield',
  EXPLORE_MANAGEMENT_GROUP = 'Explore - Management Group',
  EXPLORE_ASSET_CLASS = 'Explore - Asset Class',
  EXPLORE_DOMICILE = 'Explore - Domicile',
  EXPLORE_TAB_ANNUALISED_PERFORMANCE = 'Explore Tab - Annualised Performance',
  EXPLORE_TAB_CALENDAR_YEAR_PERFORMANCE = 'Explore Tab - Calendar Year Performance',
  EXPLORE_TAB_RISK = 'Explore Tab - Risk',
  EXPLORE_TAB_INCOME = 'Explore Tab - Income',
  EXPLORE_TAB_ESG = 'Explore Tab - ESG',
  EXPLORE_TAB_GENDER_PAY_GAP = 'Explore Tab - Gender Pay Gap',
  SIGNIFICANT_CHANGES = 'Significant Changes',
  PORTFOLIO = 'Portfolio',
  SETTINGS_CUSTOM_BENCHMARKS = 'Settings - Custom Benchmarks',
  SETTING_SIGNIFICANT_CHANGES = 'Settings - Significant Changes',
  SETTING_NOTIFICATIONS = 'Settings - Notifications',
  DATE_PICKER = 'Date Picker',
  RESEARCH_DASHBOARD = 'Research Dashboard',
}
