import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import { portfolioReducer } from './reducers';
import { PortfolioContextProps, PortfolioState } from './types';

export const initialState: PortfolioState = {
  portfolios: [],
  arePortfoliosLoading: true,
  isPerformanceTabLoading: true,
  markedFundsForDelete: [],
  currentPortfolioId: undefined,
};

const Context = createContext<PortfolioContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const initializer = (state: PortfolioState) => state;

const PortfolioProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(
    portfolioReducer,
    initialState,
    initializer,
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { PortfolioProvider };

export const usePortfolioContext = (): PortfolioContextProps => {
  return useContext(Context);
};

export default Context;
