import { ReactComponent as ClockIcon } from '@/assets/svg/icons/icon-clock.svg';
import { ReactComponent as ListIcon } from '@/assets/svg/icons/icon-list.svg';
import { ReactComponent as PencilIcon } from '@/assets/svg/icons/icon-pencil-v3.svg';
import { ReactComponent as WarningIcon } from '@/assets/svg/icons/icon-warning-triangle.svg';
import { ResearchCardItem, ResearchCardType } from '@aminsights/contract';

export const generateResearchCards = (
  researchCardsData: ResearchCardItem[],
  isLoading: boolean,
) => {
  return researchCardsData.map(r => {
    switch (r.cardType) {
      case ResearchCardType.Overdue:
        return {
          title: 'Overdue',
          subtitle: 'Notes or meetings requiring attention',
          count: r.value ?? 0,
          icon: (
            <span
              className={`p-2 mr-3 rounded-full flex items-center justify-center text-center
              bg-destructive-500 bg-opacity-5`}
            >
              <WarningIcon className="icon text-xl &_path:stroke-width-2.4 !fill-none" />
            </span>
          ),
          key: 1,
          isLoading,
        };
      case ResearchCardType.Upcoming:
        return {
          title: 'Upcoming',
          subtitle: 'Notes or meetings due soon',
          count: r.value ?? 0,
          icon: (
            <span
              className={`p-2 mr-3 rounded-full flex items-center justify-center text-center
              bg-primary-50`}
            >
              <ClockIcon className="icon text-xl &_path:stroke-width-2.4 !fill-none [&>path]:stroke-primary" />
            </span>
          ),
          key: 2,
          isLoading,
        };
      case ResearchCardType.Draft:
        return {
          title: 'Draft Notes',
          subtitle: 'Notes yet to be published',
          count: r.value ?? 0,
          icon: (
            <span
              className={`p-2 mr-3 rounded-full flex items-center justify-center text-center
              bg-warning-50`}
            >
              <PencilIcon className="icon text-xl &_path:stroke-width-2.4 !fill-none" />
            </span>
          ),
          key: r.value,
          isLoading,
        };
      case ResearchCardType.Coverage:
        return {
          title: 'Fund Coverage',
          subtitle: 'Funds under your coverage',
          count: r.value ?? 0,
          icon: (
            <span
              className={`p-2 mr-3 rounded-full flex items-center justify-center text-center
              bg-grey-lighter`}
            >
              <ListIcon className="icon text-xl &_path:stroke-width-2.4 !fill-none" />
            </span>
          ),
          key: 4,
          isLoading,
        };
    }
  });
};
