import { ChartDataTag } from '@aminsights/contract';

import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RollingReturnsChart from '@/partials/Charts/rolling-returns/RollingReturnsChart';
import RollingReturnsChartTitle from '@/partials/Charts/rolling-returns/RollingReturnsChartTitle';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RollingReturnsChartingToolPage = () => {
  const {
    isins,
    benchmark,
    sector,
    portfolios,
    setBenchmark,
    setSector,
    setIsins,
    setPortfolios,
  } = useChartTrustFundsFromSearch();
  const chartingCtx = useChartingContext();
  const chartColors = chartingCtx.chartColors;
  const legendData = chartingCtx.chartLegend;

  if (!(isins.length || portfolios.length || sector || benchmark)) {
    return (
      <ChartEmptyState
        title="No added funds/trusts/portfolios to chart"
        subtitle="Search funds/portfolios to chart"
        isDashboardPage={true}
      />
    );
  }

  return (
    <>
      <RollingReturnsChartTitle />
      <RollingReturnsChart
        emptyState={{
          title: 'No data for this time period',
          subTitle: 'Try a different time range',
        }}
        onRemoveFund={(id, dataTag) => {
          if (dataTag === ChartDataTag.Benchmark) {
            setBenchmark('');
            return;
          }
          if (dataTag === ChartDataTag.Sector) {
            setSector('');
            return;
          }
          if (dataTag === ChartDataTag.Portfolio) {
            setPortfolios([id], true);
            const targetId =
              legendData.find(ld => ld.id?.includes(id))?.id || '';
            const targetColor = chartColors[targetId];
            targetColor && chartingCtx.setColorsRemoved(targetColor);
            return;
          }

          const targetId = legendData.find(ld => ld.isin === id)?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          setIsins([id], true);
        }}
        isins={isins}
        benchmarkId={benchmark}
        sectorId={sector}
        portfolios={portfolios}
        resetInvReturnFilterOnChange={false}
        chartColors={chartColors}
      />
    </>
  );
};

export default RollingReturnsChartingToolPage;
