import './style.less';

import { ManagerItem } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';

type ManagerListProps = {
  managersListRaw: ManagerItem[] | undefined;
  providerName?: string;
  isInvestmentTrust: boolean;
};

const ManagerList: React.FC<PropsWithChildren<ManagerListProps>> = ({
  managersListRaw = [],
  providerName,
  isInvestmentTrust,
}) => {
  const [managers, setManagers] = useState(EMPTY_DATA_POINT);

  useEffect(() => {
    if (managersListRaw?.length) {
      setManagers(
        managersListRaw
          .map(x => x.name)
          .join(', ')
          .trim(),
      );
    }
  }, [managersListRaw]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (managers) {
      const isNotDisclosed = managers === 'Not Disclosed';
      if (isNotDisclosed) {
        setManagers(
          isInvestmentTrust
            ? (providerName ?? EMPTY_DATA_POINT)
            : EMPTY_DATA_POINT,
        );
      }
    }
  }, [managers]);

  return (
    <>
      <p className="manager-title">Manager</p>
      <h5
        className={cx('manager-list', {
          'manager-list-toolip': managersListRaw.length > 2,
        })}
      >
        <Tooltip
          placement="bottom"
          title={managersListRaw.length > 2 ? managers : ''}
          overlayClassName="max-w-60 md:max-w-none"
          overlayInnerStyle={{ borderRadius: 4 }}
          arrowPointAtCenter
        >
          {managers}
        </Tooltip>
      </h5>
    </>
  );
};

export default ManagerList;
