import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NoConnectionIcon } from '@/assets/svg/icons/icon-no-connection.svg';
import Button from '@/components/Button';

const NotFoundPage: React.FC<PropsWithChildren> = () => {
  const history = useHistory();
  return (
    <div className="h-[100vh] w-full absolute left-0 top-0 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <NoConnectionIcon className="w-[20rem] sm:w-[30rem] xl:w-[40rem]" />
        <div className="flex flex-col items-center font-bold text-neutral-200">
          <h1 className="text-[4rem] mb-[-1rem]">404</h1>
          <p className="text-[1.5rem]">Page not found</p>
        </div>
        <p className="text-neutral !mb-8">
          We can&lsquo;t seem to find the page you&lsquo;re looking for
        </p>
        <Button type="primary" onClick={() => history.replace('/')}>
          Go back to dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
