import { AnnualisedReturn } from '@aminsights/contract';
import { MonthCode, isInvestmentTrust, roundOrEmpty } from '@aminsights/shared';

type RequiredFundProps = {
  legalStructure?: string;
  computedNavAnnualisedReturns?: AnnualisedReturn[];
  computedSharePriceAnnualisedReturns?: AnnualisedReturn[];
};

interface Props<T extends RequiredFundProps> {
  fund?: T;
  timePeriod?: MonthCode;
  format?: (value: number | undefined) => string;
}

const getComputedReturnValue = (
  fund: RequiredFundProps | undefined,
  timePeriod: MonthCode | undefined,
): number | undefined => {
  if (!fund || !fund.legalStructure) {
    console.error('No fund or legal structure found to get computed return');
    return undefined;
  }

  const isTrust = isInvestmentTrust(fund.legalStructure);
  const computedReturns = isTrust
    ? fund.computedSharePriceAnnualisedReturns
    : fund.computedNavAnnualisedReturns;

  return computedReturns?.find(
    computedReturn => computedReturn.timePeriod === timePeriod,
  )?.percentage;
};

export const RenderAnnualisedReturns = <T extends RequiredFundProps>({
  fund,
  timePeriod = MonthCode.M1,
  format,
}: Props<T>) => {
  const getReturnValue = (): number | undefined => {
    return getComputedReturnValue(fund, timePeriod);
  };

  const value = getReturnValue();

  const output = format ? format(value) : roundOrEmpty(value, 1, '%');

  return output;
};
