import { ReactComponent as IconAction } from '@/assets/svg/icons/icon-action-transparent.svg';
import { ReactComponent as IconFolder } from '@/assets/svg/icons/icon-solid-folder.svg';
import { BucketsFolder } from '@aminsights/contract';
import { Dropdown } from 'antd';

const FolderCard = ({
  folder,
}: {
  folder: BucketsFolder;
}) => {
  return (
    <div className="h-[88px] bg-primary-50 rounded p-2 hover:border-[#99CCFF] border border-primary-50 hover:cursor-pointer">
      <div className="flex items-center justify-between pb-2">
        <IconFolder />
        <Dropdown
          placement="bottomRight"
          trigger={['click']}
          overlayClassName="w-[200px]"
          menu={{
            items: [
              {
                label: 'Rename',
                key: 'rename',
              },
              {
                type: 'divider',
              },
              {
                label: 'Delete',
                key: 'delete',
                danger: true,
              },
            ],
          }}
        >
          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconAction className="hover:bg-midLight rounded" />
          </div>
        </Dropdown>
      </div>
      <div>
        <p className="text-xs text-neutral-900 font-bold">{folder.name}</p>
        <p className="text-xs text-neutral-500 font-normal">
          ({folder.buckets?.length} buckets)
        </p>
      </div>
    </div>
  );
};

export default FolderCard;
